import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AuthState, AuthStoreStore } from './auth-store.store';

@Injectable({
  providedIn: 'root',
})
export class AuthStoreQuery extends QueryEntity<AuthState> {
  getAccessToken$ = this.select((state) => state.accessToken);
  getAuthConfig$ = this.select((state) => state.authConfig);

  constructor(protected store: AuthStoreStore) {
    super(store);
  }
}
