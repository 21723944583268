import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from '../../../../../src/app/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class ResolverSMR implements Resolve<any> {
  constructor(private permissionService: PermissionService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.permissionService.getUserPermissionCMP();
  }
}
