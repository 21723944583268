import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import validationEngine from 'devextreme/ui/validation_engine';
import dxTreeList, { dxTreeListColumn } from 'devextreme/ui/tree_list';
import dxCheckBox from 'devextreme/ui/check_box';

@Component({
  selector: 'pp-ui-dropdown-tree-list',
  templateUrl: './dropdown-tree-list.component.html',
  styleUrls: ['./dropdown-tree-list.component.scss'],
})
export class DropdownTreeListComponent implements OnInit {
  @ViewChild(DxDropDownBoxComponent, { static: true })
  dropdown!: DxDropDownBoxComponent;

  @Input() value: any; // eslint-disable-line
  @Input() dataSource: DataSource;
  @Input() selectionMode: 'multiple' | 'single' = 'single';
  @Input() valueExpr = 'Id';
  @Input() placeholder = $localize`Введите название`;
  @Input() columns: dxTreeListColumn[];
  @Input() displayExpr: string;
  @Input() parentIdExpr: string;
  @Input() disabled = false;
  @Input() rootValue: any; // eslint-disable-line
  @Input() height: number | string = '100%';
  @Input() scrollingMode: 'virtual' | 'standart' = 'standart';
  @Input() showClearButton = false;
  @Input() isRequired = true;
  @Input() sortOrder = 'asc';

  @Output() onChangeItem = new EventEmitter();
  @Output() onPressClearButton = new EventEmitter();
  @Output() onInitialized = new EventEmitter();
  @Output() onValueChanged = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.onInitialized.next(this);
  }

  validate(e) {
    validationEngine.validateGroup('objectVG');

    if (e?.previousValue && !e.value) {
      this.onPressClearButton.emit(true);
    }

    this.selectionMode === 'single' ? this.dropdown.instance.close() : null;
    this.onValueChanged.emit(e);
  }

  selectionItem(event: {
    component: dxTreeList;
    selectedRowKeys: string[];
    selectedRowsData: any[]; // eslint-disable-line
    currentDeselectedRowKeys: string[];
  }): void {
    if (this.selectionMode === 'multiple') {
      if (event.selectedRowsData.length && event.selectedRowsData.some((x) => x.blockSelection)) {
        event.component.selectRows(
          event.selectedRowsData.filter((x) => !x.blockSelection).map((x) => x.Id),
          false
        );
        return;
      }
    } else {
      if (event.selectedRowsData.length && event.selectedRowsData[0].blockSelection) {
        event.component.selectRows(event.currentDeselectedRowKeys, false);
        return;
      }
    }

    this.onChangeItem.emit(event);
    this.value = event.selectedRowKeys;
  }

  hideCheckboxes(e: { row?: { data: { blockSelection?: boolean } }; editorName: string; editorElement: HTMLElement }) {
    if (e.editorName === 'dxCheckBox' && e.row?.data?.blockSelection) {
      const CBinstance = dxCheckBox.getInstance(e.editorElement);

      e.editorElement.parentElement.classList.remove('dx-editor-inline-block');
      CBinstance.dispose();
    }
  }

  adapterConfig = {
    getValue: () => {
      return this.value;
    },
    applyValidationResults: (e) => {
      this.dropdown.isValid = e.isValid;
    },
    // validationRequestsCallbacks: []
  };
}
