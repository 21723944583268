import { inject, Injectable } from '@angular/core';
import {
  bufferTime,
  catchError,
  concatMap,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  Observable,
  of,
  share,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { DownloadsStore } from './store/downloads.store';
import { IProcess } from './process.interface';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';

@Injectable()
export class DownloadsService {
  private downloadsStore = inject(DownloadsStore);

  constructor() {}

  toggleDownloads() {
    this.downloadsStore.toggle();
  }

  download(observable: Observable<HttpEvent<Blob>>, fileName: string) {
    const unsub$ = new Subject<void>();

    const id = this.downloadsStore.newDownload();

    const process$: IProcess = observable
      .pipe(
        takeUntil(unsub$),
        tap((e) => {
          console.log(e);
        }),
        filter((event) => {
          return [HttpEventType.Sent, HttpEventType.Response, HttpEventType.DownloadProgress].includes(event.type);
        }),
        map((event) => {
          if (event.type === HttpEventType.Sent) {
            return {
              status: 'start' as const,
              progress: 0 as const,
              fileName,
            } as const;
          }

          if (event.type === HttpEventType.DownloadProgress) {
            if (event.loaded && event.total) {
              return {
                status: 'pending' as const,
                progress: Math.round((100 * event.loaded) / event.total),
                fileName,
              };
            }
          }

          // save
          if (event.type === HttpEventType.Response) {
            const downloadedFileName = this.downloadFile(event, fileName);
            return {
              status: 'done' as const,
              progress: 100 as const,
              fileName: downloadedFileName || fileName,
            };
          }

          return null;
        }),
        bufferTime(300),
        concatMap((arr) => from(arr).pipe(distinctUntilKeyChanged('status'))),
        share()
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.log(err);
          unsub$.next();

          if (err.error instanceof Blob) {
            return err.error.text().then((errText) => {
              return {
                status: 'error' as const,
                progress: 100 as const,
                error: JSON.parse(JSON.parse(errText).Message).details,
                fileName,
              };
            });
          } else {
            return of({
              status: 'error' as const,
              progress: 100 as const,
              error: err.message,
              fileName,
            });
          }
        }),
        tap({
          complete: () => {
            unsub$.next();
            this.downloadsStore.removeProcess(id);
          },
        })
      );

    this.downloadsStore.addProcess(id, process$);

    const subscription = process$.subscribe((event) => {
      console.log(event);

      this.downloadsStore.updateStatus(id, event);
    });

    this.downloadsStore.addSubscription(id, subscription);
    this.downloadsStore.addUnsubscriber(id, unsub$);

    this.downloadsStore.update({
      showDownloads: true,
    });
  }

  removeDownload(id: number) {
    const unsub = this.downloadsStore.getValue().unsubscribers.get(id);
    if (unsub) {
      unsub.next();
      unsub.complete();
    }
    const sub = this.downloadsStore.getValue().subscriptions.get(id);
    if (sub) sub.unsubscribe();
    this.downloadsStore.removeProcessAndStatus(id);
  }

  private downloadFile(event: HttpEvent<Blob>, fileName: string) {
    let actualFileName = '';

    if (event.type === HttpEventType.Response) {
      const rawData = event.headers.get('content-disposition');

      if (rawData) {
        const decodedFileName = decodeURI(rawData)
          .split(';')
          .find((n) => n.includes('filename*='))
          .replace("filename*=UTF-8''", '')
          .replace(/%3A/g, '-')
          .trim();

        if (decodedFileName) actualFileName = decodedFileName;
      }

      let anchor = document.createElement('a');
      document.body.appendChild(anchor);
      let objectUrl = window.URL.createObjectURL(event.body);
      anchor.href = objectUrl;
      anchor.download = actualFileName || fileName;
      anchor.click();
      anchor.remove();
      window.URL.revokeObjectURL(objectUrl);
    }

    return actualFileName;
  }
}
