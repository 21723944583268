import dxTreeList, { Properties } from 'devextreme/ui/tree_list';
import { Properties as ToolbarProperties } from 'devextreme/ui/toolbar';

const TreeListProperties: Properties = {
  paging: { enabled: true, pageSize: 25 },
  filterPanel: { visible: false },
  filterMode: 'withAncestors',
  columnResizingMode: 'widget',
  columnMinWidth: 65,
  width: '100%',
  showBorders: false, // взято из грида
  showColumnLines: false, // взято из грида
};

// По конфигурации столбцов:
// Отключаем поиск в столбцах с значениями из короткого списка, строго зафиксированных значений в системе. Например, статусы согласования документа.
// Так же в таких столбцах отключаем filterRow (allowFiltering: false, allowHeaderFiltering: true)

export function getSimpleTreeListProperties(specificOptions: Properties = {}): Properties {
  return {
    ...TreeListProperties,
    sorting: { mode: 'none' },
    scrolling: { mode: 'virtual', showScrollbar: 'always' },
    pager: { showPageSizeSelector: false },
    searchPanel: { visible: true, width: 250 },
    filterRow: { visible: false },
    headerFilter: { visible: false },
    columnChooser: { enabled: false },
    allowColumnReordering: false,
    allowColumnResizing: false,
    stateStoring: { enabled: false },
    showColumnHeaders: false,
    showRowLines: false,
    rowAlternationEnabled: false,
    ...specificOptions,
  };
}

export function getBaseTreeListProperties(specificOptions: Properties = {}): Properties {
  return {
    ...TreeListProperties,
    sorting: { mode: 'multiple' },
    scrolling: { mode: 'virtual', showScrollbar: 'always' },
    pager: { showPageSizeSelector: false },
    searchPanel: { visible: false },
    filterRow: { visible: true },
    headerFilter: { visible: false },
    columnChooser: { enabled: false },
    allowColumnReordering: false,
    allowColumnResizing: true,
    // stateStoring: { enabled: true, storageKey },
    showColumnHeaders: true,
    showRowLines: true,
    rowAlternationEnabled: false,
    ...specificOptions,
  };
}

export function getBigTreeListProperties(specificOptions: Properties = {}): Properties {
  return {
    ...TreeListProperties,
    sorting: { mode: 'multiple' },
    scrolling: { mode: 'standard', showScrollbar: 'always' },
    pager: { visible: true, showPageSizeSelector: true, allowedPageSizes: [25, 50, 75, 100], showInfo: true },
    searchPanel: { visible: true, width: 250 },
    filterRow: { visible: true },
    headerFilter: { visible: true, allowSearch: true },
    columnChooser: { enabled: true, mode: 'select', sortOrder: 'asc' },
    allowColumnReordering: true,
    allowColumnResizing: true,
    // stateStoring: { enabled: true, storageKey },
    showColumnHeaders: true,
    showRowLines: true,
    rowAlternationEnabled: true,
    onToolbarPreparing: addToolbarResetButton,
    ...specificOptions,
  };
}

function addToolbarResetButton(e: { component: dxTreeList; toolbarOptions: ToolbarProperties }) {
  e.toolbarOptions.items.forEach((item) => {
    if (item.name === 'columnChooserButton') {
      item.showText = 'always';
    }
  });
  e.toolbarOptions.items.push({
    locateInMenu: 'always',
    widget: 'dxButton',
    options: {
      text: $localize`Сброс настроек`,
      onClick: () => {
        e.component.state(null);
        location.reload();
      },
    },
  });
}
