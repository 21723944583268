import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthStoreQuery } from '@pp/auth-store';
import { BackendError, handleError } from 'src/app/services/services-utils';

@Component({
  selector: 'lib-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent {
  @Input() file: any;
  @Input() token: any;

  constructor(
    private authStore: AuthStoreQuery,
    private sanitizer: DomSanitizer
  ) {
    this.authStore.getAccessToken$.subscribe((token) => (this.token = token));
  }

  openInNewTab(file) {
    if (file.Type !== 'img' && file.Type !== 1) {
      return;
    }

    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.token);

    fetch(file.url, { headers })
      .then((response) => response.blob())
      .then((blob) => {
        var _url = window.URL.createObjectURL(blob);
        window.open(_url, '_blank').focus();
      })
      .catch((err) => {
        handleError(new BackendError(err));
      });
  }
}

@Pipe({ name: 'safeResourceUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}
  public transform(url: string): SafeResourceUrl {
    console.log(url);

    if (url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return url;
    }
  }
}
