import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  C3DViewCommands,
  EditSectionPlaneOptions,
  SelectProcessMode,
  C3DModelView,
  C3DViewer,
  StdCameraOrientationsList,
  RenderMode,
  ViewerSettings,
  createC3DViewer,
  ModelViewSettings,
  GetParametersTypes,
  GetSelectionListResult,
  GetAnnotationListResult,
  GetParametersResult,
  CustomCameraOrientation,
  AnimationResult,
  C3DViewComment,
  CameraProjection,
} from '@c3dlabs/c3dviewer-api';
import * as actions from './actions';
import { ISelectModelEvent, SelectModelComponent } from './forms/select-model/select-model.component';
import { LoadModelsService } from './services/load-models.service';
import { Observable } from 'rxjs';
import { ViewerQuery } from './store/viewer.query';
import { ViewerService } from './store/viewer.service';
import { HttpClient } from '@angular/common/http';
import { ICommentEvent } from './forms/comment/comment.component';
import { PositionConfig } from 'devextreme/animation/position';
import { DxPopoverComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';

declare global {
  interface Window {
    api: any;
  }
}

@Component({
  selector: 'pp-ui-bim-viewer',
  templateUrl: './bim-viewer.component.html',
  styleUrls: ['./bim-viewer.component.scss'],
})
export class BimViewerComponent implements OnInit, AfterContentInit {
  @ViewChild(DxPopoverComponent, { static: true }) popover: DxPopoverComponent | undefined;
  settingsService: any;
  explorer: any = {};
  HTMLElementId = 'graphicsView';

  instance: C3DViewer | undefined;
  view3D: C3DModelView;
  actions: actions.Actions | undefined;

  basicActions: any[] = [];
  actionToolbarInstance: any;

  showCommentPopup = false;
  showPopover = false;
  targetPopover: HTMLElement | undefined;
  positionPopover: PositionConfig = { my: 'left top', at: 'top' };

  _savedView: any;

  nowDate = new Date();

  // Переменная для правой кнопки
  objectInfo: any[] = [];

  selectMode: 'single' | 'multiple' = 'single';

  loadingModelVisible: boolean = false;
  loadingViewer: boolean = false;

  sectionPlaneOptions: EditSectionPlaneOptions & { id?: number; name?: string } = {};

  queryParams: any;

  viewCreated = false;

  @ViewChild(SelectModelComponent) selectFilesPopup;

  constructor(
    private modelService: LoadModelsService,
    private viewerQuery: ViewerQuery,
    private viewerService: ViewerService,
    private _http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.actions = new actions.Actions();

    this.basicActions = [
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'activefolder',
          hint: 'Открыть модель',
          //text: 'Открыть модель',
          onClick: () => {
            this.selectFilesPopup.open(this.popupSelectedFiles);
          },
        },
      },
      {
        location: 'center',
        template: 'separatorTemplate',
        visible: true,
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon cursor',
          hint: 'Режим курсора',
          onClick: () => {
            this.runSelectionProcess();
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon comment',
          hint: 'Добавить комментарий',
          onClick: () => {
            this.addComment();
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon cut',
          hint: 'Режим сечения',
          onClick: () => {
            this.cutModel();
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon measure',
          hint: 'Режим линейных измерений',
          onClick: () => {
            this.linearDimension();
          },
        },
      },
      {
        location: 'center',
        template: 'separatorTemplate',
        visible: true,
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon paint',
          hint: 'Сделать выделенный элемент прозрачным',
          onClick: () => {
            this.setColor(4);
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon saveView',
          hint: 'Сохранить позицию камеры',
          onClick: () => {
            this.saveView();
          },
        },
      },
      // {
      //   location: 'center',
      //   widget: "dxButton",
      //   options: {
      //     icon: 'toolbar-icon plan-fact',
      //     hint: 'Отобразить прогресс по графику проекта',
      //     onClick: () => {
      //       this.makePlanFact();
      //     }
      //   }
      // },
      {
        location: 'center',
        template: 'separatorTemplate',
        visible: true,
      },

      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon cube-top',
          hint: 'Вид сверху',
          onClick: () => {
            this.standartCameraOrientation(StdCameraOrientationsList.Up);
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon cube-bottom',
          hint: 'Вид снизу',
          onClick: () => {
            this.standartCameraOrientation(StdCameraOrientationsList.Down);
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon cube-front',
          hint: 'Вид спереди',
          onClick: () => {
            this.standartCameraOrientation(StdCameraOrientationsList.Front);
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon cube-right',
          hint: 'Вид справа',
          onClick: () => {
            this.standartCameraOrientation(StdCameraOrientationsList.Right);
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon cube-rear',
          hint: 'Вид сзади',
          onClick: () => {
            this.standartCameraOrientation(StdCameraOrientationsList.Rear);
          },
        },
      },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          icon: 'toolbar-icon cube-left',
          hint: 'Вид слева',
          onClick: () => {
            this.standartCameraOrientation(StdCameraOrientationsList.Left);
          },
        },
      },
      {
        location: 'center',
        template: 'separatorTemplate',
        visible: true,
      },
      {
        location: 'center',
        widget: 'dxButtonGroup',
        options: {
          keyExpr: 'icon',
          selectedItemKeys: ['toolbar-icon view-color'],
          stylingMode: 'text',
          items: [
            {
              icon: 'toolbar-icon view-wire',
              hint: 'Стиль отображения каркасный',
              onClick: () => {
                this.renderMode(RenderMode.wireFrame);
              },
            },
            {
              icon: 'toolbar-icon view-color',
              hint: 'Стиль отображения цветной',
              onClick: () => {
                this.renderMode(RenderMode.shaded);
              },
            },
            {
              icon: 'toolbar-icon view-wire-and-color',
              hint: 'Стиль отображения цветной с каркасом',
              onClick: () => {
                this.renderMode(RenderMode.shadedAndWireFrame);
              },
            },
          ],
        },
      },
      {
        location: 'center',
        template: 'separatorTemplate',
        visible: true,
      },
      {
        location: 'center',
        widget: 'dxButtonGroup',
        options: {
          keyExpr: 'icon',
          stylingMode: 'text',
          selectedItemKeys: ['toolbar-icon view-standard'],
          items: [
            {
              icon: 'toolbar-icon view-standard',
              hint: 'Графическое отображениние Изометрическое',
              onClick: () => {
                this.switch_prj(true);
              },
            },
            {
              icon: 'toolbar-icon view-iso',
              hint: 'Графическое отображениние Перспективное',
              onClick: () => {
                this.switch_prj(false);
              },
            },
          ],
        },
      },
      {
        location: 'center',
        template: 'separatorTemplate',
        visible: true,
      },
      // {
      //   location: 'center',
      //   widget: "dxDateBox",
      //   options: {
      //     icon: 'event',
      //     value: new Date(),
      //     onValueChanged: (e: any) => {
      //       this.setDate(e.value)
      //     }
      //   }
      // },
      {
        location: 'center',
        widget: 'dxButton',
        options: {
          // icon: 'clearsquare',
          hint: 'Сброс',
          text: 'Сброс',
          onClick: () => {
            this.stopProcess();
            this.clearCutModel();
            this.resetColors();
            this.deleteAllAnnotation();
            // this.runSelectionProcess();
            // this.resetColors();
            // this.removeAllModels();
          },
        },
      },
    ];

    // this.backEndService.getAllTasks().subscribe(res => {
    //   this.viewerService.updateAllWorks(res);
    // })

    window.api = {};
    window.api.renderMode = this.renderMode.bind(this);
    window.api.selectionData = this.viewerQuery.selectionData$;
    window.api.modelTree = this.viewerQuery.modelTree$;

    this.loadingViewer = true;

    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
  }

  ngOnInit(): void {
    this.viewerQuery.viewerSettings$.subscribe((res) => {
      this.settingsService = res;
      this.initApplication();
    });
  }

  ngAfterContentInit() {
    this.targetPopover = document.getElementById('#graphicsView')!;
  }

  initApplication() {
    console.warn('Call initApplication');
    this.initViewer(this.options(), this.settingsService).then((view) => {
      this.view3D = view;
      localStorage.setItem('workspace', view.uuid);
      this.actions.init(view);
      this.runSelectionProcess();

      // Ждем пока заинитится вьювер и
      // Если в queryParams есть id модели, то открываем её якобы через колбек попапа выбора модели
      if (this.queryParams && this.queryParams.modelId) {
        this.viewerService.updateModelIds(this.queryParams.modelId);
        this.getChangeExplorerSelectPopup({
          action: 'selectMultiple',
          selectedModels: [this.queryParams.modelId],
        });
      }
    });
  }

  initViewer(options: any, settings: any): Promise<C3DModelView> {
    console.warn('Call initViewer');
    this.viewCreated = false;
    return new Promise<C3DModelView>((resolve, reject) => {
      let serviceSettings: ViewerSettings = {
        c3dService: {
          host: window.location.protocol + '//' + window.location.hostname, // host name to connect with c3d service
          port: window.location.port, // port to connect with c3d service
        },
      };

      if (settings.service.api.host !== undefined && settings.service.api.host !== '') {
        serviceSettings.c3dService.host = settings.service.api.protocol + '//' + settings.service.api.host;
      }

      if (settings.service.api.port !== undefined && settings.service.api.port !== '') {
        serviceSettings.c3dService.port = settings.service.api.port;
      }

      createC3DViewer(serviceSettings)
        .then((instance) => {
          console.warn('get options', options);
          this.loadingViewer = false;
          return instance.createView(this.HTMLElementId, settings.view as ModelViewSettings, options);
        })
        //2. create view
        .then((view) => {
          console.log('view Created');
          this.viewCreated = true;
          resolve(view);
        })
        .catch((error) => {
          this.viewCreated = false;
          reject(error);
        });
    });
  }

  runSelectionProcess() {
    this.view3D.runCommand({
      name: 'RunProcessCommand',
      process: {
        name: 'Selection',
        options: {
          mode: SelectProcessMode.Multiply,
        },
        events: {
          onUpdate: () => {
            this.getSelectionProcess();
          },
          onContextMenu: (xClient: number, yClient: number) => {
            // Пока отключем показ на парвую кнопку мыши поповера
            // this.showPopover = true;
            // this.popover?.instance.option('position.offset', (xClient) + ' ' + (yClient))
          },
        },
      },
    });
  }

  getSelectionProcess() {
    (
      this.view3D.runCommand({
        name: 'GetParametersCommand',
        type: GetParametersTypes.SelectionList,
      }) as GetParametersResult
    ).result.then((res: GetSelectionListResult) => {
      this.viewerService.updateSelectionData(res);
      // this.checkSelectComment(res.uuids);
    });
  }

  checkSelectComment(uuids: string[]) {
    if (uuids.length == 1) {
      this.viewerQuery.annotationListData$.subscribe((res) => {
        if (res && res.objs.some((x) => x.uuid == uuids[0])) {
        }
      });
    }
  }

  getAnnotationListProcess() {
    (
      this.view3D.runCommand({
        name: 'GetParametersCommand',
        type: GetParametersTypes.AnnotationList,
      }) as GetParametersResult
    ).result.then((res: GetAnnotationListResult) => {
      this.viewerService.updateAnnotationListData(res);
    });
  }

  options(): any {
    console.warn('Call options');
    let workspaceUUID = ''; // try run demo with uuid 2bd1fe5b-5b09-4497-87dd-96d1c6d84d1e
    // if (localStorage.getItem('workspace')) {
    //   // @ts-ignore
    //   workspaceUUID = localStorage.getItem('workspace'); // try to recover last connection id
    // }
    return {
      uuid: workspaceUUID,
      callbacksList: {
        onAddNode: (uuid: any) => {
          console.log('Call onAddNode', uuid);
          // this.loadingModelVisible = true;
        },
        onCompletedModel: (uuid: any) => {
          console.warn('Call onCompletedModel', uuid);
          this.initModelRoot(uuid);
          this.zoom(uuid);
        },
        onFullLoadedModel: (uuid: any) => {
          console.log('Call onFullLoadedModel', uuid);
          this.loadingModelVisible = false;
        },
      },
    };
  }

  initModelRoot(modelUUID: string) {
    this.viewerService.updateViewerParams({ uuidViewer: this.view3D.uuid, uuidModel: modelUUID });
    this.modelService.getAllModelNodes(this.view3D.uuid, modelUUID, true).subscribe(
      (res) => {
        this.viewerService.updateAllModelItems(res);
      },
      (err) => {}
    );
    this.loadTree(this.view3D.uuid, modelUUID, modelUUID).subscribe((info: any) => {
      this.addTreeModel(info);
    });
  }

  addTreeModel(root: any) {
    let brokenTree = root;
    if (root.children && root.children.length) {
      if (root.children[0] && root.children[0].children.length) {
        brokenTree = root.children[0].children[0];
      }
    }

    this.viewerService.updateModelTreeData(brokenTree);
  }

  loadTree(workspaceUuid: string, modelUuid: string, nodeUuid: string): Observable<any> {
    return this.modelService.loadTreeModel(workspaceUuid, nodeUuid, modelUuid);
  }

  ///////////////////////////////////////////////////////
  popupSelectedFiles: string[] = [];
  getChangeExplorerSelectPopup(event: ISelectModelEvent) {
    switch (event.action) {
      case 'select': {
        this.selectMode = 'single';
        if (event.selectedModel && event.selectedModel.path) {
          this.viewerService.updateModelInfo(event.selectedModel);
          this.viewerService.clearModelTreeData();
          this.openModel(event.selectedModel.path, event.selectedModel.key);
        }
        break;
      }
      case 'selectMultiple': {
        this.selectMode = 'multiple';
        this.viewerService.clearModelTreeData();
        // this.loadingModelVisible = true;
        const ids = event.selectedModels.filter(Boolean);
        this.popupSelectedFiles = ids;
        this.viewerService.updateModelIds(ids[0]);
        this.modelService.addModelInWorkspace(this.view3D.uuid, ids, true).subscribe(
          () => {},
          (error) => {
            this.loadingModelVisible = false;
          }
        );
        break;
      }
    }
  }

  openModel(path: string, key: string, onProgress?: any, onError?: any) {
    // this.loadingModelVisible = true;
    this.modelService.loadModel(this.view3D.uuid, path, key).subscribe(
      (res) => {
        console.info('Successful load model');
      },
      (error) => {
        console.error('Error load model!', new Error(error));
      }
    );
  }

  removeAllModels() {
    this.modelService.removeAllModels(this.view3D.uuid, '');
    this.ngOnInit();
  }

  selectNode(e: { uuid }) {
    console.log(e);

    this.view3D.runCommand({
      name: 'SelectCommand',
      uuids: [e.uuid],
      // clean: true,
    });

    // run commant zoomfit
    this.zoom(e.uuid);

    this.viewerService.updateSelectNode(_.cloneDeep(e));
  }

  zoom(uuid: string) {
    this.view3D.runCommand({
      name: 'ZoomFitCommand',
      uuids: [uuid],
      duration: 1000,
    });
  }

  saveView() {
    (
      this.view3D.runCommand({
        name: 'GetParametersCommand',
        type: GetParametersTypes.AnnotationList,
      }) as GetParametersResult
    ).result.then((res) => {
      if (res.name == GetParametersTypes.AnnotationList) {
        let objs = JSON.stringify(res.objs);

        (
          this.view3D.runCommand({
            name: 'GetParametersCommand',
            type: GetParametersTypes.Camera,
          }) as GetParametersResult
        ).result.then((res) => {
          if (res.name == GetParametersTypes.Camera) {
            let view = JSON.stringify(res.params.orientation);

            const savedView = {
              camera: view,
              objs: objs,
            };
            this.viewerService.updateSavedViews(savedView);
          }
        });
      }
    });
  }

  zoomToSavedView(_savedView: any) {
    if (_savedView) {
      // this.view3D.runCommand({
      //   name:"DeleteAnnotation"
      // })

      const view = JSON.parse(_savedView.camera) as CustomCameraOrientation;
      (
        this.view3D.runCommand({
          name: 'CameraOrientationCommand',
          params: view,
          duration: 300,
          options: {
            capture: () => {
              // this.view3D.runCommand({
              //   name:"DeleteAnnotation"
              // })
            },
          },
        }) as AnimationResult
      ).finish.then(() => {
        //reset annotation
        const objs = JSON.parse(_savedView!.objs) as C3DViewComment[];
        // this.view3D.runCommand({
        //   name:"AddAnnotation",
        //   objs:objs,
        // })
      });
    }
  }

  visibleNode(e: any) {
    console.log('change nodes visible', e);

    const command: C3DViewCommands = {
      name: e.currentSelectedRowKeys.length > 0 ? 'ShowNodesCommand' : 'HideNodesCommand',
      uuids: e.currentSelectedRowKeys.length > 0 ? e.currentSelectedRowKeys : e.currentDeselectedRowKeys,
    };

    console.log('command', command);

    this.view3D.runCommand(command);
  }

  addComment() {
    this.showCommentForm();
  }

  showCommentForm() {
    this.showCommentPopup = true;
  }

  getChangeCommentPopup(event: ICommentEvent) {
    switch (event.action) {
      case 'add': {
        this.showCommentPopup = false;
        this.addCommentInModel(event.data).then(() => {
          if (event.data && event.data.saveView) {
            this.saveView();
          }
        });
        break;
      }
      case 'edit': {
        this.showCommentPopup = false;
        break;
      }
    }
  }

  async addCommentInModel(data: any) {
    return new Promise((resolve) => {
      this.view3D.runCommand({
        name: 'RunProcessCommand',
        process: {
          name: 'AddComment',
          options: {
            text: data.name,
            font: {
              size: Number(13),
              family: '',
            },
          },
          events: {
            onCreate: (uuid: string) => {
              this.getAnnotationListProcess();
              setTimeout(() => {
                this.runSelectionProcess();
                resolve(true);
              }, 100);
              return null;
            },
            onNew: () => {
              return data.name;
            },
          },
        },
      });
    });
  }

  cutModel() {
    this.view3D.runCommand({
      name: 'RunProcessCommand',
      process: {
        name: 'SetPlaneProcess',
        events: {
          onInitPlacement: (a: number, b: number, c: number) => {
            this.sectionPlaneOptions = {
              id: 1,
              placement: { a, b, c },
              name: 'Cutting plane ' + new Date().toLocaleTimeString(),
            };

            this.view3D.runCommand({
              name: 'SetCuttingPlanesCommand',
              planes: [this.sectionPlaneOptions],
            } as any);
            this.editCutModel();
          },
        },
      },
    });
  }

  editCutModel() {
    this.view3D.runCommand({
      name: 'RunProcessCommand',
      process: {
        name: 'EditSectionPlane',
        options: this.sectionPlaneOptions,
        events: {},
      },
    });
  }

  clearCutModel() {
    this.view3D.runCommand({
      name: 'SetCuttingPlanesCommand',
      planes: [],
    } as any);
  }

  linearDimension() {
    this.view3D.runCommand({
      name: 'RunProcessCommand',
      process: {
        name: 'AddLinearDimension',
        options: {
          font: {
            size: Number(13),
            family: '',
          },
        },
        events: {},
      },
    });
  }

  deleteAllAnnotation() {
    this.view3D.runCommand({
      name: 'DeleteAnnotation',
      uuids: [],
    });
  }

  setColor(groupIndex?: number, uuids?: string[]) {
    (
      this.view3D.runCommand({
        name: 'GetParametersCommand',
        type: GetParametersTypes.SelectionList,
      }) as GetParametersResult
    ).result.then((res) => {
      if (res.name == GetParametersTypes.SelectionList) {
        this.view3D.runCommand({
          name: 'SetColorCommand',
          groupIndex: groupIndex || 0,
          uuids: uuids || res.uuids,
        });
      }
    });
  }

  resetColors() {
    this.view3D.runCommand({
      name: 'SetColorCommand',
      groupIndex: 0,
      uuids: [],
      options: {
        clean: true,
      },
    });
  }

  stopProcess() {
    this.view3D.runCommand({
      name: 'StopProcessCommand',
    });
  }

  standartCameraOrientation(stdView: any) {
    this.view3D.runCommand({
      name: 'CameraOrientationCommand',
      params: {
        type: 'standard',
        value: stdView,
      },
      duration: 200,
    });
  }

  renderMode(mode: RenderMode) {
    this.view3D.setParameters({
      name: 'renderMode',
      mode: mode,
    });
  }

  switch_prj(value: boolean) {
    this.view3D.setParameters({
      name: 'camera',
      projection: value ? CameraProjection.Orthogonal : CameraProjection.Perspective,
    });
  }

  setToolbarInstance(e: any) {
    this.actionToolbarInstance = e.component;
  }

  makePlanFact() {
    this.viewerQuery.allModelItems$.subscribe((modelItems) => {
      this.viewerQuery.allWorks$.subscribe((works) => {
        // debugger
        let d: {
          transparent: number;
          red: number;
          yellow: number;
          green: number;
        } = { transparent: 0, red: 0, yellow: 0, green: 0 };

        const transparent = works.filter((x: any) => x.actual_Finish != null);
        let uuids: string[] = [];
        transparent.map((x: any) => {
          if (x.taskModels.length > 0) {
            x.taskModels.map((task: any) => uuids.push(task.id));
          }
        });
        d.transparent = uuids.length;
        this.setColor(4, uuids);

        const red = works.filter(
          (x: any) => new Date(x.finish).getTime() > new Date(x.bL_Project_Finish).getTime() && x.actual_Finish == null
        );
        uuids = [];
        red.map((x: any) => {
          if (x.taskModels.length > 0) {
            x.taskModels.map((task: any) => uuids.push(task.id));
          }
        });
        d.red = uuids.length;
        this.setColor(1, uuids);

        const yellow = works.filter(
          (x: any) =>
            new Date(x.start || x.actual_Start).getTime() > new Date(x.bL_Project_Start).getTime() &&
            new Date(x.finish).getTime() <= new Date(x.bL_Project_Finish).getTime() &&
            x.actual_Finish != null
        );
        uuids = [];
        yellow.map((x: any) => {
          if (x.taskModels.length > 0) {
            x.taskModels.map((task: any) => uuids.push(task.id));
          }
        });
        d.yellow = uuids.length;
        this.setColor(2, uuids);

        const green = works.filter(
          (x: any) => new Date(x.finish).getTime() <= new Date(x.bL_Project_Finish).getTime() && x.actual_Finish == null
        );
        uuids = [];
        green.map((x: any) => {
          if (x.taskModels.length > 0) {
            x.taskModels.map((task: any) => uuids.push(task.id));
          }
        });
        d.green = uuids.length;
        this.setColor(3, uuids);

        console.log(d);
      });
    });
  }
}
