import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Pipe({
  name: 'authImg',
})
export class AuthImgPipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  transform(url: string) {
    return new Observable<SafeUrl>((observer) => {
      if (!url) {
        // This is a tiny blank image
        // observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
        return { unsubscribe() {} };
      }

      this.http.get(url, { responseType: 'blob' }).subscribe(
        (responseBlob) => {
          observer.next(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(responseBlob)));
        },
        (err) => {
          observer.unsubscribe();
        }
      );

      return { unsubscribe() {} };
    });
  }
}

@NgModule({
  declarations: [AuthImgPipe],
  exports: [AuthImgPipe],
})
export class AuthImgPipeModule {}
