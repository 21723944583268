<dx-popup [width]="size === 'sm' ? 370 : 555" [height]="'auto'" [showTitle]="true" [showCloseButton]="true" [title]="title" [dragEnabled]="true"
          [hideOnOutsideClick]="true" [(visible)]="popupVisible" (onHidden)="hidden($event)">
  <div *dxTemplate="let data of 'content'" id="confirm-popup-wrapper">
    <div class="popup-wrapper">
      <ng-container *ngIf="contentTemplate; else textTpl">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </ng-container>
      <ng-template #textTpl><dx-scroll-view height="100%" class="accept-txt">{{text}}</dx-scroll-view></ng-template>
      <div class="accept-btns">
        <pp-ui-buttons *ngIf="cancelCallback !== null" [options]="{text: btnCancelText, stylingMode: 'contained', type: btnCancelType}" (onClick)="cancelClick($event)"></pp-ui-buttons>
        <pp-ui-buttons [options]="{text: btnOkText, stylingMode: 'contained', type: btnOkType}" (onClick)="okClick($event)"></pp-ui-buttons>
      </div>
    </div>
  </div>
</dx-popup>
