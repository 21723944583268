import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IPermissionQualityControl, PermissionService } from '../../../../../src/app/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class ResolverQC implements Resolve<IPermissionQualityControl> {
  constructor(private permissionService: PermissionService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPermissionQualityControl> {
    return this.permissionService.getUserPermissionQualityControl();
  }
}
