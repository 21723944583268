import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarComponent, DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import {
  DxMenuModule,
  DxPopoverModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxTabsModule,
  DxTooltipModule,
} from 'devextreme-angular';
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
  RoutesRecognized,
} from '@angular/router';

import { PermissionService } from '../../../../src/app/services/permission.service';
import { UserInfoModule } from '../user-info/user-info.component';
import { UsersService } from 'src/app/services/users/users.service';
import { dxToolbarItem } from 'devextreme/ui/toolbar';
import { AuthService } from '@pp/auth';
import { filter, map, Observable } from 'rxjs';
import { LocalStorageConstants } from 'src/app/shared/constants';
import { HeaderService } from './header.service';
import { INavigation, navigation } from 'src/app/app-navigation';
import { DownloadsService } from 'projects/ui-components/src/lib/downloads/downloads.service';
import { DownloadsQuery } from 'projects/ui-components/src/lib/downloads/store/downloads.query';

const basicElements: dxToolbarItem[] = [
  {
    location: 'before',
    template: 'navModuleMenu',
  },
  {
    location: 'before',
    cssClass: 'header-title',
    options: {
      url: '/',
      logo: true,
    },
  },
  {
    location: 'after',
    template: 'menuUserInfo',
  },
  {
    location: 'after',
    template: 'menuItem',
  },
  {
    location: 'after',
    template: 'downloadsItem',
  },
];

@Component({
  selector: 'app-portal-core-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @ViewChild('navBar', { static: true }) navBar: DxToolbarComponent;

  @Output() menuToggle = new EventEmitter<boolean>();

  @Input() menuToggleEnabled = false;

  @Input() title: string;

  popupVisible = false;
  currentLanguage = 'en-US';
  currentUserId: string;

  withShadingOptionsVisible: boolean;

  moduleMenu$: Observable<any[]>;

  languages = ['Russian', 'English'];

  navBase$: Observable<INavigation[]>;

  showFirstSubmenuModesMain = {
    name: 'onClick',
    delay: { show: 0, hide: 300 },
  };

  userMenuItems = [
    {
      text: 'Change language',
      icon: 'globe',
      isPPIcon: false,
      onClick: () => {
        this.popupVisible = true;
        this.changeDetector.markForCheck();
      },
    },
    {
      text: $localize`Выйти`,
      icon: 'outlined_logout',
      isPPIcon: true,
      onClick: () => {
        this.logout();
      },
    },
  ];

  data: any;
  currentModule = null;
  currentUrl: string;

  isActiveDownloads$ = this.downloadsQuery.isSomePending$;

  constructor(
    private router: Router,
    private permissionsService: PermissionService,
    private userService: UsersService,
    private authService: AuthService,
    private headerService: HeaderService,
    private changeDetector: ChangeDetectorRef,
    private downloadsService: DownloadsService,
    private downloadsQuery: DownloadsQuery
  ) {
    this.navBase$ = this.headerService.navigation$;

    if (['ru', 'ru-RU', 'RU'].includes(localStorage.getItem(LocalStorageConstants.USER_LANG))) {
      this.currentLanguage = 'Russian';
    } else {
      this.currentLanguage = 'English';
    }

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
      const firstElement = event.url
        .split('/')
        .filter((x) => x)
        .shift();
      if (firstElement) {
        this.currentModule = navigation.find((item) => {
          if (item.route) {
            return item.route.includes(firstElement);
          } else {
            return false;
          }
        });
      } else {
        this.currentModule = null;
      }
    });
  }

  ngOnInit() {
    this.moduleMenu$ = this.router.events.pipe(
      filter((data) => data instanceof RoutesRecognized),
      map((data: RoutesRecognized) => {
        let moduleNav = basicElements;
        this.currentUrl = data.url;

        if (this.permissionsService.isAdmin) {
          moduleNav = this.takeNavRecursively(data.state.root).map((menuItem) => {
            return {
              location: 'center',
              widget: 'dxButton',
              cssClass: 'menu-item-button',
              template: 'moduleMenuItem',
              options: {
                text: menuItem.text,
                url: menuItem.url,
                queryParams: menuItem.queryParams,
              },
            };
          });
          return [...basicElements, ...moduleNav];
        } else {
          moduleNav = this.takeNavRecursively(data.state.root)
            .filter((x) => {
              return !x.forAdmin || x.forAdmin === false;
            })
            .map((menuItem) => {
              return {
                location: 'center',
                widget: 'dxButton',
                template: 'moduleMenuItem',
                options: {
                  text: menuItem.text,
                  url: menuItem.url,
                  queryParams: menuItem.queryParams,
                },
              };
            });
        }

        return moduleNav;
      })
    );

    this.userService.myId.subscribe((userId: string) => (this.currentUserId = userId));
  }

  toggleMenu() {
    this.menuToggle.emit();
  }

  logout(): void {
    this.authService.logoff();
  }

  getRootHref(): string {
    const path = location.pathname.split('/');
    const indexASPXfile = path.indexOf('ProjectPoint');
    const pathToApp = path.slice(0, indexASPXfile + 1);
    const result = pathToApp.join('/');
    console.log('getBaseHref', result);
    return result;
  }

  goTo(item) {
    if (!item.isActive && !item.forAdmin) return;

    if (item.route) {
      this.router.navigate([item.route]);
    } else if (item.url) {
      window.open(item.url, '_blank');
    }

    this.withShadingOptionsVisible = false;
  }

  onValueChanged(event) {
    if (event) {
    }
    if (event.value === 'Russian') {
      localStorage.setItem(LocalStorageConstants.USER_LANG, 'ru-RU');
      window.location.href = window.location.href.replace('/en/', '/ru/');
    } else {
      localStorage.setItem(LocalStorageConstants.USER_LANG, 'en-US');
      window.location.href = window.location.href.replace('/ru/', '/en/');
    }
  }

  p(e, r) {
    console.warn(e, '| ', r);
  }

  navigate(event) {
    if (event.itemData.options && event.itemData.options.url) {
      if (event.itemData.options.logo) {
        // window.location.href = location.origin;
        this.router.navigate([event.itemData.options.url]);
      }
    }
  }

  toggleWithShadingOptions() {
    this.withShadingOptionsVisible = !this.withShadingOptionsVisible;
  }

  takeNavRecursively(routeSnapshot: ActivatedRouteSnapshot): any[] {
    let res = [];

    if (routeSnapshot.data.navigation) {
      res = [...routeSnapshot.data.navigation];
    }

    for (let childRoute of routeSnapshot.children) {
      let childNavData = this.takeNavRecursively(childRoute);
      if (childNavData.length) res = childNavData;
    }

    return res;
  }

  isDocumentPageActive(options) {
    return this.currentUrl.includes('document') && options.text === $localize`Документы`;
  }

  toggleDownloads() {
    this.downloadsService.toggleDownloads();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    UserInfoModule,
    DxToolbarModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxMenuModule,
    DxTabsModule,
    DxPopoverModule,
    RouterLink,
    RouterLinkActive,
    DxTooltipModule,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
