import { User } from 'src/app/services';

// abstract task
export interface Task {
  CreatedBy: User;
  CreatedById: string;
  ModifiedBy: User;
  ModifiedById: string;
  Created: Date | string;
  Modified: Date | string;
  Id: string;
  StageId: string;
  StageName: string;
  StageSortOrder: number;
  AutoComplete: true;
  EndProcessIfRejected: true;
  Code: string;
  Type: TaskType;
  RunningStartedDate: Date | string;
  PlanCompletedDate: Date | string;
  CompletedDate: Date | string;
  Status: TaskStatus;
  Result: TaskResult;
  CreationMethod: TaskCreationMethod;
  AssignedTo: User;
  AssignedToId: string;
  ParentTaskId: string;
  CompletedBy: User;
  CompletedById: string;
  Comment: string;
}

export interface TaskPermissions {
  Approve: boolean;
  ApproveFinal: boolean;
  Reject: boolean;
  TakeToWork: boolean;
  Delegate: boolean;
}

export interface AutoComplete {
  Value: boolean;
  Text: string;
}

// 0 - Не начата 1 - В работе 2 - Завершена
export enum TaskStatus {
  NotStarted = 0,
  Active = 1,
  Completed = 2,
}

// 0 - Согласовано 1 - Утверждено 2 - Не рассмотрено 3 - Отклонено 4 - Отменено 5 - Назначена на другого 6 -Прервана утверждающим
export enum TaskResult {
  Approved = 0,
  Agreed = 1,
  AutoApproved = 2,
  Rejected = 3,
  Calceled = 4,
  Reassigned = 5,
  InterruptedByApprover = 6,
}

// Тип задачи согласования: 0 - Согласование 1 - Утверждение
export enum TaskType {
  Approval = 0,
  Agreement = 1,
}

// Способ создания задачи согласования: 0 - Запуск этапа согласования 1 - Назначение задачи другому 2- Создана досрочно
export enum TaskCreationMethod {
  ApproveStageStarted = 0,
  TaskReassigned = 1,
  CreatedAheadOfSchedule = 2,
}

export function getTaskStatuses() {
  return [
    {
      value: TaskStatus.NotStarted,
      title: $localize`Не начата`,
      color: '',
    },
    {
      value: TaskStatus.Active,
      title: $localize`В работе`,
      color: 'color-warn',
    },
    {
      value: TaskStatus.Completed,
      title: $localize`Завершена`,
      color: 'color-success',
    },
  ];
}

export function getTaskStatusColor(status: TaskStatus) {
  const res = getTaskStatuses().find((stat) => stat.value === status);
  return res ? res.color : '';
}

export function getTaskStatusView(status: TaskStatus) {
  const res = getTaskStatuses().find((stat) => stat.value === status);
  return res ? res.title : 'undefined status';
}

export function getTaskResultView(status: TaskResult) {
  const res = getTaskResults().find((stat) => stat.value === status);
  return res ? res.title : '';
}

export function getTaskTypeView(taskType: TaskType) {
  const res = getTaskTypes().find((type) => type.value === taskType);
  return res ? res.title : '';
}

export function getTaskCreationMethodView(creationMethod: TaskCreationMethod) {
  const res = getTaskCreationMethods().find((type) => type.value === creationMethod);
  return res ? res.title : $localize`Неизвестный способ создания`;
}

export function getTaskResults() {
  return [
    {
      value: TaskResult.Approved,
      title: $localize`Согласовано`,
    },
    {
      value: TaskResult.Agreed,
      title: $localize`Утверждено`,
    },
    {
      value: TaskResult.AutoApproved,
      title: $localize`Не рассмотрено`,
    },
    {
      value: TaskResult.Rejected,
      title: $localize`Отклонено`,
    },
    {
      value: TaskResult.Reassigned,
      title: $localize`Назначена на другого`,
    },
    {
      value: TaskResult.Calceled,
      title: $localize`Отменено`,
    },
    {
      value: TaskResult.InterruptedByApprover,
      title: 'Прервана утверждающим',
    },
  ];
}

export function getTaskTypes() {
  return [
    {
      value: TaskType.Approval,
      title: $localize`Согласование`,
    },
    {
      value: TaskType.Agreement,
      title: $localize`Утверждение`,
    },
  ];
}

export function getTaskCreationMethods() {
  return [
    {
      value: TaskCreationMethod.ApproveStageStarted,
      title: $localize`Запуск этапа согласования`,
    },
    {
      value: TaskCreationMethod.TaskReassigned,
      title: $localize`Назначение задачи другому`,
    },
    {
      value: TaskCreationMethod.CreatedAheadOfSchedule,
      title: 'Создана досрочно',
    },
  ];
}
