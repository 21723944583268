import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  private _notifiy$: Subject<NotifyMessage> = new Subject();

  public notify(
    message: { text: string; bold?: boolean }[] | string,
    type: 'info' | 'warning' | 'success' | 'error' = 'info',
    displayTime: number = 5000
  ) {
    if (typeof message === 'string') {
      message = [{ text: message }];
    }
    this._notifiy$.next({
      message,
      type,
      displayTime,
    });
  }

  getObservable(): Observable<NotifyMessage> {
    return this._notifiy$;
  }
}

export interface NotifyMessage {
  message: { text: string; bold?: boolean }[];
  type: 'info' | 'warning' | 'success' | 'error';
  displayTime: number;
}
