import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SettingsAppService } from '@pp/settings';

@Injectable({
  providedIn: 'root',
})
export class ControlSmrGuard implements CanActivate {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private service: SettingsAppService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>((observer) => {
      this.service.$activeModules
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: [{ Id: number; IsActive: boolean }]) => {
          const smrModule = res.find((x) => x.Id === 5);
          if (smrModule && smrModule.IsActive) {
            observer.next(true);
          } else {
            observer.next(false);
            this.router.navigate(['errors/403']);
          }
        });
    });
  }
}
