import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthService {
  loggedIn = true;
  Token;

  constructor(
    private router: Router,
    private oauthService: OAuthService
  ) {}

  logIn(login: string, passord: string) {
    this.oauthService.tryLogin();
    // this.router.navigate(['/']);
  }

  logOut() {
    this.loggedIn = false;
    this.router.navigate(['/login-form']);
  }

  get isLoggedIn() {
    return this.loggedIn;
  }

  /*  getPrivateMessage(): Observable<string> {
    let options = this.getAuthHeader();

    return this.http.get(this.UrlService + "home/private", options)
      .map((res: Response) => console.log(res))
  }

  protected getAuthHeader() {
    this.Token = this.oauthService.getAccessToken();

    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', `Bearer ${this.Token}`);

    let options = new HttpHeaders().set(headers);
    return options;
  }*/
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.isLoggedIn;
    const isLoginForm = route.routeConfig.path === 'login-form';

    if (isLoggedIn && isLoginForm) {
      this.router.navigate(['/']);
      return false;
    }

    if (!isLoggedIn && !isLoginForm) {
      this.router.navigate(['/login-form']);
    }

    return isLoggedIn || isLoginForm;
  }
}
