import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeInfoComponent } from './change-info.component';

@NgModule({
  declarations: [ChangeInfoComponent],
  exports: [ChangeInfoComponent],
  imports: [CommonModule],
})
export class ChangeInfoModule {}
