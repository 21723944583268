<div class="content">
  <div class="content__section info">
    <h2>Отсутствует соединение с сервером</h2>
    <div class="desc">
      Попробуйте подключиться позже.
    </div>
  </div>
  <div class="content__section icon">
    <img [src]="'assets/images/svg/no_connection.svg'">
  </div>
</div>
