import { Injectable } from '@angular/core';
import { ViewerStore } from './viewer.store';
import * as c3dviewer from '@c3dlabs/c3dviewer-api';

@Injectable({
  providedIn: 'root',
})
export class ViewerService {
  constructor(private viewerStore: ViewerStore) {}

  updateModelTreeData(newData: any | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      modelTree: (state.modelTree || []).concat([newData]),
    }));

    this.viewerStore.setLoading(false);
  }

  updateAllModelItems(newData: any | undefined) {
    // newData.name = new Date().getTime();
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      allModelItems: (state.allModelItems || []).concat(newData),
    }));
    this.viewerStore.setLoading(false);
  }

  clearModelTreeData() {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      modelTree: [],
    }));
    this.viewerStore.setLoading(false);
  }

  updateSelectionData(newData: c3dviewer.GetSelectionListResult | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      selectionData: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateAnnotationListData(newData: c3dviewer.GetAnnotationListResult | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      annotationListData: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateViewerSettings(newData: any | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      viewerSettings: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateSavedViews(newData: any | undefined) {
    newData.name = new Date().getTime();
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      savedViews: [...state.savedViews, newData],
    }));
    this.viewerStore.setLoading(false);
  }

  updateBindedData(newData: any | undefined) {
    newData.name = new Date().getTime();
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      bindedData: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateModelInfo(newData: any | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      modelInfo: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateModelIds(newData: string | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      ...state,
      modelIds: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateSelectNode(newData: any | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      ...state,
      selectNode: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateTmp(newData: any | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      ...state,
      tmp: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateAllWorks(newData: any | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      ...state,
      allWorks: newData,
    }));
    this.viewerStore.setLoading(false);
  }

  updateViewerParams(newData: any | undefined) {
    this.viewerStore.setLoading(true);
    this.viewerStore.update((state) => ({
      ...state,
      viewerParams: newData,
    }));
    this.viewerStore.setLoading(false);
  }
}
