<div class="dx-field">
  <div class="dx-field-label dx-field-item-label-location-top">Комментарий</div>
  <dx-text-area #commentTextArea
    [height]="90"
    stylingMode="outlined"
    placeholder="Текст комментария"
    [(value)]="comment"
    (onValueChanged)="validate()"
  ></dx-text-area>
</div>

<dx-file-uploader #fileUploader
  selectButtonText="Выберите файлы"
  [multiple]="true"
  uploadMode="useButtons"
  labelText="Или перетащите сюда"
  (onValueChanged)="validate()"
  [(value)]="files">
</dx-file-uploader>
<div class="forms-buttons">
  <pp-ui-buttons
    class="m-l-top"
    [options]="{
      text: 'Отменить',
      onClick: clearForms(commentTextArea, fileUploader)
    }">
  </pp-ui-buttons>
  <pp-ui-buttons
    class="m-l-top"
    [options]="{
      text: 'Отправить',
      onClick: uploadCallback.bind(this, commentTextArea, fileUploader),
      disabled: !isValid
    }">
  </pp-ui-buttons>
</div>
