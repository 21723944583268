<dx-popup *ngIf="popupVisible"
  [width]="'80vw'"
  [minWidth]="'800px'"
  height="auto"
  [maxHeight]="'90%'"
  [showTitle]="true"
  title="Выберите модель" i18n-title
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
  (onHiding)="close()"
>
  <div *dxTemplate="let data of 'content'" class="column-flex">

    <pp-ui-global-project-select [unlink]="true" (projectChanged)="changeProject($event)" [projectId]="projectId$ | async"></pp-ui-global-project-select>
    <div style="min-height: 0">
      <dx-tree-list *ngIf="popupVisible"
        #treelist
        [dataSource]="bimFilesDS$ | async"
        keyExpr="Id"
        parentIdExpr="ParentId"
        [rootValue]="null"
        [height]="'100%'"
        [allowColumnResizing]="true"
        (onInitialized)="setTreeListOptions($event)"
      >
        <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
        
        <dxo-state-storing [enabled]="!!localStorageKey" [storageKey]="localStorageKey" type="custom" [customSave]="saveState" [customLoad]="loadState"></dxo-state-storing>

        <dxi-column dataField="Name" caption="Расположение" i18n-caption dataType="string" fixed="true" [showInColumnChooser]="false"
          cellTemplate="nameCellTemplate" [sortIndex]="0" sortOrder="asc" [fixed]="true" fixedPosition="left"></dxi-column>
        <dxi-column dataField="DocumentName" caption="Наименование документа" dataType="string"></dxi-column>
        <dxi-column dataField="RevisionTitle" caption="Ревизия" dataType="string" [visible]="false"></dxi-column>
        <dxi-column dataField="ChangeNumber" caption="Номер изменения" dataType="string" [visible]="false"></dxi-column>
        <dxi-column dataField="Version" caption="Версия файла" dataType="string" cellTemplate="versionTemplate" [visible]="false"></dxi-column>
        <dxi-column dataField="ApproveStatus" caption="Статус согласования" alignment="left"
          [headerFilter]="statusColumnHeaderFilter"
          [calculateSortValue]="calcSortStatusCol"
          cellTemplate="approveStatusTemplate"></dxi-column>
        <dxi-column dataField="ApproveResultDisplayedTitle" caption="Результат согласования" alignment="left"
          cellTemplate="approveResultTemplate" [visible]="false"></dxi-column>
        <dxi-column dataField="ContentTypeTitle" caption="Тип документа" dataType="string"></dxi-column>
        <dxi-column dataField="DisciplineDisplayedTitle" caption="Дисциплина" dataType="string" [visible]="false"></dxi-column>
        <dxi-column dataField="MarkTitle" caption="Категория" dataType="string"></dxi-column>

        <div class="treelist-text-overflow" *dxTemplate="let item of 'nameCellTemplate'" style="position: relative">
          <i [ngClass]="getIcon(item.data.Id)" class="pp-icon_outlined_doc center-v-absolute type-icon"></i>
  
          <div style="margin-left: 20px;">{{isDocument(item.data.Id) ? item.data.NumberAndRevision : item.data.Name }}</div>
        </div>
  
        <div *dxTemplate="let item of 'versionTemplate'">
          <div *ngIf="item.value" class="budget">{{item.value}}</div>
        </div>
  
        <div *dxTemplate="let item of 'approveStatusTemplate'">
          <div *ngIf="isDocument(item.data.Id)">{{item.data.ApproveStatus | approveStatus}}</div>
        </div>
  
        <div *dxTemplate="let item of 'approveResultTemplate'">
          <i [ngClass]="[item.data.ApproveResultIcon | approveResultIcon, item.data.ApproveResultIcon | approveResultColorClass]"
            style="margin-right: 8px; font-size: 18px; vertical-align: middle"></i>
          <span style="vertical-align: middle">{{item.data.ApproveResultDisplayedTitle}}</span>
        </div>
      </dx-tree-list>
    </div>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="closeOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="acceptOptions"
  >
  </dxi-toolbar-item>
</dx-popup>
