import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ViewerQuery } from '../../store/viewer.query';
import * as _ from 'lodash';
import { DxTreeListComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { ViewerService } from '../../store/viewer.service';

@Component({
  selector: 'app-model-tree',
  templateUrl: './model-tree.component.html',
  styleUrls: ['./model-tree.component.scss'],
})
export class ModelTreeComponent implements OnInit {
  modelTree: DataSource<IModelTree[]>;
  modelTreeItems: any[] = [];

  documents: any;
  selectedRowKeys: string[] = [];
  @ViewChild(DxTreeListComponent) treeList!: DxTreeListComponent;
  @Output() selectNode: EventEmitter<any> = new EventEmitter<any>();
  @Output() visibleNode: EventEmitter<any> = new EventEmitter<any>();

  expandCollapseButton = {
    name: 'expandCollapseButton',
    widget: 'dxButton',
    location: 'before',
    options: {
      text: 'Развернуть всё',
      onClick: () => {
        this.onExpandCollapse();
      },
    },
  };

  constructor(
    private viewerQuery: ViewerQuery,
    private viewerService: ViewerService
  ) {
    // this.addExpandCollapseButton.bind(this);
    // this.onExpandCollapse.bind(this);
  }

  ngOnInit(): void {
    this.modelTree = new DataSource({
      key: 'Id',
      loadMode: 'raw',
      sort: 'attrs.name',
      store: new CustomStore({
        load: () => {
          return this.modelTreeItems;
        },
      }),
    });

    this.viewerQuery.modelTree$.subscribe((res) => {
      if (res) {
        let items = _.cloneDeep(res instanceof Array ? res : []);
        this.selectedRowKeys = [];
        this.selectAllNodes(items);
        this.viewerService.updateTmp(_.cloneDeep(items));
        this.modelTreeItems = items;
        this.modelTree.reload().then(() => {
          // this.onExpandCollapse();
        });
      }
    });
  }

  selectNodeChanged(e: any) {
    if (
      !(
        e.event.target.parentElement.classList.contains('dx-treelist-expanded') ||
        e.event.target.parentElement.classList.contains('dx-treelist-collapsed')
      )
    ) {
      this.selectNode.emit(e.data);
    }
  }

  visibleNodeChanged(e: any) {
    this.visibleNode.emit(e);
  }

  selectAllNodes(tree: IModelTree[], puuid: string = '', doc?: any) {
    if (tree.length > 0) {
      tree.map((x: any) => {
        x.name = !x.attrs ? 'Элемент модели' : x.attrs.name;
        // if (!doc) {
        //   x.doc = this.documents.data.find((d: any) => d.tree_node == x.name)
        // } else {
        //   x.doc = doc;
        // }
        this.selectedRowKeys.push(x.uuid);
        if (x.children && x.children.length > 0) {
          this.selectAllNodes(x.children, x.uuid, x.doc);
        }
      });
    }
  }

  selectAll(e: any) {
    this.treeList.instance.selectAll();
  }

  deselectAll() {
    this.treeList.instance.deselectAll();
  }

  addExpandCollapseButton(treeList: { component: DxTreeListComponent; toolbarOptions: any }) {
    treeList.toolbarOptions.items.push(this.expandCollapseButton);
  }

  onExpandCollapse() {
    let listInstance = this.treeList.instance;
    let expanding = this.expandCollapseButton.options.text === 'Свернуть всё';
    if (expanding) {
      listInstance.option('expandedRowKeys', []);
      listInstance.option('autoExpandAll', false);
    } else {
      listInstance.option('expandedRowKeys', []);
      listInstance.option('autoExpandAll', true);
    }
    this.expandCollapseButton.options.text = expanding ? 'Развернуть всё' : 'Свернуть всё';
    listInstance.repaint();
  }
}

export interface IModelTree {
  uuid: string;
  puuid: string;
  selected: boolean;
  expanded: boolean;
  name: string;
  attrs: { name: string };
  children: IModelTree[];
  doc: any;
}
