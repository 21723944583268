import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDocAccessMatrixItem } from '@pp/interfaces';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MatrixDocService {
  private _apiUrl: string;
  private _serviceUrl: string;
  private _permissonsUrl: string;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private _http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.documentAccessMatrixService;
    this._permissonsUrl = environment.permissionUrl;
  }

  getAll() {
    return this._http.get<IDocAccessMatrixItem[]>(this._apiUrl + this._serviceUrl + 'GetAll');
  }

  getForUserRoleInProjectsAll() {
    return this._http.get(this._apiUrl + this._serviceUrl + 'GetForUserRoleInProjectsAll');
  }

  getById(id) {
    return this._http.get(this._apiUrl + this._serviceUrl + 'Get?id=' + id);
  }

  getUserById(): Observable<any> {
    return this._http.get(this._apiUrl);
  }

  getAllPermissionsCore(): Observable<any> {
    return this._http.get(this._apiUrl + this._permissonsUrl + 'GetCurrentUserCorePermissions');
  }

  getActiveForSelect() {
    return this._http.get(this._apiUrl + this._serviceUrl + 'GetActiveForSelect');
  }

  create(body) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Create', body, this._httpOptions);
  }

  update(body) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Update', body, this._httpOptions);
  }

  delete(id) {
    return this._http.delete(this._apiUrl + this._serviceUrl + 'Delete?id=' + id);
  }
}
