import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { RootStoreQuery, RootStoreService } from '@pp/root-store';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { DocsService } from '../../../../../src/app/shared/services/docs.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { DxSelectBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'pp-ui-global-project-select',
  templateUrl: './global-project-select.component.html',
  styleUrls: ['./global-project-select.component.scss'],
})
export class GlobalProjectSelectComponent implements OnInit, OnDestroy {
  @ViewChild(DxSelectBoxComponent) selectBox: DxSelectBoxComponent;
  @Output() projectChanged = new EventEmitter<string>();

  @Input() unlink = false;
  @Input() projectId: string;

  projectsDS: DataSource;

  isLoadingItems = false;

  destroy$ = new Subject<void>();

  constructor(
    private rootStoreService: RootStoreService,
    private docsService: DocsService,
    private projectsService: ProjectsService,
    private rootStoreQuery: RootStoreQuery
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    if (!this.unlink) {
      this.rootStoreQuery.rootProjectId$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
        if (!res) return;
        this.projectId = res;
      });
    }

    this.projectsDS = new DataSource({
      key: 'Id',
      store: new CustomStore({
        loadMode: 'raw',
        onLoaded: () => {
          console.log('loading projects end');
          this.isLoadingItems = false;
        },
        load: () => {
          console.log('loading projects');
          this.isLoadingItems = true;

          return lastValueFrom(this.docsService.getProjectsMyForView()).then((res) => {
            if (!this.projectId && res.length > 0) {
              if (!this.unlink) {
                this.rootStoreService.updateRootProject(res[0].Id);
              } else {
                this.projectId = res[0].Id;
              }
            }
            return res.map((proj) => {
              return {
                Id: proj.Id,
                CodeName: `${proj.Code}. ${proj.Title}`,
              };
            });
          });
        },
        byKey: (key) => {
          console.log('loading bykey');
          this.isLoadingItems = true;
          if (!key) return null;
          return lastValueFrom(this.projectsService.get(key)).then((res) => {
            return {
              Id: res.Item.Id,
              CodeName: `${res.Item.Code}. ${res.Item.Title}`,
            };
          });
        },
      }),
      sort: [{ selector: 'CodeName', desc: false }],
    });
  }

  changeRootProject(e) {
    if (e.previousValue !== e.value) {
      if (!this.unlink) this.rootStoreService.updateRootProject(e.value);
      this.projectChanged.emit(e.value);
    }
  }
}
