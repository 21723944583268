import { Query } from '@datorama/akita';
import { SelectionState, ViewerStore } from './viewer.store';
import { Injectable } from '@angular/core';
import * as c3dviewer from '@c3dlabs/c3dviewer-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewerQuery extends Query<SelectionState> {
  allState$ = this.select();
  modelInfo$ = this.select('modelInfo');
  modelIds$ = this.select('modelIds');
  modelTree$ = this.select('modelTree');
  viewerSettings$ = this.select('viewerSettings');
  savedViews$ = this.select('savedViews');
  // @ts-ignore
  selectionData$: Observable<c3dviewer.GetSelectionListResult> = this.select('selectionData');
  selectNode$ = this.select('selectNode');
  // @ts-ignore
  annotationListData$: Observable<c3dviewer.GetAnnotationListResult> = this.select('annotationListData');
  allModelItems$ = this.select('allModelItems');
  bindedData$ = this.select('bindedData');

  allWorks$ = this.select('allWorks');

  viewerParams$ = this.select('viewerParams');

  constructor(protected override store: ViewerStore) {
    super(store);
  }
}
