<ng-container *ngIf="isAutorized(); else notAuthorized">
  <app-portal-core-side-nav-outer-toolbar title="{{appInfo.title}}" [rightContent]="rightContent" id="app-window">
    <div *ngIf="isIE" class="danger-box">
      <b>ВНИМАНИЕ! Вы используете устаревший браузер Internet Explorer.</b><br>
      При использовании данного браузера работа с порталом может быть затруднена или вовсе не возможна.<br>
      Настоятельно рекомендуем Вам для работы с порталом использовать браузеры Google Chrome, Mozilla Firefox, Microsoft Edge последней версии.
    </div>
    <ng-container *ngIf="loadingRoute || loadingContent">
      <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="progressbar progressbar-indeterminate">
        <div class="progressbar-indeterminate-container">
          <div class="progressbar-value progressbar-value-animate"></div>
        </div>
      </div>
    </ng-container>
    <router-outlet></router-outlet>
  </app-portal-core-side-nav-outer-toolbar>
  <pp-ui-notifier></pp-ui-notifier>
</ng-container>

<ng-template #notAuthorized>
  <app-portal-core-card>
    <router-outlet></router-outlet>
  </app-portal-core-card>
</ng-template>

<lib-pp-ui-downloads></lib-pp-ui-downloads>
<pp-ui-confirm-popup></pp-ui-confirm-popup>