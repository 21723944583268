<dx-tabs
  #apiTabs
  [dataSource]="tabs"
  [selectedIndex]="0"
></dx-tabs>

<div *ngIf="apiTabs.selectedIndex === 0">
  <dx-data-grid
    [dataSource]="data" keyExpr="spWorkId"
    [showBorders]="true" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [columnResizingMode]="'widget'"
    [columnMinWidth]="50" (onToolbarPreparing)="addActionButtons($event)" (onInitialized)="setInstanceWorksGrid($event)"
  >
    <dxo-filter-row
      [visible]="true"
    ></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel
      [visible]="true"
    ></dxo-search-panel>
    <dxo-column-chooser [enabled]="true" [mode]="'select'"></dxo-column-chooser>
    <dxo-export [enabled]="true" [fileName]="'Works_' + now"></dxo-export>
    <dxo-state-storing
      [enabled]="true"
      [type]="'localStorage'"
      [storageKey]="'storageWorkGrid'"
    ></dxo-state-storing>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling [mode]="'virtual'"></dxo-scrolling>
    <dxo-group-panel [visible]="true"></dxo-group-panel>

    <dxo-selection
      [selectAllMode]="'allPages'"
      [showCheckBoxesMode]="'always'"
      mode="multiple"
    ></dxo-selection>

    <dxi-column dataField="name" caption="Наименование"></dxi-column>
    <dxi-column dataField="userID" caption="Код"></dxi-column>
    <dxi-column dataField="start" caption="Начало" dataType="date"></dxi-column>
    <dxi-column dataField="finish" caption="Окончание" dataType="date"></dxi-column>
    <dxi-column dataField="bL_Project_Start" caption="Начало по ЦП проекта" dataType="date"></dxi-column>
    <dxi-column dataField="bL_Project_Finish" caption="Окончание по ЦП проекта" dataType="date"></dxi-column>
    <dxi-column dataField="actual_Start" caption="Факт. начало" dataType="date"></dxi-column>
    <dxi-column dataField="actual_Finish" caption="Факт. окончание" dataType="date"></dxi-column>
  </dx-data-grid>
</div>

<div *ngIf="apiTabs.selectedIndex === 1">

  <dx-data-grid
    [dataSource]="documents"
    [showBorders]="true" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [columnResizingMode]="'widget'"
    [columnMinWidth]="50">
    <dxo-filter-row
      [visible]="true"
    ></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel
      [visible]="true"
    ></dxo-search-panel>
    <dxo-column-chooser [enabled]="true" [mode]="'select'"></dxo-column-chooser>
    <dxo-export [enabled]="true" [fileName]="'Documents_' + now"></dxo-export>
    <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="storageDocumentsGrid"
    ></dxo-state-storing>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxi-column dataField="author" caption="Разработчик"></dxi-column>
    <dxi-column dataField="description" caption="Наименование"></dxi-column>
    <dxi-column dataField="referenceNo" caption="Номер"></dxi-column>
    <dxi-column dataField="revisionDate" caption="Дата выдачи" dataType="date"></dxi-column>
    <dxi-column dataField="status" caption="Согласование"></dxi-column>
    <dxi-column dataField="title" caption="Номер документа" cellTemplate="doc_numTemplate"></dxi-column>
    <dxi-column dataField="version" caption="Версия"></dxi-column>

    <div *dxTemplate="let data of 'doc_numTemplate'">
      <a [href]="data.data.publicLocation" target="_blank">{{data.value}}</a>
    </div>
    <div *dxTemplate="let data of 'statusTemplate'">
      <a [href]="data.data.status_link" target="_blank">{{data.value}}</a>
    </div>
  </dx-data-grid>
</div>


<dx-popup
  [width]="800"
  [height]="650"
  [showTitle]="true"
  title="Добавление связей элементов информационной модели с работами графика"
  [dragEnabled]="true" [closeOnOutsideClick]="false" [shading]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="popupVisible" (onHidden)="clearSelections()"
>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="addBindButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="closeButtonOptions"
  >
  </dxi-toolbar-item>

  <div *dxTemplate="let content of 'content'">
    <dx-data-grid #WorksForBindGrid
      [dataSource]="DSWorksForBind"
      [showBorders]="true" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [columnResizingMode]="'nextColumn'"
      [columnMinWidth]="50" [height]="543" (onInitialized)="setInstance($event)">
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-search-panel [visible]="true"></dxo-search-panel>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
      <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
      <dxo-paging [pageSize]="10"> </dxo-paging>
      <dxo-pager
        [visible]="true"
        [showPageSizeSelector]="false"
        [showInfo]="true"
        [showNavigationButtons]="false"
      >
      </dxo-pager>
      <dxo-selection
        [selectAllMode]="'allPages'"
        [showCheckBoxesMode]="'always'"
        mode="multiple"
      ></dxo-selection>

      <dxi-column dataField="name" caption="Наименование"></dxi-column>
      <dxi-column dataField="userID" caption="Код" width="150"></dxi-column>
      <dxi-column dataField="start" caption="Начало" dataType="date" [visible]="false"></dxi-column>
      <dxi-column dataField="finish" caption="Окончание" dataType="date" [visible]="false"></dxi-column>
      <dxi-column dataField="bL_Project_Start" caption="Начало по ЦП проекта" dataType="date" [visible]="false"></dxi-column>
      <dxi-column dataField="bL_Project_Finish" caption="Окончание по ЦП проекта" dataType="date" [visible]="false"></dxi-column>
      <dxi-column dataField="actual_Start" caption="Факт. начало" dataType="date" [visible]="false"></dxi-column>
      <dxi-column dataField="actual_Finish" caption="Факт. окончание" dataType="date" [visible]="false"></dxi-column>
    </dx-data-grid>
  </div>
</dx-popup>
