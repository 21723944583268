import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import * as c3dviewer from '@c3dlabs/c3dviewer-api';
import { IModelTree } from '../blocks/model-tree/model-tree.component';

export interface SelectionState extends EntityState {
  modelInfo: any;
  modelIds: string;
  selectionData: c3dviewer.GetSelectionListResult | undefined;
  selectNode: any;
  annotationListData: c3dviewer.GetAnnotationListResult | undefined;
  modelTree: IModelTree[] | undefined | [];
  allModelItems: any;
  viewerSettings: any;
  savedViews: any;
  tmp: any;
  allWorks: any;
  viewerParams: { uuidViewer: string; uuidModel: string };
}

export function createInitialState(): SelectionState {
  return {
    modelInfo: undefined,
    modelIds: undefined,
    selectionData: undefined,
    selectNode: undefined,
    annotationListData: undefined,
    modelTree: undefined,
    allModelItems: [],
    bindedData: undefined,
    viewerSettings: undefined,
    savedViews: [],
    tmp: [],
    allWorks: [],
    viewerParams: { uuidViewer: '', uuidModel: '' },
  };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'session' })
export class ViewerStore extends EntityStore<SelectionState> {
  constructor() {
    super(createInitialState());
  }
}
