import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, TitleStrategy } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  private history = [];
  private unsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private location: Location,
    titleStrategy: TitleStrategy
  ) {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back() {
    this.history.pop();
    if (this.history.length) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
