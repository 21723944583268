import {
  ColumnResizeMode,
  HeaderFilter,
  Pager,
  PagingBase,
  Properties,
  Scrolling,
  Sorting,
} from 'devextreme/ui/data_grid';

export class BaseGridProperties implements Properties {
  sorting?: Sorting = { mode: 'multiple' }; // multiple sorting
  paging?: PagingBase = { enabled: true, pageSize: 25 }; // enable paging
  pager?: Pager = { visible: true, showPageSizeSelector: true, allowedPageSizes: [25, 50, 75, 100], showInfo: true }; // pager settings
  filterType? = 'include';
  headerFilter?: HeaderFilter = { visible: true }; // header filter settings
  allowColumnResizing?: boolean = true; // allow column resizing
  columnResizingMode?: ColumnResizeMode = 'widget'; // column resizing mode
  rowAlternationEnabled?: boolean = true; // enable row alternation
  // columnWidth?: number | 'auto' = 130;   // default column width
  columnMinWidth?: number = 65; // minimum column width
  scrolling?: Scrolling = { showScrollbar: 'always' };
  width?: string | number | (() => string | number) = '100%';
}
