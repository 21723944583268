import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDropDownBoxModule, DxLoadIndicatorModule, DxTreeListModule, DxValidatorModule } from 'devextreme-angular';
import { DropdownTreeListComponent } from './dropdown-tree-list.component';

@NgModule({
  declarations: [DropdownTreeListComponent],
  exports: [DropdownTreeListComponent],
  imports: [CommonModule, DxDropDownBoxModule, DxLoadIndicatorModule, DxValidatorModule, DxTreeListModule],
})
export class DropdownTreeListModule {}
