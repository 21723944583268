import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BimViewerComponent } from './bim-viewer.component';
import { HttpClientModule } from '@angular/common/http';
import { DevExtremeModule } from 'devextreme-angular';
import { SelectModelComponent } from './forms/select-model/select-model.component';
import { ModelTreeComponent } from './blocks/model-tree/model-tree.component';
import { AngularSplitModule } from 'angular-split';
import { HeaderComponent } from './blocks/header/header.component';
import { RightPanelComponent } from './blocks/right-panel/right-panel.component';
import { DetailsComponent } from './blocks/details/details.component';
import { CommentComponent } from './forms/comment/comment.component';
import { ViewerService } from './store/viewer.service';
import { WorksComponent } from './blocks/works/works.component';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '@pp/ui-components';
import { environment } from '@pp/root-env';
import { SharedApprovePipesModule } from 'src/app/shared/components/shared-approve/shared-approve.pipe';

const viewSettings = {
  view: {
    environment: {
      background: {
        brush: 'LinearGradient',
        color_1: {
          r: 255,
          g: 255,
          b: 255,
        },
        color_2: {
          r: 210,
          g: 230,
          b: 255,
        },
      },
      default: {
        camera: {
          orientation: {
            type: 'standard',
            value: 'Front',
          },
          projection: 'Orthogonal',
          stdUpVector: 2,
        },
        renderMode: 'shaded',
      },
    },
    workspace: {
      colors: {
        highlightedMesh: {
          r: 200,
          g: 0,
          b: 0,
        },
        selectedMesh: {
          r: 0,
          g: 200,
          b: 0,
        },
        highlightedWireframe: {
          r: 250,
          g: 70,
          b: 70,
        },
        selectedWireframe: {
          r: 70,
          g: 250,
          b: 70,
        },
        groups: [
          {
            index: 4,
            opacity: 0.1,
            color: {
              r: 0.0,
              g: 0.0,
              b: 0.0,
            },
          },
          {
            index: 1,
            color: {
              r: 255.0,
              g: 130.0,
              b: 130.0,
            },
          },
          {
            index: 2,
            color: {
              r: 200.0,
              g: 200.0,
              b: 0.0,
            },
          },
          {
            index: 3,
            color: {
              r: 30.0,
              g: 170.0,
              b: 100.0,
            },
          },
        ],
      },
    },
  },
  showWorksPanel: true,
};

function initializeAppFactory(viewerService: ViewerService): () => boolean {
  const apiHost = environment.production ? location.host : environment.apiUrlBim;
  const settings: any = {
    service: {
      api: {
        protocol: 'https:',
        host: apiHost,
        port: '443',
      },
      c3dApi: {
        protocol: 'https:',
        host: apiHost,
        port: '/api/bim',
        version: 'v1',
      },
      secure: true,
      apiFilesUrl: 'https://' + apiHost + '/api/Archive/BIMFileServiceOData',
      apiUrl: 'https://' + apiHost + '/api/bim/',
    },
  };

  settings.view = viewSettings.view;
  settings.showWorksPanel = viewSettings.showWorksPanel;
  viewerService.updateViewerSettings(settings);
  return () => true;
}

@NgModule({
  declarations: [
    BimViewerComponent,
    ModelTreeComponent,
    HeaderComponent,
    RightPanelComponent,
    DetailsComponent,
    CommentComponent,
    WorksComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    DevExtremeModule,
    AngularSplitModule,
    UiComponentsModule,
    SharedApprovePipesModule,
    SelectModelComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [ViewerService],
      multi: true,
    },
  ],
  bootstrap: [BimViewerComponent],
})
export class BimViewerModule {}
