<dx-drop-down-box
  [valueExpr]="valueExpr" [displayExpr]="displayExpr" [(value)]="value"
  [showClearButton]="showClearButton" [placeholder]="placeholder" [dataSource]="dataSource"
  [stylingMode]="'outlined'" [disabled]="disabled || dataSource?.isLoading()" [dropDownButtonTemplate]="'dropDownButton'"
  (onValueChanged)="validate($event)"
>
  <div *dxTemplate="let data of 'content'" id="dropdown-tree-list-wrapper">
    <dx-tree-list
      class="pp-drop-down-box-list"
      [rootValue]="rootValue"
      [dataSource]="dataSource"
      [keyExpr]="valueExpr"
      [parentIdExpr]="parentIdExpr"
      [selectedRowKeys]="value"
      [wordWrapEnabled]="true"
      [showBorders]="false" [showRowLines]="false" [showColumnHeaders]="false"
      [disabled]="disabled"
      (onSelectionChanged)="selectionItem($event)"
      [height]="height">
      <dxo-scrolling [mode]="scrollingMode"></dxo-scrolling>
      <dxo-search-panel [visible]="true" width="auto"></dxo-search-panel>
      <dxo-selection [mode]="selectionMode"></dxo-selection>
      <dxi-column *ngFor="let column of columns" [dataField]="column.dataField" [sortOrder]="sortOrder"></dxi-column>
      <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    </dx-tree-list>
  </div>
  <div *dxTemplate="let data of 'dropDownButton'">
    <dx-load-indicator [visible]="dataSource?.isLoading && dataSource?.isLoading()"
                       style="height: 18px; width: 18px; position: relative; top: 5px;"></dx-load-indicator>
    <div *ngIf="!dataSource?.isLoading()" class="dx-dropdowneditor-icon"></div>
  </div>
  <dx-validator [adapter]="adapterConfig" validationGroup="objectVG">
    <dxi-validation-rule *ngIf="isRequired" type="required"></dxi-validation-rule>
  </dx-validator>
</dx-drop-down-box>
