export const apiPathUrls = {
  Core: 'api/Core/',
  ManagingSMR: 'api/ManagingCMP/',
  QC: 'api/QualityControl/',
  ControlTask: 'ControlTask/',
  Archive: 'api/Archive/',
  ExecutionSMR: 'api/ExecutionCMP/',
  Remarks: 'api/Remarks/',
  AE: 'api/ApproveEngine/',
} as const;

export const endpoints = {
  a0UserService: apiPathUrls.Core + 'A0UserService/',
  userServiceOData: apiPathUrls.Core + 'UserServiceOData/',
  actService: apiPathUrls.Core + 'ActService/',
  blueprintsUrl: apiPathUrls.Core + 'BlueprintMarkService/', // Valid *
  accessControlUrl: apiPathUrls.Core + 'UserRoleInProjectService/', // Valid *
  contractTypeUrl: apiPathUrls.Core + 'ContractTypeService/', // Valid *
  contractsUrl: apiPathUrls.Core + 'ContractService/', // Valid *
  departmentsUrl: apiPathUrls.Core + 'DepartmentsService/', // Valid *
  DocRemarksDrawingUrl: apiPathUrls.Archive + 'DocumentDrawingService/', // Valid
  DocRemarksUrl: apiPathUrls.Archive + 'DocumentRemarkService/',
  DocsPermissionsService: apiPathUrls.Archive + 'PermissionsService/', // +
  DocsApproveService: apiPathUrls.Archive + 'DocumentApproveService/',
  DocsApproveTaskOData: apiPathUrls.Archive + 'DocumentApproveTaskServiceOData/', // +

  DocumentApproveTaskAssignedToMeNewServiceOData:
    apiPathUrls.Archive + 'DocumentApproveTaskAssignedToMeNewServiceOData/', // +
  DocumentApproveTaskAssignedToMeInWorkServiceOData:
    apiPathUrls.Archive + 'DocumentApproveTaskAssignedToMeInWorkServiceOData/', // +
  DocumentApproveTaskAssignedToMeExpiredServiceOData:
    apiPathUrls.Archive + 'DocumentApproveTaskAssignedToMeExpiredServiceOData/', // +

  DocsApproveTaskService: apiPathUrls.Archive + 'DocumentApproveTaskService/',
  DocsRemarksOData: apiPathUrls.Archive + 'DocumentRemarkServiceOData/', // +
  DocsNoPermissionsOData: apiPathUrls.Archive + 'DocumentNoPermissionsServiceOData/', // +
  DocsRemarksNoPermissionsOData: apiPathUrls.Archive + 'DocumentRemarkNoPermissionsServiceOData/', // +
  TransmittalService: apiPathUrls.Archive + 'TransmittalService/',
  TransmittalServiceOData: apiPathUrls.Archive + 'TransmittalServiceOData',
  TransmittalContractsForCreateServiceOData: apiPathUrls.Archive + 'TransmittalContractsForCreateServiceOData',
  TransmittalContractsForUpdateServiceOData: apiPathUrls.Archive + 'TransmittalContractsForUpdateServiceOData',
  documentService: apiPathUrls.Archive + 'DocumentService/', // +
  documentServiceOData: apiPathUrls.Archive + 'DocumentServiceOData/', // +
  documentForSelectionServiceOData: apiPathUrls.Archive + 'DocumentForSelectionServiceOData/', // ?revisions=1 -  0 - "Все ревизии" 1 - "Последняя ревизия"
  RemarksRejectionReasonService: apiPathUrls.Remarks + 'RemarkRejectReasonService/', // +
  RemarkResponsibleMatrixService: apiPathUrls.Archive + 'RemarkResponsibleMatrixService/',
  documentAccessMatrixService: apiPathUrls.Archive + 'DocumentAccessMatrixService/',
  collaborationService: apiPathUrls.Archive + 'CollaborationService/',
  emailServiceUrl: apiPathUrls.Core + 'EmailService/', // Valid *
  ModuleService: apiPathUrls.Core + 'ModuleService/', // Valid *
  objectStructureUrl: apiPathUrls.Core + 'ObjectStructureService/', // Valid *
  PermissionServiceUrl: apiPathUrls.Core + 'Permissions/',
  permissionUrl: apiPathUrls.Core + 'PermissionService/', // Valid *
  PrintTemplateServiceUrl: apiPathUrls.Core + 'PrintTemplateService/',
  projectsUrl: apiPathUrls.Core + 'ProjectService/', // Valid *
  archiveProjectsUrl: apiPathUrls.Archive + 'ProjectService/', // Valid *
  pushNotificationUrl: apiPathUrls.Core + 'PushNotificationService/', // Valid *
  RemarksCategoryService: apiPathUrls.Remarks + 'RemarkCategoryService/', // +
  RemarksPermissionsService: apiPathUrls.Remarks + 'PermissionsService/',
  RemarksPriorityService: apiPathUrls.Remarks + 'RemarkPriorityService/', // *
  responsibleUserServiceUrl: apiPathUrls.Core + 'ResponsibleUserService/', // Valid *
  RuleStartServiceUrl: apiPathUrls.Core + 'RuleStartService/',
  rolesUrl: apiPathUrls.Core + 'RoleService/', // Valid *
  TaskServiceUrl: apiPathUrls.Core + 'TaskService/',
  usersUrl: apiPathUrls.Core + 'UsersService/', // Valid *
  workCalendarUrl: apiPathUrls.Core + 'WorkCalendarService/', // Valid *
  workTypesUrl: apiPathUrls.Core + 'WorkTypeService/', // Valid *
  ConfigService: apiPathUrls.Core + 'ConfigService/',
  UserServiceContractOData: apiPathUrls.Core + 'UserServiceODataContract/', // Valid *
  ProjectServiceOData: apiPathUrls.Core + 'ProjectServiceOData/',
  ProjectStructureServiceOData: apiPathUrls.Core + 'ProjectStructureServiceOData/',
  ProjectsAndProjectStructuresForAdminServiceOData:
    apiPathUrls.Core + 'ProjectsAndProjectStructuresForAdminServiceOData/',
  ProjectsAndProjectStructuresForTableServiceOData:
    apiPathUrls.Core + 'ProjectsAndProjectStructuresForTableServiceOData/',
  ProjectsAndProjectStructuresForTileServiceOData:
    apiPathUrls.Core + 'ProjectsAndProjectStructuresForTileServiceOData/',
  ObjectStructureServiceOData: apiPathUrls.Core + 'ObjectStructureServiceOData/',
  projectStructureUrl: apiPathUrls.Core + 'ProjectStructureService/',
  settingsServiceUrl: apiPathUrls.Core + 'SettingsService/',
  StructuralElementServiceUrl: apiPathUrls.Core + 'StructuralElementService/',

  // --------------- Admin ---------------- //
  answerOptionsUrl: apiPathUrls.QC + 'AnswearOptionTemplateService/', // Valid *
  answerValueListTemplateUrl: apiPathUrls.QC + 'AnswerValueListTemplateService/', // Valid *
  categoriesUrl: apiPathUrls.QC + 'RemarkCategoriesService/', // Valid *
  categoryUrl: apiPathUrls.Core + 'CategoryService/',
  categoryGroupsUrl: apiPathUrls.Core + 'CategoryGroupService/',
  CategoriesAndCategoryGroupsServiceOData: apiPathUrls.Core + 'CategoriesAndCategoryGroupsServiceOData',
  categoryMeetingsUrl: apiPathUrls.Core + 'MeetingCategoryService/', // not found in all swagger

  checklistStatusTemplate: apiPathUrls.QC + 'ChecklistStatusTemplateService/',
  checklistItemUrl: apiPathUrls.QC + 'ChecklistItemService/', // Valid *
  checklistItemTemplateUrl: apiPathUrls.QC + 'ChecklistItemTemplateService/', // Valid *
  checklistItemTemplateTypeUrl: apiPathUrls.QC + 'ChecklistItemTemplateTypeService/', // Valid *

  checklistSectionUrl: apiPathUrls.QC + 'ChecklistSectionService/', // Valid *
  checklistSectionTemplateUrl: apiPathUrls.QC + 'ChecklistSectionTemplateService/', // Valid *
  checklistUrl: apiPathUrls.QC + 'ChecklistService/', // Valid *
  checklistTemplateUrl: apiPathUrls.QC + 'ChecklistTemplateService/', // Valid *

  contentTypesUrl: apiPathUrls.Core + 'ContentTypeService/', // Valid *
  contentTypesOData: apiPathUrls.Core + 'ContentTypesAndProjectStagesServiceOData/', // Valid *
  contractsODataUrl: apiPathUrls.Core + 'ContractServiceOData/', // *
  controlTaskPermissionsUrl: apiPathUrls.ControlTask + 'Permissions/', // not found in all swagger
  coverLetterTemplateUrl: apiPathUrls.Core + 'CoverLetterTemplateService/', // not found in all swagger
  curatorMatrixUrl: apiPathUrls.ManagingSMR + 'CuratorMatrixService/', // Valid *
  delegateRightsUrl: apiPathUrls.Core + 'DelegateRightsService/', // err 502 *
  documentStatusUrl: apiPathUrls.Core + 'DocumentStatusService/', // err 502 *
  factRejectReasonsUrl: apiPathUrls.ManagingSMR + 'ActualWorkCompletionLogApprovalRejectReasonService/', // Valid *
  initiatorsUrl: apiPathUrls.QC + 'RemarkInitiatorService/', // Valid *
  inspectionCategoryUrl: apiPathUrls.QC + 'InspectionCategoryService/', // Valid *
  inspectionRejectReasonsUrl: apiPathUrls.QC + 'InspectionRejectReasonService/', // Valid *
  inspectionUrl: apiPathUrls.QC + 'InspectionService/', // Valid *
  inspectionTemplateUrl: apiPathUrls.QC + 'InspectionTemplateService/', // Valid *
  inspectionFormUrl: apiPathUrls.QC + 'InspectionFormService',
  languageUrl: apiPathUrls.Core + 'LanguageService/',
  measureUnitsUrl: apiPathUrls.Core + 'MeasureUnitService/', // err 404 *
  meetingSubjectUrl: 'MeetingSubjectService/', // not found in all swagger
  priorityUrl: apiPathUrls.QC + 'PriorityService/', // Valid *
  prescriptionService: apiPathUrls.QC + 'PrescriptionService/', // Valid *
  prescriptionTemplateService: apiPathUrls.QC + 'PrescriptionTemplateService/', // Valid *
  prescriptionODataUrl: apiPathUrls.QC + 'PrescriptionServiceOData/', // Valid *
  projectStageUrl: apiPathUrls.Core + 'ProjectStageService/',
  reasonChangesUrl: apiPathUrls.Core + 'ReasonChangesService/', // err 404 *
  remarkBlueprintUrl: apiPathUrls.QC + 'RemarkDocumentFileService/', // not found in all swagger
  remarkStatusUrl: apiPathUrls.Core + 'ApproveRemarkStatusService/', // not found in all swagger
  remarkTypesUrl: apiPathUrls.Core + 'RemarkTypesService/', // not found in all swagger
  remarkUrl: apiPathUrls.QC + 'RemarkService/', // Valid/err 500 *
  resultUrl: apiPathUrls.QC + 'RemarkResultService/', // Valid *
  approveResultUrl: apiPathUrls.AE + 'ApproveResultService/',
  approveResultPermissionsUrl: apiPathUrls.AE + 'PermissionsService/',
  documentApproveRouteService: apiPathUrls.Archive + 'DocumentApproveRouteService/', // no archive yet
  stampService: apiPathUrls.Core + 'StampService/', // err 404
  stampSignerService: apiPathUrls.Core + 'StampSignerService/', // err 404
  stampTypeService: apiPathUrls.Core + 'StampTypeService/', // err 404
  WorkCompletionPlanVersionApprovalUrl: apiPathUrls.ManagingSMR + 'WorkCompletionPlanVersionApprovalService/', // Valid *
  workCompletionPlanVersionVolumeUrl: apiPathUrls.ManagingSMR + 'WorkCompletionPlanVersionVolumeService/', // Valid *
  workPlanRejectReasonsUrl: apiPathUrls.ManagingSMR + 'WorkCompletionPlanVersionApprovalRejectReasonService/', // Valid *
  workPlanUrl: apiPathUrls.ManagingSMR + 'WorkCompletionPlanService/', // Valid *
  workPlanVersionsUrl: apiPathUrls.ManagingSMR + 'WorkCompletionPlanVersionService/', // Valid *
  workVolumeUrl: apiPathUrls.ManagingSMR + 'WorkVolumeListService/', // Valid *
  revisionUrl: apiPathUrls.Core + 'RevisionService/', // Valid *
  disciplineUrl: apiPathUrls.Core + 'DisciplineService/', // Valid *
  releaseTargetsUrl: apiPathUrls.Core + 'ReleaseTargetService/', // Valid *

  transmittalCategoriesUrl: apiPathUrls.Archive + 'TransmittalCategoryService/', // Valid *
  transmittalBlanksUrl: apiPathUrls.Archive + 'TransmittalListTemplateService/', // Valid *

  // --------------- QualityControl ---------------- // not checked
  ManagingSMRPermissionsService: apiPathUrls.ManagingSMR + 'PermissionsService/',
  QCPermissionsService: apiPathUrls.QC + 'PermissionService/',
  actualWorkCompletionODataUrl: apiPathUrls.ManagingSMR + 'ActualWorkCompletionLogServiceOData/',
  actualWorkCompletionUrl: apiPathUrls.ManagingSMR + 'ActualWorkCompletionLogService/',
  projectRemarkUrl: apiPathUrls.QC + 'ProjectRemarkService/',
  QCRemarksODataUrl: apiPathUrls.QC + 'RemarkServiceOData/',
  QCInspectionsODataUrl: apiPathUrls.QC + 'InspectionServiceOData/',
  inspectionFormTemplateUrl: apiPathUrls.QC + 'InspectionFormTemplateService/',
  collaborationManagingSMRService: apiPathUrls.ManagingSMR + 'CollaborationService/',

  // --------------- ExecutionSMR2 ---------------- //
  ExecutionSMRPermissionsService: apiPathUrls.ExecutionSMR + 'PermissionsService/', // Valid *
  workCompletetionUrl: apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateService/', // Valid *
  workCompletetionODataUrl: apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateServiceOData/', // Valid *
  workCompletionCertificateWorkService: apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateWorkService/', // Valid *
  workCompletionCertificateWorkServiceOData: apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateWorkServiceOData/', // Valid *
  workCompletionCertificateSettingsServiceSP: apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateSettingsService/', // Valid *
  workCompletionCertificateFinalValueServiceSP:
    apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateFinalValueService/',
  WorkCompletionCertificateFinalValueServiceODataSP:
    apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateFinalValueServiceOData/', // Valid *

  // --------------- Archive ---------------- // not checked
  WorkCompletionCertificateApproveServiceSP: apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateApproveService/',
  WorkCompletionCertificateApproveTemplateService:
    apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateApproveTemplateService/',
  WorkCompletionCertificateApproveTemplateServiceOData:
    apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateApproveTemplateServiceOData/',
  worksProductionUrl: apiPathUrls.Archive + 'WorkProductionService/',
  catalogUrl: apiPathUrls.Archive + 'CatalogService/', // Valid *
  contentTypesArchiveUrl: apiPathUrls.Archive + 'ContentTypeService/',
  DocumentNumerationRuleServiceUrl: apiPathUrls.Archive + 'DocumentNumerationRuleService/',
  CatalogServiceOData: apiPathUrls.Archive + 'CatalogServiceOData/',
  crsTemplatesServiceUrl: apiPathUrls.Archive + 'CommentReviewSheetTemplateService/',
  DocumentFileForViewerServiceOData: apiPathUrls.Archive + 'DocumentFileForViewerServiceOData/',

  // --------------- Approve ---------------- //
  actUrl: apiPathUrls.Core + 'ObjectsService/', // not used
  ApproveServiceUrl: apiPathUrls.ExecutionSMR + 'ApproveService/', // not used
  ApproveTasks: apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateApproveTaskService/', // Valid *
  ApproveTasksOData: apiPathUrls.ExecutionSMR + 'WorkCompletionCertificateApproveTaskServiceOData/', // Valid *
  asyncTaskService: apiPathUrls.Core + 'AsyncTaskService/', // not used
} as const;
