import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nominativeMonth' })
export class NominativeDatePipe implements PipeTransform {
  constructor() {}

  transform(date: Date | string) {
    if (typeof date === 'string') date = new Date(date);

    let monthIdx = date.getMonth();

    let months = [
      'январь',
      'февраль',
      'март',
      'апрель',
      'май',
      'июнь',
      'июль',
      'август',
      'сентябрь',
      'октябрь',
      'ноябрь',
      'декабрь',
    ];

    if (monthIdx >= 0 && monthIdx < 12) return months[monthIdx];
    else return 'Invalid Date';
  }
}

@NgModule({
  declarations: [NominativeDatePipe],
  exports: [NominativeDatePipe],
})
export class NominativeDatePipeModule {}
