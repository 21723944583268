import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from 'projects/shared/interfaces/tmp/user.interface';
import { environment } from '../environments/environment';
import { IAnswerValueListTemplate } from './answer-vluelist-template.service';
import { IAnswearOptionsId, IControlType } from './checklists.service';

@Injectable({
  providedIn: 'root',
})
export class ChecklistItemTemplateService {
  private _checklistItemTemplateUrl = '';
  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private _http: HttpClient) {
    // TODO PPDEV-837 swith to apiUrl
    this._checklistItemTemplateUrl = environment.apiUrl + environment.checklistItemTemplateUrl;
  }

  getAllItemByChecklistTemplate(checklistTemplateId: string) {
    return this._http.get<IChecklistItemTemplate[]>(
      this._checklistItemTemplateUrl + 'GetAllByChecklistTemplate?checklistTemplateId=' + checklistTemplateId
    );
  }

  getItemTemplate() {
    return this._http.get<IChecklistItemTemplate>(this._checklistItemTemplateUrl + 'Get?id=');
  }

  createItemTemplate(body: IChecklistItemTemplateCreate) {
    return this._http.post(this._checklistItemTemplateUrl + 'Create', body, this._httpOptions);
  }

  updateItemTemplate(body: IChecklistItemTemplateUpdate) {
    return this._http.post(this._checklistItemTemplateUrl + 'Update', body, this._httpOptions);
  }

  deleteItemTemplate(id: string) {
    return this._http.delete(this._checklistItemTemplateUrl + 'Delete?id=' + id);
  }
}

export class ChecklistItemTemplateCreate implements IChecklistItemTemplateCreate {
  Title = '';
  SortOrder = 0;
  Type = 5;
  Attachments = false;
  TextDefault = null;
  NumberMin = 0;
  NumberMax = 100;
  AnswerValueListTemplateId = null;
  AnswerValueListTemplate? = null;
  ControlType = null;

  customValidation = () => {
    return this.NumberMin <= this.NumberMax;
  };

  get isValid() {
    return this.customValidation();
  }

  public get NumberFractionalDigits() {
    return this._NumberFractionalDigits;
  }

  public set NumberFractionalDigits(val: number) {
    this._NumberFractionalDigits = val;
    this.NumberMax = this.NumberMax != null ? this.sliceDecimal(this.NumberMax, val) : null;
    this.NumberMin = this.NumberMin != null ? this.sliceDecimal(this.NumberMin, val) : null;
  }
  private _NumberFractionalDigits = 0;

  public get numberFormat() {
    const sharps = this.NumberFractionalDigits ? '.' + Array(this.NumberFractionalDigits).fill('#').join('') : '';

    return this.NumberFractionalDigits ? '#0' + sharps : '#0';
  }

  constructor(order?: number, value?: ChecklistItemTemplateCreate) {
    if (order != undefined) this.SortOrder = order;

    if (value) {
      Object.keys(value)
        .filter((key) => key != 'customValidation')
        .forEach((key) => {
          this[key] = value[key];
        });

      this.NumberMin = this.NumberMin ? this.NumberMin : 0;
      this.NumberMax = this.NumberMax ? this.NumberMax : 100;
      this.NumberFractionalDigits = this.NumberFractionalDigits ? this.NumberFractionalDigits : 0;

      if (value.ControlType === 0 && value.Type === 5) {
        this.Type = 1;
      }

      this.AnswerValueListTemplateId = value.AnswerValueListTemplate ? value.AnswerValueListTemplate.Id : null;
    }
  }

  sliceDecimal(val: number, length: number): number {
    const valString = '' + val;
    const arr = valString.split('.');

    if (arr.length < 2) return val;
    if (!length) {
      return Number(arr[0]);
    }
    if (arr[1].length <= length) return val;

    const resultString = arr[0] + '.' + arr[1].slice(0, length);

    return Number(resultString);
  }
}

export interface IChecklistItemTemplate {
  CreatedBy: IUser;
  CreatedById: string;
  ModifiedBy: IUser;
  ModifiedById: string;
  Created: string;
  Modified: string;
  Id: string;
  Title: string;
  SortOrder: number;
  Attachments: boolean;
  Type: IChecklistItemTypeId;
  ChecklistTemplateId: string;
  ChecklistSectionTemplateId: string;
  ChecklistItemTemplates: string;
  AnswearOptions: IAnswearOptionsId;
  TextDefault: string;
  NumberMin: number;
  NumberMax: number;
  NumberFractionalDigits: number;
  AnswerValueListTemplate: IAnswerValueListTemplate;
  AnswerValueListTemplateId?: string;
  ControlType: IControlType;
}

export interface IChecklistItemTemplateCreate {
  Title: string;
  SortOrder: number;
  Type: IChecklistItemTypeId;
  Attachments: boolean;
  TextDefault?: string;
  NumberMin?: number;
  NumberMax?: number;
  NumberFractionalDigits?: number;
  AnswerValueListTemplateId?: string;
  ControlType?: IControlType;
  customValidation?: Function;
}

export interface IChecklistItemTemplateUpdate extends IChecklistItemTemplateCreate {
  Id: string;
}

export type IChecklistItemTypeId = number; // 1 | 2 | 3 | 4 | 5
