import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScreenService {
  @Output() changed = new EventEmitter();
  @Output() mouseWheelScrolled = new EventEmitter();

  @Output() appHeight = new EventEmitter();
  @Output() appWidth = new EventEmitter();

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) document
  ) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
      .subscribe(() => {
        this.changed.next(null);
        this.appHeight.next(
          document.getElementById('s4-bodyContainer') ? document.getElementById('s4-bodyContainer').offsetHeight : 1000
        );
        this.appWidth.next(
          document.getElementById('s4-bodyContainer') ? document.getElementById('s4-bodyContainer').offsetWidth : 1000
        );
      });

    // this.appHeight.next(document.getElementById('s4-bodyContainer').offsetHeight);
    // this.appWidth.next(document.getElementById('s4-bodyContainer').offsetWidth);
    // console.log('app height', document.getElementById('s4-bodyContainer').offsetHeight);
    // document.getElementById('app-nav').style.height = document.getElementById('s4-bodyContainer').offsetHeight + 'px'
  }

  private isLargeScreen() {
    const isLarge = this.breakpointObserver.isMatched(Breakpoints.Large);
    const isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge);

    return isLarge || isXLarge;
  }

  public get sizes() {
    return {
      'screen-x-small': this.breakpointObserver.isMatched(Breakpoints.XSmall),
      'screen-small': this.breakpointObserver.isMatched(Breakpoints.Small),
      'screen-medium': this.breakpointObserver.isMatched(Breakpoints.Medium),
      'screen-large': this.isLargeScreen(),
    };
  }

  public mouseWheel() {
    this.mouseWheelScrolled.next(true);
  }
}
