import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmService } from './confirm.service';

@Component({
  selector: 'pp-ui-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent implements OnInit, OnDestroy {
  popupVisible: boolean = false;
  text: string = null;
  title: string = null;
  size: 'sm' | 'lg' = 'sm';
  btnOkText: string = null;
  btnOkType: string = 'default';
  btnCancelText: string = null;
  btnCancelType: string = 'normal';
  contentTemplate: TemplateRef<any> = null;

  okCallback: Function = () => {};
  cancelCallback: Function = () => {};
  hiddenCallBack: Function = () => {};

  private unsubscribe$ = new Subject();

  constructor(private _confirmService: ConfirmService) {}

  ngOnInit() {
    this._confirmService
      .getObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((popupData) => {
        // console.log(popupData.options);
        this.text = popupData.options.message;
        this.contentTemplate = popupData.options.contentTemplate;
        this.title = popupData.options.title;
        this.size = popupData.options.dialogSize;
        this.btnOkText = popupData.options.btnOkText;
        this.btnOkType = popupData.options.btnOkType;
        this.btnCancelText = popupData.options.btnCancelText;
        this.okCallback = popupData.confirm;
        this.cancelCallback = popupData.reject;
        this.hiddenCallBack = popupData.hiddenCallBack;
        this.popupVisible = true;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  async okClick(e) {
    if (this.okCallback) {
      await this.okCallback(e);
      this.popupVisible = false;
    } else this.popupVisible = false;
  }

  async cancelClick(e) {
    console.log(this.contentTemplate);

    if (this.cancelCallback) {
      await this.cancelCallback(e);
      this.popupVisible = false;
    } else this.popupVisible = false;
  }

  async hidden(e) {
    if (this.hiddenCallBack) {
      await this.hiddenCallBack(e);
    } else this.popupVisible = false;
  }
}
