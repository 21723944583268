import { NgModule } from '@angular/core';
import { Error403Component } from '../error403/error403.component';
import { Error404Component } from './error404.component';
import { ErrorUndefComponent } from '../error-undef/error-undef.component';
import { ErrorNoConnectionComponent } from '../error-no-connection/error-no-connection.component';

@NgModule({
  declarations: [Error403Component, Error404Component, ErrorUndefComponent, ErrorNoConnectionComponent],
})
export class ErrorsModule {}
