import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'projects/pp-administration/src/environments/environment';
import { lastValueFrom, map, Observable, of, tap } from 'rxjs';
import {
  IObjectItem,
  IProject,
  IProjectItem,
  IProjectOData,
  IProjectPermissions,
  IProjectsMyForView,
} from '@pp/interfaces';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  public getAll(): Observable<{
    CanEdit: boolean;
    Items: (IProjectItem & { CodeTitle: string })[];
  }> {
    return this.http
      .get<{
        CanEdit: boolean;
        Items: IProjectItem[];
      }>(environment.apiUrl + environment.projectsUrl + 'GetAll')
      .pipe(
        map((res) => {
          return {
            ...res,
            Items: res.Items.map((p) => {
              return {
                ...p,
                CodeTitle: p.Code ? p.Code + '. ' + p.Title : p.Title,
              };
            }),
          };
        })
      );
  }

  public getAllProjects(): Observable<IProjectOData[]> {
    // same as getAll but no caching and using odata
    return this.http.get(environment.apiUrl + environment.ProjectServiceOData).pipe(
      map((res: { value: IProjectOData[] }) => {
        return res.value;
      })
    );
  }

  public get(id): Observable<{ Item: IProjectItem & { CodeTitle: string }; Permissions: IProjectPermissions }> {
    return this.http.get<IProject>(environment.apiUrl + environment.projectsUrl + 'Get?Id=' + id).pipe(
      map((proj) => {
        return {
          Item: {
            ...proj.Item,
            CodeTitle: proj.Item.Code ? proj.Item.Code + '. ' + proj.Item.Title : proj.Item.Title,
          },
          Permissions: proj.Permissions,
        };
      })
    );
  }

  public getODataFilteredProjectsCount(filter = '') {
    return this.http.get<number>(environment.apiUrl + environment.ProjectServiceOData + `$count${filter}`);
  }

  public getObjectStructures(id: string) {
    return this.http
      .get<IObjectItem[]>(`${environment.apiUrl}${environment.projectsUrl}GetObjectStructures?id=${id}`)
      .pipe(
        map((res) => {
          return res.map((item) => {
            return {
              ...item,
              CodeTitle: item.Code ? item.Code + '. ' + item.Title : item.Title,
            };
          });
        })
      );
  }

  public edit(body: ICreateProjectRequest & { Id: string }) {
    return this.http.post(environment.apiUrl + environment.projectsUrl + 'Update', body, this.httpOptions);
  }

  public create(body: ICreateProjectRequest) {
    return this.http.post(environment.apiUrl + environment.projectsUrl + 'Create', body, this.httpOptions);
  }

  public delete(id: string) {
    return this.http.delete(environment.apiUrl + environment.projectsUrl + 'Delete?Id=' + id);
  }

  public copy(body: IProjectCopy, isArchive: boolean) {
    const serviceUrl = isArchive ? environment.archiveProjectsUrl : environment.projectsUrl;
    return this.http.post(environment.apiUrl + serviceUrl + 'Copy', body, this.httpOptions);
  }
  // Получение оригинала изображения проекта с неправильным именем файла, но быстро
  public getImageFastUrl(id: string) {
    return environment.apiUrl + environment.projectsUrl + 'GetImageFileFast' + '?projectId=' + id;
  }

  // Получение оригинала изображения проекта с корректным именем файла, но медленно
  public getImageUrl(id: string) {
    return environment.apiUrl + environment.projectsUrl + 'GetImageFile' + '?id=' + id;
  }

  // Получение превью изображения проекта с неправильным соотношением сторон и с корректным именем файла, но медленно
  public getImagePreviewRatioUrl(imgId: string, height = 500, width = 500) {
    const queryParamsString = new HttpParams({ fromObject: { id: imgId, width, height } }).toString();

    return environment.apiUrl + environment.projectsUrl + 'GetImageFilePreview?' + queryParamsString;
  }

  //  Получение превью изображения проекта с неправильным соотношением сторон и с неправильным именем файла, но быстро
  public getImageFilePreviewFast(imgId: string, height = 500, width = 500) {
    const queryParamsString = new HttpParams({ fromObject: { id: imgId, width, height } }).toString();

    return environment.apiUrl + environment.projectsUrl + 'GetImageFilePreviewFast?' + queryParamsString;
  }

  // Получение превью изображения проекта с правильным соотношением сторон и с корректным именем файла, но медленно
  public getImageFilePreviewRatio(imgId: string, height = 500, width = 500) {
    const queryParamsString = new HttpParams({ fromObject: { id: imgId, width, height } }).toString();

    return environment.apiUrl + environment.projectsUrl + 'GetImageFilePreviewRatio?' + queryParamsString;
  }

  //  Получение превью изображения проекта с правильным соотношением сторон и с неправильным именем файла, но быстро
  public getImageFilePreviewRatioFast(id: string, height = 168, width = 292) {
    const queryParamsString = new HttpParams({ fromObject: { projectId: id, width, height } }).toString();

    return environment.apiUrl + environment.projectsUrl + 'GetImageFilePreviewRatioFast?' + queryParamsString;
  }

  //  Получение оригинала логотипа проекта с корректным именем файла, но медленно
  public getLogoFile(id: string) {
    // ИД файла изображения проекта
    return environment.apiUrl + environment.projectsUrl + 'GetLogoFile' + '?id=' + id;
  }

  // Получение оригинала логотипа проекта с неправильным именем файла, но быстро
  public getLogoFastUrl(id: string) {
    return environment.apiUrl + environment.projectsUrl + 'GetLogoFileFast' + '?projectId=' + id;
  }

  //  Получение превью логотипа проекта с неправильным соотношением сторон и с корректным именем файла, но медленно
  public getLogoFilePreview(imgId: string, height = 500, width = 500) {
    const queryParamsString = new HttpParams({ fromObject: { id: imgId, width, height } }).toString();

    return environment.apiUrl + environment.projectsUrl + 'GetLogoFilePreview?' + queryParamsString;
  }

  // Получение превью логотипа проекта с правильным соотношением сторон и с корректным именем файла, но медленно
  public getLogoFilePreviewRatio(imgId: string, height = 500, width = 500) {
    const queryParamsString = new HttpParams({ fromObject: { id: imgId, width, height } }).toString();

    return environment.apiUrl + environment.projectsUrl + 'GetLogoFilePreviewRatio?' + queryParamsString;
  }

  // олучение превью логотипа проекта с неправильным соотношением сторон и с неправильным именем файла, но быстро
  public GetLogoFilePreviewFast(id: string, height = 168, width = 292) {
    const queryParamsString = new HttpParams({ fromObject: { projectId: id, width, height } }).toString();

    return environment.apiUrl + environment.projectsUrl + 'GetLogoFilePreviewFast?' + queryParamsString;
  }

  // Получение превью логотипа проекта с правильным соотношением сторон и с неправильным именем файла, но быстро
  public getLogoFilePreviewRatioFast(id: string, height = 168, width = 292) {
    const queryParamsString = new HttpParams({ fromObject: { projectId: id, width, height } }).toString();

    return environment.apiUrl + environment.projectsUrl + 'GetLogoFilePreviewRatioFast?' + queryParamsString;
  }

  public getImageBlob(projId: string) {
    return this.configGetImageFastMethod('GetImageFileFast')(projId);
  }
  public getLogoBlob(projId: string) {
    return this.configGetImageFastMethod('GetLogoFileFast')(projId);
  }

  private configGetImageFastMethod(subUrl: string) {
    return (projectId) => {
      return this.http.get(environment.apiUrl + environment.projectsUrl + subUrl, {
        params: { projectId },
        responseType: 'blob',
      });
    };
  }

  public getProjectsMyForView() {
    return this.http.get<IProjectsMyForView[]>(
      environment.apiUrl + environment.documentService + 'GetProjectsMyForView'
    );
  }

  getProjectsMyForViewDS() {
    return new DataSource({
      key: 'Id',
      store: new CustomStore({
        loadMode: 'raw',
        load: () => {
          return this.getProjectsMyForView()
            .toPromise()
            .then((res) => {
              return res.map((proj) => {
                return {
                  ...proj,
                  CodeName: `${proj.Code}. ${proj.Title}`,
                };
              });
            });
        },
        byKey: (key) => {
          if (!key) return null;

          return lastValueFrom(this.get(key)).then((res) => {
            return {
              Item: {
                ...res.Item,
                CodeName: `${res.Item.Code}. ${res.Item.Title}`,
              },
              Permissions: res.Permissions,
            };
          });
        },
      }),
    });
  }

  getProjectsDS() {
    return new DataSource({
      key: 'Id',
      store: new CustomStore({
        loadMode: 'raw',
        load: () => {
          return lastValueFrom(this.getAll()).then((res) => {
            return res.Items.filter((x) => x.IsActive);
          });
        },
        byKey: (key: string) => {
          if (!key) return null;

          return lastValueFrom(this.get(key)).then((res) => {
            return {
              ...res.Item,
              CodeName: `${res.Item.Code}. ${res.Item.Title}`,
              CodeTitle: `${res.Item.Code}. ${res.Item.Title}`,
            };
          });
        },
      }),
    });
  }
}

export class ICreateProjectRequest {
  Title: string;
  Code: string;
  FullTitle: string;
  QuickNavigation: 0 | 1 | 2 | 3;
  Location: string;
  Stage: string;
  Type: string;
  Scale: string;
  Priority: string;
  StartDate: string;
  EndDate: string;
  Owner: string;
  Address: string;
  AdditionalInformation: string;
  IsActive: boolean;
  DisplayInTable: boolean;
  DisplayInTile: boolean;
  ProjectStructureId: string;
  ImageFileName: string;
  ImageFileContent: string;
  LogoFileName: string;
  LogoFileContent: string;
  ContentTypeAndProjectStageDisplayParameters: number;

  constructor(props: Partial<ICreateProjectRequest>) {
    Object.keys(props).forEach((key) => {
      this[key] = props[key];
    });
  }
}

export interface IProjectCopy {
  Id?: string;
  Code: string;
  ProjectStructureId: string;
  CopyObjectStructures: boolean;
  CopyCatalogs?: boolean;
}
