import { ChangeDetectorRef, Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { AppInfoService, AuthService, ScreenService } from './services';

import { NavigationCancel, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import DataGrid from 'devextreme/ui/data_grid';
import TreeList from 'devextreme/ui/tree_list';
import { GridProperties } from './grid/Grid.Options';
import dxFileUploader from 'devextreme/ui/file_uploader';
import { filter, map } from 'rxjs';
import { RootStoreQuery } from '@pp/root-store';

@Component({
  selector: 'app-portal-core-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  rightContent = {};
  isIE = false;
  loadingRoute = false;
  loadingContent = false;

  constructor(
    private authService: AuthService,
    private screen: ScreenService,
    public appInfo: AppInfoService,
    private router: Router,
    private titleService: Title,
    private rootStoreQuery: RootStoreQuery,
    private detector: ChangeDetectorRef
  ) {
    this.isIE = this.checkIE();

    // Устанавливаем опции по умолчанию во все гриды
    DataGrid.defaultOptions({
      device: [{ deviceType: 'desktop' }, { deviceType: 'tablet' }],
      options: new GridProperties({}),
    });
    // Устанавливаем опции по умолчанию во все tree-листы
    TreeList.defaultOptions({
      device: [{ deviceType: 'desktop' }, { deviceType: 'tablet' }],
      options: { columnChooser: { enabled: true, mode: 'select', sortOrder: 'asc' } },
    });
    // Устанавливаем опции по умолчанию для аплоадера
    dxFileUploader.defaultOptions({
      device: [{ deviceType: 'desktop' }, { deviceType: 'tablet' }],
      options: { maxFileSize: 524288000 / 2 },
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    document.getElementById('app-window').style.height = window.innerHeight + 'px';
  }

  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(' ');
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe(() => {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' | ');
        this.titleService.setTitle(title);
      });

    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRoute = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRoute = false;
      } else if (event instanceof NavigationCancel) {
        this.loadingRoute = false;
      }
    });

    this.rootStoreQuery.contentLoaderState$.subscribe((state) => {
      this.loadingContent = state;
      this.detector.detectChanges();
    });
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  checkIE() {
    const userAgent = navigator.userAgent;
    return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1 || userAgent.indexOf('Edge/') > -1;
  }

  isAutorized() {
    return this.authService.isLoggedIn;
  }
}
