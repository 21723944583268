import { Column, Export, GroupPanel, Grouping, Properties, Selection, Summary } from 'devextreme/ui/data_grid';
import { ColumnChooser, FilterRow, LoadPanel, SearchPanel, StateStoring } from 'devextreme/common/grids';
import { BaseGridProperties } from './Base-grid.Options';

export class GridProperties extends BaseGridProperties {
  export?: Export & { fileName: string } = GridProperties.getExportOption('Export');
  columns?: Column[];
  filterRow?: FilterRow = { visible: true };
  columnChooser?: ColumnChooser = { enabled: true, mode: 'select', sortOrder: 'asc' };
  searchPanel?: SearchPanel = { visible: true, width: 200 };
  stateStoring: StateStoring = GridProperties.getStorageOption('gridStorage');
  activeStateEnabled?: boolean = true;
  hoverStateEnabled?: boolean = true;
  showColumnLines?: boolean = false;
  showRowLines?: boolean = false;
  showBorders?: boolean = false;
  loadPanel?: LoadPanel = { enabled: true };
  allowColumnReordering?: boolean = true;
  groupPanel?: GroupPanel = { allowColumnDragging: true, visible: true };
  grouping?: Grouping = { texts: { groupContinuedMessage: null, groupContinuesMessage: null }, autoExpandAll: false };
  summary?: Summary = {
    skipEmptyValues: false,
    groupItems: [
      {
        displayFormat: $localize`Кол-во:` + ' {0}',
      },
    ],
  };
  selection?: Selection;
  // editing?: Editing;
  selectedRowKeys?: any[];

  constructor(specificOptions: Properties, columns?: Column[], storageKey?: string, exportName?: string) {
    super();
    Object.keys(specificOptions).forEach((key) => {
      this[key] = specificOptions[key];
    });
    if (columns) {
      this.columns = columns;
    }
    if (storageKey) {
      this.stateStoring.storageKey = storageKey;
    }
    if (exportName) {
      this.export.fileName = exportName;
    }
  }

  onToolbarPreparing?: any = (e) => {
    e.toolbarOptions.items.forEach((item) => {
      if (item.name === 'columnChooserButton') {
        item.showText = 'always';
      }
    });
    e.toolbarOptions.items.push({
      locateInMenu: 'always',
      widget: 'dxButton',
      options: {
        text: $localize`Сброс настроек`,
        onClick: () => {
          e.component.state(null);
          location.reload();
        },
      },
    });
  };

  static getExportOption(exportFileName: string) {
    return { enabled: true, fileName: exportFileName, allowExportSelectedData: false };
  }

  static getStorageOption(storageParamName: string): StateStoring {
    return { enabled: true, type: 'localStorage', storageKey: storageParamName };
  }
}
