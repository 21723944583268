import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SelectFileService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getFilesForSelectUrl() {
    return environment.apiUrl + environment.DocumentFileForViewerServiceOData;
  }

  makeGetFilesForSelectODataRequest(params: HttpParams) {
    return this.http.get<{ value: ItemForSelectFileTree[] }>(this.getFilesForSelectUrl(), { params }).pipe(
      map((res) => {
        return res.value;
      })
    );
  }
}

export interface ItemForSelectFileTree {
  Id: string;
  ParentId: string;
  Name: string;
  FileSize: number;
  FileType: number;
  SourceObjectId: string;
  SourceObjectType: number;
  Modified: string;
  ModifiedBy: string;
  ModifiedById: string;
  Created: string;
  CreatedBy: string;
  CreatedById: string;
  NumberAndRevision: string;
  DocumentNumber: string;
  DocumentName: string;
  ChangeNumber: string;
  DocumentGroupId: string;
  Description: string;
  LastRevision: boolean;
  RevisionReleasePlanDate: string;
  Date: string;
  ApprovedPlanDate: string;
  ContentTypeId: string;
  ContentTypeTitle: string;
  DisciplineId: string;
  DisciplineDisplayedTitle: string;
  CustomerId: string;
  CustomerCode: string;
  CustomerFullName: string;
  DeveloperId: string;
  DeveloperCode: string;
  DeveloperFullName: string;
  MarkId: string;
  MarkTitle: string;
  ObjectStructureId: string;
  ObjectStructurePath: string;
  ObjectStructureTitle: string;
  ObjectStructureCode: string;
  ObjectStructureCodeAndTitle: string;
  RevisionId: string;
  RevisionTitle: string;
  ReleaseTargetId: string;
  ReleaseTargetDisplayedTitle: string;
  ProjectId: string;
  ProjectTitle: string;
  ProjectCode: string;
  Version: number;
  DocumentFileGroupId: string;
  ApproveId: string;
  ApproveCode: string;
  ApproveStatus: number;
  ApproveResultDisplayedTitle: string;
  ApproveResultIcon: number;
  ApproveResultId: string;
  ApproveStageId: string;
  ApproveStageName: string;
  ApproveCreatedDate: string;
  ApproveCompletedDate: string;
}
