import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IODataApproveTask } from 'projects/shared/interfaces/tmp/approve-task.odata';
import { map, Observable, share } from 'rxjs';
import { environment } from 'src/environments/environment';
import { makeLocalDate } from '../../../../../src/app/services';
import { TaskType } from 'projects/shared/entities/task';

export interface IApproveReject {
  Id: string;
  Comment: string;
  ApproveResultId: string;
}

export interface IDelegate {
  Id: string;
  Comment: string;
  ToUsersIds: string[];
}

export interface ITaskToDelegate {
  id: string;
  type: TaskType;
  users: any[];
  comment: string;
  _typeView?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DocApproveTaskService {
  private _apiUrl: string;
  private _serviceUrl: string;
  private _serviceODataUrl: string;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private _http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.DocsApproveTaskService;
    this._serviceODataUrl = environment.DocsApproveTaskOData;
  }

  getAll<T>() {
    return this._http.get<T>(this._apiUrl + this._serviceUrl + `GetAll`);
  }

  getById<Task>(id: string) {
    return this._http.get<Task>(this._apiUrl + this._serviceUrl + `Get?id=` + id).pipe(
      map((res: any) => {
        const task = res.Item;

        task.Created = makeLocalDate(task.Created);
        task.Modified = makeLocalDate(task.Modified);
        task.PlanCompletedDate = makeLocalDate(task.PlanCompletedDate);
        task.CompletedDate = task.CompletedDate && makeLocalDate(task.CompletedDate);
        task.RunningStartedDate = task.RunningStartedDate && makeLocalDate(task.RunningStartedDate);

        task.Document.Files.forEach((file) => {
          file.Created = makeLocalDate(file.Created);
          file.Modified = makeLocalDate(file.Modified);
        });

        return res;
      })
    );
  }

  getDocumentApproveTaskServiceODataUrl(filter: string = '') {
    return this._apiUrl + this._serviceODataUrl + filter;
  }

  getDocumentApproveTaskAssignedToMeNewServiceODataUrl() {
    return this._apiUrl + environment.DocumentApproveTaskAssignedToMeNewServiceOData;
  }

  getDocumentApproveTaskAssignedToMeInWorkServiceODataUrl() {
    return this._apiUrl + environment.DocumentApproveTaskAssignedToMeInWorkServiceOData;
  }

  getDocumentApproveTaskAssignedToMeExpiredServiceODataUrl() {
    return this._apiUrl + environment.DocumentApproveTaskAssignedToMeExpiredServiceOData;
  }

  getDocumentApproveTaskCountServiceODataUrl(id: string) {
    return this._http.get(this._apiUrl + this._serviceODataUrl + `?transmittalId=${id}&$count=true`).pipe(
      map((res) => {
        return res['@odata.count'];
      })
    );
  }

  public makeODataRequest(filter: string = '') {
    return this._http.get<{ value: IODataApproveTask[] }>(this.getDocumentApproveTaskServiceODataUrl() + `?${filter}`);
  }

  getODataFilteredCount(filter = '') {
    return this._http.get<number>(this._apiUrl + this._serviceODataUrl + `$count${filter}`);
  }

  approve(body: IApproveReject) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Approve', body, this._httpOptions);
  }

  approveFinal(body: IApproveReject) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'ApproveFinal', body, this._httpOptions);
  }

  delegate(body: IDelegate) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Delegate', body, this._httpOptions);
  }

  reject(body: IApproveReject) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Reject', body, this._httpOptions);
  }

  takeToWork(id: string) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'TakeToWork', { Id: id }, this._httpOptions);
  }

  docsObservablesMap: { [docId: string]: Observable<number> } = {};
  getDocTasksCount(docId) {
    if (this.docsObservablesMap[docId]) {
      return this.docsObservablesMap[docId];
    } else {
      this.docsObservablesMap[docId] = this.getODataFilteredCount(`?filter=(DocumentId eq ${docId})`).pipe(share());
      return this.docsObservablesMap[docId];
    }
  }

  docGrpsObservablesMap: { [docId: string]: Observable<number> } = {};
  getDocumentTasksCount(docId) {
    if (this.docGrpsObservablesMap[docId]) {
      return this.docGrpsObservablesMap[docId];
    } else {
      this.docGrpsObservablesMap[docId] = this.getODataFilteredCount(`?filter=(DocumentId eq ${docId})`).pipe(share());
      return this.docGrpsObservablesMap[docId];
    }
  }

  getFilterTasksCounts(projId: string) {
    const body = {
      ProjectId: projId,
    };

    return this._http.post<{
      AllTasks; // ВСЕ
      ResponsibleToMeTasks; // НАЗНАЧЕННЫЕ МНЕ
      ResponsibleToMeTasksNew; // Новые
      ResponsibleToMeTasksInWork; // В работе
      ResponsibleToMeTasksExpired; // Просроченные
      ResponsibleToMeTasksCompleted; // Завершенные
      MyTasks; // СОЗДАННЫЕ МНОЙ
    }>(this._apiUrl + environment.DocsApproveTaskService + 'DocumentApproveTaskCounter', body);
  }
}
