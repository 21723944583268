import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { PermissionService } from 'src/app/services/permission.service';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { BackendError, handleError } from '../../../../src/app/services/services-utils';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private permissionsService: PermissionService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    return forkJoin([
      this.permissionsService.getUserPermissionQualityControl(),
      this.permissionsService.getAllPermissionCore(),
      this.permissionsService.getUserPermissionApprove(),
      this.permissionsService.getUserPermissionCMP(),
      this.permissionsService.getUserPermissionForTaskControl(),
      this.permissionsService.getUserPermissionRemarks(),
    ]).pipe(
      catchError((err) => {
        handleError(new BackendError(err));
        return of(false);
      }),
      map((res) => {
        if (
          res[0].IsAdmin ||
          res[1].HasAnyPermission ||
          res[2].HasAnyPermission ||
          res[3].HasAnyPermission ||
          res[4].HasAnyPermission ||
          res[5].HasAnyPermission
        ) {
          return true;
        } else {
          return this.router.parseUrl('/errors/403');
        }
      })
    );
  }
}
