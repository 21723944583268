import { Injectable } from '@angular/core';
import { ITileStructureOData } from '@pp/interfaces';
import { of, tap } from 'rxjs';
import { ProjectStructuresService } from 'src/app/services/project-structures.service';

@Injectable()
export class MainPageService {
  private originTileStructures: ITileStructureOData[] = null;
  private originTableStructures: ITileStructureOData[] = null;

  constructor(private projectStructuresService: ProjectStructuresService) {}

  getStructuresForTiles() {
    if (this.originTileStructures) {
      return of(this.originTileStructures);
    } else {
      return this.projectStructuresService.getStructuesForTile().pipe(
        tap((res) => {
          this.originTileStructures = res;
        })
      );
    }
  }

  getStructuresForTable() {
    if (this.originTableStructures) {
      return of(this.originTableStructures);
    } else {
      return this.projectStructuresService.getStructuesForTable().pipe(
        tap((res) => {
          this.originTableStructures = res;
        })
      );
    }
  }
}
