<ng-container *ngIf="displayedUser">
  <span class="user-info">
    <i *ngIf="displayedUser.Company || displayedUser.Position"
      [id]="'userInfoIcon' + '_' + id"
      class="mi"
      [ngStyle]="{'font-size.px': iconSize, 'color': iconColor}"
    >info_outline</i>
    <span *ngIf="displayedUser?.DisplayName" [ngStyle]="{'font-size.px': textSize}"> {{ displayedUser?.DisplayName }} </span>
  </span>


  <dx-tooltip [target]="'#userInfoIcon' + '_' + id" *ngIf="displayedUser?.Company || displayedUser?.Position"
    [(visible)]="tooltipVisible"
    [position]="'top'"
    [hideEvent]="{name: 'mouseleave', delay: hideTooltipDelay}"
    [showEvent]="{name: 'mouseenter', delay: showTooltipDelay}"
  >
    <div *dxTemplate="let data = data of 'content'" class="align-left">
      <div *ngIf="displayedUser?.Position" i18n>Должность: {{displayedUser?.Position}}</div>
      <div *ngIf="displayedUser?.Company" i18n>Организация: {{displayedUser?.Company}}</div>
    </div>

  </dx-tooltip>
</ng-container>

