import { NgModule } from '@angular/core';
import { RouterModule, TitleStrategy } from '@angular/router';

import { appRoutes } from './app.routes';
import { CustomTitleStrategy } from '../shared/resolvers/custom-title-strategy';

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
  providers: [
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
