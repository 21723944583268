import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, first, map, Observable, of, switchMap } from 'rxjs';
import { SettingsAppService } from '@pp/settings';
import { environment } from '@pp/root-env';
import { RootStoreService } from '@pp/root-store';
import { IRemarksPermissions } from '@pp/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private apiUrl: string;
  private coreApiUrl: string;
  private permissionUrl: string;
  private remarksPermissionUrl: string;
  private ApproveResultsUrl: string;

  public filesExt: BehaviorSubject<any> = new BehaviorSubject(null);

  public isAdmin = false;

  private _cache: PermissionCache = {
    core: null,
    approve: null,
    qualityControl: null,
    taskControl: null,
    smr: null,
    smr2: null,
    archive: null,
    archivePermissionsByProject: null,
    remarks: null,
    approveResults: null,
  };

  constructor(
    private http: HttpClient,
    private settingsService: SettingsAppService,
    private rootStoreService: RootStoreService
  ) {
    this.permissionUrl = environment.permissionUrl;
    this.remarksPermissionUrl = environment.RemarksPermissionsService;
    this.ApproveResultsUrl = environment.approveResultPermissionsUrl;
    this.apiUrl = environment.apiUrl;
  }

  public getUserPermissionQualityControl(): Observable<IPermissionQualityControl> {
    return this.settingsService.$activeModules.pipe(
      first(),
      switchMap((activeModules: any[]) => {
        const module = activeModules.find((module) => +module.Id === 4);
        if (!module || !module.IsActive) {
          return of({
            CanAdminAnswerValueListTemplateReference: false,
            CanAdminChecklistStatusTemplateReference: false,
            CanAdminChecklistTemplates: false,
            CanAdminDepartments: false,
            CanAdminInspectionCategories: false,
            CanAdminInspectionFormTemplateReference: false,
            CanAdminInspectionRejectReasons: false,
            CanAdminInspectionTemplates: false,
            CanAdminPrescriptionTemplates: false,
            CanAdminProjects: false,
            CanAdminReferences: false,
            CanAdminRemarkCategories: false,
            CanAdminRemarkInitiators: false,
            CanAdminRemarkPriorities: false,
            CanAdminRemarkResults: false,
            CanAdminRoles: false,
            CanAdminUserRolesInProjects: false,
            CanAdminUsers: false,
            CanAdminWorkCalendar: false,
            CanAdminWorkVolumeLists: false,
            CanCreateInspection: false,
            CanCreatePrescriptions: false,
            CanCreateRemark: false,
            CanDeclareInspection: false,
            CanEditRemarkStatus: false,
            CanViewPrescriptions: false,
            IsAdmin: false,
          });
        } else if (this._cache.qualityControl) {
          return of(this._cache.qualityControl);
        } else {
          return this.http.get(this.apiUrl + environment.QCPermissionsService + 'GetAll').pipe(
            first(),
            map((res: IPermissionQualityControl) => {
              this._cache.qualityControl = res;

              if (res.IsAdmin) {
                this.isAdmin = res.IsAdmin;
              }
              console.groupCollapsed('Permission for QC');
              console.table(res);
              console.groupEnd();
              return res;
            })
          );
        }
      })
    );
  }

  public getAllPermissionCore(reloadCache = false) {
    if (this._cache.core && !reloadCache) {
      return of(this._cache.core);
    } else {
      return this.http.get(this.apiUrl + this.permissionUrl + 'GetCurrentUserCorePermissions').pipe(
        map((res: IPermissionCore) => {
          this._cache.core = res;
          console.groupCollapsed('Permission for core services');
          console.table(res);
          console.groupEnd();
          return res;
        })
      );
    }
  }

  public getUserPermissionForInspection(id: string) {
    return this.http.get(this.coreApiUrl + environment.inspectionUrl + 'GetUserPermission').pipe(
      map((res: IItemPermission) => {
        console.groupCollapsed('Permission for current inspection');
        console.table(res);
        console.groupEnd();
        return res;
      })
    );
  }

  public getUserPermissionForTaskControl() {
    return this.settingsService.$activeModules.pipe(
      first(),
      switchMap((activeModules: any[]) => {
        const module = activeModules.find((module) => +module.Id === 2);
        if (!module || !module.IsActive) {
          return of({
            CanAdminMeetingCategoriesReference: false,
            CanAdminMeetingSubjectsReference: false,
            CanAdminRoles: false,
            CanAdminUserRolesInProjects: false,
            CanAdminUsers: false,
            CanCreateControlTasks: false,
            CanCreateMeetings: false,
            CanEditControlTaskInitiator: false,
            HasAllPermissions: false,
            HasAnyPermission: false,
            UserId: '',
          });
        } else if (this._cache.taskControl) {
          return of(this._cache.taskControl);
        } else {
          return this.http
            .get(environment.apiUrl + environment.controlTaskPermissionsUrl + 'GetCurrentUserCorePermissions')
            .pipe(
              map((res: IControlTaskPermission) => {
                this._cache.taskControl = res;

                console.groupCollapsed('Permission for Control Task module');
                console.table(res);
                console.groupEnd();
                return res;
              })
            );
        }
      })
    );
  }

  public getUserPermissionCMP() {
    return this.settingsService.$activeModules.pipe(
      first(),
      switchMap((activeModules: any[]) => {
        const module = activeModules.find((module) => +module.Id === 5);
        if (!module || !module.IsActive) {
          return of({
            CanAcceptActualWorkCompletionLogs: false,
            CanAdminActualWorkCompletionLogApprovalRejectReasons: false,
            CanAdminCuratorMatricesReference: false,
            CanAdminRoles: false,
            CanAdminUserRolesInProjects: false,
            CanAdminUsers: false,
            CanAdminWorkCompletionPlanVersionApprovalRejectReasonsReference: false,
            CanCreateActualWorkCompletionLogs: false,
            CanCreateWorkCompletionPlans: false,
            CanCreateWorkVolumeLists: false,
            CanImportWorkVolumeLists: false,
            // CanReplaceDepartmentWorkVolumeLists: false,
            CanUpdateWorkVolumeListsContract: false,
            CanUpdateActualWorkCompletionLogsDepartmentResponsible: false,
            CanUpdateWorkVolumeListsCurator: false,
            HasAllPermissions: false,
            HasAnyPermission: false,
          });
        } else if (this._cache.smr) {
          return of(this._cache.smr);
        } else {
          return this.http
            .get(environment.apiUrl + environment.ManagingSMRPermissionsService + 'GetCurrentUserCorePermissions')
            .pipe(
              map((res: ICMPPermisson) => {
                // testing
                // res = {
                //   CanAdminRoles: false,
                //   CanAdminUserRolesInProjects: false,
                //   CanAdminUsers: false,
                //   CanAdminWorkCompletionPlanVersionApprovalRejectReasonsReference: false,
                //   CanCreateActualWorkCompletionLogs: false,
                //   CanCreateWorkCompletionPlans: false,
                //   CanCreateWorkVolumeLists: false,
                //   CanImportWorkVolumeLists: false,
                //   CanReplaceDepartmentWorkVolumeLists: false,
                //   HasAllPermissions: false,
                //   HasAnyPermission: false,
                // }

                this._cache.smr = res;

                // this.permissionCoreCMP.next(res);
                console.groupCollapsed('Permission for CMP module');
                console.table(res);
                console.groupEnd();
                return res;
              })
            );
        }
      })
    );
  }

  public getUserPermissionCMP2(): Observable<ICMP2Permisson> {
    return this.settingsService.$activeModules.pipe(
      first(),
      switchMap((activeModules: any[]) => {
        const module = activeModules.find((module) => +module.Id === 6);
        if (!module || !module.IsActive) {
          return of({
            HasAllPermissions: false,
            HasAnyPermission: false,
            CanCreateWorkCompletionCertificates: false,
            CanAdminWorkCompletionCertificateApproveTemplates: false,
            HasAnyCreateWorkCompletionCertificates: false,
            HasAnyViewWorkCompletionCertificates: false,
            HasAnyViewWorkCompletionCertificatesDraftStatus: false,
            HasAnyViewWorkCompletionCertificatesDeletedStatus: false,
          });
        } else if (this._cache.smr2) {
          return of(this._cache.smr2);
        } else {
          return this.http
            .get(environment.apiUrl + environment.ExecutionSMRPermissionsService + 'GetCurrentUserCorePermissions')
            .pipe(
              map((res: ICMP2Permisson) => {
                this._cache.smr2 = res;
                console.groupCollapsed('Permission for CMP2 module');
                console.table(res);
                console.groupEnd();
                return res;
              })
            );
        }
      })
    );
  }

  public getUserPermissionRemarks(): Observable<IRemarksPermissions> {
    if (this._cache.remarks) {
      return of(this._cache.remarks);
    } else {
      return this.http.get(this.apiUrl + this.remarksPermissionUrl + 'GetCurrentUserCorePermissions').pipe(
        map((res: IRemarksPermissions) => {
          this._cache.remarks = res;
          console.groupCollapsed('Permission for remarks services');
          console.table(res);
          console.groupEnd();
          return res;
        })
      );
    }
  }

  public getUserPermissionApproveResults(): Observable<IApproveResultsPermission> {
    if (this._cache.approveResults) {
      return of(this._cache.approveResults);
    } else {
      return this.http.get(this.apiUrl + this.ApproveResultsUrl + 'GetCurrentUserCorePermissions').pipe(
        map((res: any) => {
          this._cache.approveResults = res;
          console.groupCollapsed('Permission for approveResults services');
          console.table(res);
          console.groupEnd();
          return res;
        })
      );
    }
  }

  getCurrentUserCorePermissions() {
    //TODO Реализовать метод получение разрешений с сервера
    return this.http.get(environment.apiUrl + environment.permissionUrl + 'GetCurrentUserCorePermissions');
  }

  public getFilesExt() {
    return this.http.get('../quality-control/assets/filesExt.json').pipe(
      map((res: any) => {
        this.filesExt.next(res);
        console.log('filesExt ', res);
        return res;
      })
    );
  }

  public getUserPermissionApprove() {
    return this.settingsService.$activeModules.pipe(
      first(),
      switchMap((activeModules: any[]) => {
        const module = activeModules.find((module) => +module.Id === 1);

        if (!module || !module.IsActive) {
          return of({
            CanAdminApproveRemarkStatusesReference: false,
            CanAdminApproveRemarkTypesReference: false,
            CanAdminApproveStartRulesReference: false,
            CanAdminNotificationsReference: false,
            CanAdminRoles: false,
            CanAdminUserRolesInProjects: false,
            CanAdminUsers: false,
            CanStartApproves: false,
            HasAllPermissions: false,
            HasAnyPermission: false,
          });
        } else if (this._cache.approve) {
          return of(this._cache.approve);
        } else {
          return this.http.get(this.apiUrl + this.permissionUrl + 'GetCurrentUserCorePermissions').pipe(
            map((res: IApprovePermisson) => {
              this._cache.approve = res;
              return res;
            })
          );
        }
      })
    );
  }

  public getCorePermissionsByProject(projectId: string) {
    this.rootStoreService.setContentLoaderState$(true);
    return this.http.get<{ CanAdminStructuralElementReference: boolean }>(
      this.apiUrl + environment.permissionUrl + 'GetCurrentUserPermissionsByProject?id=' + projectId
    );
    // .pipe(
    //   map((res: IArchivePermissionForProject) => {
    //     this.rootStoreService.setContentLoaderState$(false);
    //     this.rootStoreService.setUserPermissionArchiveForProject(res);

    //     console.groupCollapsed('Permission for core services');
    //     console.table(res);
    //     console.groupEnd();
    //     return res;
    //   })
    // );
  }

  public getUserPermissionArchive() {
    if (this._cache.archive) {
      return of(this._cache.archive);
    } else {
      this.rootStoreService.setContentLoaderState$(true);
      return this.http.get(this.apiUrl + environment.DocsPermissionsService + 'GetCurrentUserCorePermissions').pipe(
        map((res: IArchivePermission) => {
          this._cache.archive = res;
          this.rootStoreService.setContentLoaderState$(false);
          this.rootStoreService.userPermissionArchive(res);

          console.groupCollapsed('Permission for core services');
          console.table(res);
          console.groupEnd();
          return res;
        })
      );
    }
  }

  public getCurrentUserPermissionsByProject(projectId: string) {
    this.rootStoreService.setContentLoaderState$(true);
    return this.http
      .get<IArchivePermissionForProject>(
        this.apiUrl + environment.DocsPermissionsService + 'GetCurrentUserPermissionsByProject?id=' + projectId
      )
      .pipe(
        map((res: IArchivePermissionForProject) => {
          this.rootStoreService.setContentLoaderState$(false);
          this.rootStoreService.setUserPermissionArchiveForProject(res);

          console.groupCollapsed('Permission for core services');
          console.table(res);
          console.groupEnd();
          return res;
        })
      );
  }
}

export interface IPermissionQualityControl {
  CanAdminAnswerValueListTemplateReference?: boolean;
  CanAdminChecklistStatusTemplateReference?: boolean;
  CanAdminChecklistTemplates?: boolean;
  CanAdminDepartments?: boolean;
  CanAdminInspectionCategories?: boolean;
  CanAdminInspectionFormTemplateReference?: boolean;
  CanAdminInspectionRejectReasons?: boolean;
  CanAdminInspectionTemplates?: boolean;
  CanAdminPrescriptionTemplates?: boolean;
  CanAdminProjects?: boolean;
  CanAdminReferences?: boolean;
  CanAdminRemarkCategories?: boolean;
  CanAdminRemarkInitiators?: boolean;
  CanAdminRemarkPriorities?: boolean;
  CanAdminRemarkResults?: boolean;
  CanAdminRoles?: boolean;
  CanAdminUserRolesInProjects?: boolean;
  CanAdminUsers?: boolean;
  CanAdminWorkCalendar?: boolean;
  CanAdminWorkVolumeLists?: boolean;
  CanCreateInspection?: boolean;
  CanCreatePrescriptions?: boolean;
  CanCreateRemark?: boolean;
  CanDeclareInspection?: boolean;
  CanEditRemarkStatus?: boolean;
  CanViewPrescriptions?: boolean;
  IsAdmin?: boolean;
}

export interface IPermissionCore {
  HasAllPermissions: boolean;
  HasAnyPermission: boolean;
  CanAdminDelegateRightsReference: boolean;
  CanAdminWorkCalendarReference: boolean;
  CanAdminNotificationsReference: boolean;
  CanAdminCategoriesReference: boolean;
  CanAdminContractTypesReference: boolean;
  CanAdminContentTypesReference: boolean;
  CanAdminObjectStructuresReference: boolean;
  CanAdminProjectsReference: boolean;
  CanAdminWorkTypesReference: boolean;
  CanAdminDocumentStatusesReference: boolean;
  CanAdminReasonChangesReference: boolean;
  CanAdminDepartmentsReference: boolean; // not used in admin
  CanAdminMeasureUnitsReference: boolean;
  CanAdminContractsReference: boolean;
  CanAdminStampTypesReference: boolean;
  CanAdminStampReference: boolean;
  CanAdminRevisionsReference: boolean;
  CanAdminReleaseTargetsReference: boolean;
  CanAdminDisciplinesReference: boolean;
  CanAdminCoverLetterTemplatesReference: boolean;
  CanCreateProjects: boolean;
  CanCopyProjects: boolean;
  CanViewReports: boolean;
}

export interface IItemPermission {
  AddComment: boolean;
  Conduct: boolean;
  CreateInspectionForm: boolean;
  CreatingPrescription: false;
  Delete: boolean;
  DownloadInspectionFormForEdit: boolean;
  SignInspectionForm: boolean;
  Update: boolean;
  View: boolean;
}

export interface IControlTaskPermission {
  CanAdminMeetingCategoriesReference: boolean;
  CanAdminMeetingSubjectsReference: boolean;
  CanAdminRoles: boolean;
  CanAdminUserRolesInProjects: boolean;
  CanAdminUsers: boolean;
  CanCreateControlTasks: boolean;
  CanCreateMeetings: boolean;
  CanEditControlTaskInitiator: boolean;
  HasAllPermissions: boolean;
  HasAnyPermission: boolean;
  UserId: string;
}

export interface IApproveResultsPermission {
  HasAllPermissions: boolean;
  HasAnyPermission: boolean;
  CanAdminApproveResultsReference: boolean;
}

export interface ICMPPermisson {
  CanAcceptActualWorkCompletionLogs: boolean;
  CanAdminActualWorkCompletionLogApprovalRejectReasons: boolean;
  CanAdminCuratorMatricesReference: boolean;
  CanAdminRoles: boolean;
  CanAdminUserRolesInProjects: boolean;
  CanAdminUsers: boolean;
  CanAdminWorkCompletionPlanVersionApprovalRejectReasonsReference: boolean;
  CanCreateActualWorkCompletionLogs: boolean;
  CanCreateWorkCompletionPlans: boolean;
  CanCreateWorkVolumeLists: boolean;
  CanImportWorkVolumeLists: boolean;
  // CanReplaceDepartmentWorkVolumeLists: boolean;
  CanUpdateWorkVolumeListsContract: boolean;
  CanUpdateActualWorkCompletionLogsDepartmentResponsible: boolean;
  CanUpdateWorkVolumeListsCurator: boolean;
  HasAllPermissions: boolean;
  HasAnyPermission: boolean;
}

export interface ICMP2Permisson {
  HasAllPermissions: boolean;
  HasAnyPermission: boolean;
  CanCreateWorkCompletionCertificates: boolean;
  CanAdminWorkCompletionCertificateApproveTemplates: boolean;
  HasAnyCreateWorkCompletionCertificates: boolean;
  HasAnyViewWorkCompletionCertificates: boolean;
  HasAnyViewWorkCompletionCertificatesDraftStatus: boolean;
  HasAnyViewWorkCompletionCertificatesDeletedStatus: boolean;
}

export interface IApprovePermisson {
  CanAdminApproveRemarkStatusesReference: boolean;
  CanAdminApproveRemarkTypesReference: boolean;
  CanAdminApproveStartRulesReference: boolean;
  CanAdminNotificationsReference: boolean;
  CanAdminRoles: boolean;
  CanAdminUserRolesInProjects: boolean;
  CanAdminUsers: boolean;
  CanStartApproves: boolean;
  HasAllPermissions: boolean;
  HasAnyPermission: boolean;
}

export interface IArchivePermission {
  HasAllPermissions: boolean;
  HasAnyPermission: boolean;
  CanAdminDocumentAccessMatricesReference: boolean;
  CanAdminSPDocumentRevisionApproveRoutesReference: boolean;
  CanAdminDocumentApproveRoutesReference: boolean;
  CanAdminCommentReviewSheetTemplateReference: boolean;
  CanAdminRemarkResponsibleMatricesReference: boolean;
}

export interface IArchivePermissionForProject {
  CanCreateCatalog: boolean;
  CanUpdateCatalog: boolean;
  CanDeleteCatalog: boolean;
  CanViewCatalog: boolean;
  CanCreateDocument: boolean;
  CanImportDocuments: boolean;
  CanStartDocumentWorksProduction: boolean;
  CanAdminDocumentNumerationRuleReference: boolean;
  CanCreateTransmittal: boolean;
  CanImportACRS: boolean;
  CanMoveDocumentsMultiple: boolean;
  CanDownloadDocumentFiles: boolean;
}

interface PermissionCache {
  smr2: ICMP2Permisson;
  core: IPermissionCore;
  approve: IApprovePermisson;
  qualityControl: IPermissionQualityControl;
  taskControl: IControlTaskPermission;
  smr: ICMPPermisson;
  archive: IArchivePermission;
  archivePermissionsByProject: any;
  remarks: IRemarksPermissions;
  approveResults: any;
}
