<dx-tree-list [dataSource]="catalogDS" [rootValue]="null" #catalogTreeList keyExpr="Id" parentIdExpr="ParentId"
              (onSelectionChanged)="changeSelectedCatalog($event)" [showBorders]="false" [showRowLines]="false" [showColumnHeaders]="false"
              (onRowClick)="rowClick($event)" [selectedRowKeys]="[selectedCatalogId]" [height]="height"
              (onCellPrepared)="addTitleAttr($event)"  [autoExpandAll]="false"  [expandNodesOnFiltering]="true" [width]="width" (onInitialized)="selectRow($event)">

  <dxo-search-panel [visible]="true" ></dxo-search-panel>

  <dxo-state-storing
    [enabled]="true"
    type="custom"
    [customSave]="saveTreeState"
    [customLoad]="loadTreeState"
  ></dxo-state-storing>

  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate class="toolbar-label">
        <div>{{this.header || labels.catalogs}}</div>
      </div>
    </dxi-item>
    <dxi-item name="searchPanel" locateInMenu="auto"/>
    <dxi-item location="after" [visible]="enabledCRUD && canCreateRootCatalog"
      widget="dxButton" locateInMenu="auto" showText="inMenu"
      [options]="{
        icon: 'newfolder',
        text: labels.createFolder,
        type: 'defaul',
        stylingMode: 'text',
        elementAttr: {class: 'accent-color'},
        onClick: startCreateRootFolder
      }"
    >
    </dxi-item>
    <dxi-item location="after" [visible]="showCloseBtn"
      widget="dxButton" locateInMenu="auto" showText="inMenu"
      [options]="{
        icon: 'close',
        text: labels.closePanel,
        type: 'defaul',
        stylingMode: 'text',
        elementAttr: {class: 'accent-color'},
        onClick: closePanel
      }"
    >
    </dxi-item>
  </dxo-toolbar>

  <dxo-selection mode="single"></dxo-selection>
  <!-- <dxo-filter-row [visible]="true"></dxo-filter-row> -->
  <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
  <dxi-column dataField="Name" i18n-caption caption="Название" dataType="string" [cellTemplate]="'nameTemplate'">
    <div class="overflow" *dxTemplate="let item of 'nameTemplate'">
      <i class="dx-icon-folder"></i><span title="{{item.data.Description}}"> {{item.data.Name}}</span>
    </div>
  </dxi-column>
  <dxi-column dataField="Description" [cellTemplate]="'cellTemplate'" [width]="40" [visible]="enabledCRUD"></dxi-column>
  <div *dxTemplate="let item of 'cellTemplate'">
    <dx-drop-down-button class="editButton" #ddb [dropDownOptions]="{ width: 'auto' }" displayExpr="name" keyExpr="id"
                         icon="overflow" stylingMode="text" dropDownContentTemplate="buttonItem"
                         [visible]="item.data.Permissions_CreateChild || item.data.Permissions_Delete || item.data.Permissions_Update">
      <div *dxTemplate="let data of 'buttonItem'">
        <pp-ui-buttons i18n-text text="Импорт списка документов" [options]="{icon: 'import', stylingMode: 'text'}"
        (onClick)="ddb.instance.close(); startImportDocs();" *ngIf="permissionsByProject.CanImportDocuments"></pp-ui-buttons>
        <pp-ui-buttons i18n-text text="Создать подпапку" [options]="{icon: 'newfolder', stylingMode: 'text'}"
                       (onClick)="ddb.instance.close(); startCreateChildCatalog();" *ngIf="item.data.Permissions_CreateChild"></pp-ui-buttons>
        <pp-ui-buttons i18n-text text="Скачать архивом" [options]="{icon: 'download', stylingMode: 'text'}"
                      (onClick)="ddb.instance.close(); startDownloadCatalog(item.data.Id.toString());" *ngIf="item.data.Permissions_Download"></pp-ui-buttons>
        <pp-ui-buttons i18n-text text="Переименовать" [options]="{icon: 'edit', stylingMode: 'text'}"
                       (onClick)="ddb.instance.close(); startRenameCatalog(item);" *ngIf="item.data.Permissions_Update"></pp-ui-buttons>
        <pp-ui-buttons i18n-text text="Удалить" [options]="{icon: 'trash', type: 'danger', stylingMode: 'text'}"
                       (onClick)="ddb.instance.close(); startDeleteCatalog();" *ngIf="item.data.Permissions_Delete"></pp-ui-buttons>
      </div>
    </dx-drop-down-button>
  </div>
</dx-tree-list>


<dx-popup [width]="550" [height]="'auto'" [showTitle]="true"
          i18n-title title="Создание папки" [dragEnabled]="true"
          [hideOnOutsideClick]="false" [(visible)]="showCreateRootCatalogPopup" (onHiding)="closeCreateRootCatalogPopup()">
  <div *dxTemplate="let data of 'content'">
    <dx-form [(formData)]="newRootCatalog" #form [labelLocation]="'top'">
      {{rootProjectId}}
      <dxi-item dataField="Name" [label]="{text: labels.name}" editorType="dxTextBox"
                [isRequired]="true" [editorOptions]="{stylingMode: 'outlined', maxLength: 200}"></dxi-item>
      <dxi-item dataField="Description" [label]="{text: labels.description}" editorType="dxTextArea"
                [editorOptions]="{stylingMode: 'outlined', maxLength: 1000}"></dxi-item>
    </dx-form>
    <div class="forms-buttons-flat m-top-5">
      <pp-ui-buttons i18n-text text="Отмена" [options]="{type:'normal'}" (onClick)="closeCreateRootCatalogPopup()"></pp-ui-buttons>
      <pp-ui-buttons i18n-text text="Создать" [options]="{type:'default'}" (onClick)="createRootCatalog($event, form)"></pp-ui-buttons>
    </div>
  </div>
</dx-popup>

<dx-popup [width]="550" [height]="'auto'" [showTitle]="true"
          i18n-title title="Создание дочерней папки" [dragEnabled]="true"
          [hideOnOutsideClick]="false" [(visible)]="showCreateChildCatalogPopup" (onHiding)="closeCreateChildCatalogPopup()">
  <div *dxTemplate="let data of 'content'">
    <dx-form [(formData)]="newChildCatalog" #form [labelLocation]="'top'">
      {{rootProjectId}}
      <dxi-item dataField="Name" [label]="{text: labels.name}" editorType="dxTextBox"
                [isRequired]="true" [editorOptions]="{stylingMode: 'outlined', maxLength: 200}"></dxi-item>
      <dxi-item dataField="Description" [label]="{text: labels.description}" editorType="dxTextArea"
                [editorOptions]="{stylingMode: 'outlined', maxLength: 1000}"></dxi-item>
    </dx-form>
    <div class="forms-buttons">
      <pp-ui-buttons i18n-text text="Отмена" [options]="{type:'normal'}" (onClick)="closeCreateChildCatalogPopup()"></pp-ui-buttons>
      <pp-ui-buttons i18n-text text="Создать" [options]="{type:'default'}" (onClick)="createChildCatalog($event, form)"></pp-ui-buttons>
    </div>
  </div>
</dx-popup>

<dx-popup [width]="550" [height]="'auto'" [showTitle]="true"
          i18n-title title="Переименование папки" [dragEnabled]="true"
          [hideOnOutsideClick]="false" [(visible)]="showRenameCatalogPopup" (onHiding)="closeRenameCatalogPopup()">
  <div *dxTemplate="let data of 'content'">
    <dx-form [(formData)]="toRenameCatalog" #form [labelLocation]="'top'">
      {{rootProjectId}}
      <dxi-item dataField="Name" [label]="{text: labels.name}" editorType="dxTextBox"
                [isRequired]="true" [editorOptions]="{stylingMode: 'outlined', maxLength: 200}"></dxi-item>
      <dxi-item dataField="Description" [label]="{text: labels.description}" editorType="dxTextArea"
                [editorOptions]="{stylingMode: 'outlined', maxLength: 1000}"></dxi-item>
    </dx-form>
    <div class="forms-buttons">
      <pp-ui-buttons i18n-text text="Отмена" [options]="{type:'normal'}" (onClick)="closeRenameCatalogPopup()"></pp-ui-buttons>
      <pp-ui-buttons i18n-text text="Сохранить" [options]="{type:'default'}" (onClick)="renameCatalog($event, form)"></pp-ui-buttons>
    </div>
  </div>
</dx-popup>

<dx-popup [width]="600" [height]="'auto'" [showTitle]="true"
          i18n-title title="Удаление папки" [dragEnabled]="true"
          [hideOnOutsideClick]="false" [(visible)]="showDeleteCatalogPopup" (onHiding)="closeDeleteCatalogPopup()">
  <div *dxTemplate="let data of 'content'">
    <p i18n>
      Будет удалена папка "{{catalogTreeList?.instance?.getSelectedRowsData().length ? catalogTreeList?.instance?.getSelectedRowsData().pop()['Name'] : ''}}".<br>
      Будут удалены все вложенные папки и документы.<br>
      Это действие невозможно отменить.
    </p>
    <p i18n>
      Удалить папку?
    </p>
    <dx-check-box [(value)]="deleteCheckBoxValue" i18n-text text="Я понимаю, что папка с документами будет удалена без возможности восстановления"></dx-check-box>
    <div class="forms-buttons">
      <pp-ui-buttons i18n-text text="Отмена" [options]="{type:'normal'}" (onClick)="closeDeleteCatalogPopup()"></pp-ui-buttons>
      <pp-ui-buttons i18n-text text="Удалить" [options]="{type:'danger', disabled: !deleteCheckBoxValue}" (onClick)="deleteCatalog($event)"></pp-ui-buttons>
    </div>
  </div>
</dx-popup>

