import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsAppService } from '@pp/settings';

@Injectable({
  providedIn: 'root',
})
export class QualityControlGuard implements CanActivate {
  constructor(
    private router: Router,
    private settingsService: SettingsAppService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // module - 4 - Контроль качества
    const qualityControlModule = this.settingsService.activeModules.find((module) => +module.Id === 4);
    if (qualityControlModule && qualityControlModule.IsActive) {
      return true;
    }

    return this.router.parseUrl('/errors/403');
  }
}
