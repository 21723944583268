<div [attr.data-wrapper]="'pp-btn-wrapper'" class="popoverLayer" [id]="(options.disabled && (options.disabledText || disabledText)) ? uniqID : ''">
  <dx-button [ngClass]="customClasses"
             [stylingMode]="options.stylingMode"
             [text]="isRound ?  '' : (options.text || text)"
             [type]="options.type"
             (onClick)="onClickEvent($event)"
             [disabled]="options.disabled || options.wait"
             [height]="options.height || 30"
             [width]="options.width"
             [hint]="options.hint || hint"
             [focusStateEnabled]="false"
  >
    <div *dxTemplate="let data of 'content'">
      <dx-load-indicator class='button-indicator'
                         [visible]="options.wait || wait">
      </dx-load-indicator>
<!--      <i *ngIf="!options.wait && options.icon" class="dx-icon dx-icon-{{options.disabled ? 'warning' : options.icon}}"></i>-->
      <i *ngIf="!(options.wait || wait) && options.icon" class="{{'dx-icon ' + (isPPIcon ? options.icon : ('dx-icon-' + options.icon))}}"></i>
      <span class='dx-button-text'>{{isRound ?  '' : (options.text || text)}}</span>
    </div>
  </dx-button>
</div>

<dx-popover
  target="#{{uniqID}}"
  position="top"
  [width]="'auto'"
  showEvent="dxhoverstart"
  [hideEvent]="options.popoverMode === 'click' ? null : 'dxhoverend'">
  <div *dxTemplate="let data = model of 'content'">
    {{options.disabledText || disabledText}}
  </div>
</dx-popover>
