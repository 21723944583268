<dx-popup
  [width]="448"
  height="100%"
  class="right-panel"
  [title]="title"
  [showCloseButton]="true"
  [visible]="showPanel"
  [showTitle]="false"
  position="right top"
  [hideOnOutsideClick]="false"
  [animation]="animationOptions">
  <div *dxTemplate="let data of 'content'">
    <div class="right-panel__close" (click)="close()"><i class="dx-icon-close"></i></div>
    <div class="right-panel__apply" (click)="applyFn($event)"><span>{{applyBtnText}}</span></div>
    <dx-scroll-view width="100%" height="100%" [showScrollbar]="scrollbarMode">
      <div class="right-panel__head">
        <i class="{{icon}}"></i><span class="right-panel__title">{{title}}</span>
      </div>
      <ng-content></ng-content>
    </dx-scroll-view>
  </div>
</dx-popup>
