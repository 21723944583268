import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '../app.component';
import { AppLocalizeModule } from './app-localize.module';
import { FooterModule, LoginFormModule, SingleCardModule } from '../components';
import { HttpClientModule } from '@angular/common/http';
import { AppInfoService, AuthService, ScreenService } from '../services';
import { SideNavOuterToolbarModule } from '../components/side-nav-outer-toolbar/side-nav-outer-toolbar.component';
import { SideNavInnerToolbarModule } from '../components/side-nav-inner-toolbar/side-nav-inner-toolbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@pp/auth';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { UsersService } from '../services/users/users.service';
import { PermissionService } from '../services/permission.service';
import { combineLatest } from 'rxjs';
import { AuthStoreQuery } from '@pp/auth-store';
import { ConfirmPopupModule, NotificationsModule } from '@pp/ui-components';
import { NavigationService } from 'projects/pp-administration/src/services/navigation.service';
import { MainPageModule } from '../components/main-page/main-page.module';
import { BimViewerModule } from 'projects/ui-components/src/lib/bim-viewer/bim-viewer.module';
import { ErrorsModule } from 'src/app/components/error-pages/error404/errors.module';
import { DownloadsModule } from 'projects/ui-components/src/lib/downloads/downloads.module';

export function AppInitializerFactory(
  usersService: UsersService,
  permissionService: PermissionService,
  authStoreQuery: AuthStoreQuery
) {
  return () =>
    new Promise<any>((resolve: any) => {
      //TODO Т.к. что бы узнать что пользователь админ (для отображение в бургере админки) нужно пролучить права QC
      // Там есть параметр IsAdmin. Нужно оставить только получение Id профиля
      authStoreQuery.getAccessToken$.subscribe((res) => {
        if (res) {
          combineLatest(usersService.getMyId(), permissionService.getUserPermissionQualityControl()).subscribe(
            () => {
              resolve();
            },
            (err) => console.error(err)
          );
        }
      });
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    AppLocalizeModule,
    AuthModule,
    AkitaNgDevtools.forRoot(),
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    FooterModule,
    LoginFormModule,
    SingleCardModule,
    HttpClientModule,
    NotificationsModule,
    MainPageModule,
    BimViewerModule,
    ErrorsModule,
    DownloadsModule,
    ConfirmPopupModule,
  ],
  providers: [
    AuthService,
    ScreenService,
    AppInfoService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [UsersService, PermissionService, AuthStoreQuery, NavigationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    console.log('%c Frontend v2.45', 'background: #272727; color: #8ABBFC;');
  }
}
