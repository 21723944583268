import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { IModelFile, LoadModelsService } from '../../services/load-models.service';
import { DxPopupModule, DxTreeListComponent, DxTreeListModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { getApproveStatuses, getApproveStatusView } from 'projects/shared/entities/approve';
import { RootStoreQuery } from '@pp/root-store';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  Observable,
  startWith,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { getBigTreeListProperties } from 'src/app/tree-list/tree-list.options';
import dxTreeList, { Node, Properties } from 'devextreme/ui/tree_list';
import { CommonModule } from '@angular/common';
import { SharedApprovePipesModule } from 'src/app/shared/components/shared-approve/shared-approve.pipe';
import { GlobalProjectSelectModule } from '@pp/ui-components';
import CustomStore from 'devextreme/data/custom_store';
import { DxoButtonOptions } from 'devextreme-angular/ui/nested/base/button-options';
import { BackendError } from 'src/app/services/services-utils';
import { HttpParams } from '@angular/common/http';
import { SelectFileService } from 'projects/archive/src/app/comparing-viewer/select-file-popup/select-file.service';

@Component({
  selector: 'app-select-model',
  templateUrl: './select-model.component.html',
  styleUrls: ['./select-model.component.scss'],
  standalone: true,
  imports: [CommonModule, DxPopupModule, DxTreeListModule, SharedApprovePipesModule, GlobalProjectSelectModule],
})
export class SelectModelComponent implements OnDestroy {
  popupVisible: boolean = false;
  @Input() localStorageKey = ''; // по постановке должен быть разный в каждом месте где используется этот попап

  private selectedFiles: string[] = [];

  @Output()
  filesSelected: EventEmitter<ISelectModelEvent> = new EventEmitter<ISelectModelEvent>();

  @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;

  projectId$ = new BehaviorSubject<string>(null);
  private destroy$ = new Subject();

  bimFilesDS$: Observable<DataSource> = this.projectId$.pipe(
    filter((id) => !!id),
    distinctUntilChanged(),
    map((projectId) => {
      console.log('proj sub', projectId);

      return new DataSource({
        store: new CustomStore({
          key: 'Id',
          loadMode: 'raw',
          load: () => {
            return lastValueFrom(
              this.filesService.getAllModelsOData(`?$filter=
              ProjectId eq ${projectId} and IsDeleted ne true`)
            )
              .then((res) => {
                this.data = res;

                // если указаны документы, то перетираем данные из стейта
                if (this.selectedFiles) {
                  const filesData = res.filter((item) => this.selectedFiles.includes(item.Id));

                  this.treeList.onNodesInitialized.pipe(take(1)).subscribe((e: { component: dxTreeList }) => {
                    e.component.beginUpdate();

                    // закрываем все ноды и открываем заселекченые и их парентов
                    e.component.forEachNode((node) => {
                      e.component.collapseRow(node.key);
                    });

                    const docsDataParentsIds = [...new Set(filesData.map((doc) => doc.ParentId))];

                    const expandRecursively = (node: Node) => {
                      if (node.key) e.component.expandRow(node.key);
                      if (node.parent?.key) expandRecursively(node.parent);
                    };

                    docsDataParentsIds.forEach((id) => {
                      expandRecursively(e.component.getNodeByKey(id));
                    });

                    // снимаем выделение со всех айтемов
                    // e.component.deselectAll(); (само снимается в selectRows(..., false))

                    // выбираем файлы
                    e.component.selectRows(
                      filesData.map((doc) => doc.Id),
                      false
                    ); // false - перезатирает данные из стейта, true - совмещает

                    e.component.endUpdate();
                  });
                }

                return res;
              })
              .catch((err) => {
                return new BackendError(err).message;
              });
          },
        }),
      });
    })
  );

  private data: { Id; ParentId }[] = [];

  acceptOptions: Partial<DxoButtonOptions> = {
    icon: 'todo',
    text: $localize`Выбрать`,
    type: 'default',
    stylingMode: 'contained',
    onClick: (e: any) => {
      let result = [...this.treeList.selectedRowKeys];

      while (result.some((id) => id.startsWith('c_') || id.startsWith('d_'))) {
        const parents: string[] = result.filter((id) => id.startsWith('c_') || id.startsWith('d_'));
        const children = parents.reduce((acc, catalogId) => {
          acc.push(...this.data.filter((item) => item.ParentId === catalogId).map((item) => item.Id));
          return acc;
        }, []);

        result = [...result.filter((id) => id.startsWith('f_')), ...children];
      }

      result = result.filter((id) => typeof id === 'string').map((id) => id.replace('f_', ''));

      if (result.length) this.filesSelected.emit({ action: 'selectMultiple', selectedModels: result });
      this.close();
    },
  };
  closeOptions: Partial<DxoButtonOptions> = {
    icon: 'close',
    text: $localize`Отмена`,
    type: 'normal',
    stylingMode: 'contained',
    onClick: (e: any) => {
      this.close();
    },
  };

  statusColumnHeaderFilter = {
    dataSource: [
      {
        value: null,
        title: 'Не проводилось',
        color: '',
      },
      ...getApproveStatuses(),
    ].map((item) => {
      return { text: item.title, value: item.value };
    }),
  };

  constructor(
    private filesService: LoadModelsService,
    private selectFilesService: SelectFileService,
    private rootStoreQuery: RootStoreQuery
  ) {}

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  isDocument(value: string) {
    return value.startsWith('d_');
  }

  getIcon(id: string) {
    if (id.startsWith('c_')) return 'pp-icon_outlined_folder';
    if (id.startsWith('d_')) return 'pp-icon_outlined_doc';
    if (id.startsWith('f_')) return 'pp-icon_outlined_file_present';
    return '';
  }

  public open(selectedFiles?: string[]) {
    this.popupVisible = true;

    if (selectedFiles) {
      this.selectedFiles = selectedFiles.map((id) => 'f_' + id);
    }

    if (selectedFiles.length) {
      const params = new HttpParams().set('$filter', `Id eq 'f_${selectedFiles[0]}'`);
      this.selectFilesService.makeGetFilesForSelectODataRequest(params).subscribe((res) => {
        if (res.length === 1) {
          console.log('find file', res[0].ProjectId);
          this.projectId$.next(res[0].ProjectId);
        } else {
          this.projectId$.next(this.rootStoreQuery.getValue().rootProjectId);
        }
      });
    } else {
      this.projectId$.next(this.rootStoreQuery.getValue().rootProjectId);
    }
  }

  public close() {
    this.popupVisible = false;
  }

  changeProject(projId: string) {
    console.log('project changed', projId);

    this.projectId$.next(projId);
  }

  calcSortStatusCol = (rowData) => {
    getApproveStatusView(rowData.ApproveStatus);
  };

  saveState = (state: Properties) => {
    if (!this.localStorageKey) return;

    if (this.selectedFiles) {
      state.expandedRowKeys = [];
      state.selectedRowKeys = [];
    } else {
      const selectedRowsData = this.data.filter((item) => state.selectedRowKeys.includes(item.Id));
      const selectedRowsParentsKeys = selectedRowsData.map((item) => item.ParentId);
      state.expandedRowKeys = [...new Set([...selectedRowsParentsKeys, ...state.selectedRowKeys])];
    }

    return localStorage.setItem(this.localStorageKey, JSON.stringify(state));
  };

  loadState = () => {
    if (!this.localStorageKey) return null;

    try {
      const settings: Properties = JSON.parse(localStorage.getItem(this.localStorageKey));
      if (this.selectedFiles) {
        settings.expandedRowKeys = [];
        settings.selectedRowKeys = [];
      }
      return settings;
    } catch {
      return null;
    }
  };

  setTreeListOptions = (e: { component: dxTreeList }) => {
    const props = getBigTreeListProperties({
      // columnAutoWidth: true,
      wordWrapEnabled: true,
      autoExpandAll: false,
      filterRow: { visible: false },
      selection: { mode: 'multiple', recursive: true },
      scrolling: { mode: 'virtual' },
      pager: { visible: false },
    });

    e.component.option(props);
  };
}

export interface ISelectModelEvent {
  action: 'select' | 'close' | 'selectMultiple';
  selectedModel?: IModelFile;
  selectedModels?: string[];
}
