import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'infoDash',
})
export class InfoDashPipe implements PipeTransform {
  transform(value: any[] | string | number, arg?, dash?): any {
    arg = arg ? arg : '.';
    dash = dash ? dash : '—';

    if (Array.isArray(value)) {
      if (!value.find((elem) => elem)) {
        return dash;
      } else {
        if (value.length > 0) {
          return value.reduce((pre, cur, i) => (pre ? pre : dash) + arg + (cur ? cur : dash));
        }
        return dash;
      }
    } else {
      return value || value == '0' ? value : dash;
    }
  }
}

@NgModule({
  declarations: [InfoDashPipe],
  exports: [InfoDashPipe],
})
export class InfoDashPipeModule {}
