import * as c3dviewer from '@c3dlabs/c3dviewer-api';

/**
 * Обработчики действий пользователя в UI
 * @property {c3dviewer.C3DModelView} _view окно вьювера для управления сценой
 * @property {NodeJS.Timeout} _cacheCheckTimeout таймер для напоминаний о необходимости проверить прогресс генерции кэша
 */
export class Actions {
  view: c3dviewer.C3DModelView | undefined;

  constructor() {}

  public init(view: c3dviewer.C3DModelView) {
    this.view = view;
  }
}
