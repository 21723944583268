import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DownloadsComponent } from './downloads.component';
import { DownloadsService } from './downloads.service';
import { DownloadsStore } from './store/downloads.store';
import { DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { DownloadsQuery } from './store/downloads.query';

@NgModule({
  imports: [CommonModule, DxPopupModule, DxScrollViewModule],
  providers: [DownloadsService, DownloadsStore, DownloadsQuery],
  declarations: [DownloadsComponent],
  exports: [DownloadsComponent],
})
export class DownloadsModule {}
