import { User } from 'src/app/services';
import { Act } from 'src/app/services/entities';
import { History } from './history';
import { Task } from './task';
import { IDocument } from '@pp/interfaces';

export enum ApproveType {
  Approval = 0, // Утверждение
  Review = 1, // Рассмотрение
}

// 0 - Выполняется 1 - Отклонено 2 - Утверждено 3 - Отменено
export enum ApproveStatus {
  Processing = 0,
  Rejected = 1,
  Accepted = 2,
  Canceled = 3,
}

export interface Approve {
  CreatedBy: User;
  CreatedById: string;
  ModifiedBy: User;
  ModifiedById: string;
  Created: Date | string;
  Modified: Date | string;
  Id: string;
  Code: string;
  Type: ApproveType;
  CoverLetter: string;
  PlanCompletedDate: Date | string;
  CompletedDate: string;
  Admin: User;
  AdminId: string;
  Status: ApproveStatus;
  StagesCount: number;
  CopyToUsers: {
    ApproveId: string;
    User: User;
    UserId: string;
  }[];
  Stages: {
    Id: string;
    ApproveId: string;
    SortOrder: number;
    Name: string;
    Status: number; // 0 - Не запущен, 1 - Выполняется, 2 - Завершен, 3 - Отменен, 4 - Утвержден, 5 - Отклонен
    WorkingDaysDuration: number;
    AutoComplete: boolean;
    PlanCompletedDate: string;
    IsApproveStage: boolean;
    EndProcessIfRejected: boolean;
    Users: {
      StageId: string;
      User: User;
      UserId: string;
    }[];
  }[];
  Tasks: Task[]; // abstract task interface
  RelatedApproves: {
    CreatedBy: User;
    CreatedById: string;
    ModifiedBy: User;
    ModifiedById: string;
    Created: Date | string;
    Modified: Date | string;
    Id: string;
    Code: string;
    Status: number;
    CompletedDate?: Date | string;
  }[];
  Histories: History[];
  Approver: User;
  ApproverId: string;
  ApproveAutoComplete: boolean;
  ApproveWorkingDaysDuration: number;
  ApproveStageName: string;
  ApproveTemplateIdText: string;
  WorkingDaysDuration: number;
  WorkCompletionCertificate?: Act; // тут акт для согласования по акту
  Document?: IDocument; // тут документ для согласования по документу
  //TODO: Поле переименовал в Document в рамках релиза 2.2, вложенный тип DocCard не обнолялся, но стоит обновить

  Result: {
    Id: string;
    Code: string;
    Title: string;
    DisplayParameters: number;
    DisplayedTitle: string;
    Icon: number;
  };
  ResultId: string;
}

export interface ApprovePermissions {
  Update: boolean;
  Cancel: boolean;
  Print: boolean;
}

export function getApproveStatuses() {
  return [
    {
      value: ApproveStatus.Processing,
      title: $localize`Выполняется`,
      color: 'color-base',
    },
    {
      value: ApproveStatus.Rejected,
      title: $localize`Отклонено`,
      color: 'color-danger',
    },
    {
      value: ApproveStatus.Canceled,
      title: $localize`Отменено`,
      color: 'color-danger',
    },
    {
      value: ApproveStatus.Accepted,
      title: $localize`Утверждено`,
      color: 'color-success',
    },
  ];
}

export function getApproveTypes() {
  return [
    {
      value: ApproveType.Approval,
      title: $localize`Утверждение`,
      miIcon: 'done',
    },
    {
      value: ApproveType.Review,
      title: $localize`Рассмотрение`,
      miIcon: 'visibility',
    },
  ];
}

export function getApproveTypeView(type: ApproveType) {
  const res = getApproveTypes().find((stat) => stat.value === type);
  return res ? res.title : 'undefined type';
}

export function getTypeIcon(type: ApproveType) {
  const res = getApproveTypes().find((stat) => stat.value === type);
  return res ? res.miIcon : '';
}

export function getApproveStatusView(status: ApproveStatus) {
  const res = [
    {
      value: null,
      title: $localize`Не проводилось`,
      color: '',
    },
    ...getApproveStatuses(),
  ].find((stat) => stat.value === status);
  return res ? res.title : 'undefined status';
}

export function getApproveStatusColor(status: ApproveStatus) {
  const res = getApproveStatuses().find((stat) => stat.value === status);
  return res ? res.color : '';
}

export function getApproveTypeIcon(type: ApproveType) {
  const res = getApproveTypes().find((stat) => stat.value === type);
  return res ? res.miIcon : '';
}

export const approveResultIcons = [
  {
    value: 0,
    icon: 'pp-icon_outlined_check_circle',
    color: 'color-success',
  },
  {
    value: 1,
    icon: 'pp-icon_outlined_alert_warning_amber',
    color: 'color-warn',
  },
  {
    value: 2,
    icon: 'pp-icon_outlined_cancel',
    color: 'color-danger',
  },
  {
    value: 3,
    icon: 'pp-icon_outlined_feedback',
    color: 'color-lightGray',
  },
];

export function getApproveResultIcon(iconVal: number) {
  const res = approveResultIcons.find((resIcon) => resIcon.value === iconVal);
  return res ? res.icon : '';
}

export function getApproveResultColor(iconVal: number) {
  const res = approveResultIcons.find((resIcon) => resIcon.value === iconVal);
  return res ? res.color : '';
}
