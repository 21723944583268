import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelHelperComponent } from './label-helper.component';
import { DxTooltipModule } from 'devextreme-angular';

@NgModule({
  declarations: [LabelHelperComponent],
  exports: [LabelHelperComponent],
  imports: [CommonModule, DxTooltipModule],
})
export class LabelHelperModule {}
