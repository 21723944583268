import { Injectable } from '@angular/core';
import { AuthStoreStore } from './auth-store.store';
import { IAuthConfig } from '@pp/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  constructor(private authStore: AuthStoreStore) {}

  addToken(token: string) {
    this.authStore.update({ accessToken: token });
    localStorage.setItem('access_token', token);
  }

  addAuthConfig(config: IAuthConfig) {
    this.authStore.update({ authConfig: config });
  }

  deleteToken() {
    // TODO Use remove
    this.addToken(undefined);
    localStorage.removeItem('access_token');
  }
}
