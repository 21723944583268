import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfo } from './user-info.component';
import { DxTooltipModule } from 'devextreme-angular';

@NgModule({
  declarations: [UserInfo],
  exports: [UserInfo],
  imports: [CommonModule, DxTooltipModule],
})
export class UserInfoModule {}
