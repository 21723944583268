import { Injectable, inject } from '@angular/core';
import { Store, StoreConfig, UpdateStateCallback } from '@datorama/akita';
import { IProcess, IProcessStatus } from '../process.interface';
import { Observable, Subject, Subscription } from 'rxjs';

export interface DownloadsState {
  downloadStatuses: Map<number, IProcessStatus>;
  processes: Map<number, IProcess>;
  subscriptions: Map<number, Subscription>;
  unsubscribers: Map<number, Subject<void>>;
  showDownloads: boolean;
}

const createInitialState = () => {
  return {
    downloadStatuses: new Map(),
    processes: new Map(),
    subscriptions: new Map(),
    unsubscribers: new Map(),
    showDownloads: false,
  };
};

@Injectable()
@StoreConfig({ name: 'downloadsStore', resettable: false })
export class DownloadsStore extends Store<DownloadsState> {
  // newDownload(fileName: string) {
  newDownload() {
    const statuses = this.getValue().downloadStatuses;
    const ids = Array.from(statuses.keys());
    let newId = 0;
    if (ids.length) newId = Math.max(...ids) + 1;

    // this.updateStatus(newId, {
    //   status: 'start',
    //   progress: 0,
    //   fileName,
    // } as const);

    return newId;
  }

  updateStatus(id: number, data: IProcessStatus) {
    this.update((state) => {
      return {
        downloadStatuses: new Map(state.downloadStatuses.set(id, data)),
      };
    });
  }

  addProcess(id: number, process: IProcess) {
    this.update((state) => {
      return {
        processes: new Map(state.processes.set(id, process)),
      };
    });
  }

  addSubscription(id: number, sub: Subscription) {
    this.update((state) => {
      return {
        subscriptions: new Map(state.subscriptions.set(id, sub)),
      };
    });
  }

  addUnsubscriber(id: number, unsub: Subject<void>) {
    this.update((state) => {
      return {
        unsubscribers: new Map(state.unsubscribers.set(id, unsub)),
      };
    });
  }

  removeProcessAndStatus(deleteId: number) {
    this.update((state) => {
      const isStatusDeleted = state.downloadStatuses.delete(deleteId);
      const isProcessDeleted = state.processes.delete(deleteId);
      const isSubDeleted = state.subscriptions.delete(deleteId);
      const isUnsubDeleted = state.unsubscribers.delete(deleteId);

      const newState: Partial<DownloadsState> = {};

      if (isStatusDeleted) newState.downloadStatuses = new Map(state.downloadStatuses);
      if (isProcessDeleted) newState.processes = new Map(state.processes);
      if (isSubDeleted) newState.subscriptions = new Map(state.subscriptions);
      if (isUnsubDeleted) newState.unsubscribers = new Map(state.unsubscribers);

      return newState;
    });
  }

  removeProcess(deleteId: number) {
    this.update((state) => {
      const isProcessDeleted = state.processes.delete(deleteId);
      const isSubDeleted = state.subscriptions.delete(deleteId);
      const isUnsubDeleted = state.unsubscribers.delete(deleteId);

      const newState: Partial<DownloadsState> = {};

      if (isProcessDeleted) newState.processes = new Map(state.processes);
      if (isSubDeleted) newState.subscriptions = new Map(state.subscriptions);
      if (isUnsubDeleted) newState.unsubscribers = new Map(state.unsubscribers);

      return newState;
    });
  }

  toggle() {
    this.update({
      showDownloads: !this.getValue().showDownloads,
    });
  }

  constructor() {
    super(createInitialState());
  }
}
