<div class="flex-bloc">
  <h3 class="title before" i18n>Проект</h3>
  <dx-select-box id="projectSB" [dataSource]="projectsDS" [deferRendering]="false" [value]="projectId"
                 valueExpr="Id" displayExpr="CodeName" searchExpr="CodeName" [searchEnabled]="true"
                 stylingMode="outlined"
                 [width]="420" [height]="30" (onValueChanged)="changeRootProject($event)"
                 [dropDownOptions]="{width: '500px'}"
                 dropDownButtonTemplate="dropDownTemplate">

  <div *dxTemplate="let item of 'dropDownTemplate'" [ngClass]="{loadingIndicatorBox: isLoadingItems}">
    <dx-load-indicator *ngIf="isLoadingItems" [height]="20" [width]="20"></dx-load-indicator>
    <div *ngIf="!isLoadingItems" class="dx-dropdowneditor-icon"></div>
  </div>
    
  </dx-select-box>
</div>
