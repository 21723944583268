import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagePreviewComponent, SafeUrlPipe } from './image-preview.component';
import { DxButtonModule, DxLoadIndicatorModule } from 'devextreme-angular';
import { AuthImgPipeModule } from 'src/pipes/auth-img.pipe';

@NgModule({
  declarations: [ImagePreviewComponent, SafeUrlPipe],
  exports: [ImagePreviewComponent],
  imports: [CommonModule, DxLoadIndicatorModule, DxButtonModule, AuthImgPipeModule],
})
export class ImagePreviewModule {}
