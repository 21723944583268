import moment from 'moment';
import { LocalStorageConstants } from 'src/app/shared/constants';

export class DateTime {
  DateTime: moment.Moment;
  isLangRus: boolean;

  constructor(date, withUtc?) {
    moment.locale('ru');
    this.setDate(date, withUtc);
    const userLang = localStorage.getItem(LocalStorageConstants.USER_LANG);
    this.isLangRus = userLang === 'ru' || userLang === 'ru-RU';
  }

  getTimeSinceEpoch() {
    return this.DateTime.isValid() ? this.DateTime.toDate().getTime() : null;
  }

  getDate() {
    if (!this.DateTime.isValid()) {
      return null;
    }
    return this.isLangRus ? this.handleFormat() : this.DateTime.format('MM/DD/YYYY');
  }

  handleFormat() {
    let date = this.DateTime.format('DD.MM.YYYY');
    // let arr = date.split('.');
    // if (Number(arr[0]) <= 12) {
    //   [arr[0], arr[1]] = [arr[1], arr[0]];
    //   date = arr.join('.');
    // }
    return date;
  }

  getTime() {
    return this.DateTime.isValid() ? this.DateTime.format('HH:mm') : null;
  }

  getDateAndTime() {
    return this.DateTime.isValid()
      ? this.isLangRus
        ? this.DateTime.format('DD.MM.YYYY HH:mm')
        : this.DateTime.format('MM/DD/YYYY HH:mm')
      : null;
  }

  getMomentToDate() {
    return this.DateTime.toDate();
  }

  setDate(date, withUtc?, format?) {
    this.DateTime = moment(date, format);
    if (date != null && !this.DateTime.isValid()) {
      console.error('This date: ' + date + ', not valid!');
    }
    if (this.DateTime.isValid() && withUtc) {
      this.DateTime = this.DateTime.add(moment().utcOffset(), 'm');
    }
  }

  getUtcDate() {
    return this.DateTime.utc();
  }

  toString() {
    return this.DateTime.toString();
  }
}
