import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ImageCahceService } from '../app/shared/services/image-cache.service';

@Pipe({
  name: 'secureImg',
})
export class SecureImgPipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private imgCacheService: ImageCahceService,
    private sanitizer: DomSanitizer
  ) {}

  transform(url: string, id: string) {
    return new Observable<SafeUrl>((observer) => {
      if (!url || !id) {
        // This is a tiny blank image
        // observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
        return { unsubscribe() {} };
      }

      if (this.imgCacheService.getCachedData(id)) {
        observer.next(URL.createObjectURL(this.imgCacheService.getCachedData(id)));
        return { unsubscribe() {} };
      }

      this.http.get(url, { responseType: 'blob' }).subscribe(
        (responseBlob) => {
          observer.next(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(responseBlob)));
          this.imgCacheService.cacheImage(id, responseBlob);
        },
        (err) => {
          observer.unsubscribe();
        }
      );

      return { unsubscribe() {} };
    });
  }
}

@NgModule({
  declarations: [SecureImgPipe],
  exports: [SecureImgPipe],
})
export class SecureImgPipeModule {}
