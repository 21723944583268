import { Directive, ElementRef, AfterViewInit, OnDestroy, QueryList, ContentChildren } from '@angular/core';
import { ButtonsComponent } from '@pp/ui-components';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';

@Directive({
  selector: '[libPPcollapsingButton]',
})
export class CollapsingButtonDirective implements AfterViewInit {
  public initialWidth = null;

  constructor(
    public button: ButtonsComponent,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  ngAfterViewInit() {
    this.initialWidth = this.elementRef.nativeElement.clientWidth;
    return;
  }
}

@Directive({
  selector: '[collapsingButtonsContainer]',
})
export class CollapsingButtonsContainerDirective implements AfterViewInit, OnDestroy {
  @ContentChildren(CollapsingButtonDirective) collapsingButtons: QueryList<CollapsingButtonDirective>;

  private resize$: Observable<ResizeObserverEntry[]>;
  private parentResize$: Observable<ResizeObserverEntry[]>;
  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngAfterViewInit() {
    this.resize$ = new Observable((subscriber) => {
      const resizeObserver = new ResizeObserver((entries) => {
        subscriber.next(entries);
      });

      resizeObserver.observe(this.elementRef.nativeElement);
      return () => {
        resizeObserver.unobserve(this.elementRef.nativeElement);
      };
    });

    this.parentResize$ = new Observable((subscriber) => {
      const resizeObserver = new ResizeObserver((entries) => {
        subscriber.next(entries);
      });

      resizeObserver.observe(this.elementRef.nativeElement.parentElement);
      return () => {
        resizeObserver.unobserve(this.elementRef.nativeElement.parentElement);
      };
    });

    combineLatest([
      this.resize$.pipe(
        map((entries) => entries[0].target.clientWidth),
        distinctUntilChanged()
      ),
      this.parentResize$.pipe(
        map((entries) => entries[0].target.clientWidth),
        distinctUntilChanged()
      ),
      this.collapsingButtons.changes.pipe(startWith(this.collapsingButtons)),
    ])
      .pipe(takeUntil(this.destroy$), debounceTime(60))
      .subscribe({
        next: ([clientWidth, parentClientWidth, buttons]) => {
          if (clientWidth > parentClientWidth) {
            this.shrinkLast(buttons);
          } else {
            const shrinkedBtns = buttons.toArray().filter((btn) => btn.button.isRound);

            let unshrinkBtns: CollapsingButtonDirective[] = [];
            let i = 0;
            let btn = shrinkedBtns[i];
            let possibleWidth = clientWidth;

            while (btn) {
              possibleWidth += btn.initialWidth - 28;

              if (possibleWidth <= parentClientWidth) {
                unshrinkBtns.push(btn);
              }
              i++;
              btn = shrinkedBtns[i];
            }

            unshrinkBtns.forEach((btn) => (btn.button.isRound = false));
          }
        },
      });
  }

  shrinkLast(buttons: QueryList<CollapsingButtonDirective>) {
    const notRoundBtns = buttons.toArray().filter((btn) => !btn.button.isRound);
    if (notRoundBtns.length > 0) {
      notRoundBtns[notRoundBtns.length - 1].button.isRound = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
