<header id="header-wrapper">
  <dx-toolbar
    class="header-toolbar"
    [items]="moduleMenu$ | async"
    #navBar
    (onItemClick)="navigate($event)"
  >
    <div *dxTemplate="let data of 'navModuleMenu'">
      <a
        id="burgerMenu"
        class="dx-icon-menu burgerMenu"
        (click)="toggleWithShadingOptions()"
      ></a>
    </div>
    <div *dxTemplate="let data of 'moduleMenuItem'">
      <a
        class="module-menu-item"
        [routerLink]="data.options.url"
        [queryParams]="data.options.queryParams"
        routerLinkActive="active-route"
        [class.active-route2]="isDocumentPageActive(data.options)"
      >
        {{ data.options.text }}
      </a>
    </div>
    <div *dxTemplate="let data of 'menuUserInfo'">
      <app-portal-core-user-info
        [userId]="currentUserId"
      ></app-portal-core-user-info>
    </div>
    <div *dxTemplate="let data of 'menuItem'">
      <dx-button
        class="user-button authorization"
        height="100%"
        stylingMode="text"
      >
        <div *dxTemplate="let data of 'content'">
          <app-portal-core-user-panel
            [menuItems]="userMenuItems"
            menuMode="context"
          ></app-portal-core-user-panel>
        </div>
      </dx-button>
    </div>
    <div *dxTemplate="let data of 'downloadsItem'" style="overflow: hidden;">
      <div class="download-button-wrapper" (click)="toggleDownloads()">
        <i
          class="pp-icon_outlined_file_download downloads-button"
          [ngClass]="{'fall': isActiveDownloads$ | async}"
        ></i>
      </div>
    </div>
  </dx-toolbar>
  <dx-popover
    target="#burgerMenu"
    position="bottom"
    [shading]="true"
    shadingColor="rgba(0, 0, 0, 0.5)"
    [width]="472"
    height="auto"
    [(visible)]="withShadingOptionsVisible"
  >
    <div *dxTemplate="let data of 'content'" class="navModuleMenu">
      <div class="navModuleMenu__modules">

        <div *ngFor="let item of navBase$ | async; let idx = index" [id]="'nav-item-'+idx"
          class="navModuleMenu__modules__item"
          (click)="goTo(item)"
          [ngClass]="{
            'active': currentModule?.id === item.id,
            'disabled': !item.isActive && !item.forAdmin
          }"
        >
          <div *ngIf="item.svg; else navIcon">
            <img [src]="'assets/images/svg/'+item.svg+'.svg'" class="svg-nav-icon">
          </div>

          <ng-template #navIcon>  
            <i [ngClass]="item.customClass ? item.customClass : 'material-icons'">{{ item.icon }}</i>
          </ng-template>
          
          <div class="item-title">{{ item.name }}</div>

          <dx-tooltip *ngIf="!item.isActive && !item.forAdmin && item.disabledText"
            [target]="'#nav-item-'+idx" position="right" showEvent="mouseenter" hideEvent="mouseleave">
            <div *dxTemplate="let content of 'content'" class="align-left">{{item.disabledText}}</div>
          </dx-tooltip>
        </div>

      </div>
    </div>
  </dx-popover>

  <dx-popup
    class="popup"
    [width]="350"
    [height]="150"
    [showTitle]="true"
    [showCloseButton]="true"
    title="Change language"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [(visible)]="popupVisible"
  >
    <div *dxTemplate="let data of 'content'">
      <div class="form">
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">Choose language</div>
            <div class="dx-field-value">
              <dx-radio-group
                [items]="languages"
                [value]="currentLanguage"
                (onValueChanged)="onValueChanged($event)"
              >
              </dx-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dx-popup>
</header>
