<dx-popover
[target]="'#id' + previewPopoverTargetId"
position="top"
[width]="1024"
[height]="768"
class="preview-popover"
[showTitle]="false"
[(visible)]="isShowPreviewPopover">
    <div *dxTemplate="let data of 'content'"
        [style.background-image]="'url(' + previewPopoverUrl + ')'">
    </div>
</dx-popover>
<dx-tab-panel
    #tabPanel
    [elementAttr]="{class: 'pp-tabs-panel'}"
    [(items)]="inspectionCommentMenu"
    [selectedIndex]="0"
    [loop]="false"
    [animationEnabled]="true"
    [swipeEnabled]="false"
>
    <div *dxTemplate="let item of 'title'">
        <span>{{item.title}}</span>
    </div>
    <div class="m-l-top" *dxTemplate="let item of 'allTemplate'">
        <div>
        <dx-list
            height="500"
            [dataSource]="listAll"
            pageLoadMode="scrollBottom"
            itemTemplate="data"
        >
            <div *dxTemplate="let item of 'data'">
            <div *ngIf="item.Type === 0">
                <div class="comment-header">
                    <div class="comment-date">
                    {{item?.Created | date: 'shortDate'}} в {{item?.Created | date: 'shortTime'}}
                    </div>
                    <div *ngIf="item.CanDelete" class="comment-header_link-close" (click)="deleteComment(item.Id)">
                    Удалить
                    </div>
                </div>
                <div class="comment-name" >{{item?.CreatedBy?.DisplayName}}</div>
                <div class="comment-text">{{item?.Comment?.Comment}}</div>
                <div class="previews">
                    <div class="image-preview" *ngFor="let image of item.Comment?.Files?.images">

                        <!-- <div class="image"
                            (click)="openFile(image.Id, image.previewUrl + '&width=1024&height=768')"
                            [id]="'id' + image.Id"
                            (mouseenter)="showPreviewPopover(image.Id, image.previewUrl + '&width=1024&height=768')"
                            (mouseleave)="hidePreviewPopover()"
                            [style.background-image]="'url(' + image.previewUrl + '&width=95&height=60)'">
                        </div> -->
                        <lib-image-preview [file]="image"></lib-image-preview>
                        <!-- <div class="image-info"> -->
                        <div class="image-name" title="{{image.Name}}">
                            {{image.Name}}
                            <div class="image-controls">
                                <dx-button class="btn-close" icon="mi get_app" type="text"
                                            (onClick)="openFile(image.url)"></dx-button>
                                <dx-button *ngIf="item.CanDelete" class="btn-close" icon="mi delete" type="text"
                                            (onClick)="deleteAttachment(image.Id)"></dx-button>
                            </div>
                        </div>

                        <!-- </div> -->
                    </div>

                    <div class="file-box" *ngFor="let file of item.Comment?.Files?.files">
                        <div class="file-info_icon"></div>
                        <div
                        class="file-info_name" title="{{file.Name}}">{{file.Name}}</div>
                        <div class="file-info_download-icon">
                        <dx-button icon="mi get_app" (onClick)="openFile(file.url)"></dx-button>
                        <dx-button *ngIf="item.CanDelete" icon="mi delete"
                                    (onClick)="deleteAttachment(file.Id)"></dx-button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="item.Type === 1">
                <div class="comment-date">{{item?.Created | date: 'shortDate'}}
                в {{item?.Created | date: 'shortTime'}}</div>
                <div class="comment-name inline-block">{{item?.CreatedBy?.DisplayName}}</div>
                <span class="m-l-left inline-block grey-text">Изменил(а) статус</span>
                <div class="history-block" *ngIf="item.History.HistoryType === 0">
                <div class="history-old">{{item.History.HistoryValuesView[0].StatusView}}</div>
                <i class="mi">arrow_forward</i>
                <div class="history-new">{{item.History.HistoryValuesView[1].StatusView}}</div>
                </div>
            </div>
            </div>
        </dx-list>
        </div>
    </div>
    <div class="m-l-top" *dxTemplate="let item of 'filesTemplate'">
        <div>
            <dx-list [selectionMode]="'none'"
                    height="500"
                    [dataSource]="listFiles"
                    pageLoadMode="scrollBottom"
                    itemTemplate="data">
                <div *dxTemplate="let item of 'data'">
                    <div class="comment-date">
                        {{item?.Created | date: 'shortDate'}} в {{item?.Created | date: 'shortTime'}}
                    </div>
                    <div class="comment-name">{{item?.CreatedBy?.DisplayName}}</div>
                    <div class="previews">
                        <div class="image-preview" *ngFor="let image of item.Comment?.Files?.images">
                            <lib-image-preview [file]="image"></lib-image-preview>
                            <div class="image-name" title="{{image.Name}}">
                                {{image.Name}}
                                <div class="image-controls">
                                    <dx-button class="btn-close" icon="mi get_app" type="text"
                                                (onClick)="openFile(image.url)"></dx-button>
                                    <dx-button *ngIf="item.CanDelete" class="btn-close" icon="mi delete" type="text"
                                                (onClick)="deleteAttachment(image.Id)"></dx-button>
                                </div>
                            </div>
                        </div>

                        <div class="file-box" *ngFor="let file of item.Comment.Files.files">
                            <div class="file-info_icon"></div>
                            <div class="file-info_name" title="{{file.Name}}">{{file.Name}}</div>
                            <div class="file-info_download-icon">
                                <dx-button icon="mi get_app" (onClick)="openFile(file.url)"></dx-button>
                                <dx-button *ngIf="item.CanDelete" icon="mi delete"
                                            (onClick)="deleteAttachment(file.Id)"></dx-button>
                            </div>
                        </div>
                    </div>
                </div>
            </dx-list>
        </div>
    </div>
    <div class="m-l-top" *dxTemplate="let item of 'commentTemplate'">
        <dx-list
            height="500"
            [dataSource]="listComment"
            itemTemplate="data"
            pageLoadMode="scrollBottom" selectionMode="none"
        >
        <div *dxTemplate="let item of 'data'">
            <div class="comment-header">
                <div class="comment-date">
                    {{item?.Created | date: 'shortDate'}} в {{item?.Created | date: 'shortTime'}}
                </div>
                <div *ngIf="item.CanDelete" class="comment-header_link-close" (click)="deleteComment(item.Id)">Удалить
                </div>
            </div>
        <div class="comment-name">{{item?.CreatedBy?.DisplayName}}</div>
        <div class="comment-text">{{item?.Comment?.Comment}}</div>
        <div class="previews">
            <div class="image-preview" *ngFor="let image of item.Comment?.Files?.images">
                <lib-image-preview [file]="image"></lib-image-preview>
                <div class="image-name" title="{{image.Name}}">
                    {{image.Name}}
                    <div class="image-controls">
                        <dx-button class="btn-close" icon="mi get_app" type="text"
                                    (onClick)="openFile(image.url)"></dx-button>
                        <dx-button *ngIf="item.CanDelete" class="btn-close" icon="mi delete" type="text"
                                    (onClick)="deleteAttachment(image.Id)"></dx-button>
                    </div>
                </div>
            </div>
            <div class="file-box" *ngFor="let file of item.Comment.Files.files">
                <div class="file-info_icon"></div>
                <div class="file-info_name" title="{{file.Name}}">{{file.Name}}</div>
                <div class="file-info_download-icon">
                    <dx-button icon="mi get_app" (onClick)="openFile(file.url)"></dx-button>
                    <dx-button *ngIf="item.CanDelete" icon="mi delete"
                                (onClick)="deleteAttachment(file.Id)"></dx-button>
                </div>
            </div>
        </div>

        </div>
        </dx-list>
    </div>
    <div class="m-l-top" *dxTemplate="let item of 'historyTemplate'">
        <div>
            <dx-list
                height="500"
                [dataSource]="listHistory"
                pageLoadMode="scrollBottom"
                itemTemplate="data">
                <div *dxTemplate="let item of 'data'">
                    <div class="comment-date">
                        {{item?.Created | date: 'shortDate'}} в {{item?.Created | date: 'shortTime'}}
                    </div>
                    <div class="blue-text2 inline-block">{{item?.CreatedBy?.DisplayName}}</div>
                    <span class="m-l-left inline-block grey-text">Изменил(а) статус</span>
                    <div class="history-block" *ngIf="item.History.HistoryType === 0">
                        <div class="history-old">{{item.History.HistoryValuesView[0].StatusView}}</div>
                        <i class="mi">arrow_forward</i>
                        <div class="history-new">{{item.History.HistoryValuesView[1].StatusView}}</div>
                    </div>
                </div>
            </dx-list>
        </div>
    </div>
</dx-tab-panel>
<pp-ui-notifier></pp-ui-notifier>

