<dx-data-grid class="pp-grid"
  [dataSource]="IFCAttrs"
  keyExpr="Id"
  [columnAutoWidth]="true"
  [wordWrapEnabled]="true"
  [showBorders]="true"
  [columns]="columns"
>
  <dxo-filter-row
    [visible]="true"
  ></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-search-panel
    [visible]="true"
  ></dxo-search-panel>
  <dxo-column-chooser [enabled]="true" [mode]="'select'"></dxo-column-chooser>
  <dxo-export [enabled]="true" [fileName]="'IFC-attrs_' + now"></dxo-export>
  <dxo-state-storing
    [enabled]="true"
    [type]="'localStorage'"
    [storageKey]="'storageIFCAttrs'"
  ></dxo-state-storing>
  <dxo-load-panel [enabled]="true"></dxo-load-panel>
  <dxo-scrolling [mode]="'virtual'"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
</dx-data-grid>
