export function getVtiBinCore(environment: any): string {
  const path = location.pathname.split('/');
  const layouts = path.indexOf('_layouts');
  const pathTo_Vti_bin = path.slice(0, layouts);
  pathTo_Vti_bin.push('_vti_bin', 'ProjectPoint/Core/');
  if (environment.production) {
    return pathTo_Vti_bin.join('/');
  } else {
    return environment.coreApiUrl;
  }
}

export function getApiHref(): string {
  let path = location.pathname.split('/');
  let layouts = path.indexOf('_layouts');
  let pathToApp = path.slice(0, layouts);
  let result = pathToApp.join('/');
  return result;
}

export function getBaseHref(): string {
  const path = location.pathname.split('/');
  const indexASPXfile = path.indexOf('Index.aspx');
  const pathToApp = path.slice(0, indexASPXfile + 1);
  const result = pathToApp.join('/');
  console.log('getBaseHref', result);
  return result;
}

export function getVtiBinHref(): string {
  const path = location.pathname.split('/');
  const layouts = path.indexOf('_layouts');
  const pathTo_Vti_bin = path.slice(0, layouts);
  pathTo_Vti_bin.push('_vti_bin', 'ProjectPoint/ExecutionCMP/');
  const result = pathTo_Vti_bin.join('/');
  return result;
}

export function getVtiBinHrefManagingCmp(): string {
  const path = location.pathname.split('/');
  const layouts = path.indexOf('_layouts');
  const pathTo_Vti_bin = path.slice(0, layouts);
  pathTo_Vti_bin.push('_vti_bin', 'ProjectPoint/ManagingCMP/');
  const result = pathTo_Vti_bin.join('/');
  return result;
}

export function getVtiBinHrefTaskControl(): string {
  const path = location.pathname.split('/');
  const layouts = path.indexOf('_layouts');
  const pathTo_Vti_bin = path.slice(0, layouts);
  pathTo_Vti_bin.push('_vti_bin', 'ProjectPoint/ControlTask/');
  const result = pathTo_Vti_bin.join('/');
  return result;
}

export function getVtiBinCurrentProject(environment: any): string {
  if (environment.production) {
    const path = location.pathname.split('/');
    const layouts = path.indexOf('_layouts');
    const project = path[path.indexOf('ProjectPoint') + 1];
    const pathToVtiBin = path.slice(0, layouts);
    pathToVtiBin.push('_vti_bin', `ProjectPoint/${project}/`);
    const result = pathToVtiBin.join('/');
    return result;
    // return environment.apiUrl;
  } else {
    return environment.apiUrl;
  }
}

export function getVtiBinModule(moduleName: string, environment: any): string {
  if (environment.production) {
    const path = location.pathname.split('/');
    const layouts = path.indexOf('_layouts');
    const pathToVtiBin = path.slice(0, layouts);
    pathToVtiBin.push('_vti_bin', `ProjectPoint/${moduleName}/`);
    const result = pathToVtiBin.join('/');
    return result;
    // return environment.apiUrl;
  } else {
    return environment.vtiBinUrl + `/${moduleName}/`;
  }
}

export function getHrefCurrentProject(environment: any): string {
  if (environment.production) {
    const path = location.pathname.split('/');
    const pathToVtiBin = path.slice(0, path.indexOf('ProjectPoint') + 2);
    const result = pathToVtiBin.join('/');
    return result;
  } else {
    return environment.portalUrl;
  }
}

export function getRootHref(): string {
  const path = location.pathname.split('/');
  const indexASPXfile = path.indexOf('Index.aspx');
  const pathToApp = path.slice(0, indexASPXfile);
  const result = pathToApp.join('/');
  console.log('getBaseHref', result);
  return result;
}

export function getRootHrefCurrentProject(environment: any): string {
  if (environment.production) {
    const path = location.pathname.split('/');
    const pathToVtiBin = path.slice(0, path.indexOf('ProjectPoint') + 1);
    const result = pathToVtiBin.join('/');
    return result;
  } else {
    return environment.portalRootUrl;
  }
}
