import { Router, UrlTree } from '@angular/router';
import { isJsonString } from '../services-utils';
import notify from 'devextreme/ui/notify';

export function getLocalDate(date: Date) {
  return new Date(new Date(date).getTime() - new Date().getTimezoneOffset() * 60 * 1000);
}

export function getUTCDate(date: Date) {
  return new Date(new Date(date).getTime() + new Date().getTimezoneOffset() * 60 * 1000);
}

/**
 * @param date Date or string of date
 * @param returnString Need return string or Date object. Default true
 */
export function makeLocalDate(date: string | Date, returnString: boolean = true): Date | string {
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    return returnString ? getLocalDate(date).toJSON() : getLocalDate(date);
  } else {
    if (date.slice(-1) === 'Z') {
      return returnString ? date : new Date(date);
    } else {
      return returnString ? date + 'Z' : new Date(date + 'Z');
    }
  }
}

/**
 * @param fileUrl Complete URL to file
 * @param token Auth access token
 * @param name Name file
 */
export function downloadFile(fileUrl: string, token: string, name: string = '', replaceExtention = false) {
  let fileName = name;
  let anchor = document.createElement('a');
  document.body.appendChild(anchor);

  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + token);

  fetch(fileUrl, { headers })
    .then((response) => {
      if (fileName && !replaceExtention) return response.blob();
      const rawData = response.headers.get('content-disposition');

      if (rawData) {
        const decodedFileName = decodeURI(rawData)
          .split(';')
          .find((n) => n.includes('filename*='))
          .replace("filename*=UTF-8''", '')
          .replace(/%3A/g, '-')
          .trim();

        if (replaceExtention) {
          const fileExt = decodedFileName.split('.')[1];

          if (fileExt && fileName) {
            fileName += `.${fileExt}`;
          }
        } else {
          fileName = decodedFileName;
        }
      } else if (!fileName) {
        fileName =
          'file ' +
          new Date().getDate() +
          '-' +
          new Date().getMonth() +
          '-' +
          new Date().getFullYear() +
          ' ' +
          new Date().getUTCHours() +
          '-' +
          new Date().getUTCMinutes();
      }
      return response.blob();
    })
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();
      anchor.remove();

      window.URL.revokeObjectURL(objectUrl);
    });
}

export async function downloadFileAwait(fileUrl: string, token: string, name: string = '', replaceExtention = false) {
  let fileName = name;
  let headers = new Headers();
  headers.append('Authorization', 'Bearer ' + token);

  try {
    const res = await fetch(fileUrl, { headers });

    if (!res.ok) {
      throw await res.json();
    }

    const getBlob = async (response) => {
      if (fileName && !replaceExtention) return response.blob();
      const rawData = response.headers.get('content-disposition');

      if (rawData) {
        const decodedFileName = decodeURI(rawData)
          .split(';')
          .find((n) => n.includes('filename*='))
          .replace("filename*=UTF-8''", '')
          .replace(/%3A/g, '-')
          .trim();

        if (replaceExtention) {
          const fileExt = decodedFileName.split('.')[1];

          if (fileExt && fileName) {
            fileName += `.${fileExt}`;
          }
        } else {
          fileName = decodedFileName;
        }
      } else if (!fileName) {
        fileName =
          'file ' +
          new Date().getDate() +
          '-' +
          new Date().getMonth() +
          '-' +
          new Date().getFullYear() +
          ' ' +
          new Date().getUTCHours() +
          '-' +
          new Date().getUTCMinutes();
      }
      return response.blob();
    };

    const resBlob = await getBlob(res);

    const downloadBlob = (blobby) => {
      let anchor = document.createElement('a');
      document.body.appendChild(anchor);
      console.log(blobby);
      let objectUrl = window.URL.createObjectURL(blobby);
      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();
      anchor.remove();
      window.URL.revokeObjectURL(objectUrl);
    };
    downloadBlob(resBlob);
  } catch (err) {
    if (err && err.Message && isJsonString(err.Message)) {
      const errData: { code; message; details } = JSON.parse(err.Message);
      notify({ message: errData.details }, 'error', 10000);
    } else {
      console.error(err);
    }
  }
}

export class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

export function dynamicSort(property) {
  return function (a, b) {
    return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
  };
}

export function openInNewTab(router: Router, newTabUrl: UrlTree): Window {
  const preservedPath = window.location.href.split(router.url)[0];
  const url = router.serializeUrl(newTabUrl);

  return window.open(preservedPath + url, '_blank');
}

export function makeUrl(): string {
  const index = 'Index.aspx';

  let url = '';
  if (window.location.href.includes(index)) {
    url = window.location.href.split(index)[0] + index;
  } else {
    url = window.location.origin;
  }

  return url;
}
// возвращает ISO string дату с обнулённым временем
// например Wed Jun 28 2023 21:00:00 GMT+0300 (Москва, стандартное время) => 2023-06-28T00:00:00.000Z
export function mapToCleanISODate(val: Date | string): string {
  if (!val) return null;
  let mappedDate = new Date(val);

  const makeTwoDigits = (value: number) => {
    let res = value.toString();
    if (value < 10) res = '0' + res;
    return res;
  };

  return `${mappedDate.getFullYear()}-${makeTwoDigits(mappedDate.getMonth() + 1)}-${makeTwoDigits(
    mappedDate.getDate()
  )}T12:00:00.000Z`;
}

export function downloadArchive(url: string, token: string, ids?: string[], fileIds?: string[]) {
  let form = document.createElement('form');
  form.method = 'post';
  form.target = '_blank';
  form.action = url;
  form.innerHTML = '<input type="hidden" name="access_token" value="' + token + '">';
  if (ids) {
    for (const id of ids) {
      form.innerHTML += '<input type="hidden" name="ids" value="' + id + '">';
    }
  }
  if (fileIds) {
    for (const id of fileIds) {
      form.innerHTML += '<input type="hidden" name="fileIds" value="' + id + '">';
    }
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
