import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotifierService, NotifyMessage } from './notifier.service';
import { DxToastComponent } from 'devextreme-angular';

@Component({
  selector: 'pp-ui-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss'],
})
export class NotifierComponent implements OnInit, OnDestroy {
  @ViewChild('toast', { static: false }) toast: DxToastComponent;
  isVisible: boolean = false;
  text: { text: string; bold?: boolean }[] = [];
  type: string = 'info';
  displayTime: number = 5000;
  icon: string = this.getIcon(this.type);

  sub: Subscription;

  constructor(
    private notifierService: NotifierService,
    private detector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.sub = this.notifierService.getObservable().subscribe((options: NotifyMessage) => {
      this.isVisible = false;
      this.detector.detectChanges();
      this.text = options.message;
      this.type = options.type;
      this.icon = this.getIcon(options.type);
      this.displayTime = options.displayTime;
      this.isVisible = true;
      // this.detector.detectChanges();
    });
  }

  getIcon(type: string) {
    switch (type) {
      case 'info':
        return 'info';
      case 'warning':
        return 'warning';
      case 'error':
        return 'warning';
      case 'success':
        return 'check';
      default:
        return '';
    }
  }

  getResultColorClass() {
    return this.type + '-color';
  }

  hide() {
    this.isVisible = false;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
