import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  ITransmittal,
  ITransmittalOData,
  ITransmittalPermissions,
} from 'projects/shared/interfaces/transmittal.interface';
import { downloadFile } from 'projects/ui-components/src/lib/utils/utils';
import { ErrorType, FieldName } from 'projects/shared/entities/doc-import';
import {
  Observable,
  Subject,
  concat,
  filter,
  finalize,
  from,
  lastValueFrom,
  map,
  merge,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { apiPathUrls } from 'src/environments/api.paths';
import { ESearchDocsResults } from 'projects/shared/entities/transmittal';

@Injectable({
  providedIn: 'root',
})
export class TransmittalService {
  private _apiUrl: string;
  private _serviceUrl: string;
  private _serviceODataUrl: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceODataUrl = environment.TransmittalServiceOData;
    this._serviceUrl = environment.TransmittalService;
  }

  getTransmittalsODataUrl() {
    return this._apiUrl + this._serviceODataUrl;
  }

  countTransmittals(filter: string = '') {
    return this.http.get(this.getTransmittalsODataUrl() + `/$count?${filter}`);
  }

  makeODataRequest(filter: string = '') {
    return this.http.get<{ value: ITransmittalOData[] }>(this.getTransmittalsODataUrl() + `?${filter}`);
  }

  GetAllDtosItemPermissions(id: string) {
    return this.http.get(this._apiUrl + this._serviceUrl + 'GetAllDtosItemPermissions?id=' + id);
  }

  // Получение прав на действия с отдельными документами указанного сопроводительного документа.
  // Используется на вкладке с документами на странице сопроводительного документа. Содержит только
  // нужные для данного функционала проверки прав
  getDocumentsPermissions(id: string) {
    return this.http.get<{ DocumentId: string; AddFiles: boolean }[]>(
      this._apiUrl + this._serviceUrl + 'GetDocumentsPermissions',
      { params: { id } }
    );
  }

  get(id: string) {
    return this.http.get<{ Item: ITransmittal; Permissions: ITransmittalPermissions }>(
      environment.apiUrl + environment.TransmittalService + 'Get',
      { params: { id } }
    );
  }

  create(body: ITransmittalCreate) {
    return this.http.post<{ Id: string }>(environment.apiUrl + environment.TransmittalService + 'Create', body);
  }

  update(body: ITransmittalCreate & { Id: string }) {
    return this.http.post(environment.apiUrl + environment.TransmittalService + 'Update', body);
  }

  getContractsForCreateODataUrl(params?: string) {
    let url = environment.apiUrl + environment.TransmittalContractsForCreateServiceOData;
    if (params) url = url + '?' + params;
    return url;
  }
  getContractsForUpdateODataUrl(params?: string) {
    let url = environment.apiUrl + environment.TransmittalContractsForUpdateServiceOData;
    if (params) url = url + '?' + params;
    return url;
  }

  getContractsForCreate(projectId: string, params = {}, stringParams = '') {
    return this.http.get<{ value: IContractForCreateTransmittal[] }>(this.getContractsForCreateODataUrl(stringParams), {
      params: { projectId, ...params },
    });
  }
  getContractsForUpdate(transmittalId: string, params = {}, stringParams = '') {
    return this.http.get<{ value: IContractForCreateTransmittal[] }>(this.getContractsForUpdateODataUrl(stringParams), {
      params: { transmittalId, ...params },
    });
  }

  getImportFileTemplate(transmittalId: string) {
    downloadFile(
      environment.apiUrl + environment.TransmittalService + 'GetImportFileTemplate?id=' + transmittalId,
      localStorage.getItem('access_token')
    );
  }

  import = (body: ImportDocuments): Observable<void> => {
    return this.http.post<void>(environment.apiUrl + environment.TransmittalService + 'Import', body);
  };

  checkImport = (body: ImportDocuments): Observable<IImportDocumentsError[]> => {
    return this.http
      .post<{
        Errors: IImportDocumentsError[];
      }>(environment.apiUrl + environment.TransmittalService + 'CheckImport', body)
      .pipe(
        map((res) => {
          return res ? res.Errors : [];
        })
      );
  };

  deleteTransmittal(id: string) {
    return this.http.delete(environment.apiUrl + environment.TransmittalService + 'Delete', {
      params: { id },
    });
  }

  exclude(body: { TransmittalId: string; DocumentIds: string[] }) {
    return this.http.post(environment.apiUrl + environment.TransmittalService + 'Exclude', body);
  }

  register(body: ITransmittalRegister) {
    return this.http.post(environment.apiUrl + environment.TransmittalService + 'Register', body);
  }

  checkDocuments(transId: string) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'CheckDocuments?id=' + transId, this.httpOptions);
  }

  startApprovalsByRouteForTransmittal(transId: string) {
    return this.http.post(
      this._apiUrl + this._serviceUrl + 'StartApprovalsByRouteForTransmittal?id=' + transId,
      this.httpOptions
    );
  }

  StartApprovalsByRouteForDocuments(body: { TransmittalId: string; DocumentIds: string[] }) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'StartApprovalsByRouteForDocuments', body);
  }

  sendEmail(data: {
    TransmittalId: string;
    RecipientId: string;
    CopyToIds: string[];
    // FileContent: string;
    // FileName: string;
  }) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'Send', data, this.httpOptions);
  }

  searchDocuments(data: {
    TransmittalId: string;
    Files: {
      Id: string;
      Name: string;
    }[];
  }) {
    return this.http
      .post<{
        TransmittalId: string;
        Results: {
          Id: string;
          Name: string;
          Result: ESearchDocsResults;
          Documents: {
            Id: string;
            NumberAndRevision: string;
          }[];
        }[];
      }>(this._apiUrl + this._serviceUrl + 'SearchDocuments', data, this.httpOptions)
      .pipe(
        map((res) => {
          return res.Results;
        })
      );
  }

  private startFilesStream(transmittalId: string) {
    return this.http.post<{ UploadId: string }>(
      environment.apiUrl + environment.TransmittalService + 'StartAddFiles',
      null,
      { params: { id: transmittalId } }
    );
  }

  private addFilesStream(
    uploadId: string,
    signalRConnectionId: string,
    filesData: { id: string; documentId: string; file: File }[]
  ) {
    const params = {
      uploadId,
      signalRConnectionId,
      filesLength: filesData.reduce((acc, fileData) => acc + fileData.file.size, 0),
    };

    const formData = new FormData();
    filesData.forEach(({ id, documentId, file }) => {
      // Шаблон прикрепления файлов
      // file__d_b14c6b7b-ea78-4a6d-46f3-08d735f85fc9__f_2__s_9090
      // (file__d_{id документа}__f_{номер файла}__s_{размер})
      formData.set(`file__d_${documentId}__f_${id}__s_${file.size}`, file, file.name);
    });

    return this.http.post<{
      Items: {
        ClientId: string;
        DocumentId: string;
        Name: string;
        TransmittalUploadFileId: string;
      }[];
    }>(environment.apiUrl + environment.TransmittalService + 'AddFilesStream', formData, { params });
  }

  private cancelAddFiles(uploadId: string) {
    return this.http.post<{ UploadId: string }>(
      environment.apiUrl + environment.TransmittalService + 'CancelAddFiles',
      null,
      { params: { id: uploadId } }
    );
  }

  private completeAddFiles(uploadId: string) {
    return this.http.post<{ UploadId: string }>(
      environment.apiUrl + environment.TransmittalService + 'CompleteAddFiles',
      null,
      { params: { id: uploadId } }
    );
  }

  private buildTransmittalsHubConnection() {
    return new HubConnectionBuilder()
      .withUrl(environment.apiUrl + apiPathUrls.Archive + 'SignalR/Hubs/Transmittals', { withCredentials: false })
      .build();
  }

  createUploadProcess(
    transmittalId: string,
    filesData: { id: string; documentId: string; file: File }[],
    maxFileSize: number
  ): Observable<{ fileId: string; filePercents: number }> {
    const connectionsCount = 2; // сколько коннешнов одновременно обрабатывать

    // for testing purposes
    function getTap(name) {
      return {
        unsubscribe: () => {
          console.log(`unsubscribe from ${name}`);
        },
        subscribe: () => {
          console.log(`subscribe to ${name}`);
        },
        next: (value) => {
          console.log(`${name} emit `, value);
        },
        error: (err) => {
          console.log(`${name} error`, err);
        },
        complete: () => {
          console.log(`${name} completed!`);
        },
      };
    }

    const files = filesData
      .filter((fileData) => fileData.file.size < maxFileSize)
      .sort((fileData1, fileData2) => fileData1.file.size - fileData2.file.size);

    // на каждый массив будет создан stream hub и addFilesStream запрос, один массив не превышает maxFileSize суммарно
    const filesFragments: { id: string; documentId: string; file: File }[][] = [];

    while (files.length) {
      // начинаем с самых больших файлов справа
      let curFileData = files.pop();
      const curFileSize = curFileData.file.size;

      let pushed = false;
      let curFragmentIdx = 0;
      do {
        // ищем куда запушить, пока не запушим
        if (!filesFragments[curFragmentIdx]) filesFragments.push([]);

        const curFragment = filesFragments[curFragmentIdx];
        const curFragmentSize = curFragment.reduce((partialSum, fileData) => partialSum + fileData.file.size, 0);

        // если не влезает в текущий контейнер, то увеличиваем индекс - запушится в следующий
        if (curFragmentSize + curFileSize > maxFileSize) {
          curFragmentIdx++;
        } else {
          curFragment.push(curFileData);
          pushed = true;
        }
      } while (!pushed);
    }

    console.log(`должно быть ${filesFragments.length} AddFilesStream`);
    console.log(filesFragments);

    return this.startFilesStream(transmittalId)
      .pipe(
        // обозначили начало транзакции, получили UploadId
        switchMap(({ UploadId }) => {
          // uploadFilesProcesses массив, состоящий из последовательных процессов
          // процесс = hubConnection, затем addFilesObservable + percentsObservable
          const uploadFilesProcesses = filesFragments.map((requestData) => {
            const hubConnectionObservable = of(this.buildTransmittalsHubConnection()).pipe(
              switchMap((hubConnection) => {
                return from(hubConnection.start().then(() => hubConnection));
              })
            );

            return hubConnectionObservable
              .pipe(tap(getTap('start hub obs')))
              .pipe(
                switchMap((hubConnection) => {
                  const complete$ = new Subject<void>();

                  const percentsObservable = new Observable<{ fileId: string; filePercents: number }>((subscriber) => {
                    hubConnection.on(
                      'UpdateFileUploadProgressPercent',
                      (allFIlesPercent: number, fileId: string, filePercents: number) => {
                        subscriber.next({ fileId, filePercents });
                      }
                    );

                    hubConnection.on('CompleteFileUpload', (isSuccess, errMsg) => {
                      if (isSuccess) {
                      } else {
                        // emit err for file id
                      }
                    });
                  });

                  const addFilesObservable = this.addFilesStream(
                    UploadId,
                    hubConnection.connectionId,
                    requestData
                  ).pipe(
                    finalize(() => {
                      complete$.next();
                      complete$.complete();
                      hubConnection.stop();
                    })
                  );

                  return merge(
                    addFilesObservable.pipe(tap(getTap('addFiles obs'))),
                    percentsObservable.pipe(takeUntil(complete$)).pipe(tap(getTap('percents obs')))
                  );
                })
              )
              .pipe(tap(getTap('process obs')));
          });

          // распределяем, по сколько процессов одновременно обрабатывать
          const processesInParts: Observable<{ fileId: string; filePercents: number }>[] = [];

          for (let i = 0; i < uploadFilesProcesses.length; i += connectionsCount) {
            processesInParts.push(merge(...uploadFilesProcesses.slice(i, i + connectionsCount)));
          }

          // обрабатываем одновременно connectionsCount процессов последовательно
          return concat(...processesInParts, this.completeAddFiles(UploadId))
            .pipe(
              tap({
                unsubscribe: () => {
                  lastValueFrom(this.cancelAddFiles(UploadId));
                },
                error: (err) => {
                  lastValueFrom(this.cancelAddFiles(UploadId));
                },
              })
            )
            .pipe(tap(getTap('concated processes obs')));
        }),
        filter((val) => !!val?.fileId)
      )
      .pipe(tap(getTap('final obs')));
  }

  downloadTransmittalListFile(transmittalListFileId: string) {
    downloadFile(
      environment.apiUrl + environment.TransmittalService + 'GetTransmittalListFile?id=' + transmittalListFileId,
      localStorage.getItem('access_token')
    );
  }

  deleteTransmittalListFile(transmittalListFileId: string) {
    return this.http.delete(environment.apiUrl + environment.TransmittalService + 'DeleteTransmittalListFile', {
      params: { id: transmittalListFileId },
    });
  }

  searchTransmittalBlank(body: {
    ProjectId: string;
    TransmittalCategoryId: string;
  }): Observable<ISearchTransmittalBlank> {
    return this.http.post<ISearchTransmittalBlank>(
      environment.apiUrl + environment.transmittalBlanksUrl + 'Search',
      body
    );
  }

  generateTransmittalListFile(body: { TransmittalId: string }) {
    return this.http.post(environment.apiUrl + environment.TransmittalService + 'GenerateTransmittalListFile', body);
  }

  cancelTransmittal(transmittalId: string) {
    return this.http.post(environment.apiUrl + environment.TransmittalService + 'Cancel', null, {
      params: { id: transmittalId },
    });
  }

  restoreTransmittal(transmittalId: string) {
    return this.http.post(environment.apiUrl + environment.TransmittalService + 'Restore', null, {
      params: { id: transmittalId },
    });
  }

  rejectTransmittal(body: IRejectTransmittal) {
    return this.http.post(environment.apiUrl + environment.TransmittalService + 'Reject', body);
  }

  addDocuments(body: IAddDocuments) {
    return this.http.post(environment.apiUrl + environment.TransmittalService + 'Include', body);
  }
}

export interface IContractForCreateTransmittal {
  Id: string;
  Combined: string;
  Number: string;
  ConclusionDate: string;
  CustomerShortName: string;
  CustomerFullName: string;
  CustomerIsActive: boolean;
  CustomerId: string;
  ContractorShortName: string;
  ContractorFullName: string;
  ContractorIsActive: boolean;
  ContractorId: string;
  IsActive: boolean;
}

export interface ITransmittalCreate {
  Number: string;
  AdditionalNumber: string;
  Description: string;
  ProjectId: string;
  SenderId: string;
  RecipientId: string;
  ContractId: string;
  DeveloperId: string;
  CustomerId: string;
  TransmittalCategoryId: string;
  ReleaseTargetId: string;
}

export interface ImportDocuments {
  ProjectId: string;
  CatalogId: string;
  HeaderRowIndex: number;
  DataBeginRowIndex: number;
  DataEndRowIndex: number;
  Data: string;
  UpdateByEmptyValue: boolean;
  TransmittalId?: string;
}

export interface ITransmittalRegister {
  Id?: string;
  Number: string;
  PlanAnswerDate: Date | string;
  Description: string;
  StartApprovalsByRoute: boolean;
}

export interface IImportDocumentsError {
  RowNumber: number;
  Field: FieldName;
  Type: ErrorType;
  Text: string;
}

export interface ISearchTransmittalBlank {
  TransmittalListTemplateId: string | null;
  TransmittalListTemplate: {
    Id: string;
    Title: string;
  } | null;
}

export interface IRejectTransmittal {
  Id: string;
  RejectReason: string;
}

export interface IAddDocuments {
  DocumentIds: string[];
  TransmittalId: string;
}
