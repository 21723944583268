import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { DateTime } from '../entities/DateTime';

@Directive({
  selector: '[titleButtonsTemplate]',
})
export class AdditionalButtonsDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[dropdownButtonContentTemplate]',
})
export class DropdownButtonContentlDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[titleInfoTemplate]',
})
export class TitleInfoDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[titleTemplate]',
})
export class TitleTemplateDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'pp-ui-actions-panel',
  templateUrl: './actions-panel.component.html',
  styleUrls: ['./actions-panel.component.scss'],
})
export class ActionsPanel {
  @Input() panelTitle: string = '';
  @Input() createdDate: DateTime | Date | string = null;
  @Input() createdBy: string = '';
  @Input() lastModifiedDate: DateTime | Date | string = null;
  @Input() lastModifiedBy: string = '';
  @Input() showFooter: boolean = true;

  @ContentChild(TitleTemplateDirective) titleTemplate!: TitleTemplateDirective;
  @ContentChild(TitleInfoDirective) titleInfo!: TitleInfoDirective;
  @ContentChild(AdditionalButtonsDirective) additionalTitleButtons!: AdditionalButtonsDirective;
  @ContentChild(DropdownButtonContentlDirective) dropdownButtonContent!: DropdownButtonContentlDirective;

  constructor() {}
}
