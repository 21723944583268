import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploaderComponent } from './uploader.component';
import { DxFileUploaderModule, DxTextAreaModule } from 'devextreme-angular';
import { ButtonsModule } from '../buttons/buttons.module';

@NgModule({
  declarations: [UploaderComponent],
  exports: [UploaderComponent],
  imports: [CommonModule, DxTextAreaModule, DxFileUploaderModule, ButtonsModule],
})
export class UploaderModule {}
