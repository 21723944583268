import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICatalog, ICatalogCreateChild, ICatalogCreateRoot, ICatalogOData, ICatalogUpdate } from '@pp/interfaces';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  private _apiUrl: string;
  private _serviceUrl: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.catalogUrl;
  }

  getODataUrl() {
    return environment.apiUrl + environment.CatalogServiceOData;
  }

  getAllByProject(projectId: string): Observable<ICatalogOData[]> {
    if (!projectId) return of([]);

    return this.http.get<{ value: ICatalogOData[] }>(this.getODataUrl() + `?$filter=ProjectId eq ${projectId}`).pipe(
      map((res) => {
        return res.value;
      })
    );
  }

  createRootCatalog(catalog: ICatalogCreateRoot) {
    return this.http.post<{ Id: string }>(this._apiUrl + this._serviceUrl + 'CreateRoot', catalog, this.httpOptions);
  }

  createChildCatalog(catalog: ICatalogCreateChild) {
    return this.http.post<{ Id: string }>(this._apiUrl + this._serviceUrl + 'CreateChild', catalog, this.httpOptions);
  }

  deleteCatalog(id: string) {
    return this.http.delete(this._apiUrl + this._serviceUrl + 'Delete?id=' + id);
  }

  getCatalog(id: string) {
    return this.http.get<ICatalog>(this._apiUrl + this._serviceUrl + 'Get?id=' + id);
  }

  updateCatalog(catalog: ICatalogUpdate) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'Update', catalog, this.httpOptions);
  }
}
