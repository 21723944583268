<as-split direction="vertical" [restrictMove]="true">
  <as-split-area [size]="80">
    <div style="font-size: 15px; color: #444444; font-weight: bold; padding: 5px">Атрибуты элемента</div>
    <div class="list">
      <div class="no-data" *ngIf="!ifcAttr && !loader">Нет данных</div>
      <div class="no-data" *ngIf="loader">Загрузка данных...</div>
      <table style="width: 100%" class="list_item">
        <tr *ngIf="ifcAttr">
          <td colspan="2" class="title">
            <strong>{{ifcAttr?.Name}}</strong>
          </td>
        </tr>
        <tr *ngFor="let ifcAttr of ifcAttr?.IfcAttributes">
          <td style="width: 30%" class="attr_name">{{ifcAttr?.Name}}:</td>
          <td style="width: 70%">{{ifcAttr?.Value}}</td>
        </tr>
      </table>
      <!--        <div class="attrs" *ngFor="let ifcAttr of item.IfcAttributes">{{ifcAttr.Name}}: {{ifcAttr.Value}}</div>-->
    </div>
  </as-split-area>
  <as-split-area [size]="10">
    <div style="font-size: 15px; color: #444444; font-weight: bold; padding: 5px">Список комментариев</div>
    <div class="no-data" *ngIf="!annotationList?.objs?.length">Нет данных</div>
    <ul>
      <li *ngFor="let obj of annotationList?.objs">{{obj.options?.text}}
        <dx-button icon="link" (onClick)="goTo(obj.uuid)"></dx-button>
      </li>
    </ul>
  </as-split-area>
  <as-split-area [size]="10">
    <div style="font-size: 15px; color: #444444; font-weight: bold; padding: 5px">Сохраненные позиции сцены</div>
    <!--    {{savedViews | json}}-->
    <!--    <dx-button icon="link" (onClick)="goToSavedView(savedViews)"></dx-button>-->
    <div class="no-data" *ngIf="!savedViews.length">Нет данных</div>
    <ul>
      <li *ngFor="let obj of savedViews">{{obj.name | json}}
        <dx-button icon="link" (onClick)="goToSavedView(obj)"></dx-button>
      </li>
    </ul>
  </as-split-area>
</as-split>
