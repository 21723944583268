import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'projects/quality-control/src/environments/environment';
import { User } from './users/users.service';
import { map, Observable } from 'rxjs';
import { downloadFile, makeLocalDate } from './utils/utils';
import { OAuthService } from 'angular-oauth2-oidc';
import { IDocumentRemarkPermissions } from 'projects/shared/interfaces/document-remark.interface';

@Injectable({
  providedIn: 'root',
})
export class RemarksService {
  private _apiUrl: string;
  private coreUrl: string;
  private _apiCoreUrl: string;
  private _serviceUrl: string;
  private _serviceODataUrl: string;
  private projectServiceUrl: string;
  private remarkBlueprintUrl: string;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private _http: HttpClient,
    private auth: OAuthService
  ) {
    this._apiUrl = environment.apiUrl;
    this.coreUrl = environment.apiUrl;
    this._apiCoreUrl = environment.apiUrl;
    this._serviceUrl = environment.remarkUrl;
    this._serviceODataUrl = environment.QCRemarksODataUrl;
    this.remarkBlueprintUrl = environment.remarkBlueprintUrl;
    this.projectServiceUrl = environment.projectsUrl;
  }

  static getODataUrl() {
    return environment.apiUrl + environment.QCRemarksODataUrl + 'Remarks';
  }

  public getAll() {
    return this._http.get(this._apiUrl + this._serviceUrl + 'GetAll');
  }

  public getById(Id) {
    return this._http.get(this._apiUrl + this._serviceUrl + `/Get?id=${Id}`);
  }

  public edit(body: RemarkDto) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Update', body, this._httpOptions);
  }

  public create(body: RemarkDto) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Create', body, this._httpOptions);
  }

  public createWithFiles(body: RemarkDto) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'CreateWithFiles', body, this._httpOptions);
  }

  public delete(id: string) {
    return this._http.delete(this._apiUrl + this._serviceUrl + 'Delete?Id=' + id);
  }

  public changeExecutor(body: ChangeExecutorRequest) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'ChangeExecutor', body, this._httpOptions);
  }

  public sendNeedToCheck(body: SendNeedToCheckRequest) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'SendNeedToCheck', body, this._httpOptions);
  }

  public forRevision(body: ForRevisionRequest) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'ForRevision', body, this._httpOptions);
  }

  public close(body: CloseRequest) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Close', body, this._httpOptions);
  }

  public addComment(body: any) {
    // const formData = new FormData();
    // // formData.append('file', body.file);
    // formData.append('Info', JSON.stringify(body.info));
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'multipart/form-data', 'Accept': 'application/json'})
    // };
    return this._http.post(this._apiUrl + this._serviceUrl + 'AddComment', body);
  }

  public addFileToComment(body: any) {
    // const formData = new FormData();
    // formData.append('file', body.file);
    // formData.append('Info', JSON.stringify(body.info));
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'multipart/form-data', 'Accept': 'application/json'})
    // };
    return this._http.post(this._apiUrl + this._serviceUrl + 'AddFileToComment', body);
  }

  public getCommentsPaged(remarkId, page, pageSize) {
    return this._http
      .get(
        this._apiUrl + this._serviceUrl + `/GetCommentsPaged?remarkId=${remarkId}&page=${page}&pageSize=${pageSize}`,
        this._httpOptions
      )
      .pipe(
        map((items: any) => {
          for (let file of items.Results) {
            if (file.Comment) {
              file.Comment.Files.images = [];
              file.Comment.Files.files = [];
              if (file.Comment.Files.length > 0) {
                for (let item of file.Comment.Files) {
                  const arrFileName = item.Name.split('.');
                  if (arrFileName.length > 0) {
                    const fileExt: string = arrFileName[arrFileName.length - 1];
                    item.Type = 'file';
                    item.url = environment.apiUrl + environment.remarkUrl + 'GetRemarkFile?fileId=' + item.Id;
                    file.Comment.Files.files.push(item);
                  }
                }
              }
            }
          }
          return items;
        })
      );
  }

  public getHistoryPaged(remarkId, page, pageSize) {
    return this._http.get(
      this._apiUrl + this._serviceUrl + `GetHistoryPaged?remarkId=${remarkId}&page=${page}&pageSize=${pageSize}`,
      this._httpOptions
    );
  }

  public getFilesPaged(remarkId, page, pageSize) {
    return this._http
      .get(
        this._apiUrl + this._serviceUrl + `GetFilesPaged?remarkId=${remarkId}&page=${page}&pageSize=${pageSize}`,
        this._httpOptions
      )
      .pipe(
        map((items: any) => {
          for (let file of items.Results) {
            if (file.Comment) {
              file.Comment.Files.images = [];
              file.Comment.Files.files = [];
              if (file.Comment.Files.length > 0) {
                for (let item of file.Comment.Files) {
                  const arrFileName = item.Name.split('.');
                  if (arrFileName.length > 0) {
                    item.Type = 'file';
                    item.url = environment.apiUrl + environment.remarkUrl + 'GetRemarkFile?fileId=' + item.Id;
                    file.Comment.Files.files.push(item);
                  }
                }
              }
            }
          }
          return items;
        })
      );
  }

  public getAllPaged(remarkId, page, pageSize) {
    return this._http
      .get(
        this._apiUrl + this._serviceUrl + `/GetAllCollabration?remarkId=${remarkId}&page=${page}&pageSize=${pageSize}`,
        this._httpOptions
      )
      .pipe(
        map((items: any) => {
          for (let file of items.Results) {
            file.Created = makeLocalDate(file.Created);
            file.Modified = makeLocalDate(file.Modified);
            if (file.Comment) {
              if (file.Comment.Files.length > 0) {
                for (let item of file.Comment.Files) {
                  const arrFileName = item.Name.split('.');
                  if (arrFileName.length > 0) {
                    const fileExt: string = arrFileName[arrFileName.length - 1];
                    item.url = this._apiUrl + this._serviceUrl + 'GetRemarkFile?fileId=' + item.Id;
                    item.showAll = false;
                    if (
                      fileExt.toLowerCase() === 'jpg' ||
                      fileExt.toLowerCase() === 'png' ||
                      fileExt.toLowerCase() === 'jpeg' ||
                      fileExt.toLowerCase() === 'bmp'
                    ) {
                      item.Type = 'img';
                      item.urlPreview =
                        this._apiUrl +
                        this._serviceUrl +
                        'GetRemarkFilePreviewRatio?fileId=' +
                        item.Id +
                        '&width=500&height=500';
                    } else {
                      item.Type = 'file';
                    }
                  }
                }
              }
            }
          }

          return items;
        })
      );
  }

  public getProjects() {
    return this._http.get(this._apiCoreUrl + environment.projectsUrl + 'GetAll').pipe(
      map((x: any) => {
        for (let item of x.Items) {
          item.CodeTitle = item.Code ? item.Code + '. ' + item.Title : item.Title;
        }
        return x;
      })
    );
  }

  // public getProjectsMyForView() {
  //   return this._http.get<GetProjectsMyForViewProject[]>(this._apiUrl + coreEnv.projectRemarkUrl + 'GetProjectsMyForView')
  // }

  public getObjects() {
    return this._http.get(this._apiCoreUrl + environment.objectStructureUrl + 'GetAll').pipe(
      map((x: any) => {
        for (let item of x.Items) {
          item.CodeTitle = item.Code ? item.Code + '. ' + item.Title : item.Title;
        }
        return x;
      })
    );
  }

  public getDepartments() {
    return this._http.get(this._apiCoreUrl + environment.departmentsUrl + 'GetAll');
  }

  public getUserByDepartmentId(id: string) {
    return this._http.get(this._apiCoreUrl + environment.departmentsUrl + 'GetUserByDepartmentId', { params: { id } });
  }

  public getUsersAsResponsible(id: string, projectId: string) {
    return this._http.get<
      (User & {
        IsQCRemarksResponsible: boolean;
        IsManagingCMPSubmissionResponsible: boolean;
        IsManagingCMPWorkCompletionPlanCheckResponsible: boolean;
        IsArchiveDocumentRemarkResponsibleResponsible: boolean;
      })[]
    >(this._apiCoreUrl + environment.departmentsUrl + 'GetUsersAsResponsible', {
      params: {
        id,
        projectId,
      },
    });
  }

  public getWorkTypes() {
    return this._http.get(this._apiUrl + environment.workTypesUrl + 'GetAll');
  }

  public getCurrentUserId() {
    return this._http.get(this._apiUrl + environment.usersUrl + 'GetMyID');
  }

  public startWork(id: string) {
    const body = { RemarkId: id };
    return this._http.post(this._apiUrl + this._serviceUrl + 'StartWork', body, this._httpOptions);
  }

  /** Удаление комментария из замечания */
  public deleteComment(id: string) {
    return this._http.delete(this._apiUrl + this._serviceUrl + 'DeleteComment?Id=' + id);
  }

  /** Удаление файла или изображения */
  public deleteAttachment(data: any) {
    return this._http.delete(`${this._apiUrl}${this._serviceUrl}RemoveFile?Id=${data.Id}`);
  }

  public getObjectById(id: string) {
    return this._http.get(this.coreUrl + environment.objectStructureUrl + 'Get?id=' + id).pipe(
      map((x: any) => {
        x.CodeTitle = x.Code ? x.Code + '. ' + x.Title : x.Title;
        return x;
      })
    );
  }

  /** Отправка предписания по email */
  public sendOrderPost(Id: string) {
    const body = { Id };
    return this._http.post(this._apiUrl + this._serviceUrl + 'SendPrescription', body, this._httpOptions);
  }

  /** Рассчитывает срок устранения по приоритету */
  public daysToResolve(priorityId: string) {
    return this._http.get(this._apiUrl + this._serviceUrl + 'DaysToResolve', {
      params: { priorityId },
      responseType: 'text',
    });
  }

  //** получить кол-во замечаний по фильтру */
  getODataFilteredRemarksCount(filter: string = ''): Promise<number> {
    return this._http
      .get(this._apiUrl + this._serviceODataUrl + `Remarks?$count=true&$top=0${filter && '&' + filter}`)
      .toPromise()
      .then((res) => {
        return res['@odata.count'];
      });
  }

  getODataFilteredRemarksCountObservable(filter: string = ''): Observable<number> {
    //    console.log(filter);
    return this._http
      .get(this._apiUrl + this._serviceODataUrl + `Remarks?$count=true&$top=0${filter && '&' + filter}`)
      .pipe(
        map((res) => {
          return res['@odata.count'];
        })
      );
  }

  public downloadOrder(id) {
    downloadFile(this._apiUrl + this._serviceUrl + 'GetRemarkPrescriptionFile?id=' + id, this.auth.getAccessToken());
  }

  public downloadBlueprint(id) {
    downloadFile(
      this._apiUrl + this.remarkBlueprintUrl + 'GetDrawingsResponse?blueprintId=' + id,
      this.auth.getAccessToken()
    );
  }

  getDrawings(body: RemarkDocumentGetDrawingsRequest) {
    return this._http.post<IQCDrawingFiles>(
      this._apiUrl + this.remarkBlueprintUrl + 'GetDrawings',
      body,
      this._httpOptions
    );
  }

  get(id: string) {
    return this._http
      .get<{
        Item: IQCRemark;
        Permissions: IDocumentRemarkPermissions;
      }>(this._apiUrl + this._serviceUrl + `/Get?id=${id}`)
      .pipe(
        map((remark) => {
          return remark;
        })
      );
  }
}

interface IQCRemark {
  Id: string;
  Code: string;
  Status: RemarkStatus;
  StatusName: string;
  Description: string;
}

export interface IQCDrawingFiles {
  Remarks: { Id: string; Code: string; StatusId: number; StatusName: string; Description: string }[];
  Documents: { Id: string; Name: string }[];
  Items: IQCFileDrawing[];
}

export interface IQCFileDrawing {
  CanCreate: boolean;
  CanDelete: boolean;
  DocumentFileId: string;
  RemarkId: string;
  Points: IQCPoint[];
}

interface IQCPoint {
  Id: string;
  PageNumber: number;
  PageOrientation: number;
  X: number;
  Y: number;
  CreatedBy: User;
  CreatedById: string;
  ModifiedBy: User;
  ModifiedById: string;
  Created: string;
  Modified: string;
}

export enum RemarkStatus {
  New = 0, // Новое
  InWork = 1, // В работе
  Fixed = 2, // Исправлено
  Reopened = 3, // Переоткрыто
  Closed = 4, // Закрыто
  Deleted = 5, // Удалено
}

export function getRemarkStatuses() {
  return [
    {
      value: RemarkStatus.New,
      title: 'Новое',
      color: 'color-base',
    },
    {
      value: RemarkStatus.InWork,
      title: 'В работе',
      color: 'color-base',
    },
    {
      value: RemarkStatus.Fixed,
      title: 'Исправлено',
      color: 'color-notice',
    },
    {
      value: RemarkStatus.Reopened,
      title: 'Переоткрыто',
      color: 'color-base',
    },
    {
      value: RemarkStatus.Closed,
      title: 'Закрыто',
      color: 'color-lightGray',
    },
  ];
}

export function getRemarkStatusColor(status: RemarkStatus) {
  const res = getRemarkStatuses().find((stat) => stat.value == status);
  return res ? res.color : '';
}

export function getRemarkStatusView(status: RemarkStatus) {
  const res = getRemarkStatuses().find((stat) => stat.value == status);
  return res ? res.title : undefined;
}

export class RemarkListDto {
  public Id: string;
  public SuggestionForElimination: string;
  public Description: string;
  public RemarkDate: string;
  public EliminationDate: string;
  public OriginalEliminationDate: string;
  public InitiatorName: string;
  public PriorityTitle: string;
  public CategoryTitle: string;
  public ProjectTitle: string;
  public DepartmentTitle: string;
  public ObjectStructureTitle: string;
  public ResultTitle: string;
  public ExecutorTitle: string;
  public CuratorTitle: string;
  public Status: number;
  public Code: string;
  public ReOpened: number;
  public CategoryId: string;
  public CuratorId: string;
  public DepartmentId: string;
  public ExecutoreId: string;
  public InitiatorId: string;
  public ObjectStructureId: string;
  public PriorityId: string;
  public ProjectId: string;
  public ResultId: string;
  public Modified: string;
  public ModifiedBy: string;
  public Created: string;
  public CreatedBy: string;
  public HasFile: true;
  public HasPhoto: true;
  public HasBlueprint: true;
  public Longitude: number;
  public Latitude: number;
  public ClosedDate: string;
  public Deviation: number;
  public InspectionTitle: string;
  public InspectionId?: string;
  public WorkType: string;
  public WorkTypeId: string;
  public DocumentationCode: string;
  public StatusView?: string;

  public setStatusView(status) {
    switch (status) {
      case 0:
        this.StatusView = 'Новое';
        break;
      case 1:
        this.StatusView = 'В работе';
        break;
      case 2:
        this.StatusView = 'Исправлено';
        break;
      case 3:
        this.StatusView = 'Переоткрыто';
        break;
      case 4:
        this.StatusView = 'Закрыто';
        break;
      case 5:
        this.StatusView = 'Удалено';
        break;
    }
  }

  constructor(options?) {
    for (const prop in options) {
      this[prop] = options[prop];
    }
    this.setStatusView(this.Status);
  }
}
export class RemarkDocumentGetDrawingsRequest {
  public RemarkId: string | null;
  public DocumentFileId: string | null;
  public DocumentFileAllVersions: boolean | null;

  constructor(options?) {
    for (const prop in options) {
      this[prop] = options[prop];
    }
  }
}

export class RemarkDto {
  public Id: string;
  public Code?: string;
  public StatusView?: string;
  public Status?: number;
  public Description: string;
  public SuggestionForElimination: string;
  public RemarkDate: Date;
  public EliminationDate: Date;
  public InitiatorId: string;
  public PriorityId: string;
  public CategoryId: string;
  public ProjectId: string;
  public Department: any;
  public DepartmentId: string;
  public ObjectStructure: any;
  public ObjectStructureId: string;
  public CuratorId: any;
  public ExecutorId: string;
  public RemarkDateTime: Date;
  public WorkId?: string;
  public EliminationDateTime: Date;
  public InspectionId?: string;
  public File: any;
  public WorkVolumeListId?: string;

  public setStatusView(status) {
    switch (status) {
      case 0:
        this.StatusView = 'Новое';
        break;
      case 1:
        this.StatusView = 'В работе';
        break;
      case 2:
        this.StatusView = 'Исправлено';
        break;
      case 3:
        this.StatusView = 'Переоткрыто';
        break;
      case 4:
        this.StatusView = 'Закрыто';
        break;
      case 5:
        this.StatusView = 'Удалено';
        break;
    }
  }

  constructor(options?) {
    for (const prop in options) {
      this[prop] = options[prop];
    }
    this.setStatusView(this.Status);
  }
}

export class RemarkReadItemDto {
  public CreatedBy: User;
  public CreatedById: string;
  public ModifiedBy: User;
  public ModifiedById: string;
  public Created: Date;
  public Modified: Date;
  public Id: string;
  public SuggestionForElimination: string;
  public Description: string;
  public RemarkDate: Date;
  public EliminationDate: Date;
  public OriginalEliminationDate: Date;
  public WorkVolumeListId: string;
  public Initiator: {
    Id: string;
    Name: string;
    Code: string;
  };
  public InitiatorId: string;
  public PriorityId: string;
  public Priority: {
    Id: string;
    Name: string;
  };
  public CategoryId: string;
  public Category: {
    Id: string;
    Name: string;
    Code: string;
  };
  public ProjectId: string;
  public Project: {
    Id: string;
    Title: string;
    Code: string;
    Owner: string;
  };
  public DepartmentId: string;
  public Department: {
    Id: string;
    ShortName: string;
    FullName: string;
    Created: string;
    CreatedById: string;
    Modified: string;
    ModifiedById: string;
  };
  public ObjectStructureId: string;
  public ObjectStructure: {
    Id: string;
    Name: string;
    Code: string;
    Path: string;
  };
  public ResultId: string;
  public Result: {
    Id: string;
    Name: string;
  };
  public ExecutorId: string;
  Executor: User;
  public CuratorId: string;
  public Curator: User;
  public Status: RemarkStatus;
  public Code: string;
  public ReOpened: number;
  public ClosedDate: Date;
  public Inspection: {
    Id: string;
    Name: string;
  };
  InspectionId: string;
  public WorkType: string;
  public WorkTypeId: string;
  public DocumentationCode: string;
  public Document: {
    Id: string;
    Name: string;
    Number: string;
    RevisionId: string;
    Revision: {
      Id: string;
      Name: string;
    };
    NumberAndRevision: string;
  };
  public DocumentFile: {
    DocumentId: string;
    Id: string;
    Name: string;
    Version: string;
  };
  public DocumentFileId: string;
  public Prescriptions: {
    CreatedBy: User;
    CreatedById: string;
    ModifiedBy: User;
    ModifiedById: string;
    Created: string;
    Modified: string;
    Id: string;
    Number: string;
    PrescriptionTemplate: {
      Id: string;
      Name: string;
      Code: string;
    };
    PrescriptionTemplateId: string;
    Document: {
      Id: string;
      Name: string;
      Number: string;
    };
    DocumentId: string;
    PdfDocumentFile: {
      Id: string;
      Name: string;
    };
    PdfDocumentFileId: string;
    DocxDocumentFile: {
      Id: string;
      Name: string;
    };
    DocxDocumentFileId: string;
    Permissions_DownloadForEdit: true;
    Remarks: {
      Id: string;
      Status: RemarkStatus;
    }[];
  }[];

  public Collabrations: any[]; // ? откуда

  // вот эти поля походу во вьюхе используются
  public Title: string;
  public StatusView: string;
  public Blueprint: { Id: string; IsActive: boolean; Name: string };

  constructor(rawData?) {
    if (rawData) {
      for (const prop in rawData) {
        this[prop] = rawData[prop];
      }

      if (rawData.ClosedDate) {
        this.ClosedDate = makeLocalDate(rawData.ClosedDate, false) as Date;
      }
      if (rawData.Created) {
        this.Created = makeLocalDate(rawData.Created, false) as Date;
      }
      if (rawData.EliminationDate) {
        this.EliminationDate = makeLocalDate(rawData.EliminationDate, false) as Date;
      }
      if (rawData.Modified) {
        this.Modified = makeLocalDate(rawData.Modified, false) as Date;
      }
      if (rawData.OriginalEliminationDate) {
        this.OriginalEliminationDate = makeLocalDate(rawData.OriginalEliminationDate, false) as Date;
      }
      if (rawData.RemarkDate) {
        this.RemarkDate = makeLocalDate(rawData.RemarkDate, false) as Date;
      }

      this.setStatusView(this.Status);
    }
  }

  setStatusView(status) {
    switch (status) {
      case 0:
        this.StatusView = 'Новое';
        break;
      case 1:
        this.StatusView = 'В работе';
        break;
      case 2:
        this.StatusView = 'Исправлено';
        break;
      case 3:
        this.StatusView = 'Переоткрыто';
        break;
      case 4:
        this.StatusView = 'Закрыто';
        break;
      case 5:
        this.StatusView = 'Удалено';
        break;
    }
  }
}

export class ChangeExecutorRequest {
  public RemarkId: string;
  public NewExecutorId: string;
  public NewDepartmentId: string;
}

export class SendNeedToCheckRequest {
  public RemarkId: string;
  public RemarkResultId: any;
  public Comment: string;
}

export class ForRevisionRequest {
  public RemarkId: string;
  public Comment: string;
}

export class CloseRequest {
  /*[Required]*/
  public RemarkId: string;
  /*[Required]*/
  public RemarkResultId: string;
  public Comment: string;
}

export class RemarkResponse {
  public Item: RemarkReadItemDto;
  public Permissions: RemarkItemPermissions;
}

export class RemarkItemPermissions {
  public Update: boolean;
  public ChangeExecutor: boolean;
  public SendNeedToCheck: boolean;
  public ForRevision: boolean;
  public StartWork: boolean;
  public Close: boolean;
  public Delete: boolean;
  public AddComment: boolean;
  public CreatingPrescription: boolean;
  public RequestInformation: boolean;
  public CanManageBlueprints: boolean;
  public ChangeDepartment: boolean;
}

// export interface GetProjectsMyForViewProject {
//   Id: string
//   Name: string
//   Code: string
//   Description: string
//   IsActive: boolean
//   Statuses: {
//     Status: number
//     Count: number
//   }[]
//   Image: string
//   ObjectStructureId: string
//   ObjectStructure: IObject
// }
