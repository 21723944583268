import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogViewerComponent } from './catalog-viewer.component';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDropDownButtonModule,
  DxFormModule,
  DxPopupModule,
  DxTooltipModule,
  DxTreeListModule,
} from 'devextreme-angular';
import { ButtonsModule } from '../buttons/buttons.module';
import { InfoDashPipeModule } from '@pp/pipes';

@NgModule({
  declarations: [CatalogViewerComponent],
  exports: [CatalogViewerComponent],
  imports: [
    CommonModule,
    DxTooltipModule,
    DxTreeListModule,
    DxDropDownButtonModule,
    DxPopupModule,
    DxFormModule,
    ButtonsModule,
    DxCheckBoxModule,
    InfoDashPipeModule,
    DxButtonModule,
  ],
})
export class CatalogViewerModule {}
