import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable, of, Subject } from 'rxjs';
import { authConfig, environment } from '@pp/root-env';
import { IAuthConfig } from '@pp/interfaces';

export enum ModuleId {
  Approve = 1,
  KP = 2,
  CMP = 3,
  QualityControl = 4,
  ManagingCMP = 5,
  ExecutionCMP2 = 6,
  Archive = 7,
}

export interface IModule {
  Id: ModuleId;
  IsActive: boolean;
  Title: string;
  Contractor: number;
  Customer: number;
  ClientLicense: number;
  ContractorLicense: number;
  EndDate: string;
}

export function getModulesTitles() {
  return [
    {
      value: ModuleId.QualityControl,
      title: 'Контроль Качества',
    },
    {
      value: ModuleId.ManagingCMP,
      title: 'Приёмка Работ',
    },
    {
      value: ModuleId.Archive,
      title: 'Документы',
    },
  ];
}

export function getModuleTitle(id: ModuleId) {
  const res = getModulesTitles().find((item) => item.value === id);
  return res ? res.title : '';
}

@Injectable({
  providedIn: 'root',
})
export class SettingsAppService {
  private actualModuleIds = [
    4, // Планирование и контроль СМР
    5, // Контроль качества
    7, // Документы
  ];

  public isAuth = new Subject();

  public acceptFileTypes = new BehaviorSubject({});
  public activeModules: IModule[];
  public subjectActiveModules = new BehaviorSubject({});
  public $activeModules = this.subjectActiveModules.asObservable().pipe(filter((modules) => Array.isArray(modules)));

  private apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  public getAuthConfig(): Observable<IAuthConfig> {
    return of(authConfig);
  }

  getActiveModules() {
    return this.http.get<IModule[]>(this.apiUrl + environment.ModuleService + 'GetActive').pipe(
      map((res) => {
        res = res.filter((module) => this.actualModuleIds.includes(module.Id));

        console.groupCollapsed('Active modules');
        console.table(res);
        console.groupEnd();

        this.activeModules = res;
        this.subjectActiveModules.next(res);

        return res;
      })
    );
  }

  getAllModules() {
    return this.http.get<IModule[]>(this.apiUrl + environment.ModuleService + 'GetAll').pipe(
      map((res) => {
        let activeModules = res.filter((module) => this.actualModuleIds.includes(module.Id));
        return activeModules.map((module) => {
          return {
            ...module,
            Title: getModuleTitle(module.Id) || module.Title,
          };
        });
      })
    );
  }

  getSettings() {
    return this.http.get<
      {
        Name: string;
        Value: string;
      }[]
    >(this.apiUrl + environment.settingsServiceUrl + 'GetAll');
  }
  getSettingByName(name: string) {
    return this.http.get<{
      Name: string;
      Value: string;
    }>(this.apiUrl + environment.settingsServiceUrl + 'GetByName', { params: { name } });
  }

  setToken(token: string) {
    console.log('token', token);
    this.isAuth.next(true);
  }
}
