import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AuthData } from './auth-store.model';
import { Injectable } from '@angular/core';
import { IAuthConfig } from '@pp/interfaces';

export interface AuthState extends EntityState<AuthData, string> {
  accessToken: string;
  authConfig: IAuthConfig;
}

const initialState = {
  accessToken: undefined,
  authConfig: undefined,
};

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'auth' })
export class AuthStoreStore extends EntityStore<AuthState> {
  constructor() {
    super(initialState);
  }
}
