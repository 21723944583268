import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { lastValueFrom, map, Observable } from 'rxjs';
import { IProjectStructureOData, ITileStructureOData } from '@pp/interfaces';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

export interface ProjectStructureElement {
  Id?: string;
  Name: string;
  Code: string;
  ParentId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectStructuresService {
  private readonly apiCoreUrl: string;
  private readonly projectStructureServiceUrl: string;
  private readonly projectStructureODataUrl: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private _allProjStructCache: IProjectStructureOData[] = null;

  constructor(private http: HttpClient) {
    this.apiCoreUrl = environment.apiUrl;
    this.projectStructureServiceUrl = environment.projectStructureUrl;
  }

  public getAllProjectStructures(params?: { [key: string]: string }): Observable<IProjectStructureOData[]> {
    // if (this._allProjStructCache?.length) return of(this._allProjStructCache)
    let paramsStr = '';
    if (params) {
      const queryParamsString = new HttpParams({ fromObject: params }).toString();
      paramsStr = '?' + queryParamsString;
    }

    return this.http.get(this.apiCoreUrl + environment.ProjectStructureServiceOData + paramsStr).pipe(
      map((res: any) => {
        this._allProjStructCache = res.value;
        return res.value;
      })
    );
  }
  public getProjectStructure(id): Observable<IProjectStructureOData> {
    return this.http
      .get(this.apiCoreUrl + environment.ProjectStructureServiceOData, { params: { $filter: `Id eq ${id}` } })
      .pipe(
        map((res: any) => {
          this._allProjStructCache = res.value;
          return res.value;
        })
      );
  }

  public getStructuesForTile(): Observable<ITileStructureOData[]> {
    return this.http.get(ProjectStructuresService.getStructuresAndProjectsODataUrlForTile()).pipe(
      map((res: any) => {
        return res.value;
      })
    );
  }

  public getStructuesForTable(): Observable<ITileStructureOData[]> {
    return this.http.get(ProjectStructuresService.getStructuresAndProjectsODataUrlForTable()).pipe(
      map((res: any) => {
        return res.value;
      })
    );
  }

  static getStructuresAndProjectsODataUrlForTile() {
    return environment.apiUrl + environment.ProjectsAndProjectStructuresForTileServiceOData;
  }
  static getStructuresAndProjectsODataUrlForTable() {
    return environment.apiUrl + environment.ProjectsAndProjectStructuresForTableServiceOData;
  }
  static getStructuresAndProjectsODataUrlForAdmin() {
    return environment.apiUrl + environment.ProjectsAndProjectStructuresForAdminServiceOData;
  }

  public createProjectStructure(body) {
    return this.http.post(this.apiCoreUrl + environment.projectStructureUrl + 'Create', body);
  }

  public editProjectStructure(body) {
    return this.http.post(this.apiCoreUrl + environment.projectStructureUrl + 'Update', body);
  }

  public deleteProjectStructure(id: string) {
    return this.http.delete(this.apiCoreUrl + environment.projectStructureUrl + 'Delete?Id=' + id);
  }

  public getDS(params?) {
    return new DataSource({
      store: new CustomStore({
        key: 'Id',
        loadMode: 'raw',
        load: (options) => {
          return lastValueFrom(this.getAllProjectStructures(params));
        },
        byKey: (id) => {
          return lastValueFrom(this.getProjectStructure(id));
        },
      }),
    });
  }
}
