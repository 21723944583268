import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { HeaderModule, SideNavigationMenuModule } from '..';
import { ScreenService } from '../../services';
import { DxDrawerComponent, DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';

import { navigation } from '../../app-navigation';
import { NavigationEnd, Router } from '@angular/router';
import {
  DxBoxModule,
  DxButtonModule,
  DxDataGridModule,
  DxFileUploaderComponent,
  DxFileUploaderModule,
  DxPopupModule,
  DxTextAreaModule,
} from 'devextreme-angular';

@Component({
  selector: 'app-portal-core-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss'],
})
export class SideNavOuterToolbarComponent implements OnInit {
  @ViewChild('drawer1', { static: false }) drawer1: DxDrawerComponent;
  @ViewChild('drawer2', { static: false }) drawer2: DxDrawerComponent;
  @ViewChild('fileUploader', { static: false }) fileUploader: DxFileUploaderComponent;

  menuItems = navigation;
  selectedRoute = '';

  menuOpened: boolean;
  temporaryMenuOpened = false;

  @Input()
  title: string;

  @Input()
  rightContent: any;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = true;

  files: any = [];

  canAttachFile = false;

  showAllFiles = false;

  constructor(
    private screen: ScreenService,
    private router: Router
  ) {}

  ngOnInit() {
    // this.menuOpened = this.screen.sizes['screen-large'];
    this.menuOpened = false;

    // this.translateMenuItems(this.menuItems.filter(x => x.visible === true));

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  translateMenuItems(menuItems: any[]) {
    for (const menuItem of menuItems) {
      if (menuItem.items) {
        this.translateMenuItems(menuItem.items);
      }
    }
  }

  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    // this.minMenuSize = isXSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      // if (event.node.selected) {
      //   pointerEvent.preventDefault();
      // } else {
      //   this.router.navigate([path]);
      // }
      this.router.navigate([path]);

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [
    SideNavigationMenuModule,
    DxDrawerModule,
    DxButtonModule,
    HeaderModule,
    DxScrollViewModule,
    CommonModule,
    DxBoxModule,
    DxTextAreaModule,
    DxFileUploaderModule,
    DxPopupModule,
    DxDataGridModule,
  ],
  exports: [SideNavOuterToolbarComponent],
  declarations: [SideNavOuterToolbarComponent],
})
export class SideNavOuterToolbarModule {}
