<div class="info {{position}}">
  <div *ngIf="createdDate || createdBy">
    <span class="info__caption" i18n>Создано: </span>
    <ng-container *ngIf="createdDate" i18n>
      {{createdDate | date:'shortDate' }} в {{createdDate | date:'shortTime'}}
    </ng-container>
    <ng-container *ngIf="createdDate && createdBy"> • </ng-container>
    {{createdBy}}
  </div>
  <div *ngIf="lastModifiedDate || lastModifiedBy">
    <span class="info__caption" i18n>Изменено: </span>
    <ng-container *ngIf="lastModifiedDate">
      {{lastModifiedDate | date:'shortDate'}} в {{lastModifiedDate | date:'shortTime'}}
    </ng-container>
    <ng-container *ngIf="lastModifiedDate && lastModifiedBy"> • </ng-container>
    {{lastModifiedBy}}
  </div>
</div>