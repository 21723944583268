import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { ViewerQuery } from '../store/viewer.query';

@Injectable({
  providedIn: 'root',
})
export class LoadModelsService {
  public settings: any;
  private c3dApiURL: string | undefined;

  constructor(
    private _http: HttpClient,
    private viewerQuery: ViewerQuery
  ) {
    this.viewerQuery.viewerSettings$.subscribe((res) => {
      this.settings = res;
      const c3dApi = this.settings.service.c3dApi;
      this.combineC3dApiURL(c3dApi.protocol, c3dApi.host, c3dApi.port, c3dApi.version);
    });
  }

  public getAllModels(): Observable<IListModels> {
    return this._http.get<IListModels>(this.settings.service.apiUrl || '');
  }

  public getAllModelsODataUrl(): string {
    return this.settings.service.apiFilesUrl;
  }

  public getAllModelsOData(filter?: string) {
    return this._http
      .get<{ value: { Id; ParentId }[] }>(this.getAllModelsODataUrl() + filter)
      .pipe(map((res) => res.value));
  }

  public loadModel(uuid: string, modelPath: string, key: string) {
    return this._http.post((this.settings.service.apiUrl || '') + 'scripts/loading/' + uuid + '?withCleaning=true', [
      key,
    ]); // Для нашего бека
  }

  public removeAllModels(uuid: string, key: string) {
    // return this._http.post((this.settings.service.apiUrl || '') + "scripts/remove/" + uuid,
    //   [key]) // Для нашего бека
    localStorage.removeItem('workspace');
    window.location.reload();
  }

  public loadTreeModel(workspaceUuid: string, nodeUuid: string, modelUuid: string) {
    return this._http.get(
      (this.settings.service.apiUrl || '') + 'data/model/' + workspaceUuid + '/node/' + nodeUuid + '?model=' + modelUuid
    );
  }

  public getWorkspaceParam(uuid: string) {
    return this._http.get(this.c3dApiURL + 'sessions/workspaces/' + uuid);
  }

  public addModelInWorkspace(uuid: string, models: any, clearSpace?: boolean) {
    return this._http.post(
      (this.settings.service.apiUrl || '') + 'scripts/loading/' + uuid + '?withCleaning=true',
      models
    );
  }

  public deleteModelInWorkspace(uuid: string) {
    return this._http.delete(this.c3dApiURL + 'sessions/workspaces/' + uuid + '/models');
  }

  public getServiceStatus() {
    return this._http.get(this.c3dApiURL + 'monitor/status/');
  }

  private combineC3dApiURL(protocol: string, host: string, port: string, version: string) {
    this.c3dApiURL = protocol + '//' + host + ':' + port + '/' + version + '/';
  }

  public getAllModelNodes(uuidViewer: string, uuidModel: string, makeFlat?: boolean) {
    const headerDict = {
      'X-Session-ID': uuidViewer,
    };

    return this._http
      .get(this.c3dApiURL + 'models/' + uuidModel + '/nodes', { headers: new HttpHeaders(headerDict) })
      .pipe(
        map((tree: { children: any[]; uuid: string } | any) => {
          if (makeFlat) {
            let flatItems: string[] = [];
            if (tree.children.length > 0) {
              this.makeFlat(tree, flatItems);
              return flatItems;
            } else {
              return tree;
            }
          } else {
            return tree;
          }
        })
      );
  }

  public getParentNodes(uuidViewer: string, uuidModel: string, uuidSelectMesh: string) {
    const headerDict = {
      'X-Session-ID': uuidViewer,
    };

    return this._http.get(this.c3dApiURL + 'models/' + uuidModel + '/parents/' + uuidSelectMesh, {
      headers: new HttpHeaders(headerDict),
    });
  }

  private makeFlat(tree: { children: any[]; uuid: string }, result: string[]) {
    result.push(tree.uuid);
    if (tree.children.length > 0) {
      tree.children.map((x) => {
        this.makeFlat(x, result);
      });
    }
  }
}

export interface IListModels {
  address: {};
  items: IModelFile[];
  loading: boolean;
}

export interface IModelFile {
  dataItem?: any;
  dateModified?: any;
  isDirectory?: boolean;
  parentPath?: string;
  pathInfo?: any[];
  pathKeys?: any[];
  relativeName?: string;
  thumbnail?: string;
  tooltipText?: string;
  path?: string;
  key?: any;
  name?: string;
  size?: number;
  hasSubDirectories?: boolean;
  SourceObjectId?: string;
}
