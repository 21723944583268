import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalProjectSelectComponent } from './global-project-select.component';
import { DxLoadIndicatorModule, DxSelectBoxModule } from 'devextreme-angular';

@NgModule({
  declarations: [GlobalProjectSelectComponent],
  exports: [GlobalProjectSelectComponent],
  imports: [CommonModule, DxSelectBoxModule, DxLoadIndicatorModule],
})
export class GlobalProjectSelectModule {}
