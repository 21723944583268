import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from 'projects/quality-control/src/environments/environment';
import { Act } from './entities';
import { TaskCreationMethod, TaskPermissions, TaskResult, TaskStatus, TaskType } from 'projects/shared/entities/task';
import { User } from './users/users.service';

@Injectable({
  providedIn: 'root',
})
export class ApproveTasksService {
  private _apiUrl: string;
  private _serviceUrl: string;
  private _serviceODataUrl: string;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private _http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.ApproveTasks;
    this._serviceODataUrl = environment.ApproveTasksOData;
  }

  public getODataUrl() {
    return this._apiUrl + this._serviceODataUrl + 'ApproveTasks';
  }

  public get(id: string) {
    return this._http
      .get<{
        Item: GetTaskResponse;
        Permissions: TaskPermissions;
      }>(this._apiUrl + this._serviceUrl + `/Get`, { params: { id } })
      .pipe(
        map((item) => {
          return item;
        })
      );
  }

  takeToWork(Id: string) {
    return this._http.post(this._apiUrl + this._serviceUrl + `/TakeToWork`, { Id }, this._httpOptions);
  }

  approveFinal(Id: string, Comment: string) {
    return this._http.post(this._apiUrl + this._serviceUrl + `/ApproveFinal`, { Id, Comment }, this._httpOptions);
  }

  approve(Id: string, Comment: string) {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Approve`, { Id, Comment }, this._httpOptions);
  }

  delegate(Id: string, Comment: string, ToUsersIds: string[]) {
    return this._http.post(
      this._apiUrl + this._serviceUrl + `/Delegate`,
      { Id, Comment, ToUsersIds },
      this._httpOptions
    );
  }

  reject(Id: string, Comment: string) {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Reject`, { Id, Comment }, this._httpOptions);
  }

  public getAutoCompleteStatuses() {
    return [
      {
        Value: false,
        Text: $localize`Отключено`,
      },
      {
        Value: true,
        Text: $localize`Включено`,
      },
    ];
  }
}

export interface GetTaskResponse {
  CreatedBy: User;
  CreatedById: string;
  ModifiedBy: User;
  ModifiedById: string;
  Created: string;
  Modified: string;
  Id: string;
  StageId: string;
  StageSortOrder: number;
  StageName: string;
  ApproveId: string;
  ApproveCode: string;
  ApproveType: number;
  WorkCompletionCertificate: Act;
  Code: string;
  Type: TaskType;
  RunningStartedDate: string;
  PlanCompletedDate: string;
  CompletedDate: string;
  Status: TaskStatus;
  Result: TaskResult;
  CreationMethod: TaskCreationMethod;
  AssignedTo: User;
  AssignedToId: string;
  ParentTaskId: string;
  ParentTaskCode: string;
  CompletedBy: User;
  CompletedById: string;
  Comment: string;
}
