import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonsModule } from '@pp/ui-components';
import { DxButtonModule, DxCheckBoxModule, DxTooltipModule, DxTreeListModule } from 'devextreme-angular';
import { MainPageComponent } from './main-page.component';
import { SecureImgPipeModule } from 'src/pipes/secure-img.pipe';
import { TableViewComponent } from './table-view/table-view.component';
import { TilesViewComponent } from './tiles-view/tiles-view.component';

@NgModule({
  imports: [
    //angular
    CommonModule,
    RouterModule,

    // pp-lib
    ButtonsModule,

    // dx
    DxTreeListModule,
    DxButtonModule,
    DxTooltipModule,
    DxCheckBoxModule,

    // own
    SecureImgPipeModule,
  ],
  declarations: [MainPageComponent, TilesViewComponent, TableViewComponent],
  // exports: [ MainPageComponent ]
})
export class MainPageModule {}
