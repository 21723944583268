import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ICatalog, ICatalogCreateChild, ICatalogCreateRoot } from '@pp/interfaces';
import { IPPButtonOnClick, NotifierService } from '@pp/ui-components';
import { DxFormComponent, DxTreeListComponent } from 'devextreme-angular';
import { lastValueFrom, Subject, take, takeUntil } from 'rxjs';
import { CatalogService } from '../../../../archive/src/app/services/catalog.service';
import { RootStoreQuery, RootStoreService } from '@pp/root-store';
import _ from 'lodash';
import { getCatalogDSOData } from '@pp/data-sources';
import { ActivatedRoute } from '@angular/router';
import { BackendError, handleError } from '../../../../../src/app/services/services-utils';
import { IArchivePermissionForProject } from 'src/app/services/permission.service';
import { LocalStorageConstants } from 'src/app/shared/constants';
import dxDataGrid from 'devextreme/ui/data_grid';
import { DocsService } from 'src/app/shared/services/docs.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'pp-ui-catalog-viewer',
  templateUrl: './catalog-viewer.component.html',
  styleUrls: ['./catalog-viewer.component.scss'],
})
export class CatalogViewerComponent implements OnInit, OnDestroy {
  @ViewChild('catalogTreeList') catalogTreeList: DxTreeListComponent;

  @Input() height: number = null;
  @Input() width: number | string = '100%';
  @Input() showCloseBtn = false;

  @Input() selectProjectId: string;
  @Input() header: string;
  @Input() enabledCRUD: boolean = false;
  @Input() canCreateRootCatalog: boolean = false;
  @Input() permissionsByProject: IArchivePermissionForProject;

  @Output() $changeCatalog: EventEmitter<string> = new EventEmitter<string>();
  @Output() $eventCRUD: EventEmitter<'deleteCatalog' | 'updateCatalog' | 'createCatalog'> = new EventEmitter();
  @Output() $importDocs: EventEmitter<string> = new EventEmitter<string>();

  event: 'deleteCatalog' | 'updateCatalog' | 'createCatalog';

  catalogDS: DataSource;
  @Output() closingPanel = new EventEmitter<void>();

  showCreateRootCatalogPopup = false;
  showCreateChildCatalogPopup = false;
  showRenameCatalogPopup = false;
  showDeleteCatalogPopup = false;
  deleteCheckBoxValue = false;
  newRootCatalog: ICatalogCreateRoot;
  newChildCatalog: ICatalogCreateChild;

  selectedCatalogId: string;
  selectedCatalog: ICatalog;
  toRenameCatalog: ICatalog;

  rootProjectId: string;

  labels = {
    catalogs: $localize`Папки`,
    name: $localize`Наименование`,
    description: $localize`Описание`,
    createFolder: $localize`Создать папку`,
    closePanel: $localize`Закрыть панель`,
  };

  private destroy$ = new Subject<void>();

  constructor(
    private rootStoreQuery: RootStoreQuery,
    private catalogService: CatalogService,
    private rootStoreService: RootStoreService,
    private activatedRoute: ActivatedRoute,
    private notifier: NotifierService,
    private docsService: DocsService
  ) {
    this.rootStoreQuery.rootProjectId$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      if (!id) return;
      // Если есть projectId пересоздаем DS. Иначе при reload() treeList не отрисовывает данные
      this.catalogDS = undefined;
      this.rootProjectId = id;
      if (this.catalogTreeList) {
        this.catalogTreeList.instance.deselectAll();
      }
      this.catalogDS = new DataSource({
        store: new CustomStore({
          key: 'Id',
          loadMode: 'raw',
          load: () => {
            return lastValueFrom(this.catalogService.getAllByProject(id));
          },
        }),
        sort: ['Name'],
      });
    });
  }

  ngOnInit() {
    const queryCatalogId = this.activatedRoute.snapshot.queryParamMap.get('selectedCatalogId');
    const storeCatalogId = this.rootStoreQuery.getValue().selectedCatalogId;

    this.selectedCatalogId = queryCatalogId || storeCatalogId;

    if (this.selectedCatalogId) {
      this.rootStoreService.updateSelectedCatalogId(this.selectedCatalogId);
    }
  }

  saveTreeState = (state) => {
    const { selectedRowKeys, ...restState } = state;
    localStorage.setItem(LocalStorageConstants.ROOT.CATALOG_TREE_LIST, JSON.stringify(restState));
  };

  loadTreeState = () => {
    const state = localStorage.getItem(LocalStorageConstants.ROOT.CATALOG_TREE_LIST);

    if (state) {
      const stateOb = JSON.parse(state);
      stateOb.selectedRowKeys = [this.selectedCatalogId];
      return stateOb;
    } else {
      return { selectedRowKeys: [this.selectedCatalogId] };
    }
  };

  startCreateRootFolder = () => {
    this.newRootCatalog = {
      Name: '',
      ProjectId: '',
      Description: '',
    };
    this.showCreateRootCatalogPopup = true;
  };

  // Функция  используется для рекурсивного раскрытия вложенных папок в иерархической структуре
  // При открытии Документа
  expandNestedFolder(items: any[], selectedRowId: string) {
    if (!items.length || !selectedRowId) {
      return;
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item.key) return;

      const itemChildrenIds = item.children.map((child) => child.key.toString());

      if (itemChildrenIds.includes(selectedRowId.toString())) {
        this.catalogTreeList.instance.expandRow(item.key.toString());
        this.expandNestedFolder([item.parent], item.key.toString());
        break;
      } else {
        this.expandNestedFolder(item.children, selectedRowId);
      }
    }
  }

  changeSelectedCatalog(e: { selectedRowKeys: string[] }) {
    if (!e.selectedRowKeys.length) return;

    this.selectedCatalogId = e.selectedRowKeys.shift().toString();
    this.expandNestedFolder(this.catalogDS.items(), this.selectedCatalogId);

    if (this.selectedCatalogId) this.rootStoreService.updateSelectedCatalogId(this.selectedCatalogId.toString());
  }

  selectRow(e: { component: dxDataGrid }) {
    e.component.selectRows([this.selectedCatalogId], false);
  }

  // onSelectionChanged происходит перед onRowClick, поэтому
  // тут всегда эмитится актуальный айдишник выбранного каталога
  rowClick(e: { data; rowType; event; key }) {
    const treeList = this.catalogTreeList.instance;
    const clickedRowKey = e.key;

    // Проверяем, кликнули ли на стрелку
    const target = e.event.target;
    const parentClassList = target.parentElement?.classList || [];

    if (
      target.classList.contains('dx-icon') || // Клик по иконке
      parentClassList.contains('dx-treelist-expanded') || // Клик по раскрытому узлу
      parentClassList.contains('dx-treelist-collapsed') // Клик по свёрнутому узлу
    ) {
      return; // Завершаем, чтобы стандартное поведение стрелки работало
    }

    // Если кликнули по строке, управляем раскрытием узла
    const isExpanded = treeList.isRowExpanded(clickedRowKey);
    if (isExpanded) {
      treeList.collapseRow(clickedRowKey);
    } else {
      treeList.expandRow(clickedRowKey);
    }

    this.$changeCatalog.emit(this.selectedCatalogId || null);
  }

  addTitleAttr(e: { rowType: string; cellElement: HTMLElement; data: { Description: string } }) {
    if (e.rowType === 'data' && e.cellElement) {
      e.cellElement.title = e.data.Description;
    }
  }

  closeCreateRootCatalogPopup() {
    this.showCreateRootCatalogPopup = false;
    this.newRootCatalog = undefined;
  }

  createRootCatalog(btnEvent: IPPButtonOnClick, form: DxFormComponent) {
    const isValid = form.instance.validate();
    if (isValid.isValid) {
      btnEvent.options.wait = true;
      let newFolderFormData = { ...form.formData, ProjectId: this.rootProjectId };
      this.rootStoreQuery.rootProjectId$.pipe(take(1)).subscribe((id) => (newFolderFormData.ProjectId = id));
      this.catalogService.createRootCatalog(newFolderFormData).subscribe(
        (res) => {
          btnEvent.options.wait = false;
          this.closeCreateRootCatalogPopup();
          this.reloadDS();
          this.notifier.notify($localize`Создана папка`, 'success', 4000);
        },
        (error) => {
          handleError(new BackendError(error));
          btnEvent.options.wait = false;
        }
      );
    }
  }

  startCreateChildCatalog() {
    this.newChildCatalog = { Name: '', Description: '', ParentId: '' };
    this.showCreateChildCatalogPopup = true;
  }

  startDownloadCatalog(catalogId: string) {
    this.docsService.getCatalogFilesArchive(catalogId);
  }

  startImportDocs() {
    this.$importDocs.emit();
  }

  startDeleteCatalog() {
    this.showDeleteCatalogPopup = true;
  }

  createChildCatalog(btnEvent: IPPButtonOnClick, form: DxFormComponent) {
    const isValid = form.instance.validate();
    if (isValid.isValid) {
      btnEvent.options.wait = true;
      this.catalogService.createChildCatalog({ ...form.formData, ParentId: this.selectedCatalogId }).subscribe(
        (res) => {
          btnEvent.options.wait = false;
          this.closeCreateChildCatalogPopup();
          this.reloadDS().then(() => {
            this.catalogTreeList.instance.expandRow(this.selectedCatalogId);
          });
          this.notifier.notify($localize`Создана папка`, 'success', 4000);
        },
        (error) => {
          handleError(new BackendError(error));
          btnEvent.options.wait = false;
        }
      );
    }
  }

  closeCreateChildCatalogPopup() {
    this.showCreateChildCatalogPopup = false;
    this.newChildCatalog = undefined;
  }

  closeRenameCatalogPopup() {
    this.showRenameCatalogPopup = false;
    this.toRenameCatalog = undefined;
  }

  closeDeleteCatalogPopup() {
    this.showDeleteCatalogPopup = false;
    this.deleteCheckBoxValue = false;
  }

  startRenameCatalog(row: { data: ICatalog }) {
    this.toRenameCatalog = _.cloneDeep(row.data);
    this.showRenameCatalogPopup = true;
  }

  renameCatalog(btnEvent: IPPButtonOnClick, form: DxFormComponent) {
    const isValid = form.instance.validate();
    if (isValid.isValid) {
      btnEvent.options.wait = true;
      this.catalogService.updateCatalog(form.formData).subscribe(
        (res) => {
          btnEvent.options.wait = false;
          this.closeRenameCatalogPopup();
          this.reloadDS();
          this.notifier.notify($localize`Сохранены изменения в папке`, 'success', 4000);
        },
        (error) => {
          handleError(new BackendError(error));
          btnEvent.options.wait = false;
        }
      );
    }
  }

  deleteCatalog(btnEvent: IPPButtonOnClick) {
    btnEvent.options.wait = true;
    this.catalogService.deleteCatalog(this.selectedCatalogId).subscribe(
      (res) => {
        btnEvent.options.wait = false;
        this.closeDeleteCatalogPopup();
        this.reloadDS();
        //this.catalogTreeList.instance.clearSelection()
        this.$eventCRUD.emit('deleteCatalog');
        this.notifier.notify($localize`Папка удалена`, 'success', 4000);
      },
      (error) => {
        handleError(new BackendError(error));
        btnEvent.options.wait = false;
      }
    );
  }

  setSelectedCatalogId(id: string) {
    this.selectedCatalogId = id;
    this.$changeCatalog.emit(this.selectedCatalogId || null);
  }

  closePanel = () => {
    this.closingPanel.emit();
  };

  ngOnDestroy() {
    this.catalogDS?.dispose();
  }

  reloadDS() {
    return this.catalogTreeList.instance.refresh(true);
  }
}
