import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxLoadIndicatorModule, DxPopoverModule } from 'devextreme-angular';
import { buttonContainers } from './buttons.common';

@NgModule({
  declarations: [buttonContainers],
  exports: [buttonContainers],
  imports: [CommonModule, DxButtonModule, DxLoadIndicatorModule, DxPopoverModule],
})
export class ButtonsModule {}
