import { Component } from '@angular/core';
import { LocalStorageConstants } from 'src/app/shared/constants';
import { ImageCahceService } from '../../shared/services/image-cache.service';
import { MainPageService } from './main-page.service';
import notify from 'devextreme/ui/notify';
import { NotifierService } from '@pp/ui-components';

const mainPageViews = ['tiles', 'table'] as const;
type MainPageView = (typeof mainPageViews)[number];

@Component({
  selector: 'app-portal-core-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  providers: [MainPageService, ImageCahceService],
})
export class MainPageComponent {
  selectedView: MainPageView = 'tiles';

  constructor() {
    const lsView = localStorage.getItem(LocalStorageConstants.ROOT.MAIN_PAGE_VIEW);
    const resView = mainPageViews.find((view) => view === lsView);
    if (resView) this.selectedView = resView;
  }

  setView(view: MainPageView) {
    localStorage.setItem(LocalStorageConstants.ROOT.MAIN_PAGE_VIEW, view);
    this.selectedView = view;
  }
}
