import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { RootState, RootStore } from './root.store';

@Injectable({
  providedIn: 'root',
})
export class RootStoreQuery extends Query<RootState> {
  rootProjectId$ = this.select((state) => state.rootProjectId);
  selectedCatalogId$ = this.select((state) => state.selectedCatalogId);
  activeModules$ = this.select((state) => state.activeModules);
  userProfile$ = this.select((state) => state.userProfile);
  contentLoaderState$ = this.select((state) => state.loaderOn);
  userPermissionArchive$ = this.select((state) => state.userPermissions.Archive);

  constructor(protected store: RootStore) {
    super(store);
  }
}
