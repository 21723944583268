import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from 'projects/quality-control/src/environments/environment';
import { Approve, ApprovePermissions } from 'projects/shared/entities/approve';
import { Act } from './entities';

@Injectable({
  providedIn: 'root',
})
export class ActApproveService {
  private _apiUrl: string;
  private _serviceUrl: string;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private _http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.WorkCompletionCertificateApproveServiceSP;
  }

  public create(id: string) {
    return this._http.post(
      this._apiUrl + this._serviceUrl + `/Create`,
      { WorkCompletionCertificateId: id },
      this._httpOptions
    );
  }

  public searchApproveTemplate(id: string) {
    return this._http.post<{
      Result: number;
      ApproveTemplatesIds: string[];
    }>(
      this._apiUrl + this._serviceUrl + `/SearchApproveTemplate`,
      { WorkCompletionCertificateId: id },
      this._httpOptions
    );
  }

  public get(id: string) {
    return this._http
      .get<{
        Item: ActApprove;
        Permissions: ApprovePermissions;
      }>(this._apiUrl + this._serviceUrl + `/Get`, { params: { id } })
      .pipe(
        map((item) => {
          item.Item.Stages.sort((a, b) => a.SortOrder - b.SortOrder);
          item.Item.Tasks.sort((a, b) => a.StageSortOrder - b.StageSortOrder);
          return item;
        })
      );
  }

  public editApprove(approve: any) {
    let body: any = { ...approve };
    if (body.CopyTo && body.CopyTo.length) {
      body.CopyTo = body.CopyTo.map((user) => {
        return { Id: user.Id };
      });
    }

    if (body.Signer) {
      body.Signer = { Id: body.Signer.Id };
    }

    return this._http.post<any>(this._apiUrl + this._serviceUrl + 'Update', body, this._httpOptions);
  }

  public cancelApprove(Id: string) {
    return this._http.post<any>(this._apiUrl + this._serviceUrl + 'Cancel', { Id }, this._httpOptions);
  }
}

export interface ActApprove extends Approve {
  WorkCompletionCertificate: Act;
}

export enum FilterPresets {
  All = 0,
  AuthosIsMe = 1,
  AllClosed = 2,
  AllActiveApproves = 3,
  WhereIHaveTasks = 4,
}
