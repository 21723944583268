import { IAuthConfig } from '@pp/interfaces';
import { endpoints } from './api.paths';

export const authConfig: IAuthConfig = {
  typeAuth: 'adfs',
  oathSettings: {
    keycloak: {
      url: 'http://debian-1.tm.local:8447',
      clientId: 'account',
      logOffUrl: 'http://debian-1.tm.local:8447/realms/ProjectPoint/protocol/openid-connect/logout',
    },
    adfs: {
      url: 'https://adfs.tnsv.projectpoint.ru/adfs',
      clientId: 'bf5c5655-cf63-41e1-a3e0-d35c7d519872',
      logOffUrl: 'https://adfs.tnsv.projectpoint.ru/adfs/oauth2/logout',
    },
  },
};

export const environment = {
  production: true,
  apiUrl: '',
  apiUrlBim: '',
  ...endpoints,
} as const;
