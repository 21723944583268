import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxToastModule } from 'devextreme-angular';
import { NotifierService } from './notifier.service';
import { NotifierComponent } from './notifier.component';

@NgModule({
  imports: [CommonModule, DxToastModule],
  providers: [NotifierService],
  declarations: [NotifierComponent],
  exports: [NotifierComponent],
})
export class NotificationsModule {}
