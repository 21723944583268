import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'pp-ui-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent implements OnInit, OnChanges {
  @Input() stylingMode: 'contained' | 'outlined' | 'text';
  @Input() text: string;

  @Output() onClick: EventEmitter<IPPButtonOnClick> = new EventEmitter<IPPButtonOnClick>();

  @Input() options: IPPButton = {};
  @Input() disabledText: string;
  @Input() hint: string;

  @Input()
  @HostBinding('class.activeButton')
  isActive = false;

  @Input()
  @HostBinding('class.roundButton')
  isRound = false;

  private defaultStylingMode: 'contained' | 'outlined' | 'text' = 'outlined';
  private defaultText = '';

  uniqID = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();

  customClasses: string[] = [];

  isPPIcon = false;

  constructor(public hostRef: ElementRef) {}

  ngOnInit(): void {
    if (this.options.customType) {
      this.options.stylingMode = 'outlined';
      this.options.height = 32;
      this.customClasses.push('button-custom');
    }
    // if (this.options.type === 'orange' || this.options.type === 'yellow' || this.options.type === 'purple') {
    //   this.customClasses.push('button-' + this.options.type);
    // }
    if (!this.options.onClick) {
      this.options.onClick = this.onClick;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      if (['orange', 'yellow', 'purple', 'gray', 'blue'].includes(this.options.type)) {
        this.customClasses.push('button-' + this.options.type);
      } else {
        const idx = this.customClasses.findIndex((el) =>
          ['button-orange', 'button-yellow', 'button-purple', 'button-gray', 'button-blue'].includes(el)
        );
        if (idx !== -1) this.customClasses.splice(idx, 1);
      }

      if (changes.options.currentValue.icon) {
        this.isPPIcon = changes.options.currentValue.icon.startsWith('pp-icon');
      }
      this.defaultStylingMode = this.options.stylingMode;
      this.defaultText = this.options.text;
    }

    if (changes.isRound) {
      if (changes.isRound.currentValue) {
        this.options.stylingMode = 'text';
        // this.options.text = '';
        this.customClasses.push('roundButton');
      } else {
        this.options.stylingMode = this.defaultStylingMode;
        // this.options.text = this.defaultText;
        const idx = this.customClasses.indexOf('roundButton');
        if (idx !== -1) this.customClasses.splice(idx, 1);
      }
    }
  }

  onClickEvent(e): void {
    if (!this.options.onClick) {
      this.options.onClick = this.onClick;
    }
    if (this.options.onClick instanceof EventEmitter) {
      this.onClick.emit({ button: e, options: this.options });
    } else {
      this.options.onClick({ button: e, options: this.options });
    }
  }
}

export interface IPPButton {
  stylingMode?: 'contained' | 'outlined' | 'text';
  text?: string;
  type?: 'back' | 'danger' | 'default' | 'normal' | 'success' | 'orange' | 'yellow' | 'purple' | 'gray';
  customType?: boolean;
  icon?: string;
  width?: number;
  height?: number;
  onClick?: any;
  disabled?: boolean;
  disabledText?: string;
  wait?: boolean;
  popoverMode?: 'hover' | 'click';
  active?: boolean;
  hint?: string;
}

export interface IPPButtonOnClick {
  button: any;
  options: IPPButton;
}
