import { Injectable } from '@angular/core';
import { SettingsAppService } from '@pp/settings';
import { KeycloakService } from 'keycloak-angular';
import { IAuthBaseConfig } from '@pp/interfaces';
import { AuthStoreQuery, AuthStoreService } from '@pp/auth-store';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private settings: SettingsAppService,
    private keycloak: KeycloakService,
    private authStoreService: AuthStoreService,
    private oauthService: OAuthService,
    private authStoreQuery: AuthStoreQuery
  ) {}

  startKeycloakAuth(config: IAuthBaseConfig) {
    this.keycloak
      .init({
        config: {
          url: config.url,
          realm: 'ProjectPoint',
          clientId: config.clientId,
        },
        initOptions: {
          onLoad: 'login-required',
          flow: 'implicit',
        },
        shouldAddToken: (request) => {
          const { method, url } = request;
          const isGetRequest = 'GET' === method.toUpperCase();
          const acceptablePaths = ['/assets'];
          const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path));
          return !(isGetRequest && isAcceptablePathMatch);
        },
      })
      .then(() => {
        this.keycloak.getToken().then((token) => {
          this.authStoreService.addToken(token);
        });
      });
  }

  startAdfsAuth(config: IAuthBaseConfig) {
    const authConfig: AuthConfig = {
      issuer: config.url,
      redirectUri: window.location.href.replace('#', ''),
      silentRefreshRedirectUri: window.location.origin + '/assets/pages/silent-refresh.html',
      useSilentRefresh: true,
      silentRefreshTimeout: 5000,
      clientId: config.clientId,
      responseType: 'id_token token',
      scope: 'email openid profile',
      logoutUrl: config.logOffUrl,
    };
    this.oauthService.setStorage(localStorage);
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then((res) => {
      const hasToken = this.oauthService.hasValidAccessToken();
      const token: string = this.oauthService.getAccessToken();

      if (hasToken) {
        this.authStoreService.addToken(token);
        this.oauthService.setupAutomaticSilentRefresh();
      } else {
        this.oauthService.initCodeFlow();
      }
    });
  }

  addEmptyToken() {
    this.authStoreService.addToken('');
  }

  logoff() {
    this.authStoreQuery.getAuthConfig$.subscribe((config) => {
      this.oauthService.logOut();
      this.authStoreService.deleteToken();

      if (config.typeAuth == 'adfs') {
        window.location.href = config.oathSettings.adfs.logOffUrl;
      } else if (config.typeAuth == 'keycloak') {
        window.location.href = config.oathSettings.keycloak.logOffUrl;
      }
    });
  }
}
