import { DecimalPipe } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'float',
})
export class FloatPipe implements PipeTransform {
  constructor() {}

  transform(value: number | string | null | undefined, measureUnit?: string): string | null {
    // let digitsInfo = '';

    // if (value === undefined || value === null)
    //   return null;

    // let num: number = null;
    // // transform to number
    // if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) {
    //   num = Number(value);
    // }
    // if (typeof value === 'number') {
    //   num = value;
    // }

    let digitsInfo: string = null;

    if ((measureUnit && measureUnit[measureUnit.length - 1] === '3') || measureUnit === 'л') {
      // кончается на 3 - значит объем
      // res = num.toFixed(4);
      digitsInfo = '1.0-4';
    } else {
      // res = num.toFixed(2);
      digitsInfo = '1.0-2';
    }
    return new DecimalPipe(navigator.language).transform(value, digitsInfo);
  }
}

@NgModule({
  declarations: [FloatPipe],
  exports: [FloatPipe],
})
export class FloatPipeModule {}
