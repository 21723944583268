<dx-popup
  [width]="400"
  [height]="'auto'"
  [showTitle]="true"
  title="Комментарий"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="popupVisible"
>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="okButtonOptions"
  ></dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="closeButtonOptions"
  ></dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <dx-form
      id="form"
      [(formData)]="comment"
    ></dx-form>
  </div>
</dx-popup>
