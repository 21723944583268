import { Observable } from 'rxjs';

/**
 * @param fileUrl Complete URL to file
 * @param token Auth access token
 */
export function downloadFile(fileUrl: string, token: string) {
  let fileName = '';
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const headers = new Headers();
  headers.append('Authorization', 'Bearer ' + token);

  fetch(fileUrl, { headers })
    .then((response) => {
      fileName = decodeURI(
        response.headers
          .get('content-disposition')
          .split(';')
          .find((n) => n.includes('filename*='))
          .replace("filename*=UTF-8''", '')
          .trim()
      );
      return response.blob();
    })
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
}

/**
 * Функция чтения файла из файловой системы (для одного файла)
 * @param {File} file file
 */
export function uploadAsPromise(file: File) {
  const reader: FileReader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise<{ fileName: string; fileData: string }>((resolve, reject) => {
    reader.onload = () => {
      const fileData = reader.result.toString().split(',')[1];
      resolve({ fileName: file.name, fileData });
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}

/**
 * Функция чтения файлов из файловой системы (для нескольких файлов)
 * @param {File[]} files file
 */
export function uploadAsObservable(files: File[]): Observable<{ fileName: string; fileData: string }> {
  const reader: FileReader = new FileReader();

  return new Observable((observer) => {
    function readFile(idx: number) {
      if (idx >= files.length) {
        observer.complete();
        return;
      }
      const file = files[idx];
      reader.onload = (ev) => {
        const fileData = ev.target.result.toString().split(',')[1];
        observer.next({ fileName: file.name, fileData });
        readFile(idx + 1);
      };
      reader.readAsDataURL(file);
    }
    readFile(0);

    reader.onerror = (error) => {
      observer.error(error);
      observer.complete();
    };
  });
}
