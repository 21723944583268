import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Renderer2 } from '@angular/core';
import { from, map, Observable, of, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { mapToCleanISODate } from 'src/app/services';
import { uploadAsObservable } from 'projects/ui-components/src/lib/utils/utils';

export const statuses = {
  DECLARED: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  REQUESTED: 3,
  PASSED: 4,
  NOTPASSED: 5,
};

@Injectable({
  providedIn: 'root',
})
export class ActualWorkCompletionService {
  private _apiUrl: string;
  private _serviceUrl: string;
  private _serviceODataUrl: string;
  private _rejectReasonsServiceUrl: string;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private _cache: {
    allLogs: ActualWorkCompletion[];
  } = {
    allLogs: null,
  };

  private _rejectReasonsCache: IFactRejectReason[] = null;

  constructor(private _http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.actualWorkCompletionUrl;
    this._serviceODataUrl = environment.actualWorkCompletionODataUrl;
    this._rejectReasonsServiceUrl = environment.factRejectReasonsUrl;
  }

  public getAll(): Observable<ActualWorkCompletion[]> {
    if (this._cache.allLogs) {
      return of(this._cache.allLogs);
    }

    return this._http.get<ActualWorkCompletion[]>(this._apiUrl + this._serviceUrl + 'GetAll').pipe(
      tap((res) => {
        this._cache.allLogs = res;
      })
    );
  }

  public getODataUrl() {
    return this._apiUrl + this._serviceODataUrl + 'ActualWorkCompletionLogs';
  }

  public getWorksByWorkVolumeListId(id: string): Observable<any[]> {
    return this._http
      .get<{ value: any[] }>(this.getODataUrl(), { params: { $filter: `WorkVolumeListId eq ${id}` } })
      .pipe(map((res) => res.value));
  }

  public getAWCbyProjectId(id: string): Observable<ActualWorkCompletion[]> {
    return this._http
      .get<{ value: any[] }>(this.getODataUrl(), { params: { $filter: `ProjectId eq ${id}` } })
      .pipe(map((res) => res.value));
  }

  public getUpdateUrl() {
    return this._apiUrl + this._serviceUrl + 'Update';
  }

  public getById(Id: string): Observable<any> {
    return this._http.get(this._apiUrl + this._serviceUrl + `Get?id=${Id}`);
  }

  public getByWorkVolumeList(id: string): Observable<ActualWorkCompletion[]> {
    return this._http.get<ActualWorkCompletion[]>(
      this._apiUrl + this._serviceUrl + `GetByWorkVolumeList?workVolumeListId=${id}`
    );
  }

  public getByInspection(id: string): Observable<ActualWorkCompletion[]> {
    return this._http.get<ActualWorkCompletion[]>(
      this._apiUrl + this._serviceUrl + `getByInspection?inspectionId=${id}`
    );
  }

  public create(model: Creation): Observable<any> {
    let body = { ...model };
    body.ReportDate = mapToCleanISODate(body.ReportDate);

    return this._http.post(this._apiUrl + this._serviceUrl + `Create`, body, this._httpOptions).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public getAWCStatuses() {
    return [
      {
        value: statuses.DECLARED,
        title: $localize`Заявлен к приемке`,
      },
      {
        value: statuses.ACCEPTED,
        title: $localize`Подтвержден`,
      },
      {
        value: statuses.REJECTED,
        title: $localize`Отклонен`,
      },
      {
        value: statuses.REQUESTED,
        title: $localize`Запрошена инспекция`,
      },
      {
        value: statuses.PASSED,
        title: $localize`Инспекция пройдена`,
      },
      {
        value: statuses.NOTPASSED,
        title: $localize`Инспекция не пройдена`,
      },
    ];
  }

  public getAWCStatusText(status: number) {
    const res = this.getAWCStatuses().find((stat) => stat.value === status);
    return res ? res.title : '';
  }

  public AcceptPartial(model: AcceptingPartial): Observable<any> {
    let body = { ...model };
    return this._http.post(this._apiUrl + this._serviceUrl + `AcceptPartial`, body, this._httpOptions).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public update(model: Updating): Observable<any> {
    let body = { ...model };
    body.ReportDate = mapToCleanISODate(body.ReportDate);

    return this._http.post(this._apiUrl + this._serviceUrl + `Update`, body, this._httpOptions).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public AttachActualWorkCompletionLogToInspection(model: AttachAWCToInspection): Observable<any> {
    let body = { ...model };

    return this._http
      .post(this._apiUrl + this._serviceUrl + `AttachActualWorkCompletionLogToInspection`, body, this._httpOptions)
      .pipe(
        tap(() => {
          this._cache.allLogs = null;
        })
      );
  }

  public DeAttachActualWorkCompletionLogToInspection(model: AttachAWCToInspection): Observable<any> {
    let body = { ...model };

    return this._http
      .post(this._apiUrl + this._serviceUrl + `DeAttachActualWorkCompletionLogToInspection`, body, this._httpOptions)
      .pipe(
        tap(() => {
          this._cache.allLogs = null;
        })
      );
  }

  public AddActualWorkCompletionLogToInspection(model: AttachAWCToInspection): Observable<any> {
    let body = { ...model };

    return this._http
      .post(this._apiUrl + this._serviceUrl + `AddActualWorkCompletionLogToInspection`, body, this._httpOptions)
      .pipe(
        tap(() => {
          this._cache.allLogs = null;
        })
      );
  }

  public delete(id: string): Observable<any> {
    return this._http.delete(this._apiUrl + this._serviceUrl + `Delete?id=${id}`).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public accept(ids: string[]): Observable<any> {
    if (!ids.length) {
      return null;
    }

    return this._http
      .post(this._apiUrl + this._serviceUrl + 'Accept', {
        ActualWorkCompletionLogsIds: ids,
      })
      .pipe(
        tap(() => {
          this._cache.allLogs = null;
        })
      );
  }

  public ChangeStatusInspectionPassed(model: ChangingInspectionStatus): Observable<any> {
    let body = { ...model };
    return this._http.post(this._apiUrl + this._serviceUrl + 'ChangeStatusInspectionPassed', body).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public ChangeStatusInspectionNotPassedWorkAccepted(model: ChangingInspectionStatus): Observable<any> {
    let body = { ...model };
    return this._http.post(this._apiUrl + this._serviceUrl + 'ChangeStatusInspectionNotPassedWorkAccepted', body).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public ChangeStatusInspectionNotPassed(model: ChangingInspectionStatus): Observable<any> {
    let body = { ...model };
    return this._http.post(this._apiUrl + this._serviceUrl + 'ChangeStatusInspectionNotPassed', body).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public reject(model: Rejecting): Observable<any> {
    let body = { ...model };
    return this._http.post(this._apiUrl + this._serviceUrl + 'Reject', body).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public redeclare(id: string): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + 'ReDeclare', { Id: id }).pipe(
      tap(() => {
        this._cache.allLogs = null;
      })
    );
  }

  public changeResponsible(userId: string, workLogsIds: string[]): Observable<any> {
    return this._http
      .post(this._apiUrl + this._serviceUrl + 'UpdateDepartmentResponsible', {
        DepartmentResponsibleId: userId,
        ActualWorkCompletionLogsIds: workLogsIds,
      })
      .pipe(
        tap(() => {
          this._cache.allLogs = null;
        })
      );
  }

  public getIdListProjectsMyForCreateLogs() {
    return this._http.get(this._apiUrl + this._serviceUrl + 'GetProjectsMyForCreate').pipe(
      map((arr: any[]) => {
        let ids = [];
        arr.forEach((element) => {
          ids.push(element.Id);
        });
        return ids;
      })
    );
  }

  public getIdListProjectsMyForUpdateLogs() {
    return this._http.get(this._apiUrl + this._serviceUrl + 'GetProjectsMyForUpdate').pipe(
      map((arr: any) => {
        let ids = [];
        arr.forEach((element) => {
          ids.push(element.Id);
        });
        return ids;
      })
    );
  }

  public getFactRejectReasons() {
    if (this._rejectReasonsCache) {
      return of(this._rejectReasonsCache);
    }

    return this._http.get<IFactRejectReason[]>(this._apiUrl + this._rejectReasonsServiceUrl + 'GetAll').pipe(
      tap((res) => {
        this._rejectReasonsCache = res;
      })
    );
  }

  public getStatuses() {
    return [
      { Id: statuses.DECLARED, Title: 'Заявлен к приемке' },
      { Id: statuses.ACCEPTED, Title: 'Подтвержден' },
      { Id: statuses.REJECTED, Title: 'Отклонен' },
      { Id: statuses.REQUESTED, Title: 'Запрошена инспекция' },
      { Id: statuses.PASSED, Title: 'Инспекция пройдена' },
      { Id: statuses.NOTPASSED, Title: 'Инспекция не пройдена' },
    ];
  }

  public getStatus(statusId: number) {
    switch (statusId) {
      case statuses.DECLARED:
        return 'Заявлен к приемке';
      case statuses.ACCEPTED:
        return 'Подтвержден';
      case statuses.REJECTED:
        return 'Отклонен';
      case statuses.REQUESTED:
        return 'Запрошена инспекция';
      case statuses.PASSED:
        return 'Инспекция пройдена';
      case statuses.NOTPASSED:
        return 'Инспекция не пройдена';
      default:
        return 'Неизвестный статус';
    }
  }

  public getStatusColor(value: string | number) {
    value = Number(value);
    switch (value) {
      case statuses.DECLARED:
        return 'color-attention';
      case statuses.ACCEPTED:
        return 'color-base';
      case statuses.REJECTED:
        return 'color-lightGray';
      case statuses.NOTPASSED:
        return 'color-lightGray';
      case statuses.PASSED:
        return 'color-lightGray';
      case statuses.REQUESTED:
        return 'color-attention';
      case -1:
        return 'color-info';
      default:
        return 'color-lightGray';
    }
  }
}

export class Creation {
  WorkVolumeListId?: string;
  ReportDate: string;
  ActualVolume: number;
  Comment: string;
  ActualWorkCompletionLogFileAttachs: FileAttach[];
}

export class Updating {
  Id: string;
  ReportDate: string;
  ActualVolume: number;
  LastComment: string;
  ActualWorkCompletionLogFileAttachs: FileAttach[];
}

export class AcceptingPartial {
  ActualWorkCompletionLogId: string;
  AcceptPartialVolume: number;
  Comment: string;
  ActualWorkCompletionLogFileAttachs: FileAttach[];
}

export class Rejecting {
  Id: string;
  RejectReasonId: string;
  RejectReasonComment: string;
  ActualWorkCompletionLogFileAttachs: FileAttach[];
}

export class ChangingInspectionStatus {
  InspectionId: string;
  Comment: string;
  ActualWorkCompletionLogFileAttachs: FileAttach[];
}

export class FileAttach {
  FileName: string;
  File: string;
}

export class AttachAWCToInspection {
  InspectionId: string;
  InspectionTitle: string;
  InspectionDescription: string;
  ActualWorkCompletionLogIds: any[];
}

export class ActualWorkCompletion {
  //в составе массивов
  AcceptDate: string;
  AcceptedBy: string;
  AcceptedById: string;
  ActualVolume: string;
  BlueprintMark: string;
  BlueprintMarkId: string;
  Created: string;
  CreatedBy: string;
  Curator: string;
  CuratorId: string;
  Declared: string;
  DeclaredBy: string;
  DeclaredById: string;
  DepartmentId: string;
  DepartmentResponsible: string;
  DepartmentResponsibleId: string;
  DepartmentTitle: string;
  MeasureUnitId: string;
  MeasureUnitTitle: string;
  Id: string;
  Modified: string;
  ModifiedBy: string;
  ObjectStructureCode: string;
  ObjectStructureId: string;
  ObjectStructureTitle: string;

  Permissions_Accept?: boolean;
  Permissions_CreateActualWorkCompletionLog?: boolean;
  Permissions_Delete?: boolean;
  Permissions_ReDeclare?: boolean;
  Permissions_Reject?: boolean;
  Permissions_Update?: boolean;

  Permissions?: {
    Accept: boolean;
    CreateActualWorkCompletionLog: boolean;
    Delete: boolean;
    ReDeclare: boolean;
    Reject: boolean;
    Update: boolean;
  };

  ProjectCode: string;
  ProjectId: string;
  ProjectTitle: string;
  RejectDate: string;
  RejectReason: any;
  RejectReasonComment: string;
  RejectReasonId: string;
  RejectedBy: string;
  RejectedById: string;
  ReportDate: string;
  Status: number;
  WorkType: string;
  WorkTypeId: string;
  WorkVolumeListId: string;
  WorkVolumeListName: string;
  WorkVolumeListTitle: string;

  _currentActualWorkSum: number;
  _maxCurrentWorkVolume: number;
  _lastReportDate: string | Date;
  _statusTitle: string;
  _disabledDates: Date[] | string[];
}

interface IFactRejectReason {
  Id: string;
  Title: string;
  IsActive: boolean;
  IsDefault: boolean;
  Created: string;
  CreatedBy: string;
  Modified: string;
  ModifiedBy: string;
}
