import { Injectable } from '@angular/core';
import { RootStore } from './root.store';
import { IActiveModules, IUserProfile } from '@pp/interfaces';
import { IArchivePermission } from '../../../../../src/app/services/permission.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageConstants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class RootStoreService {
  constructor(
    private rootStore: RootStore,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // По-идее можно исходить из того, что projectId в рут сторе и в строке браузера - фул синхрон,
    // тогда они должны читать друг друга.
    // В некоторых компонентах projectId в строке браузера может не быть, но при этом он будет в сторе,
    // то есть источник истины здесь - стор. В таком случае стор не должен читать строку браузера, а за
    // отображение projectID в строке браузера отвечать будет локальный компонент
    // Ещё вариант - читать строку 1 раз при инициализации приложения, выставлять данные в приоритете поверх ЛС.
    // В дальнейшем, при этом, источником истины останется стор, а где и как показывать айдишники в строке
    // могут решать как компоненты, так и сам стор.
    // При этом кнопки навигации (например кнопка "назад" браузера) провоцируют навигацию с предыдущими параметрами в истории
    // Кейс:
    // 1. Юзер из реестра документов, в котором выбрана папка1, заходит в документ, который расположен в этой папке.
    // 2. Юзер перемещает документ в папку2
    // 3. Юзер нажимает кнопку браузера "назад"
    // Куда должна произойти навигация? В документы/папка2 или в документы/папка1? Оба варианта справедливы, зависит от требований бизнеса.
    // При этом навигация назад в реестр, но при этом в новую папку, подразумевает,
    // что подписки на папку в строке нет и строка просто отображает данные стора, а стор читает строку только 1 раз при инициализации для работоспособности ссылок
    // На данный момент стор читает данные из строки 1 раз(размазано по приложению), при этом показывает в строке данные после апдейта данных,
    // но до первой перенавигации без опции queryParamsHandling: 'merge'
  }

  updateRootProject(id: string) {
    this.rootStore.update({ rootProjectId: id });
    localStorage.setItem(LocalStorageConstants.ROOT.PROJECT, id);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { rootProject: id },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });

    this.updateSelectedCatalogId('');
  }

  updateSelectedCatalogId(id: string) {
    if (typeof id !== 'string') console.error('catalog Id should be string type');

    this.rootStore.update({ selectedCatalogId: id || null });

    if (id) {
      localStorage.setItem(LocalStorageConstants.ROOT.CATALOG, id);
    } else {
      localStorage.removeItem(LocalStorageConstants.ROOT.CATALOG);
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { selectedCatalogId: id || null },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  updateActiveModules(modules: IActiveModules[]) {
    this.rootStore.update({ activeModules: modules });
  }

  updateUserProfile(profile: IUserProfile) {
    this.rootStore.update({ userProfile: profile });
  }

  setContentLoaderState$(loading: boolean) {
    this.rootStore.update({ loaderOn: loading });
  }

  userPermissionArchive(perms: IArchivePermission) {
    this.rootStore.update((state) => ({
      userPermissions: { ...state.userPermissions, Archive: perms },
    }));
  }

  setUserPermissionArchiveForProject(perms) {
    this.rootStore.update((state) => ({
      userPermissions: { ...state.userPermissions, ArchiveForProject: perms },
    }));
  }
}
