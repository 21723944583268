<div class="heading" #heading>
  <h1 class="heading__title" i18n>Проекты</h1>
  <div class="heading__buttons">
    <pp-ui-buttons class="heading__buttons__btn" id="tilesViewButton"
      [options]="{
        stylingMode: 'text',
        icon: 'pp-icon_outlined_submenu'
      }"
      [isActive]="selectedView === 'tiles'"
      (onClick)="setView('tiles')"
    ></pp-ui-buttons>
    <pp-ui-buttons class="heading__buttons__btn" id="tableViewButton"
      [options]="{
        stylingMode: 'text',
        icon: 'pp-icon_outlined_menu'
      }"
      [isActive]="selectedView === 'table'"
      (onClick)="setView('table')"
    ></pp-ui-buttons>
  </div>
</div>

<dx-tooltip target="#tilesViewButton" showEvent="mouseenter" hideEvent="mouseleave">
  <div *dxTemplate="let tooltipData of 'content'" i18n>Плитка</div>
</dx-tooltip>
<dx-tooltip target="#tableViewButton" showEvent="mouseenter" hideEvent="mouseleave">
  <div *dxTemplate="let tooltipData of 'content'" i18n>Таблица</div>
</dx-tooltip>

<app-portal-core-tiles-view *ngIf="selectedView === 'tiles'"
  style="display: block"
  [ngStyle]="{'height': 'calc(100% - '+heading.offsetHeight+'px)'}"
></app-portal-core-tiles-view>
<app-portal-core-table-view *ngIf="selectedView === 'table'"
  style="display: block; border-top: 1px solid #e0e0e0;"
  [ngStyle]="{'height': 'calc(100% - '+heading.offsetHeight+'px)'}"
></app-portal-core-table-view>