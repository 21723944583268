import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '../environments/environment';
import { IApproveRoute, IApproveRouteSimple } from '@pp/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApproveRouteService {
  private _apiUrl: string;
  private _serviceUrl: string;

  constructor(private _http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.documentApproveRouteService;
  }

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAll() {
    return this._http.get<IApproveRoute[]>(this._apiUrl + this._serviceUrl + 'GetAll');
  }

  getById(id: string) {
    return this._http.get<IApproveRoute>(this._apiUrl + this._serviceUrl + 'Get?id=' + id).pipe(
      map((res) => {
        res.Stages.sort((a, b) => a.SortOrder - b.SortOrder);
        return res;
      })
    );
  }

  create(body: CreateApproveRouteBody) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Create', body, this._httpOptions);
  }

  update(body: CreateApproveRouteBody & { Id: string }) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'Update', body, this._httpOptions);
  }

  delete(id) {
    return this._http.delete(this._apiUrl + this._serviceUrl + 'Delete?id=' + id);
  }

  updateUser(body: UpdateUserBody) {
    return this._http.post(this._apiUrl + this._serviceUrl + 'UpdateUser', body);
  }
}

interface CreateApproveRouteBody {
  Id: string;
  ApproveStageName: string;
  IsActive: boolean;
  AdminId: string;
  Type: number;
  CoverLetter: string;
  CopyToUsers: { UserId: string }[];
  Stages: {
    SortOrder: number;
    Name: string;
    WorkingDaysDuration: number;
    AutoComplete: boolean;
    EndProcessIfRejected: boolean;
    Users: { UserId: string }[];
  }[];
  ApproverId: string;
  ApproveAutoComplete: boolean;
  ApproveWorkingDaysDuration: number;
  AllContentTypes: boolean;
  ContentTypeId: string;
  ProjectId: string;
  AllProjects: boolean;
  ObjectStructureId: string;
  AllObjectStructures: boolean;
  DisciplineId: string;
  AllDisciplines: boolean;
  BlueprintMarkId: string;
  AllBlueprintMarks: boolean;
  ReleaseTargetId: string;
  AllReleaseTargets: boolean;
  DeveloperId: string;
  AllDevelopers: boolean;
  CustomerId: string;
  AllCustomers: boolean;
}

export interface UpdateUserBody {
  Type: number;
  FromUserId: string;
  ToUserId: string;
  Admin?: boolean;
  StageUser?: boolean;
  Approver?: boolean;
  CopyToUser: boolean;
  StageNumber?: number;
}
