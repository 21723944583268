import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map, of, share } from 'rxjs';
import { IDocumentDTO, IDocumentOData, IProjectsMyForView, ITransmittalDocPermissions } from '@pp/interfaces';
import { IODataArchive } from 'projects/shared/interfaces/tmp/archive.odata';
import { downloadArchive, mapToCleanISODate } from 'src/app/services';
import { IArchivePermission } from 'src/app/services/permission.service';
import { IImportDocumentsError, ImportDocuments } from 'projects/archive/src/app/services/transmittal.service';
import { DownloadProgressService } from 'projects/ui-components/src/lib/download-progress/download-progress.service';
import { NotifierService } from '@pp/ui-components';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BackendError, handleError } from 'src/app/services/services-utils';
import { apiPathUrls } from 'src/environments/api.paths';
import { DownloadsService } from 'projects/ui-components/src/lib/downloads/downloads.service';
import { RootStoreService } from '@pp/root-store';

@Injectable({
  providedIn: 'root',
})
export class DocsService {
  private _apiUrl: string;
  private _serviceUrl: string;
  private _permissionsUrl: string;
  private _documentAccessUrl: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private pService: DownloadProgressService,
    private notifierService: NotifierService,
    private downloadsService: DownloadsService,
    private rootStoreService: RootStoreService
  ) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.documentService;
    this._documentAccessUrl = environment.documentAccessMatrixService;
    this._permissionsUrl = environment.DocsPermissionsService;
  }

  // Docs service

  getAll() {
    return this.http.get(this._apiUrl + this._serviceUrl + 'GetAll');
  }

  getDocumentsODataUrl() {
    return this._apiUrl + environment.documentServiceOData;
  }

  geDocumentsForSelection(allRevisions: boolean, projectId?: string) {
    // ?revisions -  0 - "Все ревизии" 1 - "Последняя ревизия"

    const params = { revisions: allRevisions ? 0 : 1 };

    if (projectId) params['$filter'] = `ProjectId eq ${projectId}`;

    return this.http
      .get<{ value: { Id; ParentId }[] }>(this._apiUrl + environment.documentForSelectionServiceOData, { params })
      .pipe(map((res) => res.value));
  }

  getDocumentNoPermissionsServiceODataUrl() {
    return this._apiUrl + environment.DocsNoPermissionsOData;
  }

  getDocumentNoPermissionsOData(filter?: string) {
    return this.http
      .get<{ value: IDocumentOData[] }>(this.getDocumentNoPermissionsServiceODataUrl() + filter)
      .pipe(map((res) => res.value));
  }

  getDocumentPermissions(docId: string) {
    return this.http.get<IDocumentItemPermissions>(
      this._apiUrl + environment.documentService + 'GetAllDtosItemPermissions',
      { params: { id: docId } }
    );
  }

  getTransmittalDocumentPermissions(docId: string) {
    return this.http.get<ITransmittalDocPermissions>(
      this._apiUrl + environment.documentService + 'GetAllDtosTransmittalItemPermissions',
      { params: { id: docId } }
    );
  }

  getById(id: string) {
    return this.http.get<IDocumentDTO>(this._apiUrl + this._serviceUrl + 'Get?id=' + id);
  }

  getDocumentFilesArchive(id: string, fileIds?: string[]) {
    downloadArchive(
      `${environment.apiUrl}${environment.documentService}GetArchiveAsync?id=${id}`,
      localStorage.access_token,
      null,
      fileIds
    );
  }

  getDocumentsFilesArchive(ids: string[]) {
    downloadArchive(
      `${environment.apiUrl}${environment.documentService}GetDocsArchiveAsync`,
      localStorage.access_token,
      ids
    );
  }

  getCatalogFilesArchive(catalogId: string) {
    downloadArchive(
      `${environment.apiUrl}${environment.documentService}GetCatalogArchiveAsync?catalogId=${catalogId}`,
      localStorage.access_token
    );
  }

  getDocumentFile(id: string) {
    const req = this.http.request<Blob>(
      new HttpRequest('GET', environment.apiUrl + environment.documentService + 'GetFile?id=' + id, {
        reportProgress: true,
        responseType: 'blob',
      })
    );
    this.downloadsService.download(req, $localize`документ`);
  }

  getPermissions() {
    return this.http
      .get<IArchivePermission>(this._apiUrl + this._permissionsUrl + 'GetCurrentUserCorePermissions')
      .pipe(
        map((res: IArchivePermission) => {
          this.rootStoreService.setContentLoaderState$(false);
          this.rootStoreService.userPermissionArchive(res);
          return res;
        })
      );
  }

  getByUserRoleInProject(id: string) {
    return this.http.get<{
      DocumentAccessMatrix: {
        Id: string;
        Name: string;
        IsActive: true;
      };
    }>(this._apiUrl + this._documentAccessUrl + 'GetByUserRoleInProject?userRoleInProjectId=' + id);
  }

  updateUserRoleInProject(body) {
    return this.http.post(this._apiUrl + this._documentAccessUrl + 'UpdateUserRoleInProject', body);
  }

  getProjectsMyForView() {
    return this.http.get<IProjectsMyForView[]>(this._apiUrl + environment.documentService + 'GetProjectsMyForView');
  }

  create(body) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'Create', body);
  }

  update(body) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'Update', body);
  }

  delete(id: string) {
    return this.http.delete(this._apiUrl + this._serviceUrl + 'Delete?id=' + id);
  }

  createInDocumentGroup(body: CreateInDocumentGroupBody) {
    if (body.Date) body.Date = mapToCleanISODate(body.Date);

    return this.http.post<{
      Id: string;
      ApproveId: string;
      ApproveRouteId: string;
      ApproveStartForbidden: boolean;
    }>(environment.apiUrl + environment.documentService + 'CreateInDocumentGroup', body);
  }

  moveDocToNewFolder(docId: string, catalogId: string) {
    return this.http.post(environment.apiUrl + environment.documentService + 'Move', {
      DocumentId: docId,
      CatalogId: catalogId,
    });
  }

  moveDocMultiple(docIds: string[], catalogId: string) {
    return this.http.post<{
      SuccessfulIds: string[];
      UnsuccessfulIds: string[];
    }>(environment.apiUrl + environment.documentService + 'MoveMultiple', {
      DocumentIds: docIds,
      CatalogId: catalogId,
    });
  }

  createWithFilesInDocumentGroup(body: CreateInDocumentGroupBody & { Files: { FileName: string; File: string }[] }) {
    if (body.Date) body.Date = mapToCleanISODate(body.Date);

    return this.http.post<{
      Id: string;
      ApproveId: string;
      ApproveRouteId: string;
      ApproveStartForbidden: boolean;
    }>(environment.apiUrl + environment.documentService + 'CreateWithFilesInDocumentGroup', body);
  }

  makeODataRequest(filter: string = '') {
    return this.http.get<{ value: IODataArchive[] }>(this.getDocumentNoPermissionsServiceODataUrl() + `?${filter}`);
  }

  makeODataCountRequest(filter: string = '', transmittalId?: string) {
    return this.http
      .get(this.getDocumentNoPermissionsServiceODataUrl() + `${filter}?transmittalId=${transmittalId}&$count=true`)
      .pipe(
        map((res) => {
          if (res['@odata.count']) {
            return true;
          } else {
            return false;
          }
        })
      );
  }

  docsObservablesMap: { [docGrpId: string]: Observable<number> } = {};
  getDocGroupDocsCount(docGrpId) {
    if (this.docsObservablesMap[docGrpId]) {
      return this.docsObservablesMap[docGrpId];
    } else {
      this.docsObservablesMap[docGrpId] = this.http
        .get<number>(this.getDocumentNoPermissionsServiceODataUrl() + `/$count?filter=(DocumentGroupId eq ${docGrpId})`)
        .pipe(share());
      return this.docsObservablesMap[docGrpId];
    }
  }

  checkIsApproveInDocGroup(docGrpId) {
    return this.http
      .get<number>(
        this.getDocumentNoPermissionsServiceODataUrl() +
          `/$count?filter=(DocumentGroupId eq ${docGrpId}) and (ApproveId ne null)`
      )
      .pipe(
        map((res) => {
          return res > 0;
        })
      );
  }

  getImportFileTemplate(id: string) {
    const req = this.http.request<Blob>(
      new HttpRequest(
        'GET',
        environment.apiUrl + environment.documentService + 'GetImportFileTemplate?projectId=' + id,
        {
          reportProgress: true,
          responseType: 'blob',
        }
      )
    );
    this.downloadsService.download(req, $localize`шаблон`);
  }

  checkImport = (body: ImportDocuments): Observable<IImportDocumentsError[]> => {
    return this.http
      .post<{ Errors: IImportDocumentsError[] }>(this._apiUrl + this._serviceUrl + 'CheckImport', body)
      .pipe(
        map((res) => {
          return res ? res.Errors : [];
        })
      );
  };

  import = (body: ImportDocuments): Observable<void> => {
    return this.http.post<void>(this._apiUrl + this._serviceUrl + 'Import', body);
  };

  // Document Service

  getDocument(id: string) {
    return this.http.get<IDocumentDTO>(environment.apiUrl + environment.documentService + 'Get?id=' + id);
  }

  getFiles(id: string) {
    return this.http.get(environment.apiUrl + environment.documentService + 'GetFiles?id=' + id);
  }

  createDocument(document: CreateDocumentRequestBody) {
    return this.http.post<{ Id: string }>(
      environment.apiUrl + environment.documentService + 'Create',
      document,
      this.httpOptions
    );
  }

  createDocumentWithFiles(document: CreateDocumentRequestBody, files: { FileName; File }[]) {
    let body = { ...document, Files: files };
    if (body.RevisionReleasePlanDate)
      body.RevisionReleasePlanDate = mapToCleanISODate(body.RevisionReleasePlanDate) as any;
    if (body.ApprovedPlanDate) body.ApprovedPlanDate = mapToCleanISODate(body.ApprovedPlanDate) as any;
    if (body.Date) body.Date = mapToCleanISODate(body.Date) as any;
    if (body.WorkProductionDate) body.WorkProductionDate = mapToCleanISODate(body.WorkProductionDate) as any;

    return this.http.post<{ Id: string }>(
      environment.apiUrl + environment.documentService + 'CreateWithFiles',
      body,
      this.httpOptions
    );
  }

  updateDocument(document: UpdateDocumentRequestBody) {
    let body = { ...document };
    if (body.RevisionReleasePlanDate)
      body.RevisionReleasePlanDate = mapToCleanISODate(body.RevisionReleasePlanDate) as any;
    if (body.ApprovedPlanDate) body.ApprovedPlanDate = mapToCleanISODate(body.ApprovedPlanDate) as any;
    if (body.Date) body.Date = mapToCleanISODate(body.Date) as any;

    return this.http.post<{ Id: string }>(
      environment.apiUrl + environment.documentService + 'Update',
      body,
      this.httpOptions
    );
  }

  getDocumentFiles(id: string) {
    return this.http.get(environment.apiUrl + environment.documentService + 'GetFiles?id=' + id);
  }

  getFileUrl(fileId: string) {
    return environment.apiUrl + environment.documentService + 'GetFile?id=' + fileId;
  }

  async addFilesToDocument(
    files: File[],
    DocumentId: string,
    type: number,
    typeObjectId: string,
    onSuccess?: () => void,
    onError?: () => void,
    onCancel?: () => void
  ): Promise<Observable<string>> {
    if (files.length === 0) {
      if (onSuccess) {
        onSuccess();
      }
      return of(null);
    }

    let hubConnection: HubConnection;

    try {
      hubConnection = new HubConnectionBuilder()
        .withUrl(environment.apiUrl + apiPathUrls.Archive + 'SignalR/Hubs/Documents', { withCredentials: false })
        .build();
      await hubConnection.start();
    } catch (err) {
      this.pService.setCurrentText('');
      handleError(new BackendError(err));
    }

    hubConnection.on(
      'UpdateDocumentFileUploadProgressPercent',
      (allFIlesPercent: number, fileId: string, filePercentsComplete: number) => {
        // console.log(allFIlesPercent, fileId, filePercentsComplete);

        this.pService.setCurrentProgress(allFIlesPercent.toFixed(1));
        this.pService.setCurrentText(
          $localize`Загрузка файла ` + files[fileId].name + ' ' + filePercentsComplete.toFixed(1) + '%'
        );
      }
    );

    hubConnection.on('CompleteDocumentFileUpload', (isSuccess, errMsg) => {
      console.log('Completed event raised!');
      this.pService.setCurrentText('');
      if (isSuccess) {
        if (onSuccess) {
          onSuccess();
        }
      } else {
        if (errMsg === 'Unexpected end of request content.') {
          if (onCancel) {
            onCancel();
          }
          this.notifierService.notify($localize`Загрузка была отменена. Файлы не загрузились.`, 'info');
        } else {
          if (onError) {
            onError();
          }
          this.notifierService.notify($localize`Ошибка загрузки. Файлы не загрузились.`, 'error');
        }
      }

      hubConnection.stop();
    });

    const filesArr: File[] = [];

    for (let i = 0; i < files.length; i++) {
      filesArr.push(files[i]);
    }

    let params = {
      documentId: DocumentId,
      signalRConnectionId: hubConnection.connectionId,
      filesLength: filesArr.reduce((acc, file) => acc + file.size, 0),
      type,
      typeObjectId,
    };

    if (params.typeObjectId === null) {
      delete params.typeObjectId;
    }

    const formData = new FormData();
    filesArr.forEach((file, idx) => {
      // Шаблон прикрепления файлов - file_1_4000 (file_{айди}_{размер})
      formData.set(`file_${idx}_${file.size}`, file, file.name);
    });

    return this.http.post<string>(environment.apiUrl + environment.documentService + 'AddFilesStream', formData, {
      params,
    });
  }

  removeFile(fileId) {
    return this.http.delete(environment.apiUrl + environment.documentService + 'RemoveFile?id=' + fileId);
  }

  removeFileGroup(Id: string) {
    return this.http.delete(environment.apiUrl + environment.documentService + 'RemoveFileGroup?id=' + Id);
  }

  getNextOrderNumber(data: { ProjectId: string; ContentTypeId: string }) {
    return this.http.post<{
      OrderNumber: number;
      CorrelationId: string;
    }>(environment.apiUrl + environment.documentService + 'GetNextOrderNumber', data, this.httpOptions);
  }

  registerOrderNumber(data: {
    OrderNumber: number;
    ProjectId: string;
    ContentTypeId: string;
    DocumentId: string; // Используется в обязательном порядке при обновлении документа для служебных целей, поэтому там его указание обязательно, иначе полученный ИД корреляции будет считаться "не найденным" при обновлении документа
  }) {
    return this.http.post<{
      OrderNumber: number;
      CorrelationId: string;
      IsUsedByOtherDocuments: boolean;
      CanUpdateOrderNumbers: boolean;
    }>(environment.apiUrl + environment.documentService + 'RegisterOrderNumber', data, this.httpOptions);
  }

  canUpdateNumberByProjectId(projectId: string) {
    return this.http.get<boolean>(
      environment.apiUrl + environment.documentService + 'CanUpdateDocumentNumberByDocumentNumerationRuleForProject',
      { params: { projectId } }
    );
  }

  canUpdateNumberByDocumentId(documentId: string) {
    return this.http.get<boolean>(
      environment.apiUrl + environment.documentService + 'CanUpdateDocumentNumberByDocumentNumerationRuleForDocument',
      { params: { documentId } }
    );
  }

  getFileBlobUrl(fileId: string) {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    return fetch(environment.apiUrl + environment.documentService + 'GetFile?id=' + fileId, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby);
        return objectUrl;
      });
  }

  getFileBlobUrlWithName(fileId: string) {
    let fileName = '';

    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    return fetch(environment.apiUrl + environment.documentService + 'GetFile?id=' + fileId, { headers })
      .then((response) => {
        fileName = decodeURI(
          response.headers
            .get('content-disposition')
            .split(';')
            .find((n) => n.includes('filename*='))
            .replace("filename*=UTF-8''", '')
            .trim()
        );
        return response.blob();
      })
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby);
        return { objectUrl, fileName };
      });
  }
}

interface DocumentRequestBasicBody {
  Name: string;
  Number: string;
  Description: string;
  Date: string;
  ChangeNumber: string;
  RevisionReleasePlanDate: string;
  ApprovedPlanDate: string;
  ObjectStructureId: string;
  DisciplineId: string;
  ReleaseTargetId: string;
  RevisionId: string;
  BlueprintMarkId: string;
  ContentTypeId: string;
  CustomerId: string;
  DeveloperId: string;
  WorkProductionDate: string;
  OrderNumber: number;
  OrderNumberCorrelationId: string;
}
type CreateDocumentRequestBody = DocumentRequestBasicBody & {
  CatalogId: string;
  ProjectId: string;
  TransmittalId: string;
};
type UpdateDocumentRequestBody = DocumentRequestBasicBody & { Id: string };

export interface CreateInDocumentGroupBody {
  DocumentId: string;
  Name: string;
  Number: string;
  Description: string;
  Date: string;
  ChangeNumber: string;
  ReleaseTargetId: string;
  RevisionId: string;
  OrderNumber: number;
  OrderNumberCorrelationId: string;
  IsNeedToStartApproveByRoute: boolean;
}

export interface IDocumentItemPermissions {
  StartWorksProduction: boolean;
  Download: boolean;
  CreateDocumentInDocumentGroup: boolean;
  Update: boolean;
  Delete: boolean;
  Move: boolean;
  DeleteFile: boolean;
  DeleteFileGroup: boolean;
  StartApprovalByRoute: boolean;
  StartApprovalManual: boolean;
}

export interface ICheckImportError {
  RowNumber: number;
  Field: number;
  Type: number;
  Text: string;
}
