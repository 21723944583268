import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { downloadFileAwait, makeLocalDate, User } from 'src/app/services';
import { Approve, ApprovePermissions } from 'projects/shared/entities/approve';
import { environment } from 'src/environments/environment';
import { DocumentApprove, IStartManualApprove } from '@pp/interfaces';

@Injectable({
  providedIn: 'root',
})
export class DocApproveService {
  private _apiUrl: string;
  private _serviceUrl: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.DocsApproveService;
  }

  get(id: string) {
    return this.http
      .get<{
        Item: Approve;
        Permissions: ApprovePermissions;
      }>(this._apiUrl + this._serviceUrl + `Get?id=${id}`)
      .pipe(
        map((res) => {
          const viewItem = res.Item;
          viewItem.Created = makeLocalDate(viewItem.Created);
          viewItem.Modified = makeLocalDate(viewItem.Modified);
          viewItem.PlanCompletedDate = makeLocalDate(viewItem.PlanCompletedDate);

          viewItem.Tasks.sort((a, b) => a.StageSortOrder - b.StageSortOrder);

          viewItem.Tasks.forEach((task) => {
            task.Created = makeLocalDate(task.Created);
            task.Modified = makeLocalDate(task.Modified);
            task.CompletedDate = task.CompletedDate && makeLocalDate(task.CompletedDate);
            task.PlanCompletedDate = makeLocalDate(task.PlanCompletedDate);
            task.RunningStartedDate = makeLocalDate(task.RunningStartedDate);
          });

          viewItem.RelatedApproves.forEach((approve) => {
            approve.Created = makeLocalDate(approve.Created);
            approve.Modified = makeLocalDate(approve.Modified);
            approve.CompletedDate = makeLocalDate(approve.CompletedDate);
          });

          viewItem.Document.Files.forEach((file) => {
            file.Created = makeLocalDate(file.Created) as string;
            file.Modified = makeLocalDate(file.Modified) as string;
          });

          viewItem.Histories.forEach((historyItem) => {
            historyItem.Created = makeLocalDate(historyItem.Created);
          });

          viewItem.Stages.sort((a, b) => a.SortOrder - b.SortOrder);

          return res;
        })
      );
  }

  update(body: {
    Id: string;
    AdminId: string;
    CopyToUsers: { UserId: string }[];
    Stages: {
      Id: string;
      SortOrder: number;
      Name: string;
      WorkingDaysDuration: number;
      AutoComplete: boolean;
      EndProcessIfRejected: boolean;
      Users: { UserId: string }[];
    }[];
    ApproverId: string;
    ApproveAutoComplete: boolean;
    ApproveWorkingDaysDuration: number;
    ApproveStageName: string;
  }) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'Update', body, this.httpOptions);
  }

  async print(id: string) {
    return downloadFileAwait(this._apiUrl + this._serviceUrl + '/Print?id=' + id, localStorage.getItem('access_token'));
  }

  cancel(id: string) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'Cancel', { Id: id });
  }

  getRoutes(documentId: string, isNeedToSendDocumentApproveRoutesDuplicatedNotification: boolean = true) {
    return this.http
      .post<DocumentApprove.IApproveRoutes>(this._apiUrl + this._serviceUrl + 'GetRoutes', {
        DocumentId: documentId,
        IsNeedToSendDocumentApproveRoutesDuplicatedNotification:
          isNeedToSendDocumentApproveRoutesDuplicatedNotification,
      })
      .pipe(
        map((res) => {
          res.Routes.forEach((route) => {
            route.Stages.sort((a, b) => a.SortOrder - b.SortOrder);
          });
          return res;
        })
      );
  }

  startManualCheck(body: { SiteId: string; WebId: string; ListId: string; ListItemId: number }) {
    return this.http.post<{
      CanStart: boolean;
      IsHasRunningApprove: boolean;
      RunningApproveId: string;
      RouteSearchResult: number;
      RoutesIds: string[];
      Route: {
        Id: string;
        ApproveStageName: string;
        IsActive: boolean;
        Admin: User;
        AdminId: string;
        Type: 0;
        CoverLetter: string;
        CopyToUsers: User[];
        Stages: {
          Id: string;
          ApproveTemplateId: string;
          SortOrder: 0;
          Name: string;
          WorkingDaysDuration: 0;
          AutoComplete: boolean;
          EndProcessIfRejected: boolean;
          Users: {
            StageTemplateId: string;
            User: User;
            UserId: string;
          }[];
        }[];
        Approver: User;
        ApproverId: string;
        ApproveAutoComplete: boolean;
        ApproveWorkingDaysDuration: 0;
        ContentType: {
          Id: string;
          Name: string;
        };
        ContentTypeId: string;
        AllContentTypes: boolean;
        Project: {
          Id: string;
          Name: string;
          Code: string;
        };
        ProjectId: string;
        AllProjects: boolean;
        ObjectStructure: {
          Id: string;
          Name: string;
          Code: string;
        };
        ObjectStructureId: string;
        AllObjectStructures: boolean;
        WorkType: {
          Id: string;
          Name: string;
        };
        WorkTypeId: string;
        AllWorkTypes: boolean;
        BlueprintMark: {
          Id: string;
          Name: string;
        };
        BlueprintMarkId: string;
        AllBlueprintMarks: boolean;
        DocumentStatus: {
          Id: string;
          Name: string;
          Code: string;
        };
        DocumentStatusId: string;
        AllDocumentStatuses: boolean;
        Department: {
          Id: string;
          ShortName: string;
          FullName: string;
          Created: string;
          CreatedById: string;
          Modified: string;
          ModifiedById: string;
        };
        DepartmentId: string;
        AllDepartments: boolean;
        Revision: string;
        AllRevision: boolean;
      };
      IsDataValid: boolean;
      IsProjectValid: boolean;
      IsDocInfoValid: boolean;
      ProjectId: string;
      IsHasRights: boolean;
      Data: {
        ListItem: {
          SiteId: string;
          WebId: string;
          WebAbsoluteUrl: string;
          ListId: string;
          ListItemId: number;
          ListItemContentTypeName: string;
          ListItemContentTypeId: string;
          ProjectId: string;
          ObjectStructureId: string;
          BlueprintMarkId: string;
          WorkTypeId: string;
          CustomerId: string;
          DeveloperId: string;
          DocumentStatusId: string;
          Title: string;
          FileLeafRef: string;
          NumberChange: string;
          SPCreatedBy: string;
        };
        MDRItem: {
          SiteId: string;
          WebId: string;
          ListId: string;
          ListItemId: number;
          ProjectId: string;
          ObjectStructureId: string;
          BlueprintMarkId: string;
          WorkTypeId: string;
          CustomerId: string;
          DeveloperId: string;
          DocumentStatusId: string;
          NameSet: string;
          Title: string;
          TypeDoc: string;
          TypeDocContentTypeId: string;
        };
        SPDocumentRevision: {
          Title: string;
          Number: string;
          ProjectId: string;
          ObjectStructureId: string;
          SiteId: string;
          WebId: string;
          ListId: string;
          ListItemId: number;
          BlueprintMarkId: string;
          WorkTypeId: string;
          ContentType: {
            Id: string;
            Name: string;
          };
          ContentTypeId: string;
          CustomerId: string;
          DeveloperId: string;
          Revision: string;
          SPCreatedBy: string;
        };
        SPDocument: {
          MDRName: string;
          MDRSiteId: string;
          MDRWebId: string;
          MDRListId: string;
          MDRListItemId: number;
          DocContentTypeId: string;
          DocName: string;
          DocSiteId: string;
          DocWebId: string;
          DocListId: string;
          DocListItemId: number;
        };
      };
    }>(this._apiUrl + this._serviceUrl + 'StartManualCheck', body, this.httpOptions);
  }

  startManual(body: IStartManualApprove) {
    return this.http.post<{ ApproveId: string }>(
      this._apiUrl + this._serviceUrl + 'StartManual',
      body,
      this.httpOptions
    );
  }
  startByRoute(docId: string) {
    return this.http.post<{
      ApproveId: string;
      RouteId: string;
    }>(this._apiUrl + this._serviceUrl + 'StartByRoute', { DocumentId: docId }, this.httpOptions);
  }

  moveToApproveStage(id: string) {
    return this.http.post(this._apiUrl + this._serviceUrl + 'MoveToApproveStage', { Id: id });
  }
}
