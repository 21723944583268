import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, Observable, of } from 'rxjs';
import { makeLocalDate, User } from '../../../../../src/app/services';
import { DepartmentCreateDto, ResponceWithId } from './organizations.interface';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private _apiUrl: string;
  private _serviceUrl: string;
  private _serviceUrlContractTypes: string;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private _http: HttpClient) {
    this._apiUrl = environment.apiUrl;
    this._serviceUrl = environment.departmentsUrl;
    this._serviceUrlContractTypes = environment.contractTypeUrl;
  }

  _cache: { all; withUsers } = null;

  public getAll() {
    if (this._cache && this._cache.all) {
      return of(this._cache.all);
    }
    return this._http.get<{ CanEdit: boolean; Items: Department[] }>(this._apiUrl + this._serviceUrl + 'GetAll').pipe(
      map((res) => {
        for (const item of res.Items) {
          item.Created = makeLocalDate(item.Created);
          item.Modified = makeLocalDate(item.Modified);
        }
        this._cache = { ...this._cache, all: res };
        return res;
      })
    );
  }

  public getAllWithUsers() {
    if (this._cache && this._cache.withUsers) {
      return of(this._cache.withUsers);
    }
    return this._http
      .get<{ CanEdit: boolean; Items: Department[] }>(this._apiUrl + this._serviceUrl + 'GetAllWithUsers')
      .pipe(
        map((res) => {
          for (const item of res.Items) {
            item.Created = makeLocalDate(item.Created);
            item.Modified = makeLocalDate(item.Modified);
          }
          this._cache = { ...this._cache, withUsers: res };
          return res;
        })
      );
  }

  public getLogoBlob(departmentId: string) {
    return this.configGetImageFastMethod('GetLogoFileFast')(departmentId);
  }

  private configGetImageFastMethod(subUrl: string) {
    return (departmentId) => {
      return this._http.get(this._apiUrl + this._serviceUrl + subUrl, {
        params: { departmentId },
        responseType: 'blob',
      });
    };
  }

  public getLogoFastUrl(id: string) {
    return this._apiUrl + this._serviceUrl + 'GetLogoFileFast' + '?departmentId=' + id;
  }

  public get(id) {
    return this._http.get(this._apiUrl + this._serviceUrl + 'Get?Id=' + id);
  }

  public edit(body) {
    this._cache = null;
    if (body.Created) {
      delete body.Created;
    }
    if (body.Modified) {
      delete body.Modified;
    }

    return this._http.post(this._apiUrl + this._serviceUrl + 'Update', body, this._httpOptions);
  }

  public create(body: DepartmentCreateDto): Observable<ResponceWithId> {
    this._cache = null;
    return this._http.post<ResponceWithId>(this._apiUrl + this._serviceUrl + 'Create', body, this._httpOptions);
  }

  public delete(body) {
    this._cache = null;
    return this._http.delete(this._apiUrl + this._serviceUrl + 'Delete?Id=' + body);
  }

  public getAllContractTypes() {
    return this._http.get(this._apiUrl + this._serviceUrlContractTypes + 'GetAll');
  }

  public getAllContractType(id) {
    return this._http.get(this._apiUrl + this._serviceUrlContractTypes + 'Get?Id=' + id);
  }

  public saveUsers(users) {
    this._cache = null;
    return this._http.post(this._apiUrl + this._serviceUrl + 'SaveUsers', users, this._httpOptions);
  }

  public getUsersAsResponsible(id) {
    return this._http.get(this._apiUrl + this._serviceUrl + 'GetUsersAsResponsible?Id=' + id);
  }

  public getUserByDepartmentId(id) {
    return this._http.get<User[]>(this._apiUrl + this._serviceUrl + 'GetUserByDepartmentId?Id=' + id);
  }
}

export class Department {
  Address: string;
  Code: string;
  Created: string | Date;
  CreatedBy: User;
  CreatedById: string;
  Email: string;
  FullName: string;
  INN: number;
  Id: string;
  IsActive: boolean;
  KPP: string;
  Modified: string | Date;
  ModifiedBy: User;
  ModifiedById: string;
  OGRN: number;
  Order: number;
  Phone: string;
  PostAddress: string;
  Site: string;
  Type: {
    Id: string;
    Order: number;
    Title: string;
  };
  Logo: {
    Id: string;
    Name: string;
    Size: number;
    ProjectId: string;
    CreatedBy: User;
    CreatedById: string;
    ModifiedBy: User;
    ModifiedById: string;
    Created: string;
    Modified: string;
  };
  TypeId: string;
  Users: User[];
  LogoId: string;
  LogoFileName: string;
  LogoFileContent: string;
  IsLogoFileDeleted: boolean;

  constructor(department?: Department) {
    this.IsActive = true;

    if (department) {
      this.Id = department.Id;
      this.Code = department.Code;
      this.FullName = department.FullName;
      this.Type = department.Type;
      this.TypeId = department.TypeId;
      this.IsActive = department.IsActive;
      this.INN = department.INN;
      this.KPP = department.KPP;
      this.OGRN = department.OGRN;
      this.Address = department.Address;
      this.PostAddress = department.PostAddress;
      this.Email = department.Email;
      this.Phone = department.Phone;
      this.Site = department.Site;
      this.Order = department.Order;
      this.Users = department.Users;
      this.Created = department.Created;
      this.Modified = department.Modified;
    }
  }
}
