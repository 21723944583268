import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

let nextId: number = 0;

@Component({
  selector: 'pp-ui-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfo implements OnChanges {
  // id инстанса компонента для корректной настройки target атрибута
  id: number;

  @Input() user: IPPUserInfoData = null;
  @Input() userId: string = null;

  displayedUser: IPPUserInfoData = null;

  @Input() textSize: number = null;
  @Input() iconSize: number = 15;
  @Input() iconColor: string = '#a3a3a3';

  @Input() showTooltipDelay: number = null;
  @Input() hideTooltipDelay: number = null;

  @Input() getUser: (userId: string) => Observable<IPPUserInfoData> = null;

  tooltipVisible: boolean = false;

  constructor() {
    this.id = nextId++;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      this.displayedUser = { ...changes.user.currentValue };
    } else if (changes.userId && this.getUser) {
      this.getUser(changes.userId.currentValue).subscribe((user: IPPUserInfoData) => {
        this.displayedUser = user;
      });
    }
  }
}

export interface IPPUserInfoData {
  Company: string;
  DisplayName: string;
  Position: string;
}
