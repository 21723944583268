import { Component, ElementRef, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { DateTime } from '@pp/ui-components';
import { DxTreeListComponent, DxTreeViewComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { Column } from 'devextreme/ui/tree_list';
import { ProjectStructuresService } from 'src/app/services/project-structures.service';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-portal-core-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
})
export class TableViewComponent {
  @ViewChildren('securedImg') imgs: QueryList<ElementRef> = new QueryList();
  @ViewChild(DxTreeViewComponent, { static: false }) tree!: DxTreeViewComponent;

  @ViewChild('noDataTemplate') noDataTemplateRef: TemplateRef<HTMLElement>;

  @ViewChild('treeList', { static: false }) treeList!: DxTreeListComponent;

  dataSource: DataSource;
  // options: Options | any;
  columns: Column[] = [];

  treeListStorageKey = this.constructor.name + '_mainpage_projects_props';

  constructor(
    private projectStructuresService: ProjectStructuresService,
    public hostRef: ElementRef,
    private projectsService: ProjectsService,
    private renderer: Renderer2
  ) {
    this.columns = [
      {
        caption: $localize`Наименование проекта`,
        dataField: 'Name',
        cellTemplate: 'titleCellTemplate',
        sortIndex: 0,
        sortOrder: 'asc',
        showInColumnChooser: false,
        fixed: true,
        fixedPosition: 'left',
        width: 320,
      },
      {
        caption: $localize`Код проекта`,
        dataField: 'Code',
        width: 130,
      },
      {
        caption: $localize`Изображение`,
        dataField: 'ImageId',
        cellTemplate: 'imageCellTemplate',
        calculateCellValue: (rowData) => !!rowData.ImageId,
        dataType: 'boolean',
        trueText: 'Есть',
        width: 150,
        allowSearch: false,
      },
      {
        caption: $localize`Локация`,
        dataField: 'Location',
        width: 130,
      },
      {
        caption: $localize`Тип проекта`,
        dataField: 'Type',
        width: 130,
      },
      {
        caption: $localize`Масштаб`,
        dataField: 'Scale',
        width: 130,
        allowSearch: false,
      },
      {
        caption: $localize`Приоритет`,
        dataField: 'Priority',
        width: 130,
        allowSearch: false,
      },
      {
        caption: $localize`Стадия`,
        dataField: 'Stage',
        width: 130,
      },
      {
        caption: $localize`Дата начала`,
        dataField: 'StartDate',
        cellTemplate: (el, item) => {
          el.textContent = new DateTime(item.data.StartDate, true).getDate();
        },
        width: 130,
        allowSearch: false,
      },
      {
        caption: $localize`Дата завершения`,
        dataField: 'EndDate',
        cellTemplate: (el, item) => {
          el.textContent = new DateTime(item.data.EndDate, true).getDate();
        },
        width: 130,
        allowSearch: false,
      },
      {
        caption: $localize`Руководитель проекта`,
        dataField: 'Owner',
        width: 145,
      },
      {
        caption: $localize`Активен`,
        dataField: 'IsActive',
        cellTemplate: 'isActiveCellTemplate',
        width: 130,
        allowSearch: false,
      },
      {
        caption: $localize`Логотип`,
        dataField: 'LogoId',
        visible: false,
        cellTemplate: 'logoCellTemplate',
        calculateCellValue: (rowData) => !!rowData.LogoId,
        dataType: 'boolean',
        trueText: 'Есть',
        width: 150,
        allowSearch: false,
      },
      {
        caption: $localize`Полное наименование проекта`,
        dataField: 'FullTitle',
        visible: false,
        width: 145,
      },
      {
        caption: $localize`Корневой объект строительства`,
        dataField: 'ObjectStructureTitle', // ObjectStructureCode
        visible: false,
        width: 145,
        allowSearch: false,
      },
      {
        caption: $localize`Включен в структуру`,
        dataField: 'ParentName',
        visible: false,
        width: 130,
        allowSearch: false,
      },
      {
        caption: $localize`Примечание`,
        dataField: 'AdditionalInformation',
        visible: false,
        width: 130,
      },
      {
        caption: $localize`Кем создан`,
        dataField: 'CreatedBy',
        visible: false,
        width: 145,
      },
      {
        caption: $localize`Создан`,
        dataField: 'Created',
        visible: false,
        cellTemplate: (el, item) => {
          el.textContent = new DateTime(item.data.Created, true).getDateAndTime();
        },
        width: 100,
        allowSearch: false,
      },
      {
        caption: $localize`Кем изменен`,
        dataField: 'ModifiedBy',
        visible: false,
        width: 145,
      },
      {
        caption: $localize`Изменен`,
        dataField: 'Modified',
        visible: false,
        cellTemplate: (el, item) => {
          el.textContent = new DateTime(item.data.Modified, true).getDateAndTime();
        },
        width: 100,
        allowSearch: false,
      },
    ];

    // this.dataSource = new DataSource({
    //   store: new CustomStore({
    //     key: 'Id',
    //     loadMode: 'raw',
    //     load: () => {
    //       return lastValueFrom(this.projectStructuresService.getStructuesForTable())
    //     }
    //   })
    // })
    this.dataSource = new DataSource({
      store: new ODataStore({
        url: ProjectStructuresService.getStructuresAndProjectsODataUrlForTable(),
        key: 'Id',
        version: 4,
        fieldTypes: {
          ProjectId: 'Guid',
          ParentId: 'Guid',
        },
        beforeSend: (e) => {
          e.headers.Authorization = 'Bearer ' + localStorage.access_token;
        },
        onLoaded: (data) => {
          let sturctElems = [];
          data.forEach((item) => {
            if (item.ProjectId) {
              item.navigateParams = { rootProject: item.ProjectId.toString() };

              switch (item.QuickNavigation) {
                case 0: // archive
                  item.navigatePath = ['/archive/docs'];
                  break;
                case 1: // QC
                  item.navigatePath = ['/quality-control/remarks'];
                  break;
                case 2: // control-smr
                  item.navigatePath = ['/control-smr/work-volume'];
                  break;
                case 3: // smr2
                  item.navigatePath = ['/smr2/acts'];
                  break;
              }
            } else {
              sturctElems.push(item.Id);
            }
          });
          let locStorArray = JSON.parse(localStorage.getItem('rowsToExpand'));
          if (!locStorArray || !locStorArray.length) {
            localStorage.setItem('rowsToExpand', JSON.stringify(sturctElems));
          }
          this.treeList.expandedRowKeys = JSON.parse(localStorage.getItem('rowsToExpand'));
        },
      }),
    });
  }

  setNoData(e: { element: HTMLElement }) {
    console.log(this.noDataTemplateRef);

    let noDataContainer = e.element.querySelector('.dx-treelist-nodata');
    if (noDataContainer) {
      noDataContainer.textContent = null;
      //generate template
      this.renderer.appendChild(noDataContainer, this.noDataTemplateRef.createEmbeddedView(null).rootNodes[0]);
    }
  }

  getImgUrl(projId: string) {
    if (!projId) return null;
    return this.projectsService.getImageFilePreviewRatioFast(projId);
  }

  getLogoUrl(projId: string) {
    if (!projId) return null;
    return this.projectsService.getLogoFilePreviewRatioFast(projId);
  }

  addRowKey(e) {
    let arr = JSON.parse(localStorage.getItem('rowsToExpand'));
    arr.push(e.key.toString());
    localStorage.setItem('rowsToExpand', JSON.stringify(arr));
  }

  deleteRowKey(e) {
    let arr = JSON.parse(localStorage.getItem('rowsToExpand'));
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element === e.key.toString()) {
        arr.splice(index, 1);
      }
    }
    localStorage.setItem('rowsToExpand', JSON.stringify(arr));
  }
}
