import { Injectable } from '@angular/core';

@Injectable()
export class ImageCahceService {
  imgCache: { [id: string]: Blob } = {};
  timeoutsMap: { [id: string]: number } = {};

  constructor() {
    // setInterval(() => {
    //   console.log('cache count', Object.keys(this.imgCache).length);
    // }, 100)
  }

  newSet(imageIds: string[]) {
    // console.log('newset', imageIds.length);

    let cacheIds = Object.keys(this.imgCache);

    cacheIds.forEach((cacheId) => {
      if (imageIds.includes(cacheId)) {
        // если айдишник актуален, то удаляем таймер на очистку
        window.clearTimeout(this.timeoutsMap[cacheId]);
      } else {
        // если нет, то ставим таймер
        this.timeoutsMap[cacheId] = window.setTimeout(
          () => {
            delete this.imgCache[cacheId];
          },
          5 * 60 * 1000
        ); // 5 минут
      }
    });
  }

  getCachedData(id: string) {
    return this.imgCache[id];
  }

  cacheImage(id: string, blob: Blob) {
    if (blob === null) return;
    this.imgCache[id] = blob;
    window.clearTimeout(this.timeoutsMap[id]);
  }
}
