import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { LocalStorageConstants } from 'src/app/shared/constants';
import { IActiveModules, IUserModulesPermissions, IUserProfile } from '@pp/interfaces';

export interface RootState {
  // выбранный проект
  rootProjectId: string;

  // выбранный каталог
  selectedCatalogId: string;

  // активные модули
  activeModules: IActiveModules[];

  // права текущего юзера
  userPermissions: IUserModulesPermissions;

  // текущий юзер
  userProfile: IUserProfile;

  // лоадер хедера
  loaderOn: boolean;
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'rootStore', resettable: false })
export class RootStore extends Store<RootState> {
  constructor() {
    super({
      rootProjectId: localStorage.getItem(LocalStorageConstants.ROOT.PROJECT), // сомнительно брать тут сразу из стораджа, но ок
      selectedCatalogId: localStorage.getItem(LocalStorageConstants.ROOT.CATALOG), // сомнительно брать тут сразу из стораджа, но ок
      activeModules: undefined,
      userPermissions: undefined,
      userProfile: undefined,
      loaderOn: false,
    });
  }
}
