import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
  @Input() popupVisible: boolean = false;
  @Output() popupChange: EventEmitter<ICommentEvent> = new EventEmitter<ICommentEvent>();

  okButtonOptions: any;
  closeButtonOptions: any;

  comment: { name: string; metaData: string; saveView: boolean } = {
    name: 'test comment',
    metaData: '',
    saveView: false,
  };

  constructor() {
    this.okButtonOptions = {
      icon: 'check',
      text: 'Поставить',
      onClick: (e: any) => {
        this.closePopup({ action: 'add', data: this.comment });
      },
    };
    this.closeButtonOptions = {
      icon: 'close',
      text: 'Закрыть',
      onClick: (e: any) => {
        this.closePopup({ action: 'edit', data: this.comment });
      },
    };
  }

  closePopup(action: ICommentEvent) {
    this.popupChange.emit(action);
    this.popupVisible = false;
  }
}

export interface ICommentEvent {
  action: 'add' | 'edit';
  data?: { name: string; metaData: string; saveView: boolean };
}
