<!-- <div *ngFor="let file of item.files"> -->
  <!-- {{ item | json }} -->
  <div class="preview">
    <ng-container *ngIf="(file.urlPreview | authImg | async) as imgSrc; else loading">
      <img [src]="imgSrc" alt="image" (click)="openInNewTab(file)" class="responsive"/>
      <div *ngIf="!imgSrc" class="preview-error">Превью в данный момент недоступно</div>
    </ng-container>
    <!-- <div *ngSwitchCase="false"> -->
                    <!-- <div [ngStyle]="{'background-image': file.preview}" [hidden]="file.previewIsLoading == false"></div> -->
    <!-- </div> -->
    <ng-template #loading>
      <dx-load-indicator height="20" width="20">
      </dx-load-indicator>
    </ng-template>
  </div>
<!-- </div> -->

