import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadProgressService {
  title = '';
  private progress$ = new BehaviorSubject<string>('0');
  private text$ = new BehaviorSubject<string>('');
  private cancelCallback$ = new BehaviorSubject<null | (() => void)>(null);

  constructor() {}

  public setCurrentProgress(progress: string): void {
    this.progress$.next(progress);
  }
  public setCurrentText(text: string): void {
    this.text$.next(text);
  }

  public cancel(text: string): void {
    this.text$.next(text);
  }

  getProgressStream() {
    return this.progress$.asObservable();
  }
  getTextStream() {
    return this.text$.asObservable();
  }
  getCancelCallbackStream() {
    return this.cancelCallback$.asObservable();
  }

  setTitle(title: string): void {
    this.title = title;
  }
  setCancelCallback(cb: () => void): void {
    this.cancelCallback$.next(cb);
  }
}
