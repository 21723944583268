import { NgModule } from '@angular/core';
import { uiComponentsContainer } from './ui-components.common';
import { ButtonsModule } from './buttons/buttons.module';
import { NotificationsModule } from './notifier/notifier.module';
import { DropdownTreeViewModule } from './dropdown-tree-view/dropdown-tree-view.module';
import { ChangeInfoModule } from './change-info/change-info.module';
import { SocialActivityModule } from './social-activity/social-activity.module';
import { DropdownTreeListModule } from './dropdown-tree-list/dropdown-tree-list.module';
import { ActionsPanelModule } from './actions-panel/actions-panel.module';
import { UploaderModule } from './uploader/uploader.module';
import { ConfirmPopupModule } from './confirm-popup/confirm-popup.module';
import { UserInfoModule } from './user-info/user-info.module';
import { ImagePreviewModule } from './image-preview/image-preview.module';
import { CatalogViewerModule } from './catalog-viewer/catalog-viewer.module';
import { GlobalProjectSelectModule } from './global-project-select/global-project-select.module';

@NgModule({
  declarations: [],
  imports: [
    ButtonsModule,
    NotificationsModule,
    DropdownTreeViewModule,
    ChangeInfoModule,
    SocialActivityModule,
    DropdownTreeListModule,
    ActionsPanelModule,
    UploaderModule,
    ConfirmPopupModule,
    UserInfoModule,
    ImagePreviewModule,
    CatalogViewerModule,
    GlobalProjectSelectModule,
  ],
  exports: [...uiComponentsContainer, CatalogViewerModule, GlobalProjectSelectModule],
})
export class UiComponentsModule {}
