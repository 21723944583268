<span class="dx-field-item-label-text label-with-icon">
  <span>{{labelText}}</span>
  <ng-container *ngIf="showIcon">
    <i [id]="labelHelperIdid" class="pp-icon_outlined_help help-icon"></i>
  
    <dx-tooltip [target]="'#'+labelHelperIdid" showEvent="dxhoverstart" hideEvent="dxhoverend">
      <dxo-position *ngIf="position"
        [my]="position.my"
        [at]="position.at">
      </dxo-position>
      <div *dxTemplate="let content of 'content'" class="tipWrap">
        <ng-content></ng-content>
      </div>
    </dx-tooltip>
  </ng-container>
</span>
