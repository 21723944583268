import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DownloadsState, DownloadsStore } from './downloads.store';
import { distinctUntilChanged, map } from 'rxjs';

@Injectable()
export class DownloadsQuery extends Query<DownloadsState> {
  statuses$ = this.select('downloadStatuses').pipe(
    map((statuses) => {
      return Array.from(statuses.entries()).map(([id, data]) => {
        return {
          ...data,
          id,
        };
      });
    })
  );
  showDownloads$ = this.select('showDownloads');

  isSomePending$ = this.select('downloadStatuses').pipe(
    map((statuses) => {
      return Array.from(statuses.values())
        .map((status) => status.status)
        .includes('pending');
    }),
    distinctUntilChanged()
  );

  constructor(protected store: DownloadsStore) {
    super(store);
  }
}
