import { Injectable } from '@angular/core';
import { environment } from '@pp/root-env';
import { HttpClient } from '@angular/common/http';
import { first, map, Observable, of, ReplaySubject, share, Subject, tap } from 'rxjs';
import moment from 'moment';
import { RootStoreService } from '@pp/root-store';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly rootUrl: string;
  private a0UserService = environment.a0UserService;
  private usersUrl = environment.usersUrl;
  private permissionService = environment.PermissionServiceUrl;

  public permission: Subject<any> = new Subject();

  public myId: ReplaySubject<string>;
  public myProfile: ReplaySubject<{
    Company: any;
    Department: any;
    DepartmentId: string;
    DisplayName: string;
    Email: string;
    Id: string;
    Position: any;
  }>;

  constructor(
    private http: HttpClient,
    private rootStoreService: RootStoreService
  ) {
    this.rootUrl = environment.apiUrl;
  }

  public getUsersByName(Name: string, skip) {
    const body = {
      Name: Name,
      Top: 20,
      Skip: skip || 0,
    };
    return Name ? this.http.post<User[]>(environment.apiUrl + this.usersUrl + 'GetByName', body) : of([]);
  }

  public getUsersByNameAll(Name: string, skip) {
    const body = {
      Name: Name,
      Top: 20,
      Skip: skip || 0,
    };
    return this.http.post<User[]>(environment.apiUrl + this.usersUrl + 'GetByNameAll', body);
  }

  public getUsersByEmail(email: string, skip) {
    const body = {
      Email: email,
      Top: 20,
      Skip: skip || 0,
    };
    return this.http.post(environment.apiUrl + this.usersUrl + 'GetByEmail', body);
  }

  public getUsersByEmailAll(email: string, skip) {
    const body = {
      Email: email,
      Top: 20,
      Skip: skip || 0,
    };
    return this.http.post(environment.apiUrl + this.usersUrl + 'GetByEmailAll', body);
  }

  public getUsersById(id: string) {
    return this.getUserById(id);
  }

  // этот метод нельзя использовать с конвертацией в промис, ReplaySubject видимо не позволяет
  public users: ReplaySubject<User>;
  getUsers() {
    if (this.users) {
      return this.users;
    } else {
      this.users = new ReplaySubject<any>(1);
      return this.http.get(environment.apiUrl + this.usersUrl + 'Users').pipe(
        tap((res: any) => {
          this.users.next(res);
        })
      );
    }
  }

  public allUsers: User[];
  getAllUsers() {
    if (this.allUsers) {
      return of(this.allUsers);
    } else {
      return this.http.get<User[]>(environment.apiUrl + this.usersUrl + 'GetAllUsers').pipe(
        map((res) => {
          for (const user of res) {
            const offset = new Date().getTimezoneOffset();
            if (user.Created) {
              user.Created = moment(user.Created).add(-offset, 'm').toDate();
            }
            if (user.Modified) {
              user.Modified = moment(user.Modified).add(-offset, 'm').toDate();
            }
          }
          return res;
        }),
        tap((res) => {
          this.allUsers = res;
        })
      );
    }
  }

  getAll() {
    return this.http.get<any>(this.rootUrl + this.a0UserService + 'GetAll');
  }

  getById(id) {
    return this.http.get(this.rootUrl + this.a0UserService + `Get?Id=${id}`);
  }

  cachedUsers: User[] = [];
  usersObservablesMap: { [userId: string]: Observable<User> } = {};
  getUserById(id): Observable<User> {
    const findedUser = this.cachedUsers.find((user) => user.Id === id);
    if (findedUser) return of(findedUser);

    if (this.usersObservablesMap[id]) {
      return this.usersObservablesMap[id];
    } else {
      this.usersObservablesMap[id] = this.http.get<User>(environment.apiUrl + this.usersUrl + `Get?Id=${id}`).pipe(
        share(),
        tap((res) => {
          if (!this.cachedUsers.map((user) => user.Id).includes(res.Id)) {
            this.cachedUsers.push(res);
          }
        })
      );
      return this.usersObservablesMap[id];
    }
  }

  getMyId() {
    if (this.myId) {
      return this.myId;
    } else {
      this.myId = new ReplaySubject<string>(1);
      this.getMyProfile().pipe(first()).subscribe();
      return this.http.get(environment.apiUrl + this.usersUrl + `GetMyID`).pipe(
        map((res: any) => {
          this.myId.next(res);
          console.groupCollapsed('Current user ID');
          console.table(res);
          console.groupEnd();
          return res;
        })
      );
    }
  }

  getMyProfile() {
    if (this.myProfile) {
      return this.myProfile;
    } else {
      this.myProfile = new ReplaySubject<{
        Company: any;
        Department: any;
        DepartmentId: string;
        DisplayName: string;
        Email: string;
        Id: string;
        Position: any;
      }>(1);
      return this.http.get(environment.apiUrl + this.usersUrl + `GetMyProfile`).pipe(
        map((res: any) => {
          this.myProfile.next(res);
          this.rootStoreService.updateUserProfile(res);
          console.groupCollapsed('Current user profile');
          console.table(res);
          console.groupEnd();
          return res;
        })
      );
    }
  }

  edit(user) {
    return this.http.post(this.rootUrl + this.a0UserService + 'ChangeUserInfo', {
      Id: user.Id,
      Login: user.Login,
      Password: user.Password,
    });
  }

  getCurrentUser() {
    return this.http.get(environment.apiUrl + '/_api/web/currentUser');
  }

  getPermission() {
    return this.http.get(this.rootUrl + this.permissionService + 'GetAll').pipe(
      map((res: any) => {
        this.permission.next(res);
        return res;
      })
    );
  }

  getUsersODataUrl() {
    return this.rootUrl + environment.userServiceOData + 'Users';
  }

  getUsersODataSource() {
    return new DataSource({
      store: new ODataStore({
        url: this.getUsersODataUrl(),
        key: 'Id',
        version: 4,
        fieldTypes: {
          Id: 'Guid',
          IsActive: 'Boolean',
          DepartmentId: 'Guid',
        },
        beforeSend: (e) => {
          e.headers.Authorization = 'Bearer ' + localStorage.access_token;
        },
      }),
      sort: [{ selector: 'DisplayName', desc: false }],
      filter: ['IsActive', '=', true],
    });
  }

  getAllForAdmin() {
    return this.http.get<User[]>(environment.apiUrl + this.usersUrl + 'GetAllForAdmin');
  }

  createUser(body: NewUserRequest) {
    return this.http.post<NewUserResponse>(environment.apiUrl + this.usersUrl + 'CreateUser', body);
  }

  rightsToCreateUser(): Observable<boolean> {
    return this.http.get<boolean>(environment.apiUrl + this.usersUrl + 'HasRightsToCreateUser');
  }
}

export interface User {
  Id: string;
  Email: string;

  DepartmentId?: string;
  ExternalId: string;
  Sid: string;
  IsActive: boolean;
  DisplayName: string;

  Company: string;
  Position: string;
  WorkPhone?: string;
  MobilePhone?: string;

  // IsQCRemarksResponsible?;

  Created?: string | Date;
  CreatedById?: string;
  CreatedBy?: User;
  Modified?: string | Date;
  ModifiedById?: string;
  // ModifiedBy?: User;

  EntryDaysCount?: string;
  LastActiveDate?: string;
  AddedDate?: string;
  RemoveDate?: string;
  HasAnyUserRoleInProject?: boolean;
  LoginName: string;
}

export class RuleStartUser {
  Id: string;
  User: User;
  SortOrder: number;
}

export class Profile {
  Company: any;
  Department: any;
  DepartmentId: string;
  DisplayName: string;
  Email: string;
  Id: string;
  Position: any;
}

export interface NewUserRequest {
  Email: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Position: string;
  Company: string;
}

export interface NewUserResponse {
  ErrorText: string;
  HistoryText: string;
  Status: number;
}
