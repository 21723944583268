import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '../buttons/buttons.module';
import { ConfirmPopupComponent } from './confirm-popup.component';
import { DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { ConfirmService } from './confirm.service';

@NgModule({
  imports: [CommonModule, ButtonsModule, DxPopupModule, DxScrollViewModule],
  providers: [ConfirmService],
  declarations: [ConfirmPopupComponent],
  exports: [ConfirmPopupComponent],
})
export class ConfirmPopupModule {}
