import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionsPanel,
  AdditionalButtonsDirective,
  DropdownButtonContentlDirective,
  TitleInfoDirective,
  TitleTemplateDirective,
} from './actions-panel.component';
import { DxDropDownButtonModule } from 'devextreme-angular';
import { ChangeInfoModule } from '../change-info/change-info.module';
import { ButtonsModule } from '../buttons/buttons.module';

@NgModule({
  declarations: [
    ActionsPanel,
    AdditionalButtonsDirective,
    DropdownButtonContentlDirective,
    TitleInfoDirective,
    TitleTemplateDirective,
  ],
  exports: [
    ActionsPanel,
    AdditionalButtonsDirective,
    DropdownButtonContentlDirective,
    TitleInfoDirective,
    TitleTemplateDirective,
  ],
  imports: [CommonModule, ButtonsModule, DxDropDownButtonModule, ChangeInfoModule],
})
export class ActionsPanelModule {}
