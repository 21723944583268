import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PermissionService } from 'src/app/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class QualityControlCatalogGuard implements CanActivate {
  constructor(
    private permissionsService: PermissionService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
    // return true;
    return this.permissionsService.getUserPermissionQualityControl().pipe(
      map((res) => {
        if (res[route.data['permissionKey']]) return true;

        return this.router.parseUrl('/errors/403');
      })
    );
  }
}
