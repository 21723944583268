<dx-drawer
    class="drawer"
    position="before"
    [closeOnOutsideClick]="shaderEnabled"
    [openedStateMode]="menuMode"
    [revealMode]="menuRevealMode"
    [minSize]="minMenuSize"
    [shading]="shaderEnabled"
    [(opened)]="menuOpened">

    <app-side-navigation-menu
        [items]="menuItems"
        [compactMode]="!menuOpened"
        [selectedItem]="selectedRoute"
        class="dx-swatch-additional"
        *dxTemplate="let dataMenu of 'panel'"
        (selectedItemChanged)="navigationChanged($event)"
        (openMenu)="navigationClick()">
            <dx-toolbar id="navigation-header">
                <dxi-item
                    *ngIf="minMenuSize !== 0"
                    location="before"
                    cssClass="menu-button"
                    widget="dxButton"
                    [options]="{
                        icon: 'menu',
                        stylingMode: 'text',
                        onClick: toggleMenu
                    }">
                </dxi-item>
                <dxi-item
                    location="before"
                    cssClass="header-title"
                    [text]="title">
                </dxi-item>
            </dx-toolbar>
    </app-side-navigation-menu>

    <div class="container">
<!--        <app-portal-core-header-->
<!--            [menuToggleEnabled]="minMenuSize === 0"-->
<!--            (menuToggle)="menuOpened = !menuOpened;">-->
<!--        </app-portal-core-header>-->

<!--        <dx-scroll-view class="layout-body full-height-scrollable">-->
<!--            <div class="content">-->
<!--                <ng-content></ng-content>-->
<!--            </div>-->

<!--            <div class="content-block">-->
<!--                <ng-content select="app-footer"></ng-content>-->
<!--            </div>-->
<!--        </dx-scroll-view>-->

      <div class="content">
        <ng-content></ng-content>
      </div>

<!--      <div class="content-block">-->
<!--        <ng-content select="app-footer"></ng-content>-->
<!--      </div>-->
    </div>
</dx-drawer>
