const disabledText = $localize`Доступ ограничен лицензией на использование`;

export const navigation: INavigation[] = [
  {
    id: null,
    name: $localize`Проекты`,
    route: '/',
    icon: 'corporate_fare',
    svg: 'Projects',
    forAdmin: true,
    isActive: false,
  },
  {
    id: 7,
    name: $localize`Документы`,
    route: '/archive',
    icon: 'archive',
    svg: 'Archive',
    forAdmin: false,
    isActive: false,
    disabledText: disabledText,
  },
  {
    id: 8,
    name: 'BIM',
    route: '/bim',
    customClass: 'pp-icon_outlined_d_rotation',
    icon: '',
    svg: 'BIM',
    forAdmin: false,
    isActive: true,
    disabledText: disabledText,
  },
  {
    id: 4,
    name: $localize`Контроль Качества`,
    route: 'quality-control',
    icon: 'engineering',
    svg: 'QualityControl',
    isActive: false,
    disabledText: disabledText,
  },
  {
    id: 5,
    name: $localize`Приёмка Работ`,
    route: 'control-smr/work-volume',
    icon: 'engineering',
    svg: 'ManagingCMP',
    isActive: false,
    disabledText: disabledText,
  },
  {
    id: 'Reports',
    name: $localize`Отчеты`,
    icon: 'fact_check',
    svg: 'Reports',
    isActive: true,
    disabledText: disabledText,
  },
  {
    id: 0,
    name: $localize`Настройки`,
    route: '/administration',
    icon: 'miscellaneous_services',
    svg: 'Administration',
    forAdmin: true,
    isActive: false,
  },
];

export interface INavigation {
  id: number | string; // module id
  name: string;
  url?: string;
  route?: string;
  customClass?: string;
  icon?: string;
  svg?: string;
  isActive: boolean;
  forAdmin?: boolean;
  disabledText?: string;
}
