<div class="wrapper">
  <div class="dx-card">
    <dx-validation-group>
      <div class="login-header">
        <div class="title">Project Point</div>
        <div>Войдите в аккаунт</div>
      </div>
      <div class="dx-field">
        <dx-text-box [(value)]="login" placeholder="Логин" width="100%">
          <dx-validator>
            <dxi-validation-rule type="required" message="Логин обязателен"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
      <div class="dx-field">
        <dx-text-box mode="password" [(value)]="password" placeholder="Пароль" width="100%">
          <dx-validator>
            <dxi-validation-rule type="required" message="Пароль обязателен"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
      <div class="dx-field">
        <dx-check-box [value]="false" text="Запомнить меня"></dx-check-box>
      </div>
      <div class="dx-field">
        <dx-button type="default" text="Вход" (onClick)="onLoginClick($event)" width="100%"></dx-button>
      </div>
      <div class="dx-field">
        <a routerLink="/recovery">Забыли пароль ?</a>
      </div>
      <!--    <div class="dx-field">
              <dx-button type="normal" text="Create an account" width="100%"></dx-button>
          </div>-->
    </dx-validation-group>
  </div>
</div>
