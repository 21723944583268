import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICreatedModifiedBase } from '../entities/base-models';
import { environment } from '../environments/environment';
import {
  ChecklistItemTemplateCreate,
  IChecklistItemTemplate,
  IChecklistItemTemplateCreate,
} from './checklist-item-template.service';

@Injectable({
  providedIn: 'root',
})
export class ChecklistSectionTemplateService {
  private _checklistSectionTemplateUrl = '';
  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private _http: HttpClient) {
    // TODO PPDEV-837 swith to apiUrl
    this._checklistSectionTemplateUrl = environment.apiUrl + environment.checklistSectionTemplateUrl;
  }

  getAllSectionByChecklistTemplate(checklistTemplateId: string) {
    return this._http.get<ChecklistSectionTemplate[]>(
      this._checklistSectionTemplateUrl + 'GetAllByChecklistTemplate?checklistTemplateId=' + checklistTemplateId
    );
  }

  getChecklistSectionTemplate(id: string) {
    return this._http.get<ChecklistSectionTemplate>(this._checklistSectionTemplateUrl + 'Get?id=' + id);
  }

  createChecklistSectionTemplate(body: IChecklistSectionTemplatesCreate) {
    return this._http.post(this._checklistSectionTemplateUrl + 'Create', body, this._httpOptions);
  }

  updateChecklistSectionTemplate(body: IChecklistSectionTemplatesUpdate) {
    return this._http.post(this._checklistSectionTemplateUrl + 'Update', body, this._httpOptions);
  }

  updateRelatedChecklistSectionTemplate(body: IChecklistSectionTemplatesUpdateRelated) {
    return this._http.post(this._checklistSectionTemplateUrl + 'UpdateRelated', body, this._httpOptions);
  }

  deleteChecklistSectionTemplate(id: string) {
    return this._http.delete(this._checklistSectionTemplateUrl + 'Delete"id=' + id);
  }
}

export interface IChecklistSectionTemplatesCreate {
  Title: string;
  SortOrder: number;
  ChecklistItemTemplates?: IChecklistItemTemplateCreate[] | [];
}

export interface IChecklistSectionTemplatesUpdate {
  Id: string;
  Title: string;
  SortOrder: number;
}

export interface IChecklistSectionTemplatesUpdateRelated extends IChecklistSectionTemplatesUpdate {
  ChecklistItemTemplates?: IChecklistItemTemplate[] | [];
}

export interface ChecklistSectionTemplate extends ICreatedModifiedBase {
  Id: string;
  Title: string;
  SortOrder: number;
  ChecklistTemplateId: string;
  ChecklistItemTemplates?: IChecklistItemTemplate[] | [];
}

export class ChecklistSectionTemplatesCreate implements IChecklistSectionTemplatesCreate {
  Title = '';
  SortOrder = 0;

  public get ChecklistItemTemplates(): ChecklistItemTemplateCreate[] {
    return this._ChecklistItemTemplates;
  }

  public set ChecklistItemTemplates(val) {
    if (!val || !val.length) return;
    this.ChecklistItemTemplates.length = 0;
    val
      .sort((a, b) => a.SortOrder - b.SortOrder)
      .forEach((item, i) => {
        this._ChecklistItemTemplates.push(new ChecklistItemTemplateCreate(i, item));
      });
  }

  private _ChecklistItemTemplates = [];

  constructor(sectionSortOrder?: number, value?: ChecklistSectionTemplatesCreate) {
    if (sectionSortOrder != undefined) {
      this.SortOrder = sectionSortOrder;
    }

    if (value) {
      this.Title = value.Title;
      this.ChecklistItemTemplates = value.ChecklistItemTemplates;
    }
  }
}
