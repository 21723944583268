/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from '@pp/ui-components';
import DataSource from 'devextreme/data/data_source';
import { Observable, Subject, takeUntil } from 'rxjs';
import { downloadFile } from 'src/app/services';

@Component({
  selector: 'pp-ui-social-activity',
  templateUrl: './social-activity.component.html',
  styleUrls: ['./social-activity.component.scss'],
})
export class SocialActivityComponent implements IPPSocialActivity, OnChanges, OnDestroy {
  @Input()
  listAll: DataSource;

  @Input()
  listFiles: DataSource;

  @Input()
  listComment: DataSource;

  @Input()
  listHistory: DataSource;

  @Input()
  notifyConfig: INotifyConfig;

  @Input()
  token: string;

  @Input()
  service: ISocialActivityService;

  @Input() commentsLoading: boolean = false;

  @Input() filesLoading: boolean = false;

  @Input() allItemsLoading: boolean = false;

  inspectionCommentMenu = [
    {
      title: 'Все',
      onClick: () => {
        this.router.navigate([], { queryParams: { view: 'all' } });
      },
      template: 'allTemplate',
    },
    {
      title: 'Файлы',
      onClick: () => {
        this.router.navigate([], { queryParams: { view: 'files' } });
      },
      template: 'filesTemplate',
    },
    {
      title: 'Комментарии',
      onClick: () => {
        this.router.navigate([], { queryParams: { view: 'comments' } });
      },
      template: 'commentTemplate',
    },
    {
      title: 'История',
      onClick: () => {
        this.router.navigate([], { queryParams: { view: 'history' } });
      },
      template: 'historyTemplate',
    },
  ];

  isShowPreviewPopover: boolean = false;
  previewPopoverTargetId: string = '';
  previewPopoverUrl: string = '';

  private unsubscribe$ = new Subject();

  constructor(
    private router: Router,
    private notifierService: NotifierService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.allItemsLoading && changes.allItemsLoading.currentValue) {
      this.getPreviews(this.listAll.items());
    }
    if (changes && changes.filesLoading && changes.filesLoading.currentValue) {
      this.getPreviews(this.listFiles.items());
    }
    if (changes && changes.commentsLoading && changes.commentsLoading.currentValue) {
      this.getPreviews(this.listComment.items());
    }
  }

  getPreviews(items) {
    for (let item of items) {
      if (item.Comment) {
        for (let file of item.Comment.Files.filter((x) => x.Type == 'img')) {
          file.previewIsLoading = true;
          let headers = new Headers();
          headers.append('Authorization', 'Bearer ' + localStorage.access_token);
          fetch(file.previewUrl, { headers })
            .then((response) => {
              if (!response.ok) {
                file.previewIsLoading = null;
                throw Error(response.statusText);
              } else {
                file.previewIsLoading = false;
                return response.blob();
              }
            })
            .then((blob) => {
              file.preview = URL.createObjectURL(blob);
            })
            .catch(() => {
              file.previewIsLoading = null;
            });
        }
      }
    }
  }

  openFile(url) {
    downloadFile(url, localStorage.access_token);
  }

  /** Удаление комментария */
  deleteComment(id: string) {
    console.log(`Попытка удаления комментария с ID: ${id}`);
    this.service
      .deleteComment(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.notifierService.notify(
          [
            { text: `Комментарий к ${this.notifyConfig.name}` },
            {
              text: this.notifyConfig.code,
              bold: true,
            },
            { text: ' успешно удалён' },
          ],
          'success',
          5000
        );
        this.updateData();
      });
  }

  /** Удаление изображения или файла */
  deleteAttachment(Id: string) {
    console.log(`Попытка удаления файла`);
    this.service
      .deleteFile(Id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.notifierService.notify(
          [
            { text: `Вложение к ${this.notifyConfig.name}` },
            {
              text: this.notifyConfig.code,
              bold: true,
            },
            { text: ' успешно удалено' },
          ],
          'success',
          5000
        );
        this.updateData();
      });
  }

  /** Скачать изображение */
  downloadImage(url: string) {
    const a: any = document.createElement('a');
    a.href = url;
    a.download = 'Attachment';
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
  }

  updateData() {
    this.listAll.reload();
    this.listFiles.reload();
    this.listComment.reload();
    this.listHistory.reload();
  }

  showPreviewPopover(id: string, url: string) {
    this.previewPopoverTargetId = id;
    this.isShowPreviewPopover = true;
    this.previewPopoverUrl = url;
  }

  hidePreviewPopover() {
    this.isShowPreviewPopover = false;
    this.previewPopoverUrl = '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}

export interface IPPSocialActivity {
  listAll: DataSource;
  listFiles: DataSource;
  listComment: DataSource;
  listHistory: DataSource;
  notifyConfig: INotifyConfig;
  token: string;
  service: ISocialActivityService;
  updateData: () => void;
}

export interface ISocialActivityService {
  deleteComment: (id) => Observable<any>;
  deleteFile: (id) => Observable<any>;
}

export interface INotifyConfig {
  name: string;
  code: string;
}
