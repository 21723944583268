<div class="content-block dx-card responsive-paddings">
  <div class="title-container">
    <h1 class="title-container__title" *ngIf="!titleTemplate?.templateRef">{{panelTitle}}</h1>

    <ng-container [ngTemplateOutlet]="titleTemplate?.templateRef"></ng-container>

    <div class="title-container__separator" *ngIf="(panelTitle || titleTemplate) && titleInfo">
      <div class="title-container__separator__line"></div>
    </div>

    <ng-container [ngTemplateOutlet]="titleInfo?.templateRef"></ng-container>

    <div class="title-container__gap"></div>

    <ng-container [ngTemplateOutlet]="additionalTitleButtons?.templateRef"></ng-container>

    <dx-drop-down-button *ngIf="dropdownButtonContent"
      #dropDownBtn
      [dropDownOptions]="{ width: 'auto' }"
      icon="overflow" stylingMode="text"
      displayExpr="name"
      keyExpr="id"
      dropDownContentTemplate="buttonItem"
    >
      <div *dxTemplate="let data of 'buttonItem'" (click)="dropDownBtn.instance.close()">
        <ng-container [ngTemplateOutlet]="dropdownButtonContent?.templateRef"></ng-container>
      </div>
    </dx-drop-down-button>
  </div>

  <ng-content></ng-content>

  <pp-ui-change-info *ngIf="showFooter"
    [createdBy]="createdBy"
    [createdDate]="createdDate"
    [lastModifiedBy]="lastModifiedBy"
    [lastModifiedDate]="lastModifiedDate"
    position="right">
  </pp-ui-change-info>
</div>
