import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

let nextLabelHelperId: number = 0;

@Component({
  selector: 'pp-ui-label-helper',
  templateUrl: './label-helper.component.html',
  styleUrls: ['./label-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelHelperComponent {
  // id инстанса компонента для корректной настройки target атрибута
  labelHelperIdid: string;
  labelHelperIdPrefix = 'label_helper_';

  @Input() labelText = '';
  @Input() showIcon = true;
  @Input() position: { at; my } = null; // default - auto

  constructor() {
    this.labelHelperIdid = this.labelHelperIdPrefix + nextLabelHelperId++;
  }
}
