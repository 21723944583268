import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxDropDownBoxModule, DxLoadIndicatorModule, DxTreeViewModule, DxValidatorModule } from 'devextreme-angular';
import { DropdownTreeViewComponent } from './dropdown-tree-view.component';

@NgModule({
  declarations: [DropdownTreeViewComponent],
  exports: [DropdownTreeViewComponent],
  imports: [CommonModule, DxDropDownBoxModule, DxLoadIndicatorModule, DxValidatorModule, DxTreeViewModule],
})
export class DropdownTreeViewModule {}
