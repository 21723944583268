<dx-drop-down-box
  [valueExpr]="valueExpr" [displayExpr]="displayExpr" [(value)]="value"
  [showClearButton]="showClearButton" [placeholder]="placeholder" [dataSource]="dataSource"
  [stylingMode]="'outlined'" [disabled]="disabled || dataSource.isLoading()" [dropDownButtonTemplate]="'dropDownButton'"
  (onValueChanged)="validate($event)">
  <div *dxTemplate="let data of 'content'">
    <dx-tree-view [items]="dataSource.items()" #dxTreeView (onInitialized)="setInstanceTreeView($event)"
                  [height]="'100%'"
                  dataStructure="plain"
                  [rootValue]="0"
                  [virtualModeEnabled]="true"
                  [displayExpr]="displayExpr"
                  [parentIdExpr]="parentIdExpr"
                  [selectionMode]="selectionMode"
                  [keyExpr]="valueExpr"
                  [searchEnabled]="true"
                  showCheckBoxesMode="normal"
                  searchMode="contains"
                  [selectByClick]="true"
                  (onContentReady)="$event.component.selectItem(value)"
                  (onItemSelectionChanged)="selectionItem($event)">
    </dx-tree-view>
  </div>
  <div *dxTemplate="let data of 'dropDownButton'">
    <dx-load-indicator [visible]="dataSource.isLoading && dataSource.isLoading()"
                       style="height: 18px; width: 18px; position: relative; top: 5px;"></dx-load-indicator>
    <div *ngIf="!dataSource.isLoading()" class="dx-dropdowneditor-icon"></div>
  </div>
  <dx-validator [adapter]="adapterConfig" validationGroup="objectVG">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dx-validator>
</dx-drop-down-box>