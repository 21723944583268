import { Injectable } from '@angular/core';
import { SettingsAppService } from '@pp/settings';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { INavigation, navigation } from 'src/app/app-navigation';
import { PermissionService } from 'src/app/services/permission.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private navigation: BehaviorSubject<INavigation[]> = new BehaviorSubject([]);
  navigation$: Observable<INavigation[]>;

  constructor(
    private permissionsService: PermissionService,
    private settingsService: SettingsAppService
  ) {
    this.navigation$ = this.navigation.asObservable();
    this.refreshNavigation();
  }

  refreshNavigation() {
    forkJoin([
      this.permissionsService.getAllPermissionCore(true),
      this.settingsService.getSettingByName('ReportsUrl'),
      this.settingsService.getActiveModules(),
    ]).subscribe(([permissions, reportSetting, activeModules]) => {
      let navs = JSON.parse(JSON.stringify(navigation));

      const reportsNav = navs.find((nav) => nav.id === 'Reports');
      reportsNav.isActive = permissions.CanViewReports;
      reportsNav.url = reportSetting.Value;

      navs.forEach((x: INavigation) => {
        if (x.forAdmin) return;
        const moduleInService = activeModules.find((y) => +y.Id === +x.id);
        if (moduleInService) {
          x.isActive = moduleInService.IsActive;
        }
      });

      this.navigation.next(navs);
    });
  }
}
