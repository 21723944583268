import {
  AfterViewInit,
  Component,
  ElementRef,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { ITileStructureOData } from '@pp/interfaces';
import { DxTreeListComponent, DxTreeViewComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { Column } from 'devextreme/ui/tree_list';
import { ProjectsService } from 'src/app/services/projects.service';
import { ImageCahceService } from '../../../shared/services/image-cache.service';
import { MainPageService } from '../main-page.service';

@Component({
  selector: 'app-portal-core-tiles-view',
  templateUrl: './tiles-view.component.html',
  styleUrls: ['./tiles-view.component.scss'],
})
export class TilesViewComponent implements AfterViewInit {
  @ViewChildren('securedImg') imgs: QueryList<ElementRef> = new QueryList();
  @ViewChild(DxTreeViewComponent, { static: false }) tree!: DxTreeViewComponent;

  @ViewChild('noDataTemplate') noDataTemplateRef: TemplateRef<HTMLElement>;

  @ViewChild('treeList', { static: false }) treeList!: DxTreeListComponent;

  dataSource: DataSource;
  // options: Options | any;
  columns: Column[] = [];

  // дерево трилиста выстраивается только по структурам
  private structures: Partial<
    ITileStructureOData & { isTilesRow?: boolean; hasItems?: boolean; expanded?: boolean }
  >[] = null;

  // проекты подгружаются только при открытии содержащей их ноды
  private projects: ITileStructureOData[] = null;

  constructor(
    private mainPageService: MainPageService,
    private imgCacheService: ImageCahceService,
    private projectsService: ProjectsService,
    public hostRef: ElementRef,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.columns = [
      {
        caption: $localize`Название`,
        dataField: 'Name',
        cellTemplate: 'rowTemplate',
        sortIndex: 0,
        sortOrder: 'asc',
      },
    ];

    this.dataSource = new DataSource({
      store: new CustomStore({
        key: 'Id',
        loadMode: 'raw',
        load: () => {
          let sturctElems = [];
          return new Promise((resolve, reject) => {
            this.mainPageService.getStructuresForTiles().subscribe(
              (res) => {
                this.projects = res.filter((x) => x.ProjectId);
                this.structures = res.filter((x) => !x.ProjectId);

                this.structures.forEach((struct) => {
                  sturctElems.push(struct.Id);
                  if (struct.ParentId) {
                    let parent = this.structures.find((x) => x.Id === struct.ParentId);
                    if (parent) parent.hasItems = true;
                  }
                });

                let locStorArray = JSON.parse(localStorage.getItem('rowsToExpand'));
                if (!locStorArray || !locStorArray.length) {
                  localStorage.setItem('rowsToExpand', JSON.stringify(sturctElems));
                }
                this.treeList.expandedRowKeys = JSON.parse(localStorage.getItem('rowsToExpand'));

                let structuresIds = this.structures.map((x) => x.Id);

                // подсунем фейковые ноды для того, что бы складывать в них плитки
                this.projects.forEach((proj) => {
                  const fakeStructureId = proj.ParentId + '_tilesRow';

                  if (!structuresIds.includes(fakeStructureId)) {
                    this.structures.push({
                      Id: fakeStructureId,
                      Name: '',
                      ParentId: proj.ParentId,
                      isTilesRow: true,
                    });
                    structuresIds = this.structures.map((x) => x.Id);
                  }
                });

                // структура для проектов без структуры
                // this.structures.unshift({
                //   Id: 'emptyProjs',
                //   Name: 'projects without parent',
                //   ProjectId: null,
                //   ParentId: null
                // })

                resolve(this.structures);
              },
              (err) => {
                reject(err);
              }
            );
          });
        },
      }),
    });
  }

  ngAfterViewInit(): void {
    this.imgs.changes.subscribe((res) => {
      this.imgCacheService.newSet(this.imgs.map((img) => img.nativeElement.id));
    });
  }

  setNoData(e: { element: HTMLElement }) {
    let noDataContainer = e.element.querySelector('.dx-treelist-nodata');
    if (noDataContainer) {
      noDataContainer.textContent = null;
      //generate template
      this.renderer.appendChild(noDataContainer, this.noDataTemplateRef.createEmbeddedView(null).rootNodes[0]);
    }
  }

  getImgUrl(projId: string) {
    if (!projId) return null;

    return this.projectsService.getImageFilePreviewRatioFast(projId);
  }

  getProjects(parentId: string | null) {
    return this.projects.filter((x) => x.ParentId === parentId).sort((a, b) => a.Name.localeCompare(b.Name));
  }

  navigate(proj: ITileStructureOData) {
    switch (proj.QuickNavigation) {
      case 0: // archive
        this.router.navigate(['/archive/docs'], { queryParams: { rootProject: proj.ProjectId } });
        break;
      case 1: // QC
        this.router.navigate(['/quality-control/remarks'], { queryParams: { rootProject: proj.ProjectId } });
        break;
      case 2: // control-smr
        this.router.navigate(['/control-smr/work-volume'], { queryParams: { rootProject: proj.ProjectId } });
        break;
      case 3: // smr2
        this.router.navigate(['/smr2/acts'], { queryParams: { rootProject: proj.ProjectId } });
        break;
    }
  }

  addRowKey(e) {
    let arr = JSON.parse(localStorage.getItem('rowsToExpand'));
    arr.push(e.key.toString());
    localStorage.setItem('rowsToExpand', JSON.stringify(arr));
  }

  deleteRowKey(e) {
    let arr = JSON.parse(localStorage.getItem('rowsToExpand'));
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element === e.key.toString()) {
        arr.splice(index, 1);
      }
    }
    localStorage.setItem('rowsToExpand', JSON.stringify(arr));
  }
}
