import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTemplateModule } from 'devextreme-angular';

@Component({
  selector: 'app-portal-core-right-panel',
  templateUrl: 'right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
})
export class RightPanelComponent {
  @Input() showPanel = false;
  @Output() closePanel = new EventEmitter();

  @Input() title = 'Заголовок';
  @Input() icon = 'dx-icon-filter';

  @Input() applyBtnText = 'Применить';
  @Output() apply = new EventEmitter();

  animationOptions = {
    show: { type: 'slideIn', direction: 'right', duration: 250 },
    hide: { type: 'slideOut', direction: 'right', duration: 250 },
  };

  scrollbarMode = 'onHover';

  constructor() {}

  close() {
    this.closePanel.emit(false);
  }

  applyFn(event) {
    this.apply.emit();
  }
}

@NgModule({
  imports: [DxPopupModule, DxScrollViewModule, DxTemplateModule, DxButtonModule, CommonModule],
  declarations: [RightPanelComponent],
  exports: [RightPanelComponent],
})
export class RightPanelModule {}
