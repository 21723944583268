<div class="user-panel" [attr.data-wrapper]="'wrapper'">
    <div class="user-info">
        <div class="icon-container">
            <i class="dx-icon-preferences"></i>
        </div>
    </div>
    <dx-context-menu
        *ngIf="menuMode === 'context'"
        [items]="menuItems"
        itemTemplate="item"
        target=".user-button"
        showEvent="dxclick"
        [position]="{ at: 'left bottom' }"
        cssClass="user-menu">
        <div *dxTemplate="let data of 'item'">
            <i [ngClass]="getIcon(data)" style="margin-right: 16px; font-size: 18px;"></i>
            <span style="vertical-align: top;">{{data.text}}</span>
        </div>
    </dx-context-menu>
    <dx-list
        *ngIf="menuMode === 'list'"
        class="dx-toolbar-menu-action"
        [items]="menuItems">
    </dx-list>
</div>
