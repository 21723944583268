import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDropDownBoxComponent, DxTreeViewComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import validationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'pp-ui-dropdown-box',
  templateUrl: './dropdown-tree-view.component.html',
  styleUrls: ['./dropdown-tree-view.component.scss'],
})
export class DropdownTreeViewComponent implements OnInit, IPPDropdownTreeView {
  @ViewChild(DxDropDownBoxComponent, { static: true })
  dropdown!: DxDropDownBoxComponent;

  @ViewChild('dxTreeView', { static: true })
  treeView!: DxTreeViewComponent;

  @Input() value: any;
  @Input() dataSource: DataSource;
  @Input() selectionMode: 'multiple' | 'single' = 'single';
  @Input() valueExpr: string = 'Id';
  @Input() placeholder: string = $localize`Введите название`;
  @Input() displayExpr: string;
  @Input() parentIdExpr: string;
  @Input() disabled = false;
  @Input() showClearButton = false;

  @Output() onChangeItem = new EventEmitter();
  @Output() onInitialized = new EventEmitter();
  @Output() getTreeView: EventEmitter<DxTreeViewComponent> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.onInitialized.next(this);
  }

  setInstanceTreeView(e) {
    this.treeView = e.component;
    this.getTreeView.next(this.treeView);
  }

  validate(e) {
    validationEngine.validateGroup('objectVG');
    this.selectionMode === 'single' ? this.dropdown.instance.close() : null;
  }

  selectionItem(event: any): void {
    this.onChangeItem.emit(event);
  }

  adapterConfig = {
    getValue: () => {
      return this.value;
    },
    applyValidationResults: (e) => {
      this.dropdown.isValid = e.isValid;
    },
    // validationRequestsCallbacks: []
  };
}

export interface IPPDropdownTreeView {
  value: any;
  dataSource: DataSource;
  selectionMode: 'multiple' | 'single';
  valueExpr: string;
  placeholder: string;
  displayExpr: string;
  parentIdExpr: string;
  disabled: boolean;
  onChangeItem: EventEmitter<any>;
  onInitialized: EventEmitter<any>;
  getTreeView: EventEmitter<DxTreeViewComponent>;
}
