import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { DocApproveTaskService } from 'projects/archive/src/app/services/doc-approve-task.service';
import { DocApproveService } from 'projects/archive/src/app/services/doc-approve.service';
import { WorkVolumeService } from 'projects/control-smr/src/app/work-volume/work-volume.service';
import { InspectionsService } from 'projects/pp-administration/src/app/inspections/inspections.service';
import { OrganizationsService } from 'projects/pp-administration/src/app/organizations/organizations.service';
import { WorkPlanService } from 'projects/pp-administration/src/app/work-plan/work-plan.service';
import { ApproveRouteService } from 'projects/pp-administration/src/services/approve-route.service';
import { ChecklistTemplateService } from 'projects/pp-administration/src/services/checklist-template.service';
import { MatrixDocService } from 'projects/pp-administration/src/services/matrix-docs.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActApproveService } from 'src/app/services/act-approve.service';
import { ApproveTasksService } from 'src/app/services/approve-tasks.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { RemarksService } from 'src/app/services/remarks.service';
import { DocsService } from '../services/docs.service';
import { TransmittalService } from 'projects/archive/src/app/services/transmittal.service';

@Injectable({
  providedIn: 'root',
})
export class CustomTitleStrategy extends TitleStrategy {
  constructor(private title: Title) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (!title) return;
    this.title.setTitle(`${title} - Project Point`);
  }
}

@Injectable({ providedIn: 'root' })
export class VolumeTitleResolver {
  constructor(private workVolumeService: WorkVolumeService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const volumeId = route.paramMap.get('id');
    return this.workVolumeService.getById(volumeId).pipe(map((volume) => $localize`${volume.Item.Name} - Работа`));
  }
}

@Injectable({ providedIn: 'root' })
export class ViewPlanTitleResolver {
  constructor(private workPlanService: WorkPlanService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const planId = route.paramMap.get('id');
    return this.workPlanService.get(planId).pipe(map((plan) => $localize`${plan.Item.Code} - План работ`));
  }
}

@Injectable({ providedIn: 'root' })
export class ViewApproveTitleResolver {
  constructor(private actApproveService: ActApproveService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const approveId = route.paramMap.get('id');
    return this.actApproveService
      .get(approveId)
      .pipe(map((approve) => $localize`${approve.Item.Code} - Согласование акта`));
  }
}

@Injectable({ providedIn: 'root' })
export class EditApproveTitleResolver {
  constructor(private actApproveService: ActApproveService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const approveId = route.paramMap.get('id');
    return this.actApproveService
      .get(approveId)
      .pipe(map((approve) => $localize`${approve.Item.Code} - Редактирование согласования акта`));
  }
}

@Injectable({ providedIn: 'root' })
export class ViewTaskTitleResolver {
  constructor(private approveTasksService: ApproveTasksService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const taskId = route.paramMap.get('id');
    return this.approveTasksService
      .get(taskId)
      .pipe(map((task) => $localize`${task.Item.Code} - Задача согласования акта`));
  }
}

@Injectable({ providedIn: 'root' })
export class ViewInspectionTitleResolver {
  constructor(private inspectionService: InspectionsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const inspectionId = route.paramMap.get('id');
    return this.inspectionService
      .getById(inspectionId)
      .pipe(map((inspection) => $localize`${inspection.Item.Code} - Инспекция`));
  }
}

@Injectable({ providedIn: 'root' })
export class EditInsectionTitleResolver {
  constructor(private inspectionService: InspectionsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const inspectionId = route.paramMap.get('id');
    return this.inspectionService
      .getById(inspectionId)
      .pipe(map((inspection) => $localize`${inspection.Item.Code} - Редактирование инспекции`));
  }
}

@Injectable({ providedIn: 'root' })
export class EditRemarkTitleResolver {
  constructor(private remarkService: RemarksService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const remarkId = route.paramMap.get('id');
    return this.remarkService
      .getById(remarkId)
      .pipe(map((remark: any) => $localize`${remark.Item.Code} - Редактирование замечания`));
  }
}

@Injectable({ providedIn: 'root' })
export class ViewRemarkTitleResolver {
  constructor(private remarkService: RemarksService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const remarkId = route.paramMap.get('id');
    return this.remarkService.getById(remarkId).pipe(map((remark: any) => $localize`${remark.Item.Code} - Замечание`));
  }
}

@Injectable({ providedIn: 'root' })
export class DocEditTitleResolver {
  constructor(private documentService: DocsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const docId = route.paramMap.get('id');
    if (!docId) return of(null);
    return this.documentService
      .getDocument(docId)
      .pipe(
        map(
          (doc) =>
            $localize`${doc.Item.Number} ${doc.Item.ChangeNumber ? doc.Item.ChangeNumber : ''} ${
              doc.Item.Revision.Name
            } - Редактирование документа`
        )
      );
  }
}

@Injectable({ providedIn: 'root' })
export class DocViewTitleResolver {
  constructor(private documentService: DocsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const docId = route.paramMap.get('id');
    if (!docId) return of(null);
    return this.documentService
      .getDocument(docId)
      .pipe(
        map(
          (doc) =>
            $localize`${doc.Item.Number} ${doc.Item.ChangeNumber ? doc.Item.ChangeNumber : ''} ${
              doc.Item.Revision.Name
            } - Документ`
        )
      );
  }
}

@Injectable({ providedIn: 'root' })
export class ViewDocApproveTitleResolver {
  constructor(private docApproveService: DocApproveService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const approveId = route.paramMap.get('id');
    return this.docApproveService
      .get(approveId)
      .pipe(map((approve) => $localize`${approve.Item.Code} - Согласование документа`));
  }
}

@Injectable({ providedIn: 'root' })
export class EditDocApproveTitleResolver {
  constructor(private docApproveService: DocApproveService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const approveId = route.paramMap.get('id');
    return this.docApproveService
      .get(approveId)
      .pipe(map((approve) => $localize`${approve.Item.Code} - Редактирование согласования документа`));
  }
}

@Injectable({ providedIn: 'root' })
export class ViewApproveTaskTitleResolver {
  constructor(private docApproveTaskService: DocApproveTaskService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const taskId = route.paramMap.get('id');
    return this.docApproveTaskService
      .getById(taskId)
      .pipe(
        map(
          (task) =>
            $localize`${task.Item.Document.Number ? task.Item.Document.Number : '-'} - Задача согласования документа`
        )
      );
  }
}

@Injectable({ providedIn: 'root' })
export class DocApproveLaunchTitleResolver {
  constructor(private documentService: DocsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const docId = route.paramMap.get('id');
    return this.documentService
      .getDocument(docId)
      .pipe(
        map(
          (doc) =>
            $localize`${doc.Item.Number} ${doc.Item.ChangeNumber ? doc.Item.ChangeNumber : ''} ${
              doc.Item.Revision.Name
            } - Запуск процесса согласования документа`
        )
      );
  }
}

@Injectable({ providedIn: 'root' })
export class ViewDepartmentTitleResolver {
  constructor(private depService: OrganizationsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const depId = route.paramMap.get('id');
    return this.depService.get(depId).pipe(map((dep: any) => $localize`${dep.Code} - Орг. единица`));
  }
}

@Injectable({ providedIn: 'root' })
export class EditProjectTitleResolver {
  constructor(private projectsService: ProjectsService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const projId = route.paramMap.get('id');
    return this.projectsService
      .get(projId)
      .pipe(map((proj: any) => $localize`${proj.Item.CodeTitle} - Редактирование и настройка проекта`));
  }
}

@Injectable({ providedIn: 'root' })
export class ViewChecklistTitleResolver {
  constructor(private checklistTemplateService: ChecklistTemplateService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const checkListId = route.paramMap.get('id');
    return this.checklistTemplateService
      .getChecklistTemplate(checkListId)
      .pipe(map((checkList: any) => $localize`${checkList.Name} - Шаблон чек-листа`));
  }
}

@Injectable({ providedIn: 'root' })
export class EditChecklistTitleResolver {
  constructor(private checklistTemplateService: ChecklistTemplateService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const checkListId = route.paramMap.get('id');
    return this.checklistTemplateService
      .getChecklistTemplate(checkListId)
      .pipe(map((checkList: any) => $localize`${checkList.Name} - Редактирование шаблона чек-листа`));
  }
}

@Injectable({ providedIn: 'root' })
export class EditMatrixDocsTitleResolver {
  constructor(private matrixDocService: MatrixDocService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const matId = route.paramMap.get('id');
    return this.matrixDocService
      .getById(matId)
      .pipe(map((mat: any) => $localize`${mat.Name} - Редактирование правила предоставления доступа к документам`));
  }
}

@Injectable({ providedIn: 'root' })
export class EditRemarkRouteTitleResolver {
  constructor(private approveRouteService: ApproveRouteService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const routeId = route.paramMap.get('id');
    return this.approveRouteService
      .getById(routeId)
      .pipe(
        map((route: any) => $localize`${route.Name ? route.Name + ' - ' : ''}Редактирование маршрута согласования`)
      );
  }
}

@Injectable({ providedIn: 'root' })
export class ViewTransmittalTitleResolver {
  constructor(private transmittalService: TransmittalService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const transmittalId = route.paramMap.get('id');
    return this.transmittalService
      .get(transmittalId)
      .pipe(map((transmittal) => $localize`${transmittal.Item.Number} - Сопроводительный документ`));
  }
}
