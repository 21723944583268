import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DownloadsQuery } from './store/downloads.query';
import { DownloadsService } from './downloads.service';
import { ConfirmService } from '../confirm-popup/confirm.service';

@Component({
  selector: 'lib-pp-ui-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadsComponent {
  private downloadsQuery = inject(DownloadsQuery);
  private downloadsService = inject(DownloadsService);
  private confirmService = inject(ConfirmService);

  statuses$ = this.downloadsQuery.statuses$;

  showPopup$ = this.downloadsQuery.showDownloads$;

  constructor() {}

  cancel(status: string, id: number) {
    if (['start', 'pending'].includes(status)) {
      this.confirmService.confirm(
        {
          message: $localize`Вы действительно хотите отменить загрузку?`,
          title: $localize`Подтвердите действие`,
          btnOkText: $localize`Да`,
          btnCancelText: $localize`Отмена`,
          btnOkType: 'danger',
        },
        () => {
          this.downloadsService.removeDownload(id);
        }
      );
    } else {
      this.downloadsService.removeDownload(id);
    }
  }

  close() {
    this.downloadsService.toggleDownloads();
  }
}
