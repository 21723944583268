<dx-tree-list #treeList [dataSource]="dataSource" [height]="hostRef.nativeElement?.offsetHeight" [width]="'100%'" 
  id="table-view-wrapper"
  [columns]="columns"
  keyExpr="Id"
  dataStructure="plain"
  parentIdExpr="ParentId"
  [rootValue]="null"
  [showBorders]="false"
  [filterRow]="{visible: true}"
  [allowColumnResizing]="true"
  [headerFilter]="{visible: true}"
  [showRowLines]="false"
  columnResizingMode="widget"
  [columnMinWidth]="65"
  [allowColumnReordering]="true"
  [stateStoring]="{enabled: true, type: 'localStorage', storageKey: treeListStorageKey}"
  (onContentReady)="setNoData($event)"
  (onRowExpanded)="addRowKey($event)"
  (onRowCollapsed)="deleteRowKey($event)"

  [remoteOperations]="{
    sorting: true
  }"
  [pager]="{
    allowedPageSizes: [25, 50, 75, 100],
    showPageSizeSelector: true,
    visible: true
  }"
  [paging]="{
    pageSize: 25,
    enabled: true
  }"
  [scrolling]="{
    mode: 'virtual',
    renderAsync: true
  }"
>
  <!-- hasItemsExpr="hasItems" -->

  <!-- [scrolling]="{
    mode: 'virtual',
    renderAsync: true,
  }" -->
  
  <div *dxTemplate="let rowData of 'titleCellTemplate'">
    <a *ngIf="rowData.data.ProjectId; else notProj"
      [routerLink]="rowData.data.navigatePath" [queryParams]="rowData.data.navigateParams"
    >{{rowData.data.Name}}</a>
    <ng-template #notProj>{{rowData.data.Name}}</ng-template>
  </div>

  <div *dxTemplate="let rowData of 'imageCellTemplate'">
    <ng-container *ngIf="rowData.data.ProjectId">
      <img #securedImg *ngIf="rowData.data.ImageId; else imgPlaceholder" class="table-img" [src]="getImgUrl(rowData.data.ProjectId) | secureImg:(rowData.data.ImageId) | async" id="{{rowData.data.ImageId}}"/>
    </ng-container>
  </div>

  <div *dxTemplate="let rowData of 'logoCellTemplate'">
    <ng-container *ngIf="rowData.data.ProjectId">
      <img #securedImg *ngIf="rowData.data.LogoId; else imgPlaceholder" class="table-img" [src]="getLogoUrl(rowData.data.ProjectId) | secureImg:(rowData.data.LogoId) | async" id="{{rowData.data.LogoId}}"/>
    </ng-container>
  </div>

  <ng-template #imgPlaceholder>
    <div class="placeholder-background">
      <img class="table-img_placeholder" src="assets/images/svg/Project.svg" />
    </div>
  </ng-template>

  <div *dxTemplate="let rowData of 'isActiveCellTemplate'">
    <dx-check-box *ngIf="rowData.data.IsActive !== null" [value]="rowData.data.IsActive" [readOnly]="true"></dx-check-box>
  </div>

</dx-tree-list>

<ng-template #noDataTemplate>
  <div>
    <img class="nodata-img" src="assets/images/svg/Project.svg" />
    <div i18n>Нет доступных проектов<br>Обратитесь к администратору для получения более подробной информации</div>
  </div>
</ng-template>