import notify from 'devextreme/ui/notify';
import { HttpErrorResponse } from '@angular/common/http';

export function handleError(error: any, customMessage?: string): Promise<any> {
  if (customMessage) {
    notify({ message: customMessage }, 'error', 10000);
    Promise.reject();
    return null;
  }
  if (error.serverCode) {
    const alertMessage = getAlertMessage(error.serverCode);
    if (alertMessage) {
      notify({ message: alertMessage }, 'error', 10000);
      return Promise.reject(getNameHttpErrorStatus(error));
    }
  } else if (error.status !== undefined) {
    const message = getNameHttpErrorStatus(error);
    notify({ message }, 'error', 10000);
    return Promise.reject(message);
  }

  return Promise.reject(error);
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getNameHttpErrorStatus(error: HttpErrorResponse) {
  switch (error.status) {
    // case 0 : {
    //   return '0: Critical error';
    // }
    case 400: {
      return '400 Неверный запрос';
    }
    case 401: {
      return '401 Вы не авторизованы';
    }
    case 403: {
      return '403 Недостаточно прав';
    }
    case 404: {
      return '404 Сервис не найден';
    }
    case 405: {
      return '405 Сервис не может ответить';
    }
    case 409: {
      return '409 Конфликт';
    }
    case 413: {
      return '413 Слишком большой запрос';
    }
    case 500: {
      return '500 Ошибка сервера';
    }
    case 501: {
      return '501 Сервис не может выполниться';
    }
    case 502: {
      return '502 Плохой шлюз';
    }
    case 503: {
      return '503 Сервис недоступен';
    }
    case 504: {
      return '504 Время ответа вышло';
    }
    default: {
      return error.message;
    }
  }
}

export function getAlertMessage(code: number | string): string {
  switch (code) {
    case 600:
      return 'Данное значение нельзя удалить, т.к. оно используется в других элементах';
    case 601:
      return 'Данные находятся в невалидном состоянии. Невозможно использовать';
    case 602:
      return 'Сущность неактивна. Невозможно использовать';
    case 603:
      return 'Запрещено';
    case 604:
      return 'Сущность удалена';
    default:
      return null;
  }
}

export class A0Error {
  message: string;
  status = 0;
  constructor(msg: string) {
    msg = isJsonString(msg) ? JSON.parse(msg) : msg;
    msg = decodeURI(msg);
    this.message = msg;
  }
}

export class BackendError {
  message: string;
  status = 0;
  serverCode = 0;

  constructor(err: HttpErrorResponse) {
    if (err.error) {
      let A0msg = err.error.Message;
      A0msg = isJsonString(A0msg) ? JSON.parse(A0msg) : A0msg;
      if (A0msg === undefined || !A0msg.message) {
        this.status = err.status;
      } else {
        this.message = decodeURI(A0msg.message);
        this.serverCode = +A0msg.code;
      }
    } else {
      this.status = err.status;
    }
  }
}
