export class LocalStorageConstants {
  public static readonly ACESS_TOKEN = 'access_token';
  public static readonly USER_LANG = 'userLang';

  public static readonly ROOT: { readonly [key: string]: string } = {
    PROJECT: 'rootProject',
    CATALOG: 'selectedCatalogId',
    CATALOG_TREE_LIST: 'PP_catalogs_viewer_state',
    OBJECTS_TAB_WIDTH: 'objects_side_tab_width',
    MAIN_PAGE_VIEW: 'pp_main_page_view',
  } as const;

  public static readonly ADMIN: { readonly [key: string]: string } = {
    REMARK_ROUTES_GRID_STATE: 'remarkRoutesListGridState',
  } as const;

  public static readonly ARCHIVE: { readonly [key: string]: string } = {
    REMARK_FILTER: 'archiveRemarkFilterParam',
    TRANSMITTAL_CREATE_CATEGORY: 'categoryIdTransmittalCreate',
    TRANSMITTAL_CREATE_CONTRACT: 'contractTransmittalCreate',
    DOCUMENT_TAB_INDEX: 'documentTabIndex',
    TRANSMITTAL_VIEW_SPLIT_SIZE: 'transmittalViewSplitSize',
    VIEWER_REMARKS_GRID: 'comparingViewerRemarksGrid',
  } as const;

  public static readonly QC: { readonly [key: string]: string } = {
    TAB_INDEX: 'QCTabIndex',
    INSPECTIONS_SELECTED_FILTER: 'inspectionPageFilterParam',
    REMARKS_SELECTED_FILTER: 'remarksPageFilterParam',
    NEW_REMARK: 'QCnewRemark',
    NEW_INSPECTION: 'QCnewInspection',
    REMARK_RIGHT_PANEL_VISIBLE: 'QC_viewRemark_right_panel_visible',
    REMARKS_FILTERS_WIDTH: 'QC_remarks_filters_width',
    REMARKS_PRESCRIPTIONS_WIDTH: 'QC_remarks_prescriptions_width',
    INSPECTIONS_FILTERS_WIDTH: 'QC_inspections_filters_width',
    VIEWER_REMARKS_GRID: 'qcViewerRemarksGrid',
  } as const;

  public static readonly MANAGING_SMR: { readonly [key: string]: string } = {
    MSMR_WORK_LIST: 'MSMR_work_list_popup',
    AWC_RIGHT_PANEL_VISIBLE: 'MSMR_awc_right_panel_visible',
  } as const;
}
