import { Component, Input } from '@angular/core';
import { DxFileUploaderComponent, DxTextAreaComponent } from 'devextreme-angular';

@Component({
  selector: 'pp-ui-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent {
  @Input() uploadCallback: (textArea: DxTextAreaComponent, fileUploader: DxFileUploaderComponent) => void = () => {};

  comment: string = '';
  files: File[] = [];

  isValid: boolean = false;

  maxSize = 52428800;

  constructor() {}

  clearForms(textArea: DxTextAreaComponent, fileUploader: DxFileUploaderComponent) {
    return () => {
      textArea.value = '';
      fileUploader.instance.reset();
      fileUploader.instance.repaint();
    };
  }

  validate() {
    if (this.files.length) {
      let isContainOversizeFile = !!this.files.find((file) => file.size > this.maxSize);
      this.isValid = !isContainOversizeFile;
    } else {
      this.isValid = !!this.comment;
    }
  }
}
