<dx-popover
  target="#hidePoint"
  [position]="positionPopover"
  [width]="450"
  [(visible)]="showPopover"
>
  <div *dxTemplate="let data = model; of: 'content'">
    <ul>
      <li *ngFor="let item of objectInfo">{{item.attr_name}} : {{item.value}}</li>
    </ul>
  </div>
</dx-popover>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#graphicsView' }"
  [(visible)]="loadingModelVisible"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
>
</dx-load-panel>

<div style="height: calc(100vh - 56px)">
  <as-split direction="horizontal" [restrictMove]="true" [useTransition]="true" unit="pixel">
    <as-split-area [size]="315" [minSize]="315">
      <as-split direction="vertical" [restrictMove]="true">
        <app-model-tree (selectNode)="selectNode($event)" (visibleNode)="visibleNode($event)" *ngIf="viewCreated"></app-model-tree>
      </as-split>
    </as-split-area>
    <as-split-area [size]="null" [minSize]="600">
      <as-split direction="vertical" [restrictMove]="true">
        <as-split-area [size]="50">
          <div class="toolbar-box">
            <dx-toolbar class="bim-toolbox" [(items)]="basicActions" (onInitialized)="setToolbarInstance($event)">
              <div *dxTemplate="let item of 'separatorTemplate'">
                <span class="toolbar-separator">|</span>
              </div>
            </dx-toolbar>
          </div>
          <div id="hidePoint" style="width: 1px"></div>
          <div id="graphicsView">
            <div class="loading" *ngIf="loadingViewer">
              <div>Загружаем вьювер...</div>
            </div>
          </div>
        </as-split-area>
        <as-split-area [size]="50">
          <app-details></app-details>
        </as-split-area>
      </as-split>
    </as-split-area>
    <as-split-area [size]="300" [maxSize]="800" [visible]="true">
      <app-right-panel (commentGoTo)="zoom($event)" (commentGoToSavedView)="zoomToSavedView($event)"></app-right-panel>
    </as-split-area>
  </as-split>
</div>

<app-select-model
  localStorageKey="BIMFilesSelectPopup"
  (filesSelected)="getChangeExplorerSelectPopup($event)"
></app-select-model>

<app-comment [popupVisible]="showCommentPopup" (popupChange)="getChangeCommentPopup($event)"></app-comment>
