import { Component } from '@angular/core';
import { ViewerQuery } from '../../store/viewer.query';
import { BackEndService } from '../../services/back-end.service';
import _ from 'lodash';

export const IIFCAttrs = [
  'Имя',
  'Тип',
  'STEEL_PROF_Y0',
  'STEEL_PROF_RADIUS_FLANGE',
  'STEEL_PROF_FLANGE_THICKNESS',
  'STEEL_DIM_LENGTH',
  'STEEL_DIM_ITEMLEN',
  'STEEL_PROF_AREA',
  'STEEL_DIM_CONNECT_2',
  'PART_WEIGHT_NETTO',
  'PART_WEIGHT',
  'PART_TYPE',
  'PART_STANDARD',
  'STEEL_PROF_WIDTH',
  'PART_SPECIALITY',
  'AEC_STEEL_GROUP',
  'STEEL_PROF_PERIMETER',
  'STEEL_PROF_THICKNESS',
  'STEEL_PROF_RADIUS_WEB',
  'STEEL_PROF_HEIGHT',
  'EXPLICATION_INCLUDE',
  'AEC_STEEL_CODE',
  'PART_GROUP',
  'STEEL_DIM_CONNECT_1',
  'BOM_SORT_ID',
  'BOM_GROUP_ID',
  'PART_NAME',
  'BOM_PART_QTY',
  'Globalld',
  'Name',
  'Description',
  'OwnerHistory',
  'PART_SURFACE_AREA',
  'MS_OBJECT_TYPE_DESCR',
  'MS_OBJECT_TYPE',
  'DIM_WIDTH',
  'DIM_HEIGHT',
  'AEC_ASSEMBLY_PARENTID',
  'EXPLICATION_NUMBER',
  'PART_BODY_VOLUME',
  'DOC_PROJECT_CODE',
  'DIM_LENGTH',
  'MS_OBJECT_COLOR',
  'DIM_DEPTH_MIN',
  'PART_MATERIAL_STANDARD',
  'DIM_DISTANCE_BOLT_MIN',
  'DIM_DISTANCE_BOLT_FACE_MIN',
  'PART_MATERIAL',
  'BOM_INCLUDE',
  'PART_STATUS',
  'PART_TAG',
  'EXPLICATION_COMMENT',
  'PART_MATERIAL_CLASS',
  'DIM_VOLUME',
];

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent {
  IFCAttrs: any = [];
  now = new Date().toLocaleDateString();

  columns = [];

  constructor(
    private viewerQuery: ViewerQuery,
    backEndService: BackEndService
  ) {
    IIFCAttrs.forEach((x, i) => {
      this.columns.push({
        caption: x,
        dataField: 'c_' + i,
      });
    });

    viewerQuery.modelIds$.subscribe((id) => {
      this.IFCAttrs = [];
      if (id) {
        backEndService.getAllForGrid(id, IIFCAttrs).subscribe((items) => {
          items.forEach((item, index) => {
            items[index] = _.mapKeys(item, function (value, key) {
              return 'c_' + key;
            });
            items[index].Id = index;
          });
          this.IFCAttrs = items;
        });
      }
    });
  }
}
