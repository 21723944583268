import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SettingsAppService } from '@pp/settings';
import { KeycloakAngularModule } from 'keycloak-angular';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthStoreQuery, AuthStoreService } from '@pp/auth-store';
import { AuthService } from './auth.service';
import { Subscription } from 'rxjs';

export function AppInitializerFactory(
  settingsAppService: SettingsAppService,
  authStoreService: AuthStoreService,
  authService: AuthService,
  authStoreQuery: AuthStoreQuery
) {
  return () =>
    new Promise((resolve, reject) => {
      settingsAppService.getAuthConfig().subscribe(
        (config) => {
          authStoreService.addAuthConfig(config);

          if (config.typeAuth == 'keycloak') {
            authService.startKeycloakAuth(config.oathSettings.keycloak);
          } else if (config.typeAuth == 'adfs') {
            authService.startAdfsAuth(config.oathSettings.adfs);
          } else {
            authService.addEmptyToken();
          }

          let sub: Subscription = authStoreQuery.getAccessToken$.subscribe((res) => {
            if (res) {
              settingsAppService.getActiveModules().subscribe(() => {});
              resolve(true);
              sub.unsubscribe();
            }
          });
        },
        (error) => {
          // new BackendError(handleError(error));
          reject(false);
        }
      );
    });
}

@NgModule({
  imports: [
    KeycloakAngularModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
      },
    }),
  ],
  exports: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [SettingsAppService, AuthStoreService, AuthService, AuthStoreQuery],
      multi: true,
    },
  ],
})
export class AuthModule {}
