<!--<pre>{{modelTree | json}}</pre>-->
<!--<dx-button icon="collapse" (onClick)="collapseAll()"></dx-button>-->
<!--<dx-button icon="expand" (onClick)="expandAll()"></dx-button>-->
<!--<dx-tree-view style="height: calc(100vh - 85px)"-->
<!--  displayExpr="name" itemsExpr="children" [searchEnabled]="true" [searchMode]="'contains'" [searchExpr]="'name'"-->
<!--  [expandAllEnabled]="false" [virtualModeEnabled]="true"-->
<!--  [dataSource]="modelTree"-->
<!--  [showCheckBoxesMode]="'normal'"-->
<!--  [selectionMode]="'multiple'"-->
<!--  [selectNodesRecursive]="false"-->
<!--  [selectByClick]="false"-->
<!--  (onItemClick)="selectNodeChanged($event)"-->
<!--  (onItemSelectionChanged)="visibleNodeChanged($event)">-->
<!--</dx-tree-view>-->
<dx-tree-list #treeList style="height: calc(100vh - 65px)"
  [dataSource]="modelTree" dataStructure="tree" id="model-tree-wrapper"
  keyExpr="uuid" itemsExpr="children" class="no-background-checkbox-selection"
  [showRowLines]="false" [showBorders]="false" [columnAutoWidth]="true" [showColumnHeaders]="false" [focusedRowEnabled]="true"
              (onToolbarPreparing)="addExpandCollapseButton($event)" [selectedRowKeys]="selectedRowKeys"
              (onSelectionChanged)="visibleNodeChanged($event)" (onRowClick)="selectNodeChanged($event)">
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
  <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
  <dxo-search-panel [visible]="true"></dxo-search-panel>
  <dxi-column dataField="attrs.name" cellTemplate="nameTemplate">
    <div *dxTemplate="let data of 'nameTemplate'">
      {{data.value || 'Элемент модели'}}
    </div>
  </dxi-column>
  <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
</dx-tree-list>
