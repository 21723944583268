import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewerQuery } from '../../store/viewer.query';
import { ViewerService } from '../../store/viewer.service';
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import DataSource from 'devextreme/data/data_source';
import { IModelTree } from '../model-tree/model-tree.component';
import { BackEndService } from '../../services/back-end.service';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss'],
})
export class WorksComponent implements OnInit {
  @ViewChild(DxListComponent) dxList!: any | DxListComponent;

  now = new Date().toLocaleDateString();

  popupVisible = false;
  selectionData: any | undefined;

  currentModel = '';
  currentTreeNode = '';

  tabs = [
    {
      id: 0,
      text: 'Работы',
    },
    {
      id: 1,
      text: 'Документы',
    },
  ];

  data = [];
  documents = [];
  DSWorksForBind: DataSource;
  GWorksForBind: any = { instance: DxDataGridComponent };
  GWorks: any = { instance: {} };

  addBindButtonOptions = {
    text: 'Добавить связь',
    onClick: (e: any) => {
      this.bindWork(this.GWorksForBind.instance.getSelectedRowKeys(), this.selectionData.uuids);
      this.viewerService.updateSelectionData(undefined);
      this.popupVisible = false;
    },
  };

  closeButtonOptions = {
    text: 'Отменить',
    onClick: (e: any) => {
      this.popupVisible = false;
      this.viewerService.updateSelectionData(undefined);
    },
  };

  constructor(
    private httpClient: HttpClient,
    private viewerQuery: ViewerQuery,
    private viewerService: ViewerService,
    private backEndService: BackEndService
  ) {
    this.viewerQuery.selectionData$.subscribe((res: any) => {
      this.selectionData = res;
      this.documents = [];
      this.backEndService.getTaskByObjectId(this.selectionData ? this.selectionData.uuids : []).subscribe((res) => {
        this.data = res;
        this.data.forEach((x: any) => {
          if (x.docs.length > 0) {
            x.docs.forEach((y: any) => {
              this.documents = this.documents.concat(y.doc);
            });
          }
        });
      });
    });

    this.DSWorksForBind = new DataSource({
      key: 'spWorkId',
      loadMode: 'raw',
      load: () => {
        return this.backEndService.getAllTasks().toPromise();
      },
    });
  }

  ngOnInit(): void {
    this.viewerQuery.modelInfo$.subscribe((res) => {
      if (res) {
        if (!this.currentModel) {
          this.currentModel = res.name;
        } else {
          if (this.currentModel != res.name) {
            this.currentModel = res.name;
          }
        }
      }
    });
    this.viewerQuery.selectNode$.subscribe((res) => {
      if (res) {
        if (!this.currentTreeNode) {
          this.currentTreeNode = _.cloneDeep(res.name);
        } else {
          if (this.currentTreeNode != res.name) {
            this.currentTreeNode = _.cloneDeep(res.name);
          }
        }
      }
    });
  }

  // @ts-ignore
  findNode(uuid: string, tree: IModelTree[]) {
    for (const item of tree) {
      // @ts-ignore
      const result = item.uuid == uuid ? item : this.findNode(uuid, item.children);
      if (result) {
        return result;
      }
    }
  }

  bindWork(ids: [], objIds: []) {
    this.backEndService.addTaskToModelId(ids, objIds).subscribe((res) => {
      notify({ message: 'Связь добавлена', width: 300, shading: false }, 'success', 5000);
      this.viewerService.updateSelectionData(_.cloneDeep(this.selectionData));
    });
  }

  deleteBinds() {
    this.backEndService
      .removeTaskToModelId(this.GWorks.instance.getSelectedRowKeys(), this.selectionData.uuids)
      .subscribe((res) => {
        notify({ message: 'Связи удалены', width: 300, shading: false }, 'success', 5000);
        this.viewerService.updateSelectionData(_.cloneDeep(this.selectionData));
      });
  }

  addActionButtons(e: any) {
    console.log(e.toolbarOptions.items);
    let AddButton = {
      widget: 'dxButton',
      location: 'after',
      options: {
        text: 'Добавить связи',
        onClick: () => {
          this.popupVisible = true;
        },
      },
    };
    let DeleteButton = {
      widget: 'dxButton',
      location: 'after',
      options: {
        text: 'Удалить связи',
        onClick: (message?: string) => {
          let result = confirm(
            'Вы собираетесь удалить связи работ графика и элементов BIM-модели. Данное действие невозможно отменить.<br>Удалить связи?',
            'Удаление связей'
          );
          result.then((dialogResult) => {
            if (dialogResult) {
              this.deleteBinds();
            }
          });
        },
      },
    };
    e.toolbarOptions.items.push(AddButton);
    e.toolbarOptions.items.push(DeleteButton);
  }

  setInstance(e: any) {
    this.GWorksForBind.instance = e.component;
  }

  setInstanceWorksGrid(e: any) {
    this.GWorks.instance = e.component;
  }

  clearSelections() {
    this.GWorksForBind.instance.clearSelection();
  }
}

//   Начало "PPIntegration_Start": "2021-11-26T05:00:00Z",
//   Окончание "PPIntegration_Finish": "2022-04-13T21:00:00Z",
//   Начало по ЦП проекта "PPIntegration_BL_Project_Start": "2022-01-24T05:00:00Z",
//   Окончание по ЦП проекта "PPIntegration_BL_Project_Finish": "2022-06-12T13:00:00Z",
//   Планируемое начало "PPIntegration_Planned_Start": "2022-01-24T05:00:00Z",
//   Планируемое окончание "PPIntegration_Planned_Finish": "2022-06-12T13:00:00Z",
//   Факт. начало "PPIntegration_Actual_Start": "2021-11-26T05:00:00Z",
//   Факт. окончание "PPIntegration_Actual__Finish": "2022-04-13T21:00:00Z",

// http://sp2016-test:8080/sites/inkotek/UGFU/_api/lists/getbytitle('Primavera_L4')/items?$select=GUID,Title,PPIntegration_Start,PPIntegration_Finish,PPIntegration_Name,PPIntegration_BL_Project_Start,PPIntegration_BL_Project_Finish,PPIntegration_Planned_Start,PPIntegration_Planned_Finish,PPIntegration_Actual_Start,PPIntegration_Actual__Finish&$top=4000
