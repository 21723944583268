import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { Profile, UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-portal-core-user-info',
  templateUrl: 'user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  @Input()
  profile: Profile;

  @Input()
  userId: string;

  noOrg = $localize`Нет связанной организации`;

  constructor(private userService: UsersService) {}

  ngOnInit() {
    if (this.userId) {
      this.userService.getMyProfile().subscribe((profile: Profile) => {
        this.profile = profile;
      });
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [UserInfoComponent],
  exports: [UserInfoComponent],
})
export class UserInfoModule {}
