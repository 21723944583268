<dx-toast #toast
  [displayTime]="displayTime"
  [type]="type"
  [visible]="isVisible"
  [wrapperAttr]="{id: 'notifier-wrapper'}"
>
  <div *dxTemplate="let data of 'content'" class="content-wrapper">
    <i class="dx-icon dx-icon-{{icon}}" [ngClass]="getResultColorClass()"></i>
    <ng-container *ngFor="let part of text">
      <ng-container *ngIf="part.bold; else elseBlock">
        <span class="bold">{{ part.text }}</span>
      </ng-container>
      <ng-template #elseBlock>
        {{ part.text }}
      </ng-template>
    </ng-container>
    <i class="dx-icon dx-icon-close close-btn" (click)="hide()"></i>
  </div>
</dx-toast>
