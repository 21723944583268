import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from 'src/app/services';
import { IObjectSimple } from 'projects/shared/interfaces/object.interface';
import { IMeasureUnitMinified, IProjectSimple, IStructuralElement, IWorkTypeMinified } from '@pp/interfaces';
import { ActualWorkCompletion } from 'projects/quality-control/src/services/actual-work-completion.service';
import { IContract } from 'projects/shared/interfaces/contract.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkVolumeService {
  private coreUrl: string;
  private serviceUrl: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {
    this.coreUrl = environment.apiUrl;
    this.serviceUrl = environment.workVolumeUrl;
  }

  public getAll(): Observable<WorkVolume[]> {
    return this.http.get<WorkVolume[]>(this.coreUrl + this.serviceUrl + 'GetAll').pipe(
      map((x: any) => {
        for (const item of x) {
          item.CodeName = item.WorkCode ? item.WorkCode + '. ' + item.Name : item.Name;
        }
        return x;
      })
    );
  }

  public getAllByProject(projectId: string): Observable<WorkVolume[]> {
    return this.http.get<WorkVolume[]>(this.coreUrl + this.serviceUrl + 'GetAllByProjectId?id=' + projectId).pipe(
      map((x: any) => {
        for (const item of x) {
          item.CodeName = item.WorkCode ? item.WorkCode + '. ' + item.Name : item.Name;
        }
        return x;
      })
    );
  }

  public getAllByProjectWithAWC(projectId: string): Observable<WorkVolumeWithAWC[]> {
    return this.http.get<WorkVolume[]>(this.coreUrl + this.serviceUrl + 'GetAllByProjectId?id=' + projectId).pipe(
      map((res) => {
        return res.map((item) => {
          return { ...item, CodeName: item.WorkCode ? item.WorkCode + '. ' + item.Name : item.Name };
        });
      }),
      map((items) => {
        let AWCs: Partial<WorkVolumeWithAWC> = {};
        Array.from({ length: 31 }, (value, index) => index).forEach((index) => {
          const day = index + 1;
          AWCs[`AWC${day.toString().padStart(2, '0')}`] = new ActualWorkCompletion();
        });

        return items.map((item) => {
          return {
            ...item,
            PeriodSum: 0,
            ...AWCs,
          } as WorkVolumeWithAWC;
        });
      })
    );
  }

  public getById(Id: string) {
    return this.http
      .get<{
        Item: WorkVolumeSingle;
        Permissions: {
          View: boolean;
          Update: boolean;
          Delete: boolean;
          CreateActualWorkCompletionLog: boolean;
        };
      }>(this.coreUrl + this.serviceUrl + `/Get?id=${Id}`)
      .pipe(
        map((x: any) => {
          x.Item.CodeName = x.Item.WorkCode ? x.Item.WorkCode + '. ' + x.Item.Name : x.Item.Name;
          return x;
        })
      );
  }

  public create(model: CreationWorkVolume): Observable<any> {
    return this.http.post(this.coreUrl + this.serviceUrl + `/Create`, model, this.httpOptions);
  }

  public update(model: UpdatingWorkVolume): Observable<any> {
    return this.http.post(this.coreUrl + this.serviceUrl + `/Update`, model, this.httpOptions);
  }

  public delete(id: string): Observable<any> {
    return this.http.delete(this.coreUrl + this.serviceUrl + `/Delete?id=${id}`);
  }

  public importFromExcel(model: ExcelImport): Observable<{
    All: number;
    Processed: number;
    NotProcessed: number;
    Errors: {
      ColumnNumber?: number;
      RowNumber?: number;
      Message: string;
    }[];
  }> {
    return this.http
      .post<{
        All: number;
        Processed: number;
        NotProcessed: number;
        RowsErrors: {
          RowNumber: number;
          ColumnNumber: string;
          Messages: string[];
        }[];
        CommonErrors: string[];
      }>(this.coreUrl + this.serviceUrl + `/Import`, model, this.httpOptions)
      .pipe(
        map((res) => {
          let flattenErrors = [];
          res.RowsErrors.forEach((err) => {
            Object.values(err.Messages).forEach((msg) => {
              flattenErrors.push({ RowNumber: err.RowNumber, Message: msg, ColumnNumber: err.ColumnNumber });
            });
          });

          return {
            All: res.All,
            Processed: res.Processed,
            NotProcessed: res.NotProcessed,
            Errors: [
              ...res.CommonErrors.map((errMsg) => {
                return { Message: errMsg };
              }),
              ...flattenErrors,
            ],
          };
        })
      );
  }

  // public ReplaceDepartment(model: ReplacingDepartment): Observable<any> {
  //   return this.http.post(this.coreUrl + this.serviceUrl + `/ReplaceDepartment`, model, this.httpOptions)
  // }

  public UpdateContract(model: any): Observable<any> {
    return this.http.post(this.coreUrl + this.serviceUrl + `/UpdateContract`, model, this.httpOptions);
  }

  public getProjectsMyForCreate() {
    return this.http.get(this.coreUrl + this.serviceUrl + 'GetProjectsMyForCreate').pipe(
      map((arr: any) => {
        for (const item of arr) {
          item.CodeTitle = item.Code ? item.Code + '. ' + item.Title : item.Title;
        }
        return arr;
      })
    );
  }

  public getProjectsMyForUpdate() {
    return this.http.get(this.coreUrl + this.serviceUrl + 'GetProjectsMyForUpdate').pipe(
      map((arr: any) => {
        for (const item of arr) {
          item.CodeTitle = item.Code ? item.Code + '. ' + item.Title : item.Title;
        }
        return arr.filter((item) => item.IsActive);
      })
    );
  }

  public updateCurator(curatorId: string, workVolumeListsIds: string[]) {
    return this.http.post(
      this.coreUrl + this.serviceUrl + `/UpdateCurator`,
      {
        CuratorId: curatorId,
        WorkVolumeListsIds: workVolumeListsIds,
      },
      this.httpOptions
    );
  }

  public isUnique(body: IForUniqueItem) {
    return this.http.post(this.coreUrl + this.serviceUrl + `/IsUnique`, body, this.httpOptions);
  }
}

export class WorkVolume {
  Id: string;
  DocumentNumber: string;
  MeasureUnitTitle: string;
  MeasureUnitId: string;
  Volume: number;
  WorkTypeTitle: string;
  WorkTypeId: string;
  BlueprintMarkTitle: string;
  BlueprintMarkId: string;
  ObjectStructurePath: string;
  ObjectStructureCode: string;
  ObjectStructureTitle: string;
  ObjectStructureId: string;
  ProjectCode: string;
  ProjectTitle: string;
  ProjectId: string;
  Modified: string;
  ModifiedBy: string;
  ModifiedById: string;
  Created: string;
  CreatedBy: string;
  CreatedById: string;
  ActualWorkCompletionLogsActualVolume: number;
  NotUsedInWorkCompletionPlansVolume: number;
  NotUsedInActualWorkCompletionLogsVolume: number;
  Name: string;
  WorkCode: string;
  Permissions: {
    Update: boolean;
    Delete: boolean;
    CreateActualWorkCompletionLog: boolean;
  };
  WorkCost: number;
  WBSElement: string;
  TechPosition: string;
  StructuralElementId: string;
  StructuralElementTitle: string;
  Mark: string;
  Curator: string;
  CuratorId: string;
  LocalEstimateName: string;
  LocalEstimateNumber: string;
  LocalEstimateItemNumber: string;
  ContractNumber: string;
  ContractConclusionDate: string;
  ContractCustomerShortName: string;
  ContractCustomerFullName: string;
  ContractCustomerId: string;
  ContractContractorShortName: string;
  ContractContractorFullName: string;
  ContractContractorId: string;
  ContractId: string;
  UnitPriceNumber: string;
  UnitPrice: number;
}

export class WorkVolumeWithAWC extends WorkVolume {
  PeriodSum: number;
  AWC01: ActualWorkCompletion;
  AWC02: ActualWorkCompletion;
  AWC03: ActualWorkCompletion;
  AWC04: ActualWorkCompletion;
  AWC05: ActualWorkCompletion;
  AWC06: ActualWorkCompletion;
  AWC07: ActualWorkCompletion;
  AWC08: ActualWorkCompletion;
  AWC09: ActualWorkCompletion;
  AWC10: ActualWorkCompletion;
  AWC11: ActualWorkCompletion;
  AWC12: ActualWorkCompletion;
  AWC13: ActualWorkCompletion;
  AWC14: ActualWorkCompletion;
  AWC15: ActualWorkCompletion;
  AWC16: ActualWorkCompletion;
  AWC17: ActualWorkCompletion;
  AWC18: ActualWorkCompletion;
  AWC19: ActualWorkCompletion;
  AWC20: ActualWorkCompletion;
  AWC21: ActualWorkCompletion;
  AWC22: ActualWorkCompletion;
  AWC23: ActualWorkCompletion;
  AWC24: ActualWorkCompletion;
  AWC25: ActualWorkCompletion;
  AWC26: ActualWorkCompletion;
  AWC27: ActualWorkCompletion;
  AWC28: ActualWorkCompletion;
  AWC29: ActualWorkCompletion;
  AWC30: ActualWorkCompletion;
  AWC31: ActualWorkCompletion;
}

export interface WorkVolumeSingle {
  CreatedBy: User;
  CreatedById: string;
  ModifiedBy: User;
  ModifiedById: string;
  Created: string;
  Modified: string;
  Id: string;
  DocumentNumber: string;
  MeasureUnit: IMeasureUnitMinified;
  MeasureUnitId: string;
  Volume: number;
  WorkType: IWorkTypeMinified;
  WorkTypeId: string;
  BlueprintMark: {
    Id: string;
    Title: string;
    DisciplineId: string;
    IsActive: boolean;
    Order: number;
    Description: string;
  };
  BlueprintMarkId: string;
  ObjectStructure: IObjectSimple;
  ObjectStructureId: string;
  Project: IProjectSimple;
  ProjectId: string;
  ActualWorkCompletionLogsActualVolume: number;
  NotUsedInWorkCompletionPlansVolume: number;
  NotUsedInActualWorkCompletionLogsVolume: number;
  DeclaredWorkCompletionLogsVolume: number;
  AcceptedWorkCompletionLogsVolume: number;
  RejectedWorkCompletionLogsVolume: number;
  InspectionRequestedWorkCompletionLogsVolume: number;
  InspectionPassedWorkCompletionLogsVolume: number;
  InspectionNotPassedWorkCompletionLogsVolume: number;
  Name: string;
  WorkCode: string;
  WorkCost: number;
  WBSElement: string;
  TechPosition: string;
  StructuralElementId: string;
  StructuralElement: IStructuralElement;
  Mark: string;
  Curator: User;
  CuratorId: string;
  LocalEstimateName: string;
  LocalEstimateNumber: string;
  LocalEstimateItemNumber: string;
  Contract: IContract;
  ContractId: string;
  UnitPriceNumber: string;
  UnitPrice: number;
}

export class UpdatingWorkVolume {
  DocumentNumber: string;
  MeasureUnitId: string;
  Volume: number;
  WorkTypeId: string;
  BlueprintMarkId: string;
  ObjectStructureId: string;
  ProjectId: string;
  DepartmentId: string;
}

export class CreationWorkVolume extends UpdatingWorkVolume {
  Id: string;
}

export class ExcelImport {
  Data: string;
  Mode: ExcelImportModeEnum;
  UpdateByEmptyValue: boolean;
  ProjectId: string;
}

export enum ExcelImportModeEnum {
  Add = 0,
  Update = 1,
}

export class ReplacingDepartment {
  DepartmentId: string;
  WorkVolumeListsIds: string[];
}

export interface IForUniqueItem {
  Id?: string;
  WorkCode: string;
  ProjectId: string;
}
