import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxFileUploaderModule,
  DxListModule,
  DxPopoverModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import { socialActivityContainers } from './social-activity.common';
import { ButtonsModule } from '../buttons/buttons.module';
import { NotificationsModule } from '../notifier/notifier.module';
import { ImagePreviewModule } from '../image-preview/image-preview.module';

@NgModule({
  declarations: [...socialActivityContainers],
  imports: [
    CommonModule,
    DxFileUploaderModule,
    DxListModule,
    DxTabPanelModule,
    DxButtonModule,
    ButtonsModule,
    DxPopoverModule,
    NotificationsModule,
    ImagePreviewModule,
  ],
  exports: [...socialActivityContainers],
})
export class SocialActivityModule {}
