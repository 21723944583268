import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ViewerQuery } from '../../store/viewer.query';
import * as c3dviewer from '@c3dlabs/c3dviewer-api';
import { BackEndService } from '../../services/back-end.service';
import { LoadModelsService } from '../../services/load-models.service';
import { catchError, concatMap, from, map, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
})
export class RightPanelComponent implements OnInit {
  @Output() commentGoTo: EventEmitter<any> = new EventEmitter<any>();
  @Output() commentGoToSavedView: EventEmitter<any> = new EventEmitter<any>();
  selection: c3dviewer.GetSelectionListResult | undefined;
  annotationList: c3dviewer.GetAnnotationListResult | undefined;
  savedViews: any;

  ifcAttr: any = null;

  viewerParams: { uuidViewer: string; uuidModel: string };
  loader: boolean;

  constructor(
    private viewerQuery: ViewerQuery,
    private backEndService: BackEndService,
    private loadModelsService: LoadModelsService
  ) {
    this.viewerQuery.viewerParams$.subscribe((res) => (this.viewerParams = res));
  }

  ngOnInit(): void {
    this.viewerQuery.selectionData$.subscribe((res) => {
      this.selection = res;
      if (res && res.uuids.length && res.uuids.length == 1) {
        this.ifcAttr = {};
        this.loader = true;

        let workspace = localStorage.getItem('workspace') || '';

        this.loadModelsService.getWorkspaceParam(workspace).subscribe((workspaceParams: IWorkspaceParam) => {
          let allModels: string[] = [];
          (workspaceParams.models || []).map((x) => allModels.push(x.uuid));

          let subModels: Subscription = from(allModels)
            .pipe(
              concatMap((modelId: string) =>
                this.loadModelsService.getParentNodes(this.viewerParams.uuidViewer, modelId, res.uuids[0]).pipe(
                  map((nodes: any) => {
                    let items: string[] = [];
                    nodes.forEach((x: any) => {
                      items.push(x.uuid);
                    });
                    let subIFCAttrs: Subscription = from(items)
                      .pipe(
                        concatMap((id: string) =>
                          this.backEndService.getIFCAttr(id).pipe(
                            catchError(() => {
                              return of(null);
                            })
                          )
                        )
                      )
                      .subscribe(
                        (nodeResIFCAttrs) => {
                          if (nodeResIFCAttrs) {
                            this.ifcAttr = nodeResIFCAttrs;
                            this.loader = false;
                            subIFCAttrs.unsubscribe();
                            subModels.unsubscribe();
                          }
                        },
                        (error) => null,
                        () => (this.loader = false)
                      );
                  }),
                  catchError(() => {
                    return of(null);
                  })
                )
              )
            )
            .subscribe(
              (modelRes: any) => {
                if (modelRes) {
                  this.loader = false;
                  subModels.unsubscribe();
                }
              },
              (error) => (this.loader = false),
              () => (this.loader = false)
            );
        });
      } else {
        this.ifcAttr = null;
      }
    });
    this.viewerQuery.annotationListData$.subscribe((res) => (this.annotationList = res));
    this.viewerQuery.savedViews$.subscribe((res) => (this.savedViews = res));
  }

  goTo(item: any) {
    this.commentGoTo.emit(item);
  }

  goToSavedView(item: any) {
    this.commentGoToSavedView.emit(item);
  }
}

export interface IWorkspaceParam {
  models?: { url: string; uuid: string }[];
  times?: { created: string; expired: string; updated: string };
  users?: [any];
  uuid?: string;
}
