import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { loadMessages, locale } from 'devextreme/localization';
import ruLocale from '@angular/common/locales/ru';
import messagesRu from 'devextreme/localization/messages/ru.json';
import { LocalStorageConstants } from 'src/app/shared/constants';

registerLocaleData(ruLocale);

export function AppInitializerFactory(injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        new Promise<any>((r: any) => {
          r(null);
        })
      );
      locationInitialized.then(() => {
        let userLang = localStorage.getItem(LocalStorageConstants.USER_LANG);
        const rusLanguagesCode = ['ru', 'ru-RU', 'RU'];
        //когда пользователь посещает сайт со скопированной ссылкой, беру  язык из URL и добавляю  в localstorage
        const pathname = window.location.pathname;
        const pathSegments = pathname.split('/');
        const languageSegment = pathSegments[1];

        if (languageSegment === 'en' || languageSegment === 'ru') {
          localStorage.setItem(LocalStorageConstants.USER_LANG, languageSegment === 'en' ? 'en-US' : 'ru');
        } else {
          if (!userLang) {
            userLang = navigator.language;
            // if (userLang !== 'ru-RU') {
            if (!rusLanguagesCode.includes(userLang)) {
              userLang = 'en-US';
              locale('en-US');
            }
            localStorage.setItem(LocalStorageConstants.USER_LANG, userLang);
            location.reload();
          } else {
            if (!rusLanguagesCode.includes(userLang)) {
              userLang = 'en-US';
              locale('en-US');
            }
          }
        }
        if (rusLanguagesCode.includes(userLang)) {
          loadMessages(messagesRu);
          locale('ru');
        }

        resolve(null);
      });
    });
}

export function getLocale() {
  const dateLocale = localStorage.getItem(LocalStorageConstants.USER_LANG);
  console.log('Date time locale', dateLocale);
  return dateLocale || navigator.language;
}

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [Injector],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: getLocale() },
  ],
  exports: [],
})
export class AppLocalizeModule {}
