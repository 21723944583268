import { Routes } from '@angular/router';
import { AdminGuard } from 'projects/pp-administration/src/guards';
import { QualityControlGuard } from 'projects/quality-control/src/app/guards/quality-control.guard';
import { ResolverQC } from 'projects/quality-control/src/app/guards/resolver-qc.service';
import { ResolverAdmin } from 'projects/pp-administration/src/guards/resolver-admin.service';
import { ArchiveGuard } from 'projects/archive/src/app/guards/archive.guard';
import { Error404Component } from 'src/app/components/error-pages/error404/error404.component';
import { Error403Component } from 'src/app/components/error-pages/error403/error403.component';
import { ControlSmrGuard } from 'projects/control-smr/src/app/guard/control-smr.guard';
import { ResolverSMR } from 'projects/control-smr/src/app/guard/resolver-smr.service';
import { ResolverArchive } from 'projects/archive/src/app/guards/resolver-archive.service';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { BimViewerComponent } from 'projects/ui-components/src/lib/bim-viewer/bim-viewer.component';
import { ErrorNoConnectionComponent } from 'src/app/components/error-pages/error-no-connection/error-no-connection.component';
import { ErrorUndefComponent } from 'src/app/components/error-pages/error-undef/error-undef.component';

// TODO Тут нужно менять с canActivate на canLoad
export const appRoutes: Routes = [
  { path: '', title: 'Проекты - Project Point', pathMatch: 'full', component: MainPageComponent },
  {
    path: 'bim',
    component: BimViewerComponent,
  },
  {
    path: 'quality-control',
    loadChildren: () =>
      import('projects/quality-control/src/app/quality-control.module').then((m) => m.QualityControlModule),
    canActivate: [QualityControlGuard],
    resolve: { ResolverQC },
  },
  {
    path: 'control-smr',
    loadChildren: () => import('projects/control-smr/src/app/control-smr.module').then((m) => m.ControlSmrModule),
    canActivate: [ControlSmrGuard],
    resolve: { ResolverSMR },
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('projects/pp-administration/src/app/pp-administration.module').then((m) => m.PpAdministrationModule),
    canActivate: [AdminGuard],
    resolve: { ResolverAdmin },
  },
  {
    path: 'archive',
    loadChildren: () => import('projects/archive/src/app/app-archive.module').then((m) => m.AppArchiveModule),
    canActivate: [ArchiveGuard],
    resolve: { ResolverArchive },
  },
  {
    path: 'errors',
    children: [
      { path: '', component: Error404Component },
      { path: '403', component: Error403Component },
      { path: '404', component: Error404Component },
      { path: 'noconnection', component: ErrorNoConnectionComponent },
      { path: 'undef', component: ErrorUndefComponent },
      { path: '**', redirectTo: 'undef' },
    ],
  },
  { path: '**', component: Error404Component },
];
