import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ViewerQuery } from '../store/viewer.query';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BackEndService {
  public settings: any;
  private c3dApi: any;

  constructor(
    private _http: HttpClient,
    private viewerQuery: ViewerQuery
  ) {
    this.viewerQuery.viewerSettings$.subscribe((res) => {
      this.settings = res;
      this.c3dApi = this.settings.service.c3dApi;
    });
  }

  public getAllTasks(): Observable<any> {
    return this._http
      .get<any>(this.c3dApi.protocol + '//' + this.c3dApi.host + ':' + this.c3dApi.port + '/' + 'Task/GetAll')
      .pipe(
        map((x) => {
          x.forEach((y: any) => this.delete0101Date(y));
          return x;
        })
      );
  }

  public getAllDocument(): Observable<any> {
    return this._http.get<any>(
      this.c3dApi.protocol + '//' + this.c3dApi.host + ':' + this.c3dApi.port + '/' + 'Document'
    );
  }

  public getTaskByObjectId(objectIds: []): Observable<any> {
    return this._http
      .post<any>(
        this.c3dApi.protocol + '//' + this.c3dApi.host + ':' + this.c3dApi.port + '/' + 'Task/GetTasksForModelIds',
        objectIds
      )
      .pipe(
        map((x) => {
          x.forEach((y: any) => this.delete0101Date(y));
          return x;
        })
      );
  }

  public addTaskToModelId(taskIds: [], modelIds: []): Observable<any> {
    return this._http.post<any>(
      this.c3dApi.protocol + '//' + this.c3dApi.host + ':' + this.c3dApi.port + '/' + 'Task/AddTaskToModels',
      { taskIds, modelIds }
    );
  }

  public removeTaskToModelId(taskids: [], modelids: []): Observable<any> {
    return this._http.post<any>(
      this.c3dApi.protocol + '//' + this.c3dApi.host + ':' + this.c3dApi.port + '/' + 'Task/RemoveTaskToModels',
      { taskids, modelids }
    );
  }

  getIFCAttr(idElement: string) {
    return this._http.get<any>(
      this.c3dApi.protocol +
        '//' +
        this.c3dApi.host +
        ':' +
        this.c3dApi.port +
        '/' +
        'IFCAttribute/GetByElementId?id=' +
        idElement
    );
  }

  getAllForGrid(id: string, columns: string[]) {
    return this._http.post<any>(
      this.c3dApi.protocol +
        '//' +
        this.c3dApi.host +
        ':' +
        this.c3dApi.port +
        '/' +
        'IFCAttribute/GetAllForGrid?id=' +
        id,
      columns
    );
  }

  delete0101Date(x: any) {
    if (x.actual_Start == '0001-01-01T00:00:00') {
      x.actual_Start = null;
    }
    if (x.actual_Finish == '0001-01-01T00:00:00') {
      x.actual_Finish = null;
    }
    if (x.bL_Project_Finish == '0001-01-01T00:00:00') {
      x.bL_Project_Finish = null;
    }
    if (x.bL_Project_Start == '0001-01-01T00:00:00') {
      x.bL_Project_Start = null;
    }
    if (x.planned_Start == '0001-01-01T00:00:00') {
      x.planned_Start = null;
    }
    if (x.planned_Finish == '0001-01-01T00:00:00') {
      x.planned_Finish = null;
    }
    if (x.start == '0001-01-01T00:00:00') {
      x.start = null;
    }
    if (x.finish == '0001-01-01T00:00:00') {
      x.finish = null;
    }

    if (x.docs.length > 0) {
      x.docs.forEach((y: any) => {
        if (y.doc.revisionDate == '0001-01-01T00:00:00') {
          y.doc.revisionDate = null;
        }
      });
    }

    return x;
  }
}
