import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUser } from 'projects/shared/interfaces/tmp/user.interface';
import { map, Observable } from 'rxjs';
import { ICreatedModifiedBase } from '../entities/base-models';
import { environment } from '../environments/environment';
import { IChecklistItemTemplate, IChecklistItemTemplateCreate } from './checklist-item-template.service';
import {
  ChecklistSectionTemplatesCreate,
  IChecklistSectionTemplatesCreate,
} from './checklist-section-template.service';

export class ChecklistItemTemplateForSave implements IChecklistItemTemplateCreate {
  Title = '';
  SortOrder = 0;
  Type = 0;
  Attachments = false;
  TextDefault = null;
  NumberMin = null;
  NumberMax = null;
  NumberFractionalDigits = null;
  AnswerValueListTemplateId = null;
  ControlType = null;
}
@Injectable({
  providedIn: 'root',
})
export class ChecklistTemplateService {
  private _checklistTemplateUrl: string;
  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  checklisTemplateState: CheckListTemplateCreate;

  constructor(private _http: HttpClient) {
    // TODO PPDEV-837 swith to apiUrl
    this._checklistTemplateUrl = environment.apiUrl + environment.checklistTemplateUrl;
  }
  // ChecklistTemplateService_

  getAllChecklistTemplates(): Observable<IChecklistTemplate[]> {
    return this._http.get<IChecklistTemplate[]>(this._checklistTemplateUrl + 'GetAll');
  }

  getActiveChecklistTemplates(): Observable<IChecklistTemplate[]> {
    return this._http
      .get<IChecklistTemplate[]>(this._checklistTemplateUrl + 'GetAll')
      .pipe(map((checklists: IChecklistTemplate[]) => checklists.filter((cl) => cl.IsActive)));
  }

  getChecklistTemplate(id: string): Observable<IChecklistTemplate> {
    return this._http.get<IChecklistTemplate>(this._checklistTemplateUrl + `Get?id=${id}`).pipe(
      map((res) => {
        res.ChecklistSectionTemplates.sort((a, b) => a.SortOrder - b.SortOrder);

        res.ChecklistSectionTemplates.forEach((section) => {
          section.ChecklistItemTemplates.sort((a, b) => a.SortOrder - b.SortOrder);

          section.ChecklistItemTemplates.forEach((item) => {
            if (item.AnswerValueListTemplate)
              item.AnswerValueListTemplate.Values.sort((a, b) => a.SortOrder - b.SortOrder);
          });
        });
        return res;
      })
    );
  }

  createChecklistTemplate(checklist): Observable<any> {
    return this._http.post(this._checklistTemplateUrl + 'Create', checklist, this._httpOptions);
  }

  updateChecklistTemplate(checklist: ICheckListTemplateUpdate): Observable<any> {
    return this._http.post(this._checklistTemplateUrl + 'Update', checklist, this._httpOptions);
  }

  updateRelatedChecklistTemplate(checklist): Observable<any> {
    return this._http.post(this._checklistTemplateUrl + 'UpdateRelated', checklist, this._httpOptions);
  }

  delete(id: string): Observable<any> {
    return this._http.delete(this._checklistTemplateUrl + `Delete?id=${id}`);
  }

  searchAll(body: { WorkTypeId: string; ProjectId: string; InspectionCategoryId: string }) {
    // return this._http.post(this._checklistTemplateUrl + `SearchAll`, body, this._httpOptions);
    return this._http
      .post<IChecklistTemplate[]>(this._checklistTemplateUrl + `SearchAll`, body, this._httpOptions)
      .pipe(
        map((checklists) => {
          checklists.forEach((cl) => {
            cl.ChecklistSectionTemplates.sort((a, b) => a.SortOrder - b.SortOrder);

            cl.ChecklistSectionTemplates.forEach((clSection) => {
              clSection.ChecklistItemTemplates.sort((a, b) => a.SortOrder - b.SortOrder);
            });
          });
          return checklists;
        })
      );
  }

  getByIds(body: { Ids: string[] }): Observable<IChecklistTemplate[]> {
    return this._http.post<IChecklistTemplate[]>(this._checklistTemplateUrl + 'GetByIds', body).pipe(
      map((checklists: IChecklistTemplate[]) => {
        checklists.forEach((cl) => {
          cl.ChecklistSectionTemplates.sort((a, b) => a.SortOrder - b.SortOrder);

          cl.ChecklistSectionTemplates.forEach((clSection) => {
            clSection.ChecklistItemTemplates.sort((a, b) => a.SortOrder - b.SortOrder);
          });
        });
        return checklists.filter((cl) => cl.IsActive);
      })
    );
  }
}

export interface IChecklistTemplate {
  CreatedBy: IUser;
  CreatedById: string;
  ModifiedBy: IUser;
  ModifiedById: string;
  Created: string;
  Modified: string;
  Id: string;
  Description: null;
  Name: string;
  WorkTypes: { Id: string; Name: string }[];
  AllWorkTypes: boolean;
  Projects: { Id: string; Name: string; Code: string }[];
  AllProjects: boolean;
  InspectionCategories: { Id: string; Name: string }[];
  AllInspectionCategories: boolean;
  IsActive: boolean;
  IsMain?: boolean;
  ChecklistSectionTemplates: {
    CreatedBy: IUser;
    CreatedById: string;
    ModifiedBy: IUser;
    ModifiedById: string;
    Created: string;
    Modified: string;
    Id: string;
    Title: string;
    SortOrder: number;
    ChecklistTemplateId: string;
    ChecklistItemTemplates: IChecklistItemTemplate[];
  }[];
  Statuses: {
    Status: IChecklistTemplateStatus;
    StatusId: string;
    SortOrder: number;
  }[];
  IsStatusUsed: boolean;
}

export interface IChecklistTemplateStatus extends ICreatedModifiedBase {
  Id: string;
  Title: string;
}

export interface ICheckListTemplateCreateBase {
  Name: string;
  WorkTypesIds: string[];
  AllWorkTypes: boolean;
  ProjectsIds: string[];
  AllProjects: boolean;
  InspectionCategoriesIds: string[];
  AllInspectionCategories: boolean;
  IsActive: boolean;
  Statuses: IChecklistTemplateStatusCreate[] | [];
  IsStatusUsed: boolean;
}

export interface ICheckListTemplateCreate extends ICheckListTemplateCreateBase {
  ChecklistSectionTemplates?: IChecklistSectionTemplatesCreate[];
}

export interface IChecklistTemplateStatusCreate {
  StatusId: string;
  SortOrder: number;
  Status?: IChecklistTemplateStatus;
}

export interface ICheckListTemplateUpdate extends ICheckListTemplateCreateBase {
  Id?: string;
}

export class CheckListTemplateCreate implements ICheckListTemplateCreate {
  Id?: string;
  Name = '';
  WorkTypesIds = [];
  AllWorkTypes = true;
  ProjectsIds = [];
  AllProjects = true;
  InspectionCategoriesIds = [];
  AllInspectionCategories = true;
  IsActive = true;

  get ChecklistSectionTemplates() {
    return this._ChecklistSectionTemplates;
  }
  set ChecklistSectionTemplates(val) {
    if (!val || !val.length) {
      this._ChecklistSectionTemplates = [];
      return;
    }
    this._ChecklistSectionTemplates.length = 0;

    val
      .sort((a, b) => a.SortOrder - b.SortOrder)
      .forEach((section, i) => this._ChecklistSectionTemplates.push(new ChecklistSectionTemplatesCreate(i, section)));
  }
  public _ChecklistSectionTemplates = [new ChecklistSectionTemplatesCreate()];

  Statuses: IChecklistTemplateStatusCreate[] = [];
  IsStatusUsed = true;

  constructor(value?: IChecklistTemplate) {
    if (value) {
      Object.keys(value).forEach((key) => {
        this[key] = value[key];
      });
      this.ProjectsIds = value.Projects.map((project) => project.Id);
      this.WorkTypesIds = value.WorkTypes.map((workTypes) => workTypes.Id);
      this.InspectionCategoriesIds = value.InspectionCategories.map((cat) => cat.Id);

      this.ChecklistSectionTemplates?.forEach((section) => {
        section?.ChecklistItemTemplates.forEach((item) => {
          if (item.Type === 5 && item.ControlType === 0) {
            item.Type = 1;
          }
        });
      });

      if (this.Statuses.length) {
        this.Statuses.sort((a, b) => a.SortOrder - b.SortOrder);
      }
    } else {
      this.ChecklistSectionTemplates = [];
    }
  }

  updateValues = (data: IChecklistTemplate): CheckListTemplateCreate | null => {
    if (!data) return null;
    const sourceKeys = Object.keys(data);
    Object.keys(this).forEach((key) => {
      if (sourceKeys.includes(key)) {
        this[key] = data[key];
      }
    });

    this.ChecklistSectionTemplates?.forEach((section) => {
      section?.ChecklistItemTemplates.forEach((item) => {
        if (item.AnswerValueListTemplate) {
          item.AnswerValueListTemplateId = item.AnswerValueListTemplate.Id;
        }

        section.ChecklistItemTemplates.forEach((item) => {
          item.AnswerValueListTemplate.Values.sort((a, b) => a.SortOrder - b.SortOrder);
        });
      });
    });

    this.ProjectsIds = data.Projects.map((project) => project.Id);
    this.WorkTypesIds = data.WorkTypes.map((workTypes) => workTypes.Id);
    this.InspectionCategoriesIds = data.InspectionCategories.map((cat) => cat.Id);
    return this;
  };

  getForSave = () => {
    this.ChecklistSectionTemplates?.forEach((section) => {
      section?.ChecklistItemTemplates.forEach((item) => {
        if (item.Type === 5) {
          item.Type = 5;
          item.ControlType = 1;
        }
        if (item.Type === 1) {
          item.Type = 5;
          item.ControlType = 0;
          item.AnswerValueListTemplate = null;
        }
      });
    });
    const baseItemTemplate = new ChecklistItemTemplateForSave();

    const sections = [...this.ChecklistSectionTemplates].map((section) => {
      let newChecklistItemTemplates = section.ChecklistItemTemplates.map((itemTemplate) => {
        let newItemTemplate = new ChecklistItemTemplateForSave();
        Object.keys(baseItemTemplate).forEach((key) => {
          newItemTemplate[key] = itemTemplate[key];
        });
        return newItemTemplate;
      });

      return { SortOrder: section.SortOrder, Title: section.Title, ChecklistItemTemplates: newChecklistItemTemplates };
    });

    const result = { ...this, ChecklistSectionTemplates: sections };
    result.Statuses.forEach((item, i) => (item.SortOrder = i));
    return result;
  };
}

export class CheckListTemplateView extends CheckListTemplateCreate implements ICreatedModifiedBase {
  Created = null;
  CreatedBy = null;
  CreatedById = null;
  Modified = null;
  ModifiedBy = null;
  ModifiedById = null;
  Projects = [];
  WorkTypes = [];
  InspectionCategories = [];

  constructor(value?: IChecklistTemplate) {
    super(value);
    this.Created = value.Created;
    this.CreatedBy = value.CreatedBy;
    this.CreatedById = value.CreatedById;
    this.Modified = value.Modified;
    this.ModifiedBy = value.ModifiedBy;
    this.ModifiedById = value.ModifiedById;
    this.InspectionCategories = value.InspectionCategories;
    this.Projects = value.Projects;
    this.WorkTypes = value.WorkTypes;
  }
}
