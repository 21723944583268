import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from '@pp/ui-components';
import { environment } from '../../environments/environment';
import { User } from '../../../../../src/app/services/users/users.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkPlanService {
  private apiUrl: string;
  private serviceUrl: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
    this.serviceUrl = environment.workPlanUrl;

    // environment.workPlanVersionsUrl
  }

  create(model: CreateWorkPlan) {
    return this.http.post(this.apiUrl + this.serviceUrl + '/Create', model, this.httpOptions);
  }

  get(id: string): Observable<{ Item: WorkPlan; Permissions: { CreateVersion: boolean; View: boolean } }> {
    return this.http.get(this.apiUrl + this.serviceUrl + '/Get' + `?id=${id}`).pipe(
      map((result: { Item: WorkPlan; Permissions: { CreateVersion: boolean; View: boolean } }) => {
        result.Item = new WorkPlan(result.Item);
        return result;
      })
    );
  }

  getAll() {
    return this.http.get(this.apiUrl + this.serviceUrl + '/GetAll');
  }

  getByWorkVolumeList(id: string) {
    return this.http.get(this.apiUrl + this.serviceUrl + `/GetByWorkVolumeList?id=${id}`);
  }

  getByWorkVolumeListPaged(id: string, page: number, pageSize: number) {
    return this.http.get(
      this.apiUrl + this.serviceUrl + `/GetByWorkVolumeListPaged?id=${id}&page=${page}&pageSize=${pageSize}`
    );
  }

  getAllPaged(page: number, pageSize: number) {
    return this.http.get(
      this.apiUrl + this.serviceUrl + `/GetAllPaged?page=${page}&pageSize=${pageSize}`,
      this.httpOptions
    );
  }

  update(model: EditWorkPlan) {
    return this.http.post(this.apiUrl + this.serviceUrl + '/Update', model, this.httpOptions);
  }

  public getProjectsMyForCreate() {
    return this.http.get(this.apiUrl + this.serviceUrl + 'GetProjectsMyForCreate').pipe(
      map((arr: any) => {
        for (const item of arr) {
          item.CodeTitle = item.Code ? item.Code + '. ' + item.Title : item.Title;
        }
        return arr;
      })
    );
  }

  ////// VERSIONS ----------------------------------------

  createVersion(model: { WorkCompletionPlanId: string }) {
    return this.http.post(this.apiUrl + environment.workPlanVersionsUrl + '/Create', model, this.httpOptions);
  }

  deleteVersion(id: string) {
    return this.http.delete(this.apiUrl + environment.workPlanVersionsUrl + '/Delete?id=' + id);
  }

  getVersion(id: string): Observable<{
    Item: IVersion;
    Permissions: { CreateApproval: boolean; UpdateVolumes: boolean; View: boolean; ImportVolumes: boolean };
  }> {
    return this.http
      .get(this.apiUrl + environment.workPlanVersionsUrl + `Get?id=${id}`)
      .pipe(map((res: { Item: IVersion; Permissions: any }) => res));
  }

  getAllVersions() {
    return this.http.get(this.apiUrl + environment.workPlanVersionsUrl + '/GetAll');
  }

  getAllPagedVersions(page: number, pageSize: number) {
    return this.http.get(
      this.apiUrl + environment.workPlanVersionsUrl + `/GetAllPaged?page=${page}&pageSize=${pageSize}`,
      this.httpOptions
    );
  }

  getAllByWorkCompletionPlan(id: string): Observable<IVersions[]> {
    return this.http
      .get(this.apiUrl + environment.workPlanVersionsUrl + 'GetAllByWorkCompletionPlan?workCompletionPlanId=' + id)
      .pipe(
        map((res: IVersions[]) => {
          res.map((x) => {
            x.Created = new DateTime(x.Created, false);
            x.Modified = new DateTime(x.Modified, false);
            x.Title = 'Версия №' + x.Number + ' от ' + x.Created.getDateAndTime();
            return x;
          });
          return res;
        })
      );
  }

  getAllPagedByWorkCompletionPlan(model: { id: string; page: number; pageSize: number }) {
    return this.http.post(
      this.apiUrl + environment.workPlanVersionsUrl + '/GetAllPagedByWorkCompletionPlanVersion',
      model,
      this.httpOptions
    );
  }

  ////// VERSIONS WORK COMPLETE ----------------------------------------

  deleteCompletionPlan(id: string) {
    return this.http.delete(this.apiUrl + environment.workCompletionPlanVersionVolumeUrl + '/Delete?id=' + id);
  }

  getCompletionPlan(id: string) {
    return this.http.get(this.apiUrl + environment.workCompletionPlanVersionVolumeUrl + `/Get?id=${id}`);
  }

  updateCompletionPlan(model: { Id: string; Volume: number }) {
    return this.http.post(
      this.apiUrl + environment.workCompletionPlanVersionVolumeUrl + `Update`,
      model,
      this.httpOptions
    );
  }

  getAllCompletionPlan() {
    return this.http.get(this.apiUrl + environment.workCompletionPlanVersionVolumeUrl + '/GetAll');
  }

  getAllPagedCompletionPlan(page: number, pageSize: number) {
    return this.http.get(
      this.apiUrl + environment.workCompletionPlanVersionVolumeUrl + `/GetAllPaged?page=${page}&pageSize=${pageSize}`,
      this.httpOptions
    );
  }

  getAllByWorkCompletionPlanVersion(id: string): Observable<IWorkVolume[]> {
    return this.http
      .get(
        this.apiUrl +
          environment.workCompletionPlanVersionVolumeUrl +
          'GetAllByWorkCompletionPlanVersion?workCompletionPlanVersionId=' +
          id
      )
      .pipe(map((res: IWorkVolume[]) => res));
  }

  getAllWorkVolumeListsByWorkCompletionPlanVersion(id: string): Observable<IWorkList[]> {
    return this.http
      .get(
        this.apiUrl +
          environment.workCompletionPlanVersionVolumeUrl +
          'GetAllWorkVolumeListsByWorkCompletionPlanVersion?workCompletionPlanVersionId=' +
          id
      )
      .pipe(
        map((res: IWorkList[]) => {
          res.map((x) => (x.customId = res.indexOf(x) + 1));
          return res;
        })
      );
  }

  getAllPagedByWorkCompletionPlanVersion(model: { id: string; page: number; pageSize: number }) {
    return this.http.post(
      this.apiUrl + environment.workCompletionPlanVersionVolumeUrl + '/GetAllPagedByWorkCompletionPlanVersion',
      model,
      this.httpOptions
    );
  }

  ////// APPROVAL VERSIONS WORK COMPLETE ----------------------------------------

  createApprovalVersion(model: {
    WorkCompletionPlanVersionId: string;
    Comment: string;
  }): Observable<{ Id: string; IsCuratorNotificationSent: boolean }> {
    return this.http
      .post(this.apiUrl + environment.WorkCompletionPlanVersionApprovalUrl + 'Create', model, this.httpOptions)
      .pipe(map((res: { Id: string; IsCuratorNotificationSent: boolean }) => res));
  }

  acceptApprovalVersion(model: { WorkCompletionPlanVersionApprovalId: string; Comment: string }) {
    return this.http.post(
      this.apiUrl + environment.WorkCompletionPlanVersionApprovalUrl + 'Accept',
      model,
      this.httpOptions
    );
  }

  rejectApprovalVersion(model: {
    WorkCompletionPlanVersionApprovalId: string;
    RejectReasonId: string;
    Comment: string;
  }) {
    return this.http.post(
      this.apiUrl + environment.WorkCompletionPlanVersionApprovalUrl + '/Reject',
      model,
      this.httpOptions
    );
  }

  getAllApprovalPlanVersion(id: string): Observable<any> {
    return this.http.get(
      this.apiUrl +
        environment.WorkCompletionPlanVersionApprovalUrl +
        'GetAllByWorkCompletionPlanVersion?workCompletionPlanVersionId=' +
        id
    );
  }

  getApprove(id: string): Observable<{
    Item: CompleteApprovalVersionPlan;
    Permissions: { View: boolean; Accept: boolean; Reject: boolean };
  }> {
    return this.http.get(this.apiUrl + environment.WorkCompletionPlanVersionApprovalUrl + `/Get?id=${id}`).pipe(
      map(
        (res: {
          Item: CompleteApprovalVersionPlan;
          Permissions: { View: boolean; Accept: boolean; Reject: boolean };
        }) => {
          return {
            Item: new CompleteApprovalVersionPlan(res.Item),
            Permissions: { View: res.Permissions.View, Accept: res.Permissions.Accept, Reject: res.Permissions.Reject },
          };
        }
      )
    );
  }

  getAllApprovalVersion() {
    return this.http.get(this.apiUrl + environment.WorkCompletionPlanVersionApprovalUrl + '/GetAll');
  }

  getAllPagedApprovalVersion(page: number, pageSize: number) {
    return this.http.get(
      this.apiUrl + environment.WorkCompletionPlanVersionApprovalUrl + `/GetAllPaged?page=${page}&pageSize=${pageSize}`,
      this.httpOptions
    );
  }

  public importFromExcel(
    WorkCompletionPlanVersionId: string,
    Data: string,
    UpdateByEmptyValue: boolean
  ): Observable<any> {
    return this.http.post(
      this.apiUrl + environment.workCompletionPlanVersionVolumeUrl + `Import`,
      { WorkCompletionPlanVersionId, Data, UpdateByEmptyValue },
      this.httpOptions
    );
  }
}

export class CreateWorkPlan {
  ProjectId: string;
  PeriodYear: number;
  PeriodMonth: number;
}

export class EditWorkPlan {
  Id: string;
  WorkVolumeListId: string;
  ReportDate: string;
  ActualVolume: number;
  DepartmentId: string;
}

export class WorkPlan {
  Code?: string;
  Created?: DateTime;
  CreatedBy?: User;
  CreatedById?: string;
  CurrentVersionApprovalCompletedDate?: any;
  CurrentVersionApprovalCurator?: any;
  CurrentVersionApprovalCuratorId?: string;
  CurrentVersionNumber?: number;
  Department?: { FullName: string; Id: string; ShortName: string };
  DepartmentId?: string;
  DepartmentResponsible?: User;
  DepartmentResponsibleId?: string;
  Id?: string;
  Modified?: DateTime;
  ModifiedBy?: User;
  ModifiedById?: string;
  PeriodEndDate?: DateTime;
  PeriodMonth?: number;
  PeriodStartDate?: DateTime;
  PeriodYear?: number;
  Project?: { Code: string; Id: string; Owner: null; ParentId: null; Title: string };
  ProjectId?: string;
  Status?: EWorkCompletionPlanStatus;
  StatusName: string;
  StatusColor: string | any;
  ObjectStructureTitle;

  constructor(workPlan?: WorkPlan | any) {
    Object.keys(workPlan).forEach((key) => {
      this[key] = workPlan[key];
    });
    this.createTime();
    this.StatusName = this.getWorkPlanStatusName(this.Status);
    this.StatusColor = this.getStatusColor(this.Status);
  }

  createTime(): void {
    if (this.Created) {
      this.Created = new DateTime(this.Created, false);
    }
    if (this.Modified) {
      this.Modified = new DateTime(this.Modified, false);
    }
    if (this.PeriodEndDate) {
      this.PeriodEndDate = new DateTime(this.PeriodEndDate, false);
    }
    if (this.PeriodStartDate) {
      this.PeriodStartDate = new DateTime(this.PeriodStartDate, false);
    }
  }

  getWorkPlanStatusName(status: EWorkCompletionPlanStatus) {
    switch (status) {
      case EWorkCompletionPlanStatus.Accepted:
        return 'Утверждено';
      case EWorkCompletionPlanStatus.Checking:
        return 'На проверке';
      case EWorkCompletionPlanStatus.Draft:
        return 'Черновик';
      case EWorkCompletionPlanStatus.Rejected:
        return 'Отклонено';
    }
  }

  getStatusColor(status: EWorkCompletionPlanStatus) {
    if (status === EWorkCompletionPlanStatus.Rejected) {
      return 'color-danger';
    } else if (status === EWorkCompletionPlanStatus.Accepted) {
      return 'color-success';
    } else if (status === EWorkCompletionPlanStatus.Checking) {
      return 'color-notice';
    } else {
      return null;
    }
  }
}

export class CompleteApprovalVersionPlan extends WorkPlan {
  RejectReason: any;
  RejectReasonId: string;

  constructor(item: CompleteApprovalVersionPlan) {
    super(item);
  }
}

export enum EWorkCompletionPlanStatus {
  /// Выполняется
  // [EnumMember(Value = "Черновик")]
  Draft = 0,

  /// Утверждено
  // [EnumMember(Value = "На проверке")]
  Checking = 1,

  /// Отклонено
  // [EnumMember(Value = "Утверждено")]
  Accepted = 2,

  /// Отменено
  // [EnumMember(Value = "Отклонено")]
  Rejected = 3,
}

/// Статус версии плана выполнения работ
export enum EWorkCompletionPlanVersionStatus {
  /// Выполняется
  // [EnumMember(Value = "Черновик")]
  Draft = 0,

  /// Утверждено
  // [EnumMember(Value = "На проверке")]
  Checking = 1,

  /// Отклонено
  // [EnumMember(Value = "Утверждено")]
  Accepted = 2,

  /// Отменено
  // [EnumMember(Value = "Отклонено")]
  Rejected = 3,
}

/// Статус согласования версии плана выполнения работ

export enum EWorkCompletionPlanVersionApprovalStatus {
  /// Выполняется
  InProgress = 0,

  /// Утверждено
  Accepted = 1,

  /// Отклонено
  Rejected = 2,
}

export interface IVersions {
  Created: DateTime;
  CreatedBy: string;
  CurrentApprovalCompletedDate: any;
  CurrentApprovalCurator: any;
  Id: string;
  Modified: DateTime;
  ModifiedBy: string;
  Number: number;
  Status: number;
  WorkCompletionPlanId: string;
  Title;
  StatusName;
  StatusColor;
}

export interface IVersion {
  Created: DateTime;
  CreatedBy: User;
  CreatedById: string;
  CurrentApprovalCompletedDate: any;
  CurrentApprovalCurator: any;
  CurrentApprovalCuratorId: any;
  Id: string;
  Modified: DateTime;
  ModifiedBy: User;
  ModifiedById: string;
  Number: number;
  Status: number;
  WorkCompletionPlanId: string;
}

export interface IWorkList {
  ActualWorkCompletionLogsActualVolume: number;
  BlueprintMarkId: string;
  BlueprintMarkTitle: string;
  Created: DateTime;
  CreatedBy: string;
  DepartmentId: string;
  DepartmentTitle: string;
  DocumentNumber: string;
  Id: string;
  MeasureUnitId: string;
  MeasureUnitTitle: string;
  Modified: DateTime;
  ModifiedBy: string;
  Name: string;
  ObjectStructureId: string;
  ObjectStructureTitle: string;
  ProjectId: string;
  ProjectTitle: string;
  Volume: number;
  WorkCode: string;
  WorkTypeId: string;
  WorkTypeTitle: string;
  customId;
}

export interface IWorkVolume {
  Created: DateTime;
  CreatedBy: string;
  Id: string;
  Modified: DateTime;
  ModifiedBy: string;
  Period: DateTime;
  Volume: number;
  WorkCompletionPlanVersionId: string;
  WorkVolumeListId: string;
}
