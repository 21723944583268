import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../quality-control/src/environments/environment';
import { downloadFile, makeLocalDate } from 'src/app/services';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class InspectionsService {
  private _apiUrl: string;
  private coreUrl: string;
  private _apiCoreUrl: string;
  private _serviceUrl: string;
  private _serviceODataUrl: string;
  private projectServiceUrl: string;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private _http: HttpClient,
    private auth: OAuthService
  ) {
    this._apiUrl = environment.apiUrl;
    this.coreUrl = environment.apiUrl;
    this._apiCoreUrl = environment.apiUrl;
    this._serviceUrl = environment.inspectionUrl;
    this._serviceODataUrl = environment.QCInspectionsODataUrl;
    this.projectServiceUrl = environment.projectsUrl;
  }

  static getODataUrl() {
    return environment.apiUrl + environment.QCInspectionsODataUrl + 'Inspections';
  }

  public getAll(): Observable<any> {
    return this._http.get(this._apiUrl + this._serviceUrl + 'GetAll');
  }

  public getById(id: string): Observable<any> {
    return this._http.get(this._apiUrl + this._serviceUrl + `/Get?id=${id}`).pipe(
      map((res: any) => {
        // сохраняем в объекте даты с оффсетом клиента
        // res.PlanDate не трогаем, т.к. он приходит правильно
        res.Item.EndDate = res.Item.EndDate ? makeLocalDate(res.Item.EndDate) : null;
        res.Item.Created = makeLocalDate(res.Item.Created);
        res.Item.Modified = makeLocalDate(res.Item.Modified);
        return res;
      })
    );
  }

  //** получить кол-во инспекций по фильтру */
  getODataFilteredInspectionsCount(filter: string = ''): Promise<number> {
    return this._http
      .get(this._apiUrl + this._serviceODataUrl + `Inspections?$count=true&$top=0&${filter}`)
      .toPromise()
      .then((res) => {
        return res['@odata.count'];
      });
  }

  public create(model: CreationInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Create`, model, this._httpOptions);
  }

  public createWithFiles(model: CreationInspection) {
    return this._http.post(this._apiUrl + this._serviceUrl + `/CreateWithFiles`, model, this._httpOptions);
  }

  public update(model: UpdatingInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Update`, model, this._httpOptions);
  }

  public delete(id: string): Observable<any> {
    return this._http.delete(this._apiUrl + this._serviceUrl + `/Delete?id=${id}`);
  }

  public accept(model: AcceptanceInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Accept`, model, this._httpOptions);
  }

  public confirm(model: ConfirmationInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Confirm`, model, this._httpOptions);
  }

  public reject(model: RejectInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Reject`, model, this._httpOptions);
  }

  public cancel(model: CancelInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Cancel`, model, this._httpOptions);
  }

  public declare(model: DeclaretingInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/Declare`, model, this._httpOptions);
  }

  public declareWithFiles(model: DeclaretingInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/DeclareWithFiles`, model, this._httpOptions);
  }

  public reDeclare(model: DeclaretingInspection): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/ReDeclare`, model, this._httpOptions);
  }

  public getRemarks(id: string): Observable<any> {
    return this._http.get(this._apiUrl + this._serviceUrl + `/GetAllRemarks?inspectionId=${id}`);
  }

  public addComment(model: AdditionalComment): Observable<any> {
    return this._http.post(this._apiUrl + this._serviceUrl + `/AddComment`, model, this._httpOptions);
  }

  public addFileToComment(model: File): Observable<any> {
    console.log('model', model);
    return this._http.post(this._apiUrl + this._serviceUrl + `/AddFileToComment`, model, this._httpOptions);
  }

  public deleteComment(commentId: string): Observable<any> {
    return this._http.delete(this._apiUrl + this._serviceUrl + `/DeleteComment?Id=${commentId}`);
  }

  public deleteFile(commentId: string): Observable<any> {
    return this._http.delete(this._apiUrl + this._serviceUrl + `/RemoveFile?Id=${commentId}`);
  }

  /** Запрос объектов по выбранному проекту */
  public getObjectStructures(id: string) {
    if (!id) {
      return null;
    }

    return this._http.get(`${this.coreUrl}${this.projectServiceUrl}GetObjectStructures?id=${id}`).pipe(
      map((x: any) => {
        for (const item of x) {
          item.CodeTitle = item.Code ? item.Code + '. ' + item.Title : item.Title;
        }
        return x;
      })
    );
  }

  public getCommentsPaged(remarkId, page, pageSize) {
    return this._http
      .get(
        this._apiUrl + this._serviceUrl + `GetCommentsPaged?inspectionId=${remarkId}&page=${page}&pageSize=${pageSize}`,
        this._httpOptions
      )
      .pipe(
        map((items: any) => {
          for (const file of items.Results) {
            if (file.Comment) {
              file.Comment.Files.images = [];
              file.Comment.Files.files = [];
              if (file.Comment.Files.length > 0) {
                for (const item of file.Comment.Files) {
                  const arrFileName = item.Name.split('.');
                  if (arrFileName.length > 0) {
                    const fileExt: string = arrFileName[arrFileName.length - 1];
                    // if (fileExt.toLowerCase() === 'jpg' || fileExt.toLowerCase() === 'png' || fileExt.toLowerCase() === 'jpeg' || fileExt.toLowerCase() === 'bmp') {
                    //   item.Type = 'img';
                    //   item.url = environment.apiUrl + environment.inspectionUrl + 'GetInspectionFile?fileId=' + item.Id;
                    //   item.previewUrl = environment.apiUrl + environment.inspectionUrl + 'GetInspectionFilePreviewRatio?fileId=' + item.Id;
                    //   file.Comment.Files.images.push(item);
                    // } else {
                    item.Type = 'file';
                    item.url =
                      environment.apiUrl + environment.inspectionUrl + 'GetInspectionCommentFile?fileId=' + item.Id;
                    file.Comment.Files.files.push(item);
                    // }
                  }
                }
              }
            }
          }
          return items;
        })
      );
  }

  public getHistoryPaged(remarkId, page, pageSize) {
    return this._http.get(
      this._apiUrl + this._serviceUrl + `GetHistoryPaged?inspectionId=${remarkId}&page=${page}&pageSize=${pageSize}`,
      this._httpOptions
    );
  }

  public getFilesPaged(remarkId, page, pageSize) {
    return this._http
      .get(
        this._apiUrl + this._serviceUrl + `GetFilesPaged?inspectionId=${remarkId}&page=${page}&pageSize=${pageSize}`,
        this._httpOptions
      )
      .pipe(
        map((items: any) => {
          for (const file of items.Results) {
            if (file.Comment) {
              file.Comment.Files.images = [];
              file.Comment.Files.files = [];
              if (file.Comment.Files.length > 0) {
                for (const item of file.Comment.Files) {
                  const arrFileName = item.Name.split('.');
                  if (arrFileName.length > 0) {
                    item.Type = 'file';
                    item.url =
                      environment.apiUrl + environment.inspectionUrl + 'GetInspectionCommentFile?fileId=' + item.Id;
                    file.Comment.Files.files.push(item);
                  }
                }
              }
            }
          }
          return items;
        })
      );
  }

  public getAllPaged(remarkId, page, pageSize) {
    return this._http
      .get(
        this._apiUrl +
          this._serviceUrl +
          `/GetAllCollabration?inspectionId=${remarkId}&page=${page}&pageSize=${pageSize}`,
        this._httpOptions
      )
      .pipe(
        map((items: any) => {
          for (const file of items.Results) {
            file.Created = makeLocalDate(file.Created);
            file.Modified = makeLocalDate(file.Modified);
            if (file.Comment) {
              if (file.Comment.Files.length > 0) {
                for (let item of file.Comment.Files) {
                  const arrFileName = item.Name.split('.');
                  if (arrFileName.length > 0) {
                    const fileExt: string = arrFileName[arrFileName.length - 1];
                    item.url = this._apiUrl + this._serviceUrl + 'GetInspectionFile?fileId=' + item.Id;
                    item.showAll = false;
                    if (
                      fileExt.toLowerCase() === 'jpg' ||
                      fileExt.toLowerCase() === 'png' ||
                      fileExt.toLowerCase() === 'jpeg' ||
                      fileExt.toLowerCase() === 'bmp'
                    ) {
                      item.Type = 'img';
                      item.urlPreview =
                        this._apiUrl +
                        this._serviceUrl +
                        'GetInspectionCommentFilePreviewRatio?fileId=' +
                        item.Id +
                        '&width=500&height=500';
                    } else {
                      item.Type = 'file';
                    }
                  }
                }
              }
            }
          }
          return items;
        })
      );
  }

  // public getProjects() {
  //   return this._http.get(this._apiCoreUrl + environment.projectsUrl + 'GetAll').map((x: any) => {
  //     for (const item of x.Items) {
  //       item.CodeTitle = item.Code ? item.Code + '. ' + item.Title : item.Title;
  //     }
  //     return x;
  //   });
  // }

  public getObjects() {
    return this._http.get(this._apiCoreUrl + environment.objectStructureUrl + 'GetAll').pipe(
      map((x: any) => {
        for (const item of x.Items) {
          item.CodeTitle = item.Code ? item.Code + '. ' + item.Title : item.Title;
        }
        return x;
      })
    );
  }

  public getDepartments() {
    return this._http.get(this._apiCoreUrl + environment.departmentsUrl + 'GetAll');
  }

  public getUserByDepartmentId(id?: string) {
    return id
      ? this._http.get(this._apiCoreUrl + environment.departmentsUrl + 'GetUserByDepartmentId', { params: { id } })
      : null;
  }

  public getUsersAsResponsible(id: string, projectId: string) {
    return this._http.get(this._apiCoreUrl + environment.departmentsUrl + 'GetUsersAsResponsible', {
      params: { id, projectId },
    });
  }

  public getWorkTypes() {
    return this._http.get(this._apiCoreUrl + environment.workTypesUrl + 'GetAll');
  }

  public getCurrentUserId() {
    return this._http.get(this._apiUrl + environment.usersUrl + 'GetMyID');
  }

  public getCategories() {
    return this._http.get(this._apiUrl + environment.inspectionCategoryUrl + 'GetAll');
  }

  public getCategoryById(id: string) {
    return this._http.get(this._apiUrl + environment.inspectionCategoryUrl + 'Get?Id=' + id);
  }

  public getMeasureUnits() {
    return this._http.get(this.coreUrl + environment.measureUnitsUrl + 'GetAll');
  }

  public getRejectReasons() {
    return this._http.get(this._apiUrl + environment.inspectionRejectReasonsUrl + 'GetAll');
  }

  public openItemImage(id) {
    const win = window.open(this._apiUrl + environment.checklistItemUrl + 'GetChecklistItemFile?id=' + id, '_blank');
    win.focus();
  }

  public openPrintFile(id) {
    downloadFile(this._apiUrl + environment.inspectionUrl + 'GetInspectionWord?id=' + id, this.auth.getAccessToken());
  }

  public itemTemplateNameForRemark: string;
}

export enum InspectionStatusEnum {
  Declared = 0,
  Planned = 1,
  Accepted = 2,
  Rejected = 3,
  Cancelled = 4,
}

export function getInspectionsStatuses() {
  return [
    {
      value: InspectionStatusEnum.Declared,
      title: $localize`Заявлена`,
      color: 'color-notice',
    },
    {
      value: InspectionStatusEnum.Planned,
      title: $localize`Запланирована`,
      color: 'color-base',
    },
    {
      value: InspectionStatusEnum.Accepted,
      title: $localize`Принята`,
      color: 'color-lightGray',
    },
    {
      value: InspectionStatusEnum.Rejected,
      title: $localize`Не принята`,
      color: 'color-lightGray',
    },
    {
      value: InspectionStatusEnum.Cancelled,
      title: $localize`Отменена`,
      color: 'color-lightGray',
    },
  ];
}

export function getInspectionStatusColor(status: InspectionStatusEnum) {
  const res = getInspectionsStatuses().find((stat) => stat.value == status);
  return res ? res.color : '';
}

export function getInspectionStatusView(status: InspectionStatusEnum) {
  const res = getInspectionsStatuses().find((stat) => stat.value == status);
  return res ? res.title : undefined;
}

export class ConfirmationInspection {
  InspectionId: string;
}

export class CreationInspection {
  DocumentNumber: string;
  WorkTypeId: string;
  WorkVolumeListId: string;
  BlueprintMarkId: string;
  ObjectStructureId: string;
  ProjectId: string;
  DepartmentId: string;
  ExecutorId: string;
  InspectionCategoryId: string;
  DeclaredVolume?: number;
  MeasureUnitId?: string;
  Description: string;
  CuratorId: string;
  PlanDate: Date;
  ChecklistTemplateIds?: string[];
  MainChecklistTemplateId?: string;
}

export class UpdatingInspection {
  Id: string;
  DepartmentId: string;
  ExecutorId: string;
  AcceptedVolume: number;
  Description: string;
  CuratorId: string;
  PlanDate: Date;
  InspectionCategoryId: string;
  BlueprintMarkId: string;
  DocumentNumber: string;
  WorkTypeId: string;
  MeasureUnitId?: string;
  DeclaredVolume?: number;
}

export class Inspection {
  CreatedById: string;
  ModifiedById: string;
  Created: Date;
  CreatedBy: {
    Company: any;
    DepartmentId: string;
    DisplayName: string;
    Email: string;
    ExternalId: number;
    Id: string;
    IsActive: true;
    MobilePhone: string;
    Position: any;
    Sid: string;
    WorkPhone: any;
  };
  Modified: Date;
  ModifiedBy: {
    Company: any;
    DepartmentId: string;
    DisplayName: string;
    Email: string;
    ExternalId: number;
    Id: string;
    IsActive: true;
    MobilePhone: string;
    Position: any;
    Sid: string;
    WorkPhone: any;
  };
  MeasureUnitTitle: string;
  Id: string;
  DocumentNumber: string;
  WorkTypeId: string;
  WorkVolumeListId: string;
  WorkType: any;
  BlueprintMarkId: string;
  BlueprintMark: any;
  ObjectStructure: any;
  ObjectStructureId: string;
  ProjectId: string;
  Project: any;
  Code: string;
  DepartmentId: string;
  Department: any;
  ExecutorId: string;
  Executor: any;
  InspectionCategoryId: string;
  InspectionCategory: {
    Name: string;
    Id: string;
    HasVolume: false;
    HasOpenedRemarks: false;
    HasOneStructureObject: false;
  };
  DeclaredVolume: number;
  AcceptedVolume: number;
  MeasureUnitId: string;
  MeasureUnit: any;
  Description: string;
  Curator: any;
  CuratorId: string;
  Status: InspectionStatusEnum;
  RejectReasonId: string;
  RejectReason: any;
  PlanDate: Date;
  EndDate: Date;
  File: any;
  StatusView: string;

  ChecklistTemplateIds?: string[];
  MainChecklistTemplateId?: string;

  constructor(options?) {
    for (const prop in options) {
      this[prop] = options[prop];
    }
    this.StatusView = Inspection.setStatusView(this.Status);

    if (options && options.ObjectStructure) {
      this.ObjectStructure.CodeTitle = options.ObjectStructure.Code
        ? options.ObjectStructure.Code + '. ' + options.ObjectStructure.Title
        : options.ObjectStructure.Title;
    }
  }

  static setStatusView(status: InspectionStatusEnum): string {
    switch (status) {
      case InspectionStatusEnum.Declared:
        return $localize`Заявлена`;
      case InspectionStatusEnum.Planned:
        return $localize`Запланирована`;
      case InspectionStatusEnum.Accepted:
        return $localize`Принята`;
      case InspectionStatusEnum.Rejected:
        return $localize`Не принята`;
      case InspectionStatusEnum.Cancelled:
        return $localize`Отменена`;
      default:
        return '';
    }
  }
}

export class AcceptanceInspection {
  InspectionId: string;
  AcceptedVolume: number;
  Comment: string;
}

export class RejectInspection {
  InspectionId: string;
  RejectReasonId: string;
  Comment: string;
}

export class CancelInspection extends RejectInspection {}

export class DeclaretingInspection {
  DocumentNumber: string;
  WorkTypeId: string;
  BlueprintMarkId: string;
  ObjectStructureId: string;
  ProjectId: string;
  DepartmentId: string;
  ExecutorId: string;
  InspectionCategoryId: string;
  DeclaredVolume?: number;
  MeasureUnitId?: string;
  Description: string;
  CuratorId: string;
  PlanDate: Date;
  ChecklistTemplateIds?: string[];
  MainChecklistTemplateId?: string;
}

export class AdditionalComment {
  InspectionId: string;
  Comment: string;
  FileName?: string;
  File?: string;
}

export class File {
  CommentId: string;
  FileName: string;
  File: string;
}
