<div class="content">
  <div class="content__section info">
    <h2>Возникла ошибка</h2>
    <div class="desc">
      Обновите страницу. Проверьте подключение. Попробуйте снова. Если ошибка повторяется, обратитесь к администратору.
    </div>
  </div>
  <div class="content__section icon">
    <img [src]="'assets/images/svg/question_lens.svg'">
  </div>
</div>
