import { NgModule, Pipe, PipeTransform } from '@angular/core';
import {
  getApproveResultColor,
  getApproveResultIcon,
  getApproveStatusView,
  getApproveTypeView,
} from 'projects/shared/entities/approve';
import { getTaskResultView, getTaskStatusView } from 'projects/shared/entities/task';

@Pipe({
  name: 'approveStatus',
})
export class ApproveStatusPipe implements PipeTransform {
  constructor() {}

  transform(value: number | null | undefined): string {
    return getApproveStatusView(value);
  }
}

@Pipe({
  name: 'approveResultIcon',
})
export class ApproveResultIconPipe implements PipeTransform {
  constructor() {}

  transform(value: number | null | undefined): string {
    return getApproveResultIcon(value);
  }
}

@Pipe({
  name: 'approveResultColorClass',
})
export class ApproveResultColorPipe implements PipeTransform {
  constructor() {}

  transform(value: number | string | null | undefined): string {
    return getApproveResultColor(+value);
  }
}

@Pipe({
  name: 'approveType',
})
export class ApproveTypePipe implements PipeTransform {
  constructor() {}

  transform(value: number | null | undefined): string {
    return getApproveTypeView(value);
  }
}

@Pipe({
  name: 'taskStatus',
})
export class TaskStatusPipe implements PipeTransform {
  constructor() {}

  transform(value: number | null | undefined): string {
    return getTaskStatusView(value);
  }
}

@Pipe({
  name: 'taskResult',
})
export class TaskResultPipe implements PipeTransform {
  constructor() {}

  transform(value: number | null | undefined): string {
    return getTaskResultView(value);
  }
}

@NgModule({
  declarations: [
    ApproveStatusPipe,
    ApproveTypePipe,
    TaskResultPipe,
    TaskStatusPipe,
    ApproveResultIconPipe,
    ApproveResultColorPipe,
  ],
  exports: [
    ApproveStatusPipe,
    ApproveTypePipe,
    TaskResultPipe,
    TaskStatusPipe,
    ApproveResultIconPipe,
    ApproveResultColorPipe,
  ],
})
export class SharedApprovePipesModule {}
