import { Component, Input } from '@angular/core';

@Component({
  selector: 'pp-ui-change-info',
  templateUrl: './change-info.component.html',
  styleUrls: ['./change-info.component.scss'],
})
export class ChangeInfoComponent implements IPPChangeInfo {
  @Input() createdDate: Date | string;
  @Input() createdBy: string;
  @Input() lastModifiedDate: Date | string;
  @Input() lastModifiedBy: string;
  @Input() position: 'left' | 'right' = 'left';

  constructor() {}
}

export interface IPPChangeInfo {
  createdDate: Date | string;
  createdBy: string;
  lastModifiedDate: Date | string;
  lastModifiedBy: string;
  position: 'left' | 'right';
}
