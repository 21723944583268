import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IPPButtonOnClick } from '../buttons/buttons.component';

export class ConfirmOptions {
  title?: string = null;
  contentTemplate?: TemplateRef<any>;
  message?: string = null;
  btnOkText?: string = $localize`OK`;
  btnOkType?: 'back' | 'danger' | 'default' | 'normal' | 'success' | 'orange' | 'yellow' = 'default';
  btnCancelText?: string = $localize`Отмена`;
  btnCancelType?: 'back' | 'danger' | 'default' | 'normal' | 'success' | 'orange' | 'yellow' = 'normal';
  dialogSize?: 'sm' | 'lg' = 'sm';

  constructor(options) {
    Object.keys(options).forEach((key) => {
      this[key] = options[key];
    });
  }
}

type btnClickCallback = (e?: IPPButtonOnClick) => void | Promise<any>;

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  private _requests$: Subject<{
    options: ConfirmOptions;
    confirm: btnClickCallback | null;
    reject: btnClickCallback | null;
    hiddenCallBack: Function;
  }> = new Subject();

  constructor() {}

  public confirm(
    options: ConfirmOptions,
    confirm: btnClickCallback | null,
    reject?: btnClickCallback | null,
    hiddenCallBack?: Function | null
  ): void {
    this._requests$.next({
      options: new ConfirmOptions(options),
      confirm,
      reject,
      hiddenCallBack,
    });
  }

  getObservable(): Observable<{
    options: ConfirmOptions;
    confirm: btnClickCallback;
    reject: btnClickCallback;
    hiddenCallBack: Function;
  }> {
    return this._requests$;
  }
}
