<dx-popup *ngIf="showPopup$ | async"
  [width]="300"
  [height]="300"
  [dragEnabled]="false"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="false"
  [visible]="showPopup$ | async"
  position="right bottom"
  [shading]="false"
  [wrapperAttr]="{id: 'downloadsPopup'}"
>
  <div *dxTemplate="let data of 'title'" class="header">
    <span class="header_title" i18n>Загрузки</span>
    <i class="pp-icon_outlined_clear pointer header_btn" (click)="close()"></i>
  </div>

  <dx-scroll-view>
    <ng-container *ngIf="statuses$ | async as statuses">
      <div *ngFor="let status of statuses" class="process-container m-top">
        <ng-template #remBtn>
          <i class="pp-icon_outlined_clear icon-sized pointer"
            (click)="cancel(status.status, status.id)"
          ></i>
        </ng-template>

        <div [ngSwitch]="status.status" class="process-tools">
          <ng-template ngSwitchCase="pending">
            <i class="pp-icon_filled_download icon-sized"></i>
            <span class="process-text" i18n>Файл скачивается {{status.progress + '%'}}</span>
            <ng-container [ngTemplateOutlet]="remBtn"></ng-container>
          </ng-template>

          <ng-template ngSwitchCase="start">
            <i class="pp-icon_filled_download icon-sized"></i>
            <span class="process-text" i18n>Подготовка к скачиванию файла</span>
            <ng-container [ngTemplateOutlet]="remBtn"></ng-container>
          </ng-template>

          <ng-template ngSwitchCase="done">
            <i class="pp-icon_outlined_download_done icon-sized icon-success"></i>
            <span class="process-text" i18n>Файл {{status.fileName}} скачан</span>
            <!-- <i class="pp-icon_filled_folder_open icon-sized"></i> -->
          </ng-template>

          <ng-template ngSwitchCase="error">
            <i class="pp-icon_outlined_alert_error_outline icon-sized icon-error"></i>
            <span class="process-text process-error" i18n>Ошибка загрузки файла. {{status.error}}</span>
          </ng-template>
        </div>

        <div class="p-bar m-top-5">
          <div *ngIf="status.status === 'start' else prog" class="p-bar_progress p-bar_start"></div>
          <ng-template #prog>
            <div class="p-bar_progress"
              [ngStyle]="{width: status.progress + '%'}"
              [ngClass]="{
                'p-bar_in-process': status.status === 'pending',
                'p-bar_complete': status.status === 'done',
                'p-bar_error': status.status === 'error'
              }">
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </dx-scroll-view>
</dx-popup>