<dx-tree-list #treeList [dataSource]="dataSource" [height]="hostRef.nativeElement?.offsetHeight" [width]="'100%'"
  [columns]="columns"
  keyExpr="Id"
  dataStructure="plain"
  parentIdExpr="ParentId"
  hasItemsExpr="hasItems"
  [rootValue]="null"
  [showColumnHeaders]="false"
  [scrolling]="{
    mode: 'virtual',
    renderAsync: true
  }"
  [paging]="{
    pageSize: 10
  }"
  [showBorders]="false"
  [showRowLines]="false"
  [showColumnLines]="false"
  (onContentReady)="setNoData($event)"
  (onRowExpanded)="addRowKey($event)"
  (onRowCollapsed)="deleteRowKey($event)"
>
  <!-- preloadEnabled: true, -->
  <dxo-column-chooser [enabled]="false"></dxo-column-chooser>

  <div *dxTemplate="let item of 'rowTemplate'">
    <div *ngIf="!item.data.isTilesRow; else projTpl">{{item.data.Name}}</div>
    <!-- <div *ngIf="!item.data.isTilesRow; else projTpl">{{item.data.Id}}</div> -->
    <ng-template #projTpl>
      <div class="tilesRow">
        <!-- tilesrow {{item.data.Id}} -->
        <div *ngFor="let proj of getProjects(item.data.ParentId)" class="tile" (click)="navigate(proj)">
          <!-- <div>{{proj.Id}}</div> -->
          <img #securedImg *ngIf="proj.ImageId; else imgPlaceholder" class="tile__img" [src]="getImgUrl(proj.ProjectId) | secureImg:(proj.ImageId) | async" id="{{proj.ImageId}}"/>

          <ng-template #imgPlaceholder>
            <div class="tile__img-placeholder__container">
              <img class="tile__img-placeholder" src="assets/images/svg/Project.svg" />
            </div>
          </ng-template>

          <div class="tile__text-container">
            <div class="tile__title">
              <i class="pp-icon_outlined_info" style="float: right" [id]="'tileTitleTooltipIcon_'+proj.Id"></i>
              {{proj.Name}}
            </div>
            <dx-tooltip [target]="'#tileTitleTooltipIcon_'+proj.Id" showEvent="mouseenter" hideEvent="mouseleave" [maxWidth]="'95vw'">
              <div *dxTemplate="let tooltipData of 'content'" class="align-left" style="white-space: break-spaces; overflow-wrap: break-word;">
                <div *ngIf="proj.Code"><span class="bold" i18n>Код проекта: </span>{{proj.Code}}</div>
                <div *ngIf="proj.FullTitle"><span class="bold" i18n>Полное наименование проекта: </span>{{proj.FullTitle}}</div>
                <div *ngIf="proj.Address"><span class="bold" i18n>Адрес: </span>{{proj.Address}}</div>
                <div *ngIf="proj.AdditionalInformation"><span class="bold" i18n>Примечание: </span>{{proj.AdditionalInformation}}</div>
              </div>
            </dx-tooltip>
            
            <div *ngIf="proj.Stage">
              <div class="tile__caption" i18n>Стадия проекта:</div>
              <div class="tile__text">{{proj.Stage}}</div>
            </div>
            <div *ngIf="proj.Scale">
              <div class="tile__caption" i18n>Масштаб:</div>
              <div class="tile__text">{{proj.Scale}}</div>
            </div>
            <div *ngIf="proj.Priority">
              <div class="tile__caption" i18n>Приоритет:</div>
              <div class="tile__text">{{proj.Priority}}</div>
            </div>
            <div *ngIf="proj.Owner">
              <div class="tile__caption" i18n>Руководитель проекта:</div>
              <div class="tile__text">{{proj.Owner}}</div>
            </div>
            <div *ngIf="proj.Location">
              <div class="tile__caption" i18n>Локация:</div>
              <div class="tile__text">{{proj.Location}}</div>
            </div>
            <div *ngIf="proj.Type">
              <div class="tile__caption" i18n>Тип проекта:</div>
              <div class="tile__text">{{proj.Type}}</div>
            </div>
            <div *ngIf="proj.StartDate">
              <div class="tile__caption" i18n>Дата начала:</div>
              <div class="tile__text">{{proj.StartDate | date:'shortDate'}}</div>
            </div>
            <div *ngIf="proj.EndDate">
              <div class="tile__caption" i18n>Дата завершения:</div>
              <div class="tile__text">{{proj.EndDate | date:'shortDate'}}</div>
            </div>
          </div>

        </div>
      </div>
    </ng-template>
  </div>
</dx-tree-list>

<ng-template #noDataTemplate>
  <div>
    <img class="nodata-img" src="assets/images/svg/Project.svg" />
    <div i18n>Нет доступных проектов<br>Обратитесь к администратору для получения более подробной информации</div>
  </div>
</ng-template>